import { Maker } from "../../../redux/api/makers/types";
import {
  MAKER_DESCRIPTION,
  MAKER_FEATURE_IMAGE,
  MAKER_LOGO,
  MAKER_NAME,
  MAKER_WEBSITE,
} from "./fieldNames";

export interface MakerValues {
  [MAKER_NAME]: string;
  [MAKER_DESCRIPTION]: string;
  [MAKER_WEBSITE]?: string;
  [MAKER_LOGO]: string;
  [MAKER_FEATURE_IMAGE]: string;
}

/**
 * Determines what values should populate the initial form state
 * @param maker an optional maker (for updating existing makers)
 * @returns
 */
export const initMakerValues = (
  maker?: Maker | null | undefined
): MakerValues => {
  const values = {
    [MAKER_NAME]: maker?.name ?? "",
    [MAKER_WEBSITE]: maker?.websiteUrl,
    [MAKER_DESCRIPTION]: maker?.description ?? "",
    [MAKER_LOGO]: maker?.logoUrl ?? "",
    [MAKER_FEATURE_IMAGE]: maker?.featureImageUrl ?? "",
  };

  return values;
};
