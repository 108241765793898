import { useState } from "react";
import { useAuthentication } from "../../hooks/useAuthentication";

import ModalContainer from ".";
import { useOpenModalCount } from "../../redux/state/modals/hooks";
import { ModalType } from "../../redux/state/modals/types";
import LoginForm from "../Forms/LoginForm";
import RegisterForm from "../Forms/RegisterForm";
import { Logo } from "../Logo";

const LoginModal = () => {
  const openModalCount = useOpenModalCount(ModalType.LOGIN);

  const { handleGoogleSignIn, handleFacebookSignIn } = useAuthentication();

  const [showRegisterForm, setShowRegisterForm] = useState(false);

  return (
    <ModalContainer
      type={ModalType.LOGIN}
      index={openModalCount + 1}
      dismissible={true}
    >
      <div className="w-[700px] max-w-full">
        <div className="flex w-full flex-col p-4 sm:flex-row sm:p-8">
          <div className="flex flex-grow flex-col items-center space-y-4">
            <div className="w-28">
              <Logo style={"color"} />
            </div>
            {showRegisterForm ? <RegisterForm /> : <LoginForm />}
            <p className="mt-4 text-center text-sm text-stone-800">
              {showRegisterForm
                ? "Already have an account? "
                : "Don't have an account? "}
              <button
                className="fancy-underline relative text-teal-500 transition-colors after:h-[1px] after:bg-teal-500 hover:text-teal-600 hover:after:bg-teal-600"
                onClick={() => setShowRegisterForm(!showRegisterForm)}
              >
                {showRegisterForm ? "Login" : "Create one"}
              </button>
            </p>
          </div>
          <div className="my-4 sm:mx-4 sm:my-0">
            <div className="flex h-full flex-row items-center px-4 before:h-full before:w-full before:border before:border-stone-300 after:h-full after:w-full after:border after:border-stone-300 sm:flex-col sm:px-0 sm:py-4 sm:before:w-0 sm:after:w-0">
              <p className="m-2 flex aspect-square h-8 w-8 items-center justify-center rounded-full border-[1.5px] border-stone-300 p-0.5 text-stone-600">
                or
              </p>
            </div>
          </div>
          <div className="flex min-h-full min-w-fit flex-shrink flex-col items-center justify-center space-y-4">
            <button
              className="flex h-10 w-full items-center gap-2 rounded-md bg-white px-4 py-2 font-bold text-gray-900 shadow-md hover:bg-gray-50"
              onClick={async () => await handleGoogleSignIn()}
            >
              <i
                className="fab fa-google fa-fw fa-lg google"
                style={{ lineHeight: "40px" }}
              />
              <span className="inline-block min-w-fit">Login with Google</span>
            </button>
            <button
              className="flex h-10 w-full items-center gap-2 rounded-md bg-[#4267B2] px-4 py-2 font-bold text-white shadow-md hover:bg-blue-900"
              onClick={async () => await handleFacebookSignIn()}
            >
              <i className="fab fa-facebook fa-fw fa-lg" />
              <span className="inline-block min-w-fit">
                Login with Facebook
              </span>
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default LoginModal;
