import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type ContextState = {
  stripe: Stripe | null;
};

const StripeContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: ReactNode;
}

const StripeProvider: FC<Props> = ({ children }: Props) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

  const state: ContextState = {
    stripe: stripe,
  };

  async function _loadStripe() {
    if (!publishableKey) {
      throw new Error("REACT_APP_STRIPE_PUBLISHABLE_KEY is not defined");
    }

    const stripe = await loadStripe(publishableKey);

    setStripe(stripe);
  }

  useEffect(() => {
    _loadStripe();
  }, []);

  return (
    <StripeContext.Provider value={state}>{children}</StripeContext.Provider>
  );
};

function useStripe() {
  const context = useContext(StripeContext);

  if (!context) {
    throw new Error("useStripe must be used within a StripeProvider");
  }

  return context;
}

export { StripeProvider, useStripe };
