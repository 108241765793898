import { CirclePlus } from "@styled-icons/fa-solid";
import { ArrowReset, Save } from "@styled-icons/fluentui-system-filled";
import { Close } from "@styled-icons/ionicons-solid";
import {
  Field,
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
} from "formik";
import _ from "lodash";
import { useState } from "react";
import { toFriendlyCase } from "../../../helpers/StringHelpers";
import { useRetrieveMakersQuery } from "../../../redux/api/makers/api";
import {
  useCreateOfferingMutation,
  useRetrieveOfferingQuery,
  useUpdateOfferingMutation,
} from "../../../redux/api/offerings/api";
import {
  resetOfferings,
  unshiftOfferings,
} from "../../../redux/api/offerings/reducer";
import {
  AllOfferingTypes,
  AllTransitDistances,
  Offering,
  TaxBehavior,
  TransitDistance,
  TransitDistanceLabels,
} from "../../../redux/api/offerings/types";
import { useAppDispatch } from "../../../redux/hooks";
import { usePopModal } from "../../../redux/state/modals/hooks";
import { ModalType } from "../../../redux/state/modals/types";
import {
  FormikAdminInput,
  FormikSelect,
  FormikTextArea,
  FormikToggle,
} from "../../Formik";
import ImageUploader from "../../Formik/ImageUploadInput";
import Loader from "../../Loader";
import Spinner from "../../Spinner";
import TransitDistanceIcon from "../../TransitDistanceIcon";
import {
  OFFERING_MAKER_ID,
  OFFERING_NAME,
  OFFERING_OPTIONS,
  OFFERING_TAX_BEHAVIOR,
  OFFERING_TRANSIT_DESCRIPTION,
  OFFERING_TRANSIT_DISTANCE,
  OFFERING_TYPE,
  OPTION_ACTIVE,
  OPTION_AMOUNT,
  OPTION_AVAILABLE_INVENTORY,
  OPTION_DESCRIPTION,
  OPTION_HAS_HERB_OF_THE_WEEK,
  OPTION_HAS_SUBSCRIPTION,
  OPTION_ID,
  OPTION_IMAGE,
  OPTION_NAME,
  OPTION_PRICE,
} from "./fieldNames";
import {
  OfferingValues,
  OptionValues,
  initialOfferingValues,
  initialOptionValues,
} from "./initialValues";
import { OfferingValidationSchema } from "./validationSchema";

interface Props {
  readonly offeringId: string | null;
}

const UpsertOfferingForm = ({ offeringId }: Props) => {
  const [_selectedIndex, _setSelectedIndex] = useState<number>(0);
  const [_deletedOptionIds, _setDeletedOptionIds] = useState<string[]>([]);

  const popUpsertOfferingModal = usePopModal(ModalType.OFFERING_CRUD);

  const dispatch = useAppDispatch();

  const [triggerUpdateOffering] = useUpdateOfferingMutation();
  const [triggerCreateOffering] = useCreateOfferingMutation();

  const {
    data: offering = null,
    isLoading: isLoadingOffering,
    isFetching: isFetchingOffering,
  } = useRetrieveOfferingQuery(
    {
      id: offeringId ?? "",
      active: false,
    },
    {
      skip: !offeringId,
    }
  );

  const {
    data: makers = [],
    isLoading: isLoadingMakers,
    isFetching: isFetchingMakers,
  } = useRetrieveMakersQuery({}, {});

  const makerData = makers.map((maker) => ({
    value: maker.id,
    label: maker.name,
  }));

  const loading = isLoadingOffering || isFetchingOffering;

  const offeringTaxBehavior = offering?.taxBehavior ?? TaxBehavior.Unspecified;

  function handleSelectOption(index: number) {
    _setSelectedIndex(index);
  }

  function handleAddOption(arrayHelpers: FieldArrayRenderProps) {
    arrayHelpers.push(initialOptionValues);
    _setSelectedIndex(arrayHelpers.form.values.options.length);
  }

  function handleDeleteOption(
    arrayHelpers: FieldArrayRenderProps,
    deleteIndex: number
  ) {
    const { form } = arrayHelpers;

    const numOptions = form.values.options.length;
    // must always have at least one option
    if (numOptions === 1) {
      return;
    }

    // logic for determining which tab index to set as active
    if (_selectedIndex === deleteIndex) {
      _setSelectedIndex(Math.max(deleteIndex - 1, 0));
    } else {
      _setSelectedIndex(
        Math.min(Math.max(_selectedIndex - 1, 0), numOptions - 1)
      );
    }

    _setDeletedOptionIds([
      ..._deletedOptionIds,
      form.values["options"][deleteIndex].id,
    ]);

    arrayHelpers.remove(deleteIndex);
  }

  /**
   * Handle form submission
   * @param values
   * @param formikHelpers
   */
  async function handleSubmit(
    values: OfferingValues,
    formikHelpers: FormikHelpers<OfferingValues>
  ) {
    if (offering) {
      // otherwise, update the existing offering
      await UpdateOffering(offering, values, formikHelpers);
    } else {
      // if no offering was provided, create an offering
      await CreateOffering(values, formikHelpers);
    }
  }

  /**
   * Create a new offering
   * @param values
   * @param formikHelpers
   */
  async function CreateOffering(
    values: OfferingValues,
    formikHelpers: FormikHelpers<OfferingValues>
  ) {
    try {
      let res = await triggerCreateOffering(values).unwrap();
      res.options = res.options.map((o) => {
        o.image += `?${Date.now().toString()}`;
        return o;
      });
      dispatch(unshiftOfferings([res]));
      formikHelpers.resetForm();
      _setSelectedIndex(0);
      popUpsertOfferingModal();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Update an existing offering
   * @param offering
   * @param values
   * @param formikHelpers
   */
  async function UpdateOffering(
    offering: Offering,
    values: OfferingValues,
    formikHelpers: FormikHelpers<OfferingValues>
  ) {
    const updatePayload = deepDiff(initialOfferingValues(offering), values);

    if (updatePayload.options?.length === 0) {
      delete updatePayload.options;
    }

    if (_deletedOptionIds.length > 0) {
      updatePayload["deleteOptions"] = _deletedOptionIds;
    }

    // remove the active property from the payload
    // TODO: figure out why the property is getting appended, deepdiff?
    if ("active" in updatePayload) {
      delete updatePayload["active"];
    }

    try {
      const updatedOffering = await triggerUpdateOffering({
        id: offeringId,
        body: updatePayload,
      }).unwrap();

      if (updatedOffering) {
        dispatch(resetOfferings());
        popUpsertOfferingModal();
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  const deepDiff = (obj1: any, obj2: any): any => {
    const diff: { [key: string]: any } = {};

    _.forEach(obj1, (value, key: any) => {
      if (key === OPTION_ID) {
        // if an id is present in obj1, make sure it is copied to the diff
        diff[key] = value;
      } else if (!_.isEqual(value, obj2[key])) {
        if (_.isObject(value) && _.isObject(obj2[key])) {
          const nestedDiff = deepDiff(value, obj2[key]);
          if (!_.isEmpty(nestedDiff)) {
            diff[key] = nestedDiff;
          }
        } else {
          diff[key] = obj2[key];
        }
      }
    });

    _.forEach(obj2, (value, key) => {
      if (!obj1.hasOwnProperty(key)) {
        if (_.isArray(value)) {
          diff[key] = `[${value}]`;
        } else {
          diff[key] = value;
        }
      }
    });

    // the options property needs to be an array of defined objects, not an object
    if (diff.options) {
      diff.options = _.toArray(diff.options).filter((o) => o !== undefined);
    }

    return diff;
  };

  function handleResetForm(formikProps: FormikProps<OfferingValues>) {
    console.log("State before", formikProps.values);
    formikProps.resetForm();
    console.log("State after", formikProps.values);
    console.log();
    _setSelectedIndex(0);
  }

  return loading ? (
    <div className="m-auto h-24 w-44">
      <Loader />
      <div className="">
        <p className="text-center">Loading...</p>
      </div>
    </div>
  ) : (
    <div className="w-fit max-w-full">
      <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 sm:mb-5 dark:border-gray-600">
        <h1 className="text-lg font-semibold text-gray-900 dark:text-white">
          {!!!offering ? (
            <span>Create Offering</span>
          ) : (
            <span>Update Offering</span>
          )}
        </h1>
      </div>

      <Formik
        initialValues={initialOfferingValues(offering)}
        onSubmit={handleSubmit}
        validationSchema={OfferingValidationSchema}
      >
        {(props) => (
          <Form
            className="my-1 flex w-full flex-col items-center space-y-4"
            onSubmit={(e) => {
              e.preventDefault();
              props.validateForm();
              props.handleSubmit();
            }}
            noValidate
          >
            <div className="flex flex-col gap-4">
              {/* Offering Section */}
              <div className="w-full">
                <div className="mb-4 flex w-full flex-col gap-2">
                  <div className="flex gap-2">
                    {/* Offering Type */}
                    <Field
                      name={OFFERING_TYPE}
                      title="Type"
                      component={FormikSelect}
                    >
                      <option value="" disabled>
                        Offering Type
                      </option>
                      {AllOfferingTypes.map((type) => (
                        <option key={type} value={type}>
                          {toFriendlyCase(type)}
                        </option>
                      ))}
                    </Field>
                    {/* Offering Tax Behavior */}
                    <Field
                      name={OFFERING_TAX_BEHAVIOR}
                      title="Tax Behavior"
                      component={FormikSelect}
                      disabled={offeringTaxBehavior !== TaxBehavior.Unspecified}
                    >
                      {Object.keys(TaxBehavior).map((type) => (
                        <option key={type} value={type.toLowerCase()}>
                          {type}
                        </option>
                      ))}
                    </Field>
                  </div>
                  {/* Offering Name */}
                  <Field
                    type="text"
                    name={OFFERING_NAME}
                    title="Name"
                    placeholder="Offering name"
                    component={FormikAdminInput}
                  />
                </div>
                {/* Transit Distances */}
                <div className="mb-4 flex flex-col items-start justify-center space-y-2">
                  <label className="block text-sm text-zinc-600 dark:text-zinc-200">
                    Transit Distance
                  </label>
                  <ul className="flex flex-wrap gap-2" role="group">
                    {AllTransitDistances.map((distance) => (
                      <li key={distance} className="flex">
                        <Field
                          type="radio"
                          id={distance}
                          name={OFFERING_TRANSIT_DISTANCE}
                          value={distance}
                          className="peer hidden [&:checked_+_label_#icon]:flex"
                        />
                        <label
                          htmlFor={distance}
                          className="flex h-[95px] w-[75px] cursor-pointer flex-col items-center justify-between rounded-lg border border-zinc-400 bg-white px-2 py-0.5 pb-2 text-zinc-600 hover:bg-zinc-100 hover:text-zinc-700 peer-checked:border-teal-600 peer-checked:text-teal-600 peer-checked:ring-1 peer-checked:ring-teal-600/40"
                        >
                          <p className="my-1 flex h-full max-h-10 w-10 items-center justify-center">
                            <TransitDistanceIcon distance={distance} />
                          </p>
                          <div className="w-full flex-col leading-none">
                            <p className="text-sm font-semibold capitalize">
                              {distance}
                            </p>
                            <p className="text-xs leading-none text-zinc-500">
                              {TransitDistanceLabels[distance]}
                            </p>
                          </div>
                        </label>
                      </li>
                    ))}
                  </ul>
                  {props.touched["transitDistance"] &&
                    props.errors["transitDistance"] && (
                      <div className="ml-1 mt-1 text-sm text-red-600">
                        {props.errors["transitDistance"]?.toLocaleString()}
                      </div>
                    )}
                </div>
                {/* Transit Description */}
                {props.values.transitDistance !== TransitDistance.None && (
                  <div className="mb-4 flex w-full gap-2">
                    <Field
                      rows={2}
                      type="textarea"
                      name={OFFERING_TRANSIT_DESCRIPTION}
                      title="Transit Description"
                      placeholder="Offering transit description"
                      component={FormikTextArea}
                    />
                  </div>
                )}

                {/* Maker ID */}
                <div className="mb-4 flex w-full gap-2">
                  <Field
                    name={OFFERING_MAKER_ID}
                    title="Maker"
                    component={FormikSelect}
                  >
                    <option value="" disabled>
                      Maker
                    </option>
                    {makerData.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              {/* Offering Options */}
              <div className="w-full">
                <FieldArray
                  name={OFFERING_OPTIONS}
                  render={(arrayHelpers) => (
                    <section className="w-full">
                      {/* Offering Options */}
                      <ul className="hide-scroll relative flex w-full overflow-x-scroll border-2 border-none text-center">
                        {props.values.options.map((option, index) => {
                          return (
                            <li
                              className={`relative inline-flex min-w-fit cursor-pointer items-center space-x-2 rounded-tl-md rounded-tr-md border-[1px] border-gray-300  p-4 text-sm ${
                                index === _selectedIndex
                                  ? "border-b-4 border-b-green-500 bg-gray-200 text-slate-700 shadow-lg"
                                  : "font-medium border-b-2 border-b-gray-500 bg-gray-300 p-4 text-sm text-gray-500 ring-0 ring-inset ring-white"
                              }`}
                              onClick={() => handleSelectOption(index)}
                              key={index}
                            >
                              <span className="text-base">
                                {option.name === ""
                                  ? "New Option"
                                  : option.name}
                              </span>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteOption(arrayHelpers, index);
                                }}
                              >
                                <Close className="icon rounded-full text-zinc-400 hover:text-red-800" />
                              </button>
                            </li>
                          );
                        })}
                        {/* Add Option Button */}
                        <li className="absolute right-3 ml-2 inline-flex translate-y-1/2 rounded-full shadow-lg">
                          <button
                            type="button"
                            onClick={(e) => {
                              handleAddOption(arrayHelpers);
                            }}
                          >
                            <CirclePlus className="icon text-green-600" />
                          </button>
                        </li>
                      </ul>
                      {props.values.options.map((option, index) => {
                        return (
                          <div
                            key={index}
                            className={`w-full border-2 border-gray-200 bg-gray-100 p-4 ${
                              index !== _selectedIndex && "hidden"
                            }`}
                          >
                            <OfferingOptionForm
                              index={index}
                              option={option}
                              arrayHelpers={arrayHelpers}
                            />
                          </div>
                        );
                      })}
                    </section>
                  )}
                />
              </div>
            </div>

            <div className="my-2 flex w-full items-center justify-between">
              <div className={`flex flex-1 justify-end space-x-2`}>
                <div className="flex flex-grow items-center justify-start space-x-2">
                  <button
                    type="submit"
                    className={`relative flex cursor-pointer items-center justify-center space-x-2 rounded-lg bg-teal-600 p-2 text-white transition-colors hover:bg-teal-700 ${
                      !props.dirty && "hidden"
                    }`}
                  >
                    {props.isSubmitting ? (
                      <>
                        <Spinner />
                        <span>Saving...</span>
                      </>
                    ) : (
                      <>
                        <Save className="h-5" />
                        <span>Save</span>
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    className={`relative flex cursor-pointer items-center justify-center space-x-2 rounded-lg border bg-gray-50 p-2 transition-colors hover:bg-gray-100 ${
                      (!props.dirty || offering === null) && "hidden"
                    }`}
                    onClick={() => handleResetForm(props)}
                  >
                    <ArrowReset className="h-5" />
                    <span>Discard</span>
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {offering && (
        <div className="w-full">
          <div className="mt-4 flex w-full flex-col items-center justify-between text-sm sm:flex-row">
            <span>
              <b>ID:&nbsp;</b>
              {offering.id}
            </span>
            <p className="space-x-2">
              <span>
                <b>Created:&nbsp;</b>
                {new Date(offering.createdOn).toLocaleDateString()}
              </span>
              <span>
                <b>Updated:&nbsp;</b>
                {new Date(offering.updatedOn).toLocaleDateString()}
              </span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

interface OptionProps {
  option?: OptionValues;
  index: number;
  arrayHelpers: FieldArrayRenderProps;
}

const OfferingOptionForm = ({ option, index, arrayHelpers }: OptionProps) => {
  const withIndex = (optionFieldName: string) =>
    `${OFFERING_OPTIONS}.${index}.${optionFieldName}`;

  return (
    <div className="flex gap-4">
      <div className="mb-4 flex w-full flex-col gap-2">
        {/* Option Name */}
        <Field
          type="text"
          name={withIndex(OPTION_NAME)}
          title="Unit Name"
          placeholder="New option"
          component={FormikAdminInput}
        />
        <div className="flex min-w-fit flex-col">
          <ImageUploader formikFieldName={withIndex(OPTION_IMAGE)} />
        </div>
        <div className="mb-4 flex h-full w-full flex-1 gap-2">
          {/* Option Description */}
          <Field
            type="textarea"
            rows={2}
            name={withIndex(OPTION_DESCRIPTION)}
            title="Description"
            placeholder="Option description"
            component={FormikTextArea}
          />
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="mb-4 flex w-full flex-1 flex-col gap-1">
            {/* Option Amount */}
            <Field
              type="number"
              name={withIndex(OPTION_AMOUNT)}
              title="Order Limit"
              placeholder="0"
              component={FormikAdminInput}
            />
            {/* Option Inventory */}
            <Field
              type="number"
              name={withIndex(OPTION_AVAILABLE_INVENTORY)}
              title="Available Inventory"
              placeholder="0"
              component={FormikAdminInput}
            />
            {/* Option Price */}
            <Field
              type="number"
              name={withIndex(OPTION_PRICE)}
              title="Unit Price"
              placeholder="0.00"
              step="0.01"
              component={FormikAdminInput}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex h-full w-fit min-w-fit flex-col lg:flex-row lg:gap-8">
              <div className="mb-4 flex flex-col items-start gap-4">
                <label className="block w-fit text-sm text-zinc-600 dark:text-zinc-300">
                  Settings
                </label>
                {[
                  { fieldName: OPTION_ACTIVE, displayName: "Active" },
                  {
                    fieldName: OPTION_HAS_HERB_OF_THE_WEEK,
                    displayName: (
                      <span>
                        <i>Herb of the Week</i> Eligible
                      </span>
                    ),
                  },
                  {
                    fieldName: OPTION_HAS_SUBSCRIPTION,
                    displayName: "Subscription",
                  },
                ].map(({ fieldName, displayName }) => (
                  <label
                    htmlFor={withIndex(fieldName)}
                    className="flex cursor-pointer items-center gap-2 text-sm tracking-wide"
                  >
                    <Field
                      type="checkbox"
                      id={withIndex(fieldName)}
                      name={withIndex(fieldName)}
                      className="h-5 w-5 cursor-pointer accent-teal-600"
                      onClick={() => {
                        arrayHelpers.form.setFieldValue(
                          fieldName,
                          !arrayHelpers.form.getFieldProps(withIndex(fieldName))
                            .value
                        );
                      }}
                      component={FormikToggle}
                    />
                    <span>{displayName}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpsertOfferingForm;
