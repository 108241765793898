import { RefObject, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import { RowData } from ".";
import { useOutsideClick } from "../../hooks/useOutsideClick";

interface RowProps {
  id: string; // a unique identifier for the row
  data: RowData;
  index: number;
  openRow: number | null;
  setOpenRow: React.Dispatch<number | null>;
  tableRef: RefObject<HTMLDivElement>;
}

const TableRow = ({
  id,
  data,
  index,
  openRow,
  setOpenRow,
  tableRef,
}: RowProps) => {
  const shouldOpen = index === openRow && data.children !== undefined;
  const [isOpen, setIsOpen] = useState<boolean>(shouldOpen);

  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    if (searchParams.get("id")) {
      const idParam = searchParams.get("id");
      if (idParam) {
        setOpenRow(0);
      }
    }
  }, [searchParams]);

  useOutsideClick([tableRef], () => {
    setOpenRow(null);
    setIsOpen(false);
  });

  useEffect(() => {
    setIsOpen(shouldOpen);
  }, [openRow]);

  function handleClickHeader() {
    setIsOpen(!isOpen);
    if (openRow === index) {
      setOpenRow(null);
    } else if (data.children) {
      setOpenRow(index);
    }
  }

  const transition = useTransition(true, {
    config: {
      mass: 1,
      tension: 120,
      friction: 14,
    },
    from: {
      opacity: 0.3,
    },
    enter: {
      opacity: 1,
    },
  });

  return (
    <div
      className={`flex flex-col border-l-[1px] border-r-[1px] bg-white hover:cursor-pointer hover:bg-zinc-50 dark:border-zinc-900 dark:bg-zinc-600 dark:text-zinc-50 dark:hover:bg-zinc-500 ${
        shouldOpen &&
        "shadow-tr scale-[1.01] rounded-md border-[1px] hover:!bg-white dark:hover:!bg-zinc-600"
      }`}
    >
      <div
        className={`flex border-b-[1px] border-b-zinc-200 py-4 px-2 text-sm dark:border-b-zinc-700 ${
          shouldOpen && "rounded-tl-md rounded-tr-md only:border-b-0"
        } ${shouldOpen && !data.children && "border-none"}`}
        onClick={handleClickHeader}
      >
        {transition((spring) =>
          data.colValues.map((colVal) => (
            <animated.div
              style={spring}
              className="mx-1 flex flex-1 items-center break-all text-left"
              key={colVal.key}
            >
              {colVal}
            </animated.div>
          ))
        )}
      </div>

      {shouldOpen && (
        <div className="hover:cursor-default">{data.children}</div>
      )}
    </div>
  );
};

export default TableRow;
