import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../..";
import { Maker } from "./types";

export const makeSelectMakerById = () => {
  return createSelector(
    (state: AppState) =>
      state.makersApi.queries["retrieveMakers({})"]?.data as
        | Maker[]
        | undefined,
    (_: AppState, id: string | null) => ({ id }),
    (data: Maker[] | undefined, { id }) =>
      data?.find((maker) => maker.id === id) || null
  );
};
