import {
  Grid,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import DashboardSection from "../../../components/DashboardSection";
import HerbOfTheWeek from "./HerbOfTheWeek";
import SearchOfferings from "./SearchOfferings";
import ShippingRates from "./ShippingRates";

interface IOfferingsProps {}

const Offerings = ({}: IOfferingsProps) => {
  return (
    <DashboardSection title={""} order={0} loading={false}>
      <Grid numItems={1} className="gap-6">
        <TabGroup className="space-y-4">
          <TabList>
            <Tab>Herb of the Week</Tab>
            <Tab>Shipping Options</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <HerbOfTheWeek />
            </TabPanel>
            <TabPanel>
              <ShippingRates />
            </TabPanel>
          </TabPanels>
        </TabGroup>

        <SearchOfferings />
      </Grid>
    </DashboardSection>
  );
};

export default Offerings;
