export enum EditorType {
  Newsletter = "newsletter",
  MakerCrud = "makerCrud",
}

export interface LexicalState {
  // the state of each editor across the application
  editors: { [id in EditorType]: EditorState };
}

const AllEditors = Object.values(EditorType);

export const initialState: LexicalState = {
  editors: AllEditors.map((editor) => ({
    id: editor,
    html: "",
    images: [],
  })).reduce(
    (acc, editor) => ({ ...acc, [editor.id]: editor }),
    {} as { [key in EditorType]: EditorState }
  ),
};

export interface EditorState {
  // the id of the editor
  id: EditorType;
  // the raw html of the editor that can be used to repopulate the editor
  html: string;
  // images uploaded by the user for this editor
  images: UploadedImage[];
}

export interface UploadedImage {
  id: string;
  url: string; // either an external url or local blob url
  altDescription: string;
  width: number;
  height: number;
}
