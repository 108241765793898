import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";
import { FulfillmentReports, RevenueReports } from "./types";

const reportsApi = createApi({
  reducerPath: "reportsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/reports`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ["FulfillmentReports", "RevenueReports"],
  endpoints: (builder) => ({
    getFulfillmentReports: builder.query({
      query: () => ({
        url: `/fulfillment`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) =>
        response as any as FulfillmentReports,
      providesTags: ["FulfillmentReports"],
    }),
    getRevenueReports: builder.query({
      query: (params: { from?: string; to?: string }) => ({
        url: `/revenue`,
        method: "GET",
        params: params,
      }),
      transformResponse: (response, meta, arg) =>
        response as any as RevenueReports,
      providesTags: (result, error, arg) => {
        // Use the from and to parameters as part of the tag
        return [
          {
            type: "RevenueReports" as const,
            id: `from:${arg.from}-to:${arg.to}`,
          },
        ];
      },
    }),
  }),
});

export const { useGetFulfillmentReportsQuery, useGetRevenueReportsQuery } =
  reportsApi;

export default reportsApi;
