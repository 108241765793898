import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Toast } from "./types";

export interface ToastsState {
  toasts: Toast[];
  currentId: number;
}

export const initialState: ToastsState = {
  toasts: [],
  currentId: 0,
};

const applicationSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    addToast(state, action: PayloadAction<Toast>) {
      state.toasts.push(action.payload);
      state.currentId++;
    },
    removeToast(state, action: PayloadAction<number>) {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
    },
  },
});

export const { addToast, removeToast } = applicationSlice.actions;

export default applicationSlice.reducer;
