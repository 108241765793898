import * as Yup from "yup";
import {
  IS_PREVIEW,
  NEWSLETTER_MESSAGE,
  NEWSLETTER_SUBJECT,
  PREVIEW_RECIPENTS,
} from "./fieldNames";

const NewsletterFormSchema = Yup.object().shape({
  [NEWSLETTER_SUBJECT]: Yup.string()
    .min(10, "Subject is too short")
    .max(55, "Subject is too long")
    .required("Subject is required"),
  [NEWSLETTER_MESSAGE]: Yup.string()
    .notOneOf(
      // prevent the user from submitting the default lexical editor value
      [`<p class="relative m-0 mb-2"><br></p>`],
      "Message is required"
    )
    .required("Message is required")
    .max(25000, "Message is too long"),
  [IS_PREVIEW]: Yup.boolean().required(),
  [PREVIEW_RECIPENTS]: Yup.string().when(IS_PREVIEW, {
    is: true, // condition
    then: Yup.string()
      .matches(
        /^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)(,\s*[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)*$/,
        "Invalid recipient list"
      )
      .required("At least one email address is required"),
    otherwise: Yup.string().notRequired(),
  }),
});

export default NewsletterFormSchema;
