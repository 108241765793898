import { CloseCircle } from "@styled-icons/ionicons-solid";
import {
  Badge,
  Bold,
  Button,
  Card,
  DateRangePicker,
  DateRangePickerItem,
  DateRangePickerValue,
  Grid,
  Metric,
  MultiSelect,
  MultiSelectItem,
  Subtitle,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text,
  Title,
} from "@tremor/react";
import { Formik } from "formik";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import DashboardSection from "../../../components/DashboardSection";
import OrderDetailsModal from "../../../components/Modals/OrderDetailsModal";
import { OrderDateCreated } from "../../../helpers/OrderHelpers";
import { useSearchOrdersQuery } from "../../../redux/api/orders/api";
import { Order } from "../../../redux/api/orders/types";
import { useToggleOrderDetailsModal } from "../../../redux/state/modals/hooks";
import ActiveSubscriptions from "./ActiveSubscriptions";
import CustomerSubscriptions from "./CustomerSubscriptions";
import { SEARCH_INPUT } from "./fieldNames";
import SearchForm from "./form";
import {
  InitialSearchOrdersValues,
  SearchOrdersFormValues,
} from "./initialValues";
import SearchOrdersValidationSchema from "./validationSchema";

interface IOrdersProps {}

const colors: { [key: string]: string } = {
  payment: "sky",
  subscription: "cyan",
  manual: "indigo",
  POS: "purple",
};

const Orders = ({}: IOrdersProps) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const toggleOrderDetailsModal = useToggleOrderDetailsModal();
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [filterByFulfilled, setFilterByFulfilled] = useState<boolean | null>(
    null
  );

  const today = new Date(); // Get current date
  today.setHours(0, 0, 0, 0); // Set time to 00:00:00.000 for consistency

  const getStartDate = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() - days); // Calculate the date for 'days' ago
    date.setHours(0, 0, 0, 0); // Set time to 00:00:00.000
    return date;
  };

  // Updated to include new range MultiSelectItems
  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: getStartDate(30), // default to "Last 30 Days"
    to: new Date(),
  });

  const {
    data: unfulfilledOrders,
    isLoading: searchOrdersQueryIsLoading,
    isFetching: searchOrdersQueryIsFetching,
  } = useSearchOrdersQuery({
    fulfilled: false,
  });

  const numUnfulfilledOrders = unfulfilledOrders?.length ?? 0;

  const queryParams =
    filterByFulfilled !== false
      ? {
          id: searchParams.get("id"),
          email: searchParams.get("email"),
          ...(dateRange.from && { from: dateRange.from.getTime().toString() }),
          ...(dateRange.to &&
            dateRange.to !== dateRange.from && {
              to: dateRange.to.getTime().toString(),
            }),
        }
      : {
          id: searchParams.get("id"),
          email: searchParams.get("email"),
          fulfilled: filterByFulfilled,
        };

  const {
    data: orders,
    isLoading: loadingSearchResult,
    isFetching: fetchingSearchResult,
    error: searchError,
  } = useSearchOrdersQuery(queryParams);

  async function handleSearch(values: SearchOrdersFormValues) {
    console.log(values);
    setSearchParams({ email: values.search });
  }

  async function handleViewOrderDetails(order: Order) {
    setSelectedOrder(order);
    toggleOrderDetailsModal();
  }

  const isLoading = loadingSearchResult || fetchingSearchResult;
  const searchOrdersLoading =
    searchOrdersQueryIsLoading || searchOrdersQueryIsFetching;

  return (
    <DashboardSection title={""} order={0} loading={false}>
      <TabGroup className="max-w-full">
        <TabList>
          <Tab>Orders</Tab>
          <Tab>Subscriptions</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Grid numItemsMd={2} numItemsLg={3} className="mt-6 gap-6">
              <Card>
                <Title>Unfulfilled Orders</Title>
                <Metric className="mt-2">
                  {searchOrdersLoading ? "Loading..." : numUnfulfilledOrders}
                </Metric>
              </Card>
            </Grid>

            {/* Purchases Dashboard Section */}

            <Card className="mt-6 w-full space-y-8">
              <div className="mb-6 flex justify-between">
                <div className="flex justify-start space-x-2">
                  <Title>Search Orders</Title>
                </div>
              </div>

              <div className="align-center my-2 flex flex-col justify-between gap-2 md:flex-row">
                {/* Search Bar */}
                <div className="flex grow flex-col">
                  <Formik
                    initialValues={InitialSearchOrdersValues}
                    onSubmit={handleSearch}
                    validationSchema={SearchOrdersValidationSchema}
                  >
                    {(props) => (
                      <div className="relative flex h-fit grow">
                        <SearchForm props={props} />
                        {!!props.values.search && (
                          <CloseCircle
                            className="absolute right-1 top-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2 cursor-pointer text-gray-400"
                            onClick={() => {
                              props.setFieldValue(SEARCH_INPUT, "");
                              setSearchParams({});
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Formik>
                </div>

                {/* Date Range Picker */}
                <div className="flex flex-col">
                  <DateRangePicker
                    disabled={filterByFulfilled === false}
                    value={dateRange}
                    onValueChange={setDateRange}
                    enableYearNavigation={true}
                  >
                    <DateRangePickerItem
                      key="week"
                      value="week"
                      from={getStartDate(6)} // Last 7 days including today
                      to={today}
                    >
                      Last Week
                    </DateRangePickerItem>
                    <DateRangePickerItem
                      key="month"
                      value="month"
                      from={getStartDate(29)} // Last 30 days including today
                      to={today}
                    >
                      Last Month
                    </DateRangePickerItem>
                    <DateRangePickerItem
                      key="90days"
                      value="90days"
                      from={getStartDate(89)} // Last 90 days including today
                      to={today}
                    >
                      Last 90 Days
                    </DateRangePickerItem>
                    <DateRangePickerItem
                      key="ytd"
                      value="ytd"
                      from={new Date(today.getFullYear(), 0, 1)} // From Jan 1st of current year
                      to={today}
                    >
                      Year to Date
                    </DateRangePickerItem>
                  </DateRangePicker>
                </div>

                {/* MultiSelect Filter */}
                <div>
                  <MultiSelect
                    placeholder="Filters..."
                    onValueChange={(values) => {
                      if (values.includes("unfulfilled")) {
                        setFilterByFulfilled(false);
                      } else {
                        setFilterByFulfilled(null);
                      }
                    }}
                  >
                    <MultiSelectItem value="unfulfilled">
                      Unfulfilled
                    </MultiSelectItem>
                  </MultiSelect>
                </div>
              </div>

              {/* Order Details Table */}

              {loadingSearchResult ? (
                <div className="flex items-center justify-center py-12">
                  <RingLoader />
                </div>
              ) : orders && orders.length > 0 ? (
                <div className="flex w-full flex-col items-center justify-center space-y-4">
                  <Subtitle className="self-start pl-4 text-sm">
                    {`Found ${orders.length} orders ${
                      dateRange?.from && dateRange?.to
                        ? `from ${dateRange.from.toLocaleDateString()} to ${dateRange.to.toLocaleDateString()}`
                        : !dateRange?.from && !dateRange?.to
                        ? "from all time"
                        : dateRange?.from
                        ? "since " + dateRange.from.toLocaleDateString()
                        : dateRange?.to
                        ? "before " + dateRange.to.toLocaleDateString()
                        : ""
                    }`}
                  </Subtitle>

                  <Table className="w-full">
                    {orders.length > 0 && (
                      <TableHead>
                        <TableRow>
                          <TableHeaderCell>Status</TableHeaderCell>
                          <TableHeaderCell>ID</TableHeaderCell>
                          <TableHeaderCell>User</TableHeaderCell>
                          <TableHeaderCell>Date</TableHeaderCell>
                          <TableHeaderCell className="text-right">
                            Amount
                          </TableHeaderCell>
                          <TableHeaderCell>Type</TableHeaderCell>
                          <TableHeaderCell></TableHeaderCell>
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody>
                      {orders.map((order) => (
                        <TableRow
                          key={`${order.orderId}-${order.created._seconds}`}
                        >
                          <TableCell>
                            <Badge
                              color={order.fulfilled ? "emerald" : "rose"}
                              size="xs"
                              className="capitalize"
                            >
                              {order.fulfilled ? "fulfilled" : "unfulfilled"}
                            </Badge>
                          </TableCell>
                          <TableCell>
                            <Bold>{order.orderId}</Bold>
                          </TableCell>
                          <TableCell className="max-w-[150px] overflow-hidden text-ellipsis">
                            {order.customer?.email}
                          </TableCell>
                          <TableCell>
                            {OrderDateCreated(order, "short")}
                          </TableCell>
                          <TableCell className="text-right">
                            ${order.total.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <Badge
                              color={colors[order.type]}
                              size="xs"
                              className="capitalize"
                            >
                              {order.type}
                            </Badge>
                          </TableCell>
                          <TableCell>
                            <Button
                              size="xs"
                              variant="secondary"
                              color="gray"
                              onClick={() => handleViewOrderDetails(order)}
                            >
                              See details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                      {orders?.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={7} className="py-12">
                            <Text className="text-center">
                              No orders found.
                            </Text>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div className="flex w-full items-center justify-center py-24">
                  <Title>No Orders</Title>
                </div>
              )}
            </Card>
          </TabPanel>
          <TabPanel>
            <CustomerSubscriptions />
            <ActiveSubscriptions />
          </TabPanel>
        </TabPanels>
      </TabGroup>

      {selectedOrder && <OrderDetailsModal order={selectedOrder} />}
    </DashboardSection>
  );
};

export default Orders;
