import { NavLink } from "react-router-dom";
import PrayerPageLanding from "../assets/images/prayer-page-landing.jpeg";
import {
  Berries3,
  Forest1,
  MotherMary,
  Squash1,
  StMichaelSnow,
} from "../constants/images";

const images = [Squash1, MotherMary, Forest1, Berries3, StMichaelSnow];

const PrayerPage = (props: any) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex h-fit w-full flex-col-reverse md:flex-row">
        <div
          className="min-h-[50rem] w-full bg-cover bg-center md:min-h-full md:w-2/3"
          style={{
            backgroundImage: `url(${PrayerPageLanding})`,
          }}
        ></div>

        <article className="w-full space-y-6 bg-gray-800 py-16 px-6 md:w-1/3 md:p-12">
          <h1 className="w-full font-blackstone text-5xl font-bold tracking-wider text-stone-300">
            our prayer
          </h1>
          <h2 className="font-serif text-2xl italic tracking-wider text-stone-200">
            The term &ldquo;Maranatha&rdquo; is an Aramaic word that traces its
            origin to the New Testament Bible.
          </h2>
          <div className="space-y-4 text-lg font-light leading-relaxed tracking-wide text-stone-50">
            <p>
              It was commonly used as both a greeting and a prayer among
              Christians in the early Church. In English, the term translates to{" "}
              <i>the Lord is coming</i> or <i>Come, O Lord</i>. As Christians,
              we honor God in all we do.
            </p>
          </div>

          <div className="flex space-x-4">
            <NavLink
              to="/story"
              className="bg-stone-700 py-2 px-4 font-serif uppercase tracking-wider text-stone-50 transition-colors hover:bg-stone-200 hover:text-stone-700"
            >
              our story
            </NavLink>
            <NavLink
              to="/offerings"
              className="bg-stone-700 py-2 px-4 font-serif uppercase tracking-wider text-stone-50 transition-colors hover:bg-stone-200 hover:text-stone-700"
            >
              our offerings
            </NavLink>
          </div>
        </article>
      </div>

      <section className="flex w-full flex-col items-center justify-center bg-stone-200 py-16 px-8">
        <article className="max-w-3xl space-y-8 text-center font-serif text-2xl font-[400] italic leading-relaxed tracking-wider text-gray-700">
          <p className="font-blackstone text-4xl text-gray-800">Dear Lord,</p>

          <p>
            We thank You for every creature that we share this blessed place
            with.
          </p>

          <p>
            Please guide us to be the best stewards of this land and bestow upon
            us the wisdom we need to help every plant, tree, creature, and
            nature spirit live in harmony and thrive within a balanced and
            sustainable ecosystem.
          </p>

          <p>
            Please bless us with an ongoing bounty of healthy and spiritual food
            filled with Your Love so that we may share it with those who need it
            most.
          </p>

          <p>To Your Honor and Glory, in Jesus' name we pray.</p>

          <p className="font-blackstone text-4xl text-gray-800">Amen</p>
        </article>
      </section>

      <div className="flex justify-center gap-1 overflow-hidden">
        {images.map((image, index) => (
          <img
            key={index}
            className="h-auto w-1/3 object-cover object-left md:w-1/5"
            src={image}
            alt={`about us`}
          />
        ))}
      </div>
    </div>
  );
};

export default PrayerPage;
