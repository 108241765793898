import ContactForm from "../components/Forms/ContactForm";
import { Forest1 } from "../constants/images";
import { AllSocialAccounts, SocialAccountInfo } from "../constants/SocialMedia";

const ContactPage = () => {
  return (
    <section
      className="min-h-screen bg-cover bg-[45%]"
      style={{
        backgroundImage: `url(
          ${Forest1}
        )`,
      }}
    >
      <div className="flex min-h-screen flex-col bg-black/50">
        <div className="container mx-auto flex flex-1 flex-col px-6 py-12 pt-[72px]">
          <div className="flex-1 lg:-mx-6 lg:flex lg:items-center">
            <div className="mt-10 lg:mx-6 lg:mt-0 lg:w-1/2">
              <h1 className="flex w-fit flex-col font-blackstone text-5xl font-bold tracking-wider text-stone-300 lg:text-5xl">
                contact us
              </h1>

              <p className="mt-6 max-w-xl text-xl tracking-wide text-stone-100">
                Have a question or comment? We'd love to hear from you! Submit
                an inquiry and we will be in touch with you as soon as possible.
              </p>

              <div className="mt-6 md:mt-8">
                <h3 className="font-serif italic tracking-widest text-slate-200">
                  Connect with us
                </h3>

                <ul className="mt-2 flex items-center gap-2">
                  {AllSocialAccounts.map((account) => {
                    const { name, url, icon } = SocialAccountInfo[account];

                    return (
                      <li key={name}>
                        <a
                          href={url}
                          target="__blank"
                          className="flex aspect-square items-center rounded-full bg-transparent p-2 text-zinc-50 shadow-lg backdrop-blur transition-all duration-300 ease-in-out hover:bg-white/50 hover:text-teal-700"
                        >
                          {icon(8)}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="mt-8 lg:mx-6 lg:w-1/2">
              <div className="mx-auto w-full max-w-lg overflow-hidden rounded bg-stone-200/70 p-8 text-gray-800 shadow-2xl backdrop-blur-sm dark:bg-zinc-800 lg:max-w-xl">
                <h1 className="font-serif text-xl font-bold md:text-2xl">
                  Contact form
                </h1>

                <p className="mt-2 mb-4">
                  Connect with us on Instagram, Facebook, and LinkedIn, or
                  simply fill out the contact form right here.
                </p>

                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
