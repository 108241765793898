import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EditorType, initialState, UploadedImage } from "./types";

/**
 * This slice is responsible for managing the state of various lexical editors
 */

const lexicalSlice = createSlice({
  name: "lexical",
  initialState: initialState,
  reducers: {
    createEditor(state, action: PayloadAction<EditorType>) {
      state.editors[action.payload] = {
        id: action.payload,
        html: "",
        images: [],
      };
    },
    resetEditor(state, action: PayloadAction<EditorType>) {
      state.editors[action.payload] = {
        id: action.payload,
        html: "",
        images: [],
      };
    },
    updateEditorHtml(
      state,
      action: PayloadAction<{ editor: EditorType; html: string }>
    ) {
      state.editors[action.payload.editor].html = action.payload.html;
    },
    addImageToEditor(
      state,
      action: PayloadAction<{ editor: EditorType; image: UploadedImage }>
    ) {
      state.editors[action.payload.editor].images.push(action.payload.image);
    },
    removeImageFromEditor(
      state,
      action: PayloadAction<{ editor: EditorType; imageId: string }>
    ) {
      const images = state.editors[action.payload.editor].images;

      if (!images) {
        return;
      }

      const imageIndex = images.findIndex(
        (image) => image.id === action.payload.imageId
      );

      if (imageIndex === -1) {
        return;
      }

      const image = images[imageIndex];

      if (image && image.url) {
        // check if the URL is the result of a createObjectURL call
        // if so, we need to revoke the object URL
        const isObjectURL = image.url.startsWith("blob:");
        if (isObjectURL) {
          URL.revokeObjectURL(image.url);
        }

        // remove the image from the state
        images.splice(imageIndex, 1);
      }
    },
  },
});

export const {
  createEditor,
  resetEditor,
  updateEditorHtml,
  addImageToEditor,
  removeImageFromEditor,
} = lexicalSlice.actions;

export default lexicalSlice.reducer;
