import * as Yup from "yup";
import { ONETIME, SUBSCRIPTION, SUBSCRIPTION_PRICE_ID } from "./fieldNames";

// Yup Validation Schema
const PurchaseOptionsValidationSchema = Yup.object().shape({
  [ONETIME]: Yup.boolean().notRequired(),
  [SUBSCRIPTION]: Yup.boolean().when(ONETIME, {
    is: false,
    then: Yup.boolean().oneOf([true], "Please select a purchase option"),
    otherwise: Yup.boolean().notRequired(),
  }),
  [SUBSCRIPTION_PRICE_ID]: Yup.string().when(SUBSCRIPTION, {
    is: true,
    then: Yup.string().required("Please select a subscription option"),
    otherwise: Yup.string().notRequired(),
  }),
});

export default PurchaseOptionsValidationSchema;
