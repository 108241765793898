import {
  Justify,
  JustifyLeft,
  JustifyRight,
  TextCenter,
  TypeBold,
  TypeItalic,
  TypeStrikethrough,
  TypeUnderline,
} from "@styled-icons/bootstrap";
import {
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  LexicalCommand,
} from "lexical";

export type Tool = {
  label: string;
  icon: JSX.Element;
  command?: {
    type: LexicalCommand<unknown>;
    payload: string;
  };
};

export const Tools: Tool[][] = [
  [
    {
      label: "Bold",
      icon: <TypeBold className="h-5 w-5" />,
      command: {
        type: FORMAT_TEXT_COMMAND,
        payload: "bold",
      },
    },
    {
      label: "Italic",
      icon: <TypeItalic className="h-5 w-5" />,
      command: {
        type: FORMAT_TEXT_COMMAND,
        payload: "italic",
      },
    },
    {
      label: "Underline",
      icon: <TypeUnderline className="h-5 w-5" />,
      command: {
        type: FORMAT_TEXT_COMMAND,
        payload: "underline",
      },
    },
    {
      label: "Strike-Through",
      icon: <TypeStrikethrough className="h-5 w-5" />,
      command: {
        type: FORMAT_TEXT_COMMAND,
        payload: "strikethrough",
      },
    },
  ],
  [
    {
      label: "Left Align",
      icon: <JustifyLeft className="h-5 w-5" />,
      command: {
        type: FORMAT_ELEMENT_COMMAND,
        payload: "left",
      },
    },
    {
      label: "Center Align",
      icon: <TextCenter className="h-5 w-5" />,
      command: {
        type: FORMAT_ELEMENT_COMMAND,
        payload: "center",
      },
    },
    {
      label: "Right Align",
      icon: <JustifyRight className="h-5 w-5" />,
      command: {
        type: FORMAT_ELEMENT_COMMAND,
        payload: "right",
      },
    },
    {
      label: "Justify Align",
      icon: <Justify className="h-5 w-5" />,
      command: {
        type: FORMAT_ELEMENT_COMMAND,
        payload: "justify",
      },
    },
  ],
];
