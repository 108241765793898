import { Unsubscribe, User } from "firebase/auth";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import Footer from "../components/Footer";
import Cart from "../components/Modals/Cart";
import LoginModal from "../components/Modals/LoginModal";
import NewsletterSignupModal from "../components/Modals/NewsletterSignupModal";
import NavBar from "../components/NavBar";
import NavMenu from "../components/NavMenu";
import Toasts from "../components/Toasts";
import { auth } from "../config/firebase";
import { useAuthentication } from "../hooks/useAuthentication";
import { usePrefetch as usePrefetchOfferingsApi } from "../redux/api/offerings/api";
import { useAppDispatch } from "../redux/hooks";
import { useDarkMode } from "../redux/state/app/hooks";
import { setExtendedNavIsOpen } from "../redux/state/app/reducer";
import { useOpenModal } from "../redux/state/modals/hooks";
import { ModalType } from "../redux/state/modals/types";
import AppBody from "./AppBody";

function App() {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const dark = useDarkMode();

  const { handleAnonymousSignIn } = useAuthentication();

  // When the screen surpasses the tailwind.config md size, close the nav menu
  const [isScreenMd, setIsScreenMd] = useState(false);

  const loginModal = useOpenModal(ModalType.LOGIN);
  const cartModal = useOpenModal(ModalType.CART);

  /**
   * Prefetch categories so they load fast from cache on shop page
   */
  const prefetchActiveOfferingCategories = usePrefetchOfferingsApi(
    "activeOfferingCategories"
  );
  prefetchActiveOfferingCategories({
    active: true,
  });

  const prefetchPaginatedOfferings =
    usePrefetchOfferingsApi("paginatedOfferings");
  prefetchPaginatedOfferings({
    active: true,
    limit: 24,
  });

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;

      setIsScreenMd(screenWidth >= 960);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isScreenMd) {
      dispatch(setExtendedNavIsOpen(false));
    }
  }, [isScreenMd]);

  const [_currentUser, _setCurrentUser] = useState<User | null>(null);
  const onAuthStateChangedRef = useRef<Unsubscribe>();

  useEffect(() => {
    // Add the listener and store a reference to it
    onAuthStateChangedRef.current = auth.onAuthStateChanged(async (user) => {
      if (user && user !== _currentUser) {
        _setCurrentUser(user);
      } else if (user === null) {
        // console.log("creating anonymous user");
        const anonUser = await handleAnonymousSignIn();
        _setCurrentUser(anonUser);
      }
    });

    // Cleanup function to remove the listener
    return () => {
      if (onAuthStateChangedRef.current) {
        onAuthStateChangedRef.current();
      }
    };
  }, [_currentUser]);

  return (
    <div
      className={`flex min-h-screen flex-col items-center justify-between overflow-x-hidden bg-stone-50 ${
        dark && "dark"
      }`}
    >
      {!["/checkout", "/subscribe", "/admin", "/account"].some((path) =>
        location.pathname.startsWith(path)
      ) && (
        <>
          <NavBar />
          <NavMenu />
        </>
      )}

      <ErrorBoundary>
        {loginModal && <LoginModal />}

        {cartModal && <Cart />}

        {<NewsletterSignupModal />}

        <Toasts />

        {Object.values(ModalType).map((type) => (
          <div id={`${type}-root`} key={type}></div>
        ))}

        <AppBody />
      </ErrorBoundary>

      {!["/checkout", "/subscribe"].some((path) =>
        location.pathname.startsWith(path)
      ) && (
        <>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
