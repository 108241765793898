import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SlideshowImages } from "../../constants/images";

interface Props {}

const SlickGallery = (props: Props) => {
  const settings: Settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 34,
    autoplay: true,
    speed: 150000,
    autoplaySpeed: 500,
    cssEase: "linear",
    variableWidth: true,
    swipeToSlide: true,
  };

  return (
    <Slider {...settings} className="h-[350px] md:h-[700px]">
      {SlideshowImages.map((img, i) => (
        <div className="h-[350px] max-w-fit md:h-[700px]">
          <img
            className="h-full"
            alt={`gallery-${i}`}
            key={`gallery-${i}`}
            loading="lazy"
            src={img}
          />
        </div>
      ))}
    </Slider>
  );
};

export default SlickGallery;
