import { NavLink } from "react-router-dom";
import Shed1 from "../assets/images/hd/Shed1.jpeg";

import {
  Berries2,
  Chicken4,
  Ducks1,
  MotherMary,
  Shed2,
} from "../constants/images";

const images = [Chicken4, MotherMary, Ducks1, Shed2, Berries2];

const AboutPage = () => {
  return (
    <div>
      <div className="flex h-fit w-full flex-col-reverse md:flex-row">
        <div
          className="min-h-[50rem] w-full bg-cover bg-center md:min-h-full md:w-1/2"
          style={{
            backgroundImage: `url(${Shed1})`,
          }}
        ></div>

        <article className="w-full space-y-6 bg-gray-800 py-16 px-6 md:w-1/2 md:p-12">
          <h1 className="w-full font-blackstone text-5xl font-bold tracking-wider text-stone-300">
            our story
          </h1>
          <h2 className="font-serif text-2xl italic tracking-wider text-stone-200">
            First and foremost, we love healthy food that is delicious.
          </h2>
          <div className="space-y-4 text-lg font-light leading-relaxed tracking-wide text-stone-50">
            <p>
              We believe as farmer poet Wendell Berry says,{" "}
              <span className="italic">
                &ldquo;eating is an agricultural act&rdquo;
              </span>
              , but we also believe that we have a responsibility to care for
              the land and animals who feed us. Nature and plants heal us. Food
              is medicine.
            </p>
            <p>
              While seeking answers and solutions to an autoimmune issue, our
              Founder Michele Logan researched and discovered that the way that
              most &ldquo;healthy&rdquo; food was grown on a large scale really
              was not what we common folk believed to be true. Labels on organic
              food really don&rsquo;t tell the whole story about the food we put
              in our bodies. There's a saying that we are what we eat{" "}
              <span className="font-bold italic">ate</span>. This is also true
              for the health of the soil that plants are grown in.
            </p>
            <p>
              In 2015, Michele and her family moved to the Somerset Hills of New
              Jersey. She felt a calling to combine her love for food with
              healing the land that was allowed to go fallow after being farmed
              more than ninety years ago. An unsuccessful successionary forest
              grew, and choices made by previous owners and neighbors led to an
              imbalance of invasive plants, soil erosion, and poor soil health
              in the forest.
            </p>
          </div>
        </article>
      </div>

      <div className="flex w-full items-center justify-center bg-stone-200 p-16">
        <article className="flex max-w-4xl flex-col items-center space-y-4 text-center text-lg font-light leading-loose tracking-wide text-gray-800">
          <h1 className="my-8 font-serif text-3xl italic tracking-wider text-gray-700">
            We are a start-up regenerative forest farm on a ridge above the
            Raritan River in the Somerset Hills of New Jersey entering our
            fourth year.
          </h1>

          <p>
            Our goal is to get back to basics practicing sustainable,
            regenerative agriculture and permaculture principles to heal the
            land, and grow nutrient dense, hyper-seasonal specialty produce that
            is inherently of this place. We farm in nature&rsquo;s image,
            without the use of GMOs, pesticides, fungicides, herbicides,
            glyphosate, or other chemical inputs. We utilize a rotational
            pastured plan for our multi-species flock with the aim of
            holistically healing the land, and building soil health, which
            ultimately produces the healthiest food possible. We also believe in
            a high standard of care for the animals who feed us, and a living
            wage for the farmers who grow our food. This is food the way God
            intended it to be.
          </p>

          <p>
            As we continue to build our farm infrastructure, we are also
            building our soil, our flock, our gardens, permaculture terraces,
            mushroom yards, and silvopastures. It is our intention to inspire
            others to do what they can to incorporate the philosophy of
            interdependence in their own life, even if they live in an
            apartment. By supporting local agriculture that is working to heal
            our land and rebalance our ecosystem, you are bringing the best food
            possible into your home to ultimately heal yourself and your family.
          </p>

          <br />

          <NavLink
            to="/contact"
            className="bg-gray-800 py-2 px-4 font-serif uppercase tracking-wider text-stone-200 transition-colors hover:bg-gray-400 hover:text-stone-50"
          >
            get in touch with us
          </NavLink>
        </article>
      </div>

      <div className="flex justify-center gap-1 overflow-hidden">
        {images.map((image, index) => (
          <img
            key={index}
            className="h-auto w-1/3 object-cover md:w-1/5"
            src={image}
            alt={`about us`}
          />
        ))}
      </div>
    </div>
  );
};

export default AboutPage;
