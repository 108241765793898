import { Field, Form, Formik, FormikHelpers } from "formik";
import { FormikInput } from "../components/Formik";
import { NEWSLETTER_SIGNUP_EMAIL } from "../components/Forms/NewsletterSignupForm/fieldNames";
import {
  InitialNewsletterSignupValues,
  NewsletterSignupValues,
} from "../components/Forms/NewsletterSignupForm/initialValues";
import { NewsletterSignupSchema } from "../components/Forms/NewsletterSignupForm/validationSchema";
import Spinner from "../components/Spinner";
import { useDeleteSubscriberMutation } from "../redux/api/newsletter/api";
import { useShowToast } from "../redux/state/toasts/hooks";

const Unsubscribe = () => {
  const { showToast } = useShowToast();
  const [triggerDeleteSubscriber] = useDeleteSubscriberMutation();

  async function handleSubmit(
    values: NewsletterSignupValues,
    formikHelpers: FormikHelpers<{ email: string }>
  ) {
    await triggerDeleteSubscriber({ email: values.email });
    formikHelpers.resetForm();
    formikHelpers.setSubmitting(false);
    showToast({
      code: 0,
      title: "Success",
      body: "Your email has been removed from our mailing list.",
    });
  }

  return (
    <div className="my-auto mt-8 flex h-screen w-full justify-center">
      <div className="m-2 flex max-w-lg flex-col items-start justify-center space-y-8">
        <h1 className="self-start font-serif text-2xl font-bold">
          Unsubscribe Email
        </h1>
        <p className="text font-serif">
          Enter your email below to unsubscribe from all marketing
          correspondance from Maranatha Farm. You will still receive
          transactional emails for online orders placed on the site.
        </p>
        <Formik
          initialValues={InitialNewsletterSignupValues}
          onSubmit={handleSubmit}
          validationSchema={NewsletterSignupSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formikProps) => (
            <Form
              className="flex-1 [direction:ltr] lg:col-span-1"
              onSubmit={(e: any) => {
                e.preventDefault();
                formikProps.validateForm();
                formikProps.handleSubmit();
              }}
              noValidate
            >
              <Field
                type="email"
                name={NEWSLETTER_SIGNUP_EMAIL}
                placeholder="EMAIL ADDRESS"
                title="STAY UPDATED"
                label={false}
                theme={"dark"}
                component={FormikInput}
                button={
                  <button
                    type="submit"
                    className={`bg-gray-800 py-3 px-5 text-xs font-bold tracking-wider text-stone-200`}
                  >
                    {formikProps.isSubmitting ? <Spinner /> : "UNSUBSCRIBE"}
                  </button>
                }
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Unsubscribe;
