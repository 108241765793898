import { Card, Flex, Switch, Title } from "@tremor/react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { FormikAdminInput } from "../../../components/Formik";
import Spinner from "../../../components/Spinner";
import {
  useGetHerbOfTheWeekQuery,
  useUpdateHerbOfTheWeekMutation,
} from "../../../redux/api/offerings/api";

interface HerbOfTheWeekValues {
  herb: string;
}

const HerbOfTheWeekSchema = Yup.object().shape({
  herb: Yup.string().trim().required("an herb is required"),
});

const HerbOfTheWeek = () => {
  const { data: hotw, isLoading: hotwIsLoading } =
    useGetHerbOfTheWeekQuery(null);

  const [updateHerbOfTheWeek] = useUpdateHerbOfTheWeekMutation();

  function handleUpdateHerbOfTheWeekName(
    values: HerbOfTheWeekValues,
    helpers: FormikHelpers<HerbOfTheWeekValues>
  ) {
    updateHerbOfTheWeek({ herb: values.herb });
    helpers.resetForm({ values: { herb: values.herb } });
    helpers.setSubmitting(false);
  }

  return (
    <Card>
      <Flex justifyContent="between" className="space-x-2">
        <Title>Herb of The Week</Title>

        <Switch
          id={"herb-of-the-week"}
          color={"emerald"}
          checked={hotw?.isEnabled as boolean}
          onChange={() => updateHerbOfTheWeek({ isEnabled: !hotw.isEnabled })}
        />
      </Flex>

      {hotw && (
        <div className="flex w-full flex-col items-start justify-center p-2">
          <div className="flex w-full items-center justify-between pb-2">
            {hotw.isEnabled && (
              <div className="flex items-center">
                <Formik
                  initialValues={{
                    herb: hotw.herb,
                  }}
                  onSubmit={handleUpdateHerbOfTheWeekName}
                  validationSchema={HerbOfTheWeekSchema}
                >
                  {(props) => (
                    <Form
                      className="flex flex-col"
                      onSubmit={(e: any) => {
                        e.preventDefault();

                        props.validateForm();
                        props.handleSubmit();
                      }}
                      noValidate
                    >
                      <Field
                        type="text"
                        name="herb"
                        component={FormikAdminInput}
                      />

                      {props.dirty && (
                        <button
                          type="submit"
                          className="btn mt-2 flex items-center justify-center !bg-slate-800"
                        >
                          {props.isSubmitting ? <Spinner /> : "Update Herb"}
                        </button>
                      )}
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default HerbOfTheWeek;
