import { LOGIN_EMAIL, LOGIN_PASSWORD } from "./fieldNames";

export interface LoginFormValues {
  [LOGIN_EMAIL]: string;
  [LOGIN_PASSWORD]: string;
}

const initialValues: LoginFormValues = {
  [LOGIN_EMAIL]: "",
  [LOGIN_PASSWORD]: "",
};

export default initialValues;
