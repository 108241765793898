import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  LexicalCommand,
  createCommand,
} from "lexical";
import { useEffect } from "react";
import {
  $createImageNode,
  ImageNode,
  ImagePayload,
} from "../../nodes/ImageNode";

export type InsertImagePayload = Readonly<ImagePayload>;

export const INSERT_IMAGE_COMMAND: LexicalCommand<InsertImagePayload> =
  createCommand("INSERT_IMAGE_COMMAND");

interface ImagesPluginProps {
  captionsEnabled?: boolean;
}

export default function ImagesPlugin({
  captionsEnabled,
}: ImagesPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  // const removeMutationListener = editor.registerMutationListener(
  //   ImageNode,
  //   (mutatedNodes: Map<string, NodeMutation>) => {
  //     console.log(mutatedNodes);
  //     for (const [nodeKey, mutation] of mutatedNodes) {
  //       if (mutation === "destroyed") {
  //         editor.update(() => {
  //           const mentionNode = $getNodeByKey<ImageNode>(nodeKey);
  //           console.log(mentionNode);
  //           // mentionNodeList.set(nodeKey, mentionNode);
  //         });
  //       }
  //     }
  //   }
  // );

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }

    return mergeRegister(
      editor.registerCommand<InsertImagePayload>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload);
          $insertNodes([imageNode]);
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
          }
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [captionsEnabled, editor]);

  return null;
}
