import {
  NumberInput as TremorNumberInput,
  Textarea as TremorTextarea,
  TextInput as TremorTextInput,
} from "@tremor/react";
import { getIn } from "formik";
import { IFormElementProps } from ".";

export const TextInput = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  return (
    <TremorTextInput
      id={field.name}
      {...field}
      {...props}
      defaultValue={field.value}
      error={touched && errors}
      errorMessage={errors}
    />
  );
};

export const NumberInput = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  return (
    <TremorNumberInput
      id={field.name}
      {...field}
      {...props}
      defaultValue={field.value}
      error={touched && errors}
      errorMessage={errors}
    />
  );
};

export const Textarea = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  const errors = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  return (
    <TremorTextarea
      id={field.name}
      {...field}
      {...props}
      defaultValue={field.value}
      error={touched && errors}
      errorMessage={errors}
    />
  );
};
