import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeAddressElementOptions,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import { CheckmarkUnderlineCircle } from "@styled-icons/fluentui-system-filled";
import { FormEvent, useState } from "react";
import { NavLink } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import Stripe from "stripe";
import Spinner from "../../components/Spinner";
import { auth } from "../../config/firebase";
import {
  useCreateSubscriptionMutation,
  useRetrieveCustomerQuery,
  useUpdateCustomerMutation,
} from "../../redux/api/stripe/api";
import { useShowToast } from "../../redux/state/toasts/hooks";
import Loader from "../Loader";

interface Props {
  mode: "payment" | "subscription";
  priceId: string;
}

const createContactFromCustomerData = (
  customerData: Stripe.Customer | undefined
) => {
  if (!customerData) return null;
  const { name, phone, address } = customerData;
  if (!name || !phone || !address) return null;
  const { line1, city, state, postal_code, line2, country } = address;
  if (!line1 || !city || !state || !postal_code) return null;

  return {
    name,
    phone: phone.replace("+1", ""),
    address: {
      line1,
      ...(line2 && { line2 }),
      city,
      state,
      postal_code,
      country: country ?? "US",
    },
  };
};

const CheckoutForm = ({ mode, priceId }: Props) => {
  // Hooks
  const stripe = useStripe();
  const elements = useElements();
  const { showErrorToast } = useShowToast();

  // Redux API
  const [triggerUpdateCustomer] = useUpdateCustomerMutation();
  const { data: customerData, isLoading: loadingCustomerData } =
    useRetrieveCustomerQuery(auth.currentUser?.uid);
  const [triggerCreateSubscription] = useCreateSubscriptionMutation();

  // State
  const [loading, setLoading] = useState(false);
  const [addressElementReady, setAddressElementReady] = useState(false);
  const [paymentElementReady, setPaymentElementReady] = useState(false);
  const [success, setSuccess] = useState(false);

  const contact = createContactFromCustomerData(customerData);
  const stripeAddressElementOptions: StripeAddressElementOptions = {
    mode: "billing",
    allowedCountries: ["US"],
    ...(contact && { contacts: [contact] }),
    fields: {
      phone: "always",
    },
    validation: {
      phone: {
        required: "always",
      },
    },
    display: {
      name: "split",
    },
  };

  const stripePaymentElementOptions: StripePaymentElementOptions = {
    business: { name: "Maranatha Farm" },
    wallets: {
      applePay: "auto",
      googlePay: "auto",
    },
  };

  const handleError = (error: any) => {
    setLoading(false);

    if (error.type === "validation_error") {
      return;
    }

    showErrorToast(
      "Error processing request",
      "We encountered an error while processing your request. Please try again. If the issue persists, feel free to contact us."
    );
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    switch (mode) {
      case "payment":
        break;
      case "subscription":
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          elements: elements,
        });

        if (error) {
          handleError(error);
          return;
        }

        // try updating the customer in Stripe with phone and address values
        // so we can prefill the values next time
        const addressElement = elements.getElement("address");
        if (addressElement) {
          const { value } = await addressElement?.getValue();

          // updating the customer email in stripe here since updating during signup doesn't work
          // TODO: figure out why updating the customer email during signup doesn't work
          const email = auth.currentUser?.email;

          await triggerUpdateCustomer({
            ...(email && { email: email }),
            phone: value.phone,
            address: {
              line1: value.address.line1,
              line2: value.address.line2,
              city: value.address.city,
              state: value.address.state,
              postalCode: value.address.postal_code,
              country: value.address.country,
            },
          });
        }

        triggerCreateSubscription({
          priceId,
          paymentMethodId: paymentMethod.id,
        })
          .unwrap()
          .then((response) => {
            console.log(response);
            setLoading(false);
            setSuccess(true);
          })
          .catch((error) => {
            setLoading(false);
            setSuccess(false);
          });

        break;
      default:
        break;
    }
  };

  const transitionStyles = {
    config: {
      mass: 1,
      tension: 120,
      friction: 14,
    },
    from: {
      display: "none",
      transform: "scale(0.9) translateY(100px)",
      opacity: 0,
    },
    enter: {
      display: "flex",
      transform: "scale(1) translateY(0)",
      opacity: 1,
    },
    leave: {
      display: "none",
      transform: "scale(0.9) translateY(100px)",
      opacity: 0,
    },
  };

  const formTransition = useTransition(!success, transitionStyles);

  const successTransition = useTransition(success, transitionStyles);

  const elementsReady = addressElementReady && paymentElementReady;

  return (
    <div className=" hide-scroll max-h-screen overflow-y-scroll p-3 sm:p-12">
      {formTransition(
        (formStyle, showForm) =>
          showForm && (
            <animated.form
              style={formStyle}
              className="w-full flex-col space-y-4"
              onSubmit={handleSubmit}
            >
              <h1 className="mb-4 text-xl">Pay with card</h1>

              {loadingCustomerData && !elementsReady ? (
                <Loader />
              ) : (
                <>
                  <AddressElement
                    options={stripeAddressElementOptions}
                    onReady={() => setAddressElementReady(true)}
                  />

                  <PaymentElement
                    options={stripePaymentElementOptions}
                    onReady={() => setPaymentElementReady(true)}
                  />

                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md bg-black py-3 text-white transition hover:shadow-lg"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="flex gap-2">
                        Processing...
                        <Spinner />
                      </span>
                    ) : (
                      <span>Pay Now</span>
                    )}
                  </button>
                </>
              )}
            </animated.form>
          )
      )}

      {successTransition(
        (successStyle, showSuccess) =>
          showSuccess && (
            <animated.div
              style={successStyle}
              className="flex flex-col items-center justify-center space-y-4 text-center"
            >
              <CheckmarkUnderlineCircle className="h-24 w-24 text-green-600" />
              <h1 className="text-2xl font-bold text-gray-800">
                {mode === "payment" ? (
                  <span>Order Placed</span>
                ) : (
                  <span>Subscribed!</span>
                )}
              </h1>
              <p className="text-lg text-gray-600">
                {mode === "payment" ? (
                  <span>
                    Thank you for supporting our regenerative forest farm! Your
                    order has been successfully processed. To view your order
                    details please visit your account page.
                  </span>
                ) : (
                  <span>
                    Thank you for supporting our regenerative forest farm! Your
                    subscription has been successfully processed. To manage your
                    subscription or update your billing details please visit
                    your account page.
                  </span>
                )}
              </p>
              <NavLink
                to="/account"
                className="rounded-md bg-gray-400 py-0.5 px-4 text-lg font-bold text-white transition-all hover:bg-gray-500 hover:shadow-lg"
              >
                View My Account
              </NavLink>
            </animated.div>
          )
      )}
    </div>
  );
};

export default CheckoutForm;
