import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { FieldHelperProps } from "formik";
import { $insertNodes } from "lexical";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLexicalEditor } from "../../../../context/LexicalEditorContext";
import { AppState } from "../../../../redux";
import { useAppDispatch } from "../../../../redux/hooks";
import { makeSelectEditor } from "../../../../redux/state/lexical/hooks";
import { updateEditorHtml } from "../../../../redux/state/lexical/reducer";

interface Props {
  setValue: FieldHelperProps<any>["setValue"];
}

const HtmlPlugin = ({ setValue }: Props) => {
  const dispatch = useAppDispatch();
  const [editor] = useLexicalComposerContext();
  const { editorType } = useLexicalEditor();
  const selectEditorById = makeSelectEditor();
  const editor_ = useSelector((state: AppState) =>
    selectEditorById(state, editorType)
  );
  const nodesInserted = useRef(false);

  // repopulate the editor with the html from the redux store
  useEffect(() => {
    if (!editor_.html || nodesInserted.current) return;

    nodesInserted.current = true;

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(editor_.html, "text/html");
      const nodes = $generateNodesFromDOM(editor, dom);
      $insertNodes(nodes);
    });
  }, [editor_.html, editor]);

  return (
    <OnChangePlugin
      onChange={(editorState) => {
        editorState.read(() => {
          const editorHtml = $generateHtmlFromNodes(editor);
          setValue(editorHtml);
          dispatch(
            updateEditorHtml({
              editor: editorType,
              html: editorHtml,
            })
          );
        });
      }}
    />
  );
};

export default HtmlPlugin;
