import { logEvent } from "firebase/analytics";
import { analytics } from "../config/firebase";
import {
  Offering,
  OfferingOption,
  OfferingType,
} from "../redux/api/offerings/types";

export const useAnalytics = () => {
  function logViewItem(offering: Offering) {
    const items = offering.options.map((option) => ({
      item_id: option?.id,
      item_name: option?.name,
      item_category: offering?.type,
      price: option?.price,
    }));

    logEvent(analytics, "view_item", {
      currency: "USD",
      items: items,
    });
  }

  function logAddToCart(type: OfferingType, option: OfferingOption) {
    logEvent(analytics, "add_to_cart", {
      currency: "USD",
      value: option?.price,
      item_id: option?.id,
      item_name: option?.name,
      item_category: type,
      price: option?.price,
    });
  }

  return { logViewItem, logAddToCart };
};
