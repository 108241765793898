import { MoreHorizontal } from "@styled-icons/fluentui-system-filled";
import { ReactNode, useRef, useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";

interface Props {
  title: string;
  items: DropdownItem[];
}

export type DropdownItem = {
  title: ReactNode;
  action: Function;
  isDangerous?: boolean;
};

const Dropdown = ({ title, items }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropDownRef = useRef<HTMLDivElement>(null);

  useOutsideClick([dropDownRef], () => setIsOpen(false));

  return (
    <div className="relative" ref={dropDownRef}>
      {/* Options Button */}
      <div
        className="group absolute top-0 right-0 z-auto flex h-8 w-8 cursor-pointer items-center justify-center rounded-md bg-gray-50/75 transition-colors hover:bg-gray-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <MoreHorizontal className="h-[26px] w-[26px] text-gray-500 group-hover:text-gray-900" />
      </div>

      {/* Dropdown Options List */}
      {isOpen && (
        <div
          className="absolute right-0 top-1 z-[99] mt-8 w-44 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg"
          role="menu"
        >
          <div className="p-1">
            {items
              .filter((item) => !!!item.isDangerous)
              .map((item, index) => {
                return (
                  <button
                    role="menuitem"
                    key={index}
                    className="block w-full rounded-lg px-4 py-2 text-start text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                    onClick={async () => {
                      await item.action();
                      setIsOpen(false);
                    }}
                  >
                    {item.title}
                  </button>
                );
              })}
          </div>

          {!items.every((item) => item.isDangerous === undefined) && (
            <div className="p-1">
              {items
                .filter((item) => !!item.isDangerous)
                .map((item, index) => {
                  return (
                    <button
                      role="menuitem"
                      key={index}
                      className="flex w-full items-center gap-2 rounded-lg px-4 py-2 text-sm text-red-700 hover:bg-red-50"
                      onClick={async () => {
                        await item.action();
                        setIsOpen(false);
                      }}
                    >
                      {item.title}
                    </button>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
