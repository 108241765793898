import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $isListNode, ListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  ArrowClockwise,
  ArrowCounterclockwise,
  Image,
  Link,
} from "@styled-icons/bootstrap";
import { ChevronDown } from "@styled-icons/fluentui-system-filled";
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLexicalEditor } from "../../../../context/LexicalEditorContext";
import { useAppDispatch } from "../../../../redux/hooks";

import { addImageToEditor } from "../../../../redux/state/lexical/reducer";
import { UploadedImage } from "../../../../redux/state/lexical/types";
import {
  useOpenModal,
  usePopModal,
} from "../../../../redux/state/modals/hooks";
import { pushModal } from "../../../../redux/state/modals/reducer";
import { ModalType } from "../../../../redux/state/modals/types";
import ImageUploadForm from "../../../Forms/ImageUploadForm";
import ModalContainer from "../../../Modals";
import { $isImageNode } from "../../nodes/ImageNode";
import FloatingLinkEditor, { getSelectedNode } from "../AutoLinkPlugin/editor";
import { INSERT_IMAGE_COMMAND } from "../ImagePlugin";
import BlockStylesDropdown, {
  blockTypeToBlockName,
  supportedBlockTypes,
} from "./BlockStylesDropdown";
import { Tools } from "./tools";

const LowPriority = 1;

function Divider() {
  return <div className="mx-1 h-8 w-[1px] bg-gray-300" />;
}

export interface ToolbarOptions {
  allowImages?: boolean;
}

interface Props {
  options?: ToolbarOptions;
}

export default function ToolbarPlugin({ options }: Props) {
  const insertImageModal = useOpenModal(ModalType.IMAGE_UPLOAD);

  const popModal = usePopModal();

  const dispatch = useAppDispatch();
  const { editorType } = useLexicalEditor();
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [formatState, setFormatState] = useState({
    bold: false,
    italic: false,
    underline: false,
    strikethrough: false,
  } as { [key: string]: boolean });
  const [isLink, setIsLink] = useState(false);
  const [isImage, setIsImage] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }

      // Update text format
      setFormatState({
        bold: selection.hasFormat("bold"),
        italic: selection.hasFormat("italic"),
        underline: selection.hasFormat("underline"),
        strikethrough: selection.hasFormat("strikethrough"),
      });

      const node = getSelectedNode(selection);
      const parent = node.getParent();

      // Update links
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      // Update image
      if ($isImageNode(parent) || $isImageNode(node)) {
        setIsImage(true);
      } else {
        setIsImage(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <div className="mb-[1px] flex items-center gap-1 rounded-tr-lg rounded-tl-lg bg-white p-1">
      <button
        type="button"
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        className={`flex cursor-pointer rounded-lg p-2 align-middle text-gray-400 ${
          canUndo && "text-black"
        }`}
      >
        <ArrowCounterclockwise className="5-4 w-5" />
      </button>

      <button
        type="button"
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        className={`flex cursor-pointer rounded-lg p-2 align-middle text-gray-400 ${
          canRedo && "text-black"
        }`}
      >
        <ArrowClockwise className="5-4 w-5" />
      </button>

      <Divider />

      {supportedBlockTypes.has(blockType) && (
        <div ref={toolbarRef}>
          <button
            type="button"
            className="toolbar-item block-controls"
            onClick={() =>
              setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
            }
            aria-label="Formatting Options"
          >
            <span className="inline-flex items-center justify-between gap-2 rounded-lg p-2 text-sm transition-colors hover:bg-sky-50">
              {blockTypeToBlockName[blockType]}
              <ChevronDown className="5-4 w-5" />
            </span>
          </button>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockStylesDropdown
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />,
              document.body
            )}
        </div>
      )}

      <Divider />

      {Tools.map((group, index) => (
        <>
          {group.map((tool) => (
            <button
              type="button"
              key={tool.label}
              onClick={() => {
                if (tool.command) {
                  const { type, payload } = tool.command;
                  editor.dispatchCommand(type, payload);
                }
              }}
              className={`flex cursor-pointer rounded-lg p-2 align-middle text-gray-900 transition-colors 
                    ${
                      tool.command && formatState[tool.command.payload]
                        ? "bg-sky-100"
                        : "hover:bg-sky-50"
                    }`}
            >
              {tool.icon || tool.label}
            </button>
          ))}
          <Divider />
        </>
      ))}

      {/* Links */}

      <button
        onClick={insertLink}
        type="button"
        className={`flex cursor-pointer rounded-lg p-2 align-middle text-gray-900 ${
          isLink ? "bg-sky-100" : "hover:bg-sky-50"
        }`}
        aria-label="Insert Link"
      >
        <Link className="h-5 w-5" />
      </button>

      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}

      {/* Images */}

      {options?.allowImages !== false && (
        <button
          type="button"
          onClick={() => {
            dispatch(
              pushModal({
                type: ModalType.IMAGE_UPLOAD,
                id: `upload-image-${editorType}`,
              })
            );
          }}
          className={`flex cursor-pointer rounded-lg p-2 align-middle text-gray-900 ${
            isImage ? "bg-sky-100" : "hover:bg-sky-50"
          }`}
        >
          <Image className="h-5 w-5" />
        </button>
      )}

      {insertImageModal &&
        insertImageModal.id === `upload-image-${editorType}` && (
          <ModalContainer {...insertImageModal}>
            <ImageUploadForm
              onSave={(image: UploadedImage) => {
                // add the image to the lexical editor redux store
                dispatch(
                  addImageToEditor({ editor: editorType, image: image })
                );
                // insert the image into the editor
                editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                  imageId: image.id,
                  altText: image.altDescription,
                  src: image.url,
                  width: image.width,
                  height: image.height,
                });
                popModal();
              }}
            />
          </ModalContainer>
        )}
    </div>
  );
}
