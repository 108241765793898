import { Helmet } from "react-helmet-async";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Page404 from "../components/ErrorPages/404";
import Logout from "../components/Logout";
import ProtectedRoute from "../components/ProtectedRoute";
import { UserRole } from "../constants/firestore";
import { useAuthentication } from "../hooks/useAuthentication";
import OurStory from "./About";
import Admin from "./Admin";
import Makers from "./Admin/Makers";
import Newsletter from "./Admin/Newsletter";
import AdminOfferings from "./Admin/Offerings";
import Orders from "./Admin/Orders";
import Reports from "./Admin/Reports";
import Users from "./Admin/Users";
import CartPage from "./Cart";
import Checkout from "./Checkout";
import ContactPage from "./Contact";
import FaqPage from "./FAQ";
import Home from "./Home";
import MakersPage from "./Makers";
import MyAccountPage from "./MyAccount";
import ManageAccount from "./MyAccount/ManageAccount";
import ManageBilling from "./MyAccount/ManageBilling";
import MyOrders from "./MyAccount/MyOrders";
import NewsletterArchivePage from "./NewsletterArchive";
import NewsletterSignUpPage from "./NewsletterSignUp";
import Offerings from "./Offerings";
import PrayerPage from "./Prayer";
import PrivacyPolicy from "./PrivacyPolicy";
import ProductDetailsPage from "./ProductDetails";
import ShopPage from "./Shop";
import TermsAndConditions from "./TermsAndConditions";
import Unsubscribe from "./Unsubscribe";

const getDefaultAdminRoute = (userClaims: { [key in UserRole]?: boolean }) => {
  if (userClaims[UserRole.Admin] || userClaims[UserRole.FulfillmentManager]) {
    return "orders";
  } else if (userClaims[UserRole.AdminReadonlyOfferings]) {
    return "offerings";
  } else {
    return "orders"; // Fallback to orders if no roles match
  }
};

const AppBody = () => {
  const location = useLocation();
  const { claims } = useAuthentication();

  const defaultAdminRoute = !!claims ? getDefaultAdminRoute(claims) : "orders";

  return (
    <div
      className={`${
        !["/checkout", "/subscribe", "/admin", "/account"].some((path) =>
          location.pathname.startsWith(path)
        ) && "mt-24"
      } w-full flex-grow overflow-hidden`}
    >
      {/* Home Page */}
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Helmet>
                <title>Home | Maranatha Farm</title>
                <link rel="canonical" href="http://www.maranatha.farm/" />
              </Helmet>
              <Home />
            </>
          }
        />
        {/* Shop Page */}
        <Route path="/shop">
          <Route index element={<Navigate to="/shop/new" replace />} />

          <Route
            index={true}
            path=":type?"
            element={
              <>
                <Helmet>
                  <title>Shop | Maranatha Farm</title>
                  <link rel="canonical" href="http://www.maranatha.farm/shop" />
                </Helmet>
                <ShopPage />
              </>
            }
          />
          <Route
            path=":type/:offeringId/:optionId"
            element={<ProductDetailsPage />}
          />
        </Route>
        {/* Cart Page */}
        <Route
          path="/cart"
          element={
            <>
              <Helmet>
                <title>Cart | Maranatha Farm</title>
              </Helmet>
              <CartPage />
            </>
          }
        />
        {/* Checkout Page */}
        <Route
          path="/checkout"
          element={
            <>
              <Helmet>
                <title>Checkout | Maranatha Farm</title>
              </Helmet>
              <Checkout mode="payment" />
            </>
          }
        />
        <Route
          path="/subscribe"
          element={
            <>
              <Helmet>
                <title>Subscribe | Maranatha Farm</title>
              </Helmet>
              <Checkout mode="subscription" />
            </>
          }
        />
        {/* Offerings Page */}
        <Route
          path="/offerings"
          element={
            <>
              <Helmet>
                <title>Offerings | Maranatha Farm</title>
                <link
                  rel="canonical"
                  href="http://www.maranatha.farm/offerings"
                />
              </Helmet>
              <Offerings />
            </>
          }
        />
        {/* Makers Page */}
        <Route
          path="/makers/:makerSlug?"
          element={
            <>
              <Helmet>
                <title>Makers | Maranatha Farm</title>
                <link rel="canonical" href="http://www.maranatha.farm/makers" />
              </Helmet>
              <MakersPage />
            </>
          }
        />
        {/* Prayer Page */}
        <Route
          path="/prayer"
          element={
            <>
              <Helmet>
                <title>Prayer | Maranatha Farm</title>
                <link rel="canonical" href="http://www.maranatha.farm/prayer" />
              </Helmet>
              <PrayerPage />
            </>
          }
        />
        {/* Story Page */}
        <Route
          path="/story"
          element={
            <>
              <Helmet>
                <title>About | Maranatha Farm</title>
                <link rel="canonical" href="http://www.maranatha.farm/story" />
              </Helmet>
              <OurStory />
            </>
          }
        />
        {/* Contact Page */}
        <Route
          path="/contact"
          element={
            <>
              <Helmet>
                <title>Contact | Maranatha Farm</title>
                <link
                  rel="canonical"
                  href="http://www.maranatha.farm/contact"
                />
              </Helmet>
              <ContactPage />
            </>
          }
        />
        {/* FAQ Page */}
        <Route
          path="/faq"
          element={
            <>
              <Helmet>
                <title>FAQ | Maranatha Farm</title>
                <link rel="canonical" href="http://www.maranatha.farm/faq" />
              </Helmet>
              <FaqPage />
            </>
          }
        />
        {/* Newsletter Archive Page */}
        <Route
          path="/newsletter/archive/:id?"
          element={
            <>
              <Helmet>
                <title>Newsletter Archive | Maranatha Farm</title>
                <link
                  rel="canonical"
                  href="http://www.maranatha.farm/newsletter/archive"
                />
              </Helmet>
              <NewsletterArchivePage />
            </>
          }
        />
        {/* Newsletter Signup Page */}
        <Route
          path="/newsletter-signup"
          element={
            <>
              <Helmet>
                <title>Newsletter Signup | Maranatha Farm</title>
                <link
                  rel="canonical"
                  href="http://www.maranatha.farm/newsletter-signup"
                />
              </Helmet>
              <NewsletterSignUpPage />
            </>
          }
        />
        {/* Unsubscribe Page */}
        <Route
          path="/unsubscribe"
          element={
            <>
              <Helmet>
                <title>Unsubscribe | Maranatha Farm</title>
                <link
                  rel="canonical"
                  href="http://www.maranatha.farm/unsubscribe"
                />
              </Helmet>
              <Unsubscribe />
            </>
          }
        />
        {/* Account Page */}
        <Route
          path="/account"
          element={
            <ProtectedRoute requiredRoles={[UserRole.User]}>
              <Helmet>
                <title>My Account | Maranatha Farm</title>
                <link
                  rel="canonical"
                  href="http://www.maranatha.farm/account"
                />
              </Helmet>
              <MyAccountPage />
            </ProtectedRoute>
          }
        >
          <Route index={true} element={<Navigate to="orders" />} />
          <Route path="orders" element={<MyOrders />} />
          <Route path="billing" element={<ManageBilling />} />
          <Route path="settings" element={<ManageAccount />} />
        </Route>
        {/* Admin Page */}
        <Route
          path="/admin"
          element={
            <ProtectedRoute
              requiredRoles={[
                UserRole.Admin,
                UserRole.AdminReadonlyOfferings,
                UserRole.FulfillmentManager,
              ]}
            >
              <Helmet>
                <title>Admin | Maranatha Farm</title>
                <link rel="canonical" href="http://www.maranatha.farm/admin" />
              </Helmet>
              <Admin />
            </ProtectedRoute>
          }
        >
          <Route index={true} element={<Navigate to={defaultAdminRoute} />} />
          <Route
            path="orders"
            element={
              <ProtectedRoute
                requiredRoles={[UserRole.Admin, UserRole.FulfillmentManager]}
              >
                <Orders />
              </ProtectedRoute>
            }
          />
          <Route
            path="reports"
            element={
              <ProtectedRoute
                requiredRoles={[UserRole.Admin, UserRole.FulfillmentManager]}
              >
                <Reports />
              </ProtectedRoute>
            }
          />
          <Route
            path="offerings"
            element={
              <ProtectedRoute
                requiredRoles={[
                  UserRole.Admin,
                  UserRole.AdminReadonlyOfferings,
                ]}
              >
                <AdminOfferings />
              </ProtectedRoute>
            }
          />
          <Route
            path="makers"
            element={
              <ProtectedRoute requiredRoles={[UserRole.Admin]}>
                <Makers />
              </ProtectedRoute>
            }
          />
          <Route
            path="newsletter"
            element={
              <ProtectedRoute requiredRoles={[UserRole.Admin]}>
                <Newsletter />
              </ProtectedRoute>
            }
          />
          <Route
            path="users"
            element={
              <ProtectedRoute requiredRoles={[UserRole.Admin]}>
                <Users />
              </ProtectedRoute>
            }
          />
        </Route>
        {/* Logout Page */}
        <Route path="/logout" element={<Logout />} />
        {/* Privacy Policy Page */}
        <Route
          path="/privacy-policy"
          element={
            <>
              <Helmet>
                <title>Privacy | Maranatha Farm</title>
                <link
                  rel="canonical"
                  href="http://www.maranatha.farm/privacy-policy"
                />
              </Helmet>
              <PrivacyPolicy />
            </>
          }
        />
        {/* Terms & Conditions Page */}
        <Route
          path="/terms-and-conditions"
          element={
            <>
              <Helmet>
                <title>Terms &amp; Conditions | Maranatha Farm</title>
                <link
                  rel="canonical"
                  href="http://www.maranatha.farm/terms-and-conditions"
                />
              </Helmet>
              <TermsAndConditions />
            </>
          }
        />
        {/* 404 Page */}
        <Route
          path="*"
          element={
            <>
              <Helmet>
                <title>404 | Maranatha Farm</title>
              </Helmet>
              <Page404 />
            </>
          }
        />
      </Routes>
    </div>
  );
};

export default AppBody;
