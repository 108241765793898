import { createSelector } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { AppState } from "../..";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { popModal, pushModal } from "./reducer";
import { Modal, ModalType, ModalsState, OpenModal } from "./types";

export function usePopModal(type?: ModalType): () => void {
  const dispatch = useAppDispatch();
  return useCallback(() => dispatch(popModal(type)), [dispatch]);
}

const makeSelectModalsByType = () => {
  return createSelector(
    [
      ({ modals }: AppState) => modals,
      (state: AppState, type: ModalType) => type,
    ],
    (modals: ModalsState, type: ModalType): Modal[] => {
      return modals.openModals[type];
    }
  );
};

export const useOpenModal = (type: ModalType): OpenModal | null => {
  const openModalCount = useOpenModalCount(type);
  const openModals = useMemo(makeSelectModalsByType, [type]);
  const modalsOfType = useAppSelector((state) => openModals(state, type));
  const numOpenModalsOfType = modalsOfType.length;
  if (numOpenModalsOfType === 0) {
    return null;
  }
  return {
    index: openModalCount,
    ...modalsOfType[numOpenModalsOfType - 1],
  };
};

export function useOpenModalCount(type?: ModalType): number {
  return useAppSelector(({ modals: { modalOrder, openModals } }) => {
    // if the modal was just added, give it a higher priority by adding 1 to the count
    // since the LoginModal and CartModal use the count to determine their z-index
    let count = 0;
    const lastAddedModal =
      modalOrder.length > 0 ? modalOrder[modalOrder.length - 1] : undefined;
    if (lastAddedModal && lastAddedModal === type) {
      count = 1;
    }
    return Object.values(openModals).reduce(
      (acc, curr) => acc + curr.length,
      count
    );
  });
}

export function useToggleNewsletterSignupModal(): () => void {
  const openModals = useAppSelector(({ modals: { openModals } }) => openModals);
  const existingNewsletterSignupModal = useMemo(
    () => openModals[ModalType.NEWSLETTER_SIGNUP].length > 0,
    [openModals]
  );
  const dispatch = useAppDispatch();
  return useCallback(
    () =>
      existingNewsletterSignupModal
        ? dispatch(popModal(ModalType.NEWSLETTER_SIGNUP))
        : dispatch(
            pushModal({
              id: "newsletter-signup",
              type: ModalType.NEWSLETTER_SIGNUP,
            })
          ),
    [dispatch, existingNewsletterSignupModal]
  );
}

export function useToggleCartModal(): () => void {
  const openModals = useAppSelector(({ modals: { openModals } }) => openModals);
  const existingCartModal = useMemo(
    () => openModals[ModalType.CART].length > 0,
    [openModals]
  );
  const dispatch = useAppDispatch();
  return useCallback(
    () =>
      existingCartModal
        ? dispatch(popModal(ModalType.CART))
        : dispatch(pushModal({ id: "cart", type: ModalType.CART })),
    [dispatch, existingCartModal]
  );
}

export function useToggleLoginModal(): () => void {
  const openModals = useAppSelector(({ modals: { openModals } }) => openModals);
  const existingLoginModal = useMemo(
    () => openModals[ModalType.LOGIN].length > 0,
    [openModals]
  );
  const dispatch = useAppDispatch();
  return useCallback(
    () =>
      existingLoginModal
        ? dispatch(popModal(ModalType.LOGIN))
        : dispatch(pushModal({ id: "login", type: ModalType.LOGIN })),
    [dispatch, existingLoginModal]
  );
}

export function useToggleOrderDetailsModal(): () => void {
  const openModals = useAppSelector(({ modals: { openModals } }) => openModals);
  const existingOrderDetailsModal = useMemo(
    () => openModals[ModalType.ORDER_DETAILS].length > 0,
    [openModals]
  );
  const dispatch = useAppDispatch();
  return useCallback(
    () =>
      existingOrderDetailsModal
        ? dispatch(popModal(ModalType.ORDER_DETAILS))
        : dispatch(
            pushModal({ id: "order-details", type: ModalType.ORDER_DETAILS })
          ),
    [dispatch, existingOrderDetailsModal]
  );
}
