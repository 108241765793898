import { Spinner } from "@styled-icons/fa-solid";
import { ChevronUp } from "@styled-icons/fluentui-system-regular";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { UploadedImage } from "../../../redux/state/lexical/types";
import { usePopModal } from "../../../redux/state/modals/hooks";
import { FormikAdminInput } from "../../Formik";
import ImageUploader from "../../Formik/ImageUploadInput";
import { ALT_TEXT, BASE64, HEIGHT, IMAGE_URL, WIDTH } from "./fieldNames";
import { ImageUploadValues, InitialImageUploadValues } from "./initialValues";
import { ImageUploadSchema } from "./validationSchema";

type onSaveCallback = (image: UploadedImage) => any;

interface ImageUploadFormProps {
  allowHttpsUpload?: boolean;
  allowAdvancedOptions?: boolean;
  onSave: onSaveCallback;
}

const ImageUploadForm = ({
  allowHttpsUpload = true,
  allowAdvancedOptions = true,
  onSave,
}: ImageUploadFormProps) => {
  const popModal = usePopModal();

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  async function handleSubmit(
    values: ImageUploadValues,
    formikHelpers: FormikHelpers<ImageUploadValues>
  ) {
    const { setSubmitting, resetForm } = formikHelpers;

    const image: UploadedImage = {
      id: Date.now().toString(),
      altDescription: values.altText,
      url: values.url,
      width: values.width,
      height: values.height,
    };

    // the user either provided a url or uploaded a file
    // if they uploaded a file, convert the base64 string to a blob
    // and set the image url to the object url
    if (values.base64) {
      const sliceSize = 512;
      const byteCharacters = atob(values.base64);
      const byteArrays = [];
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const bytes = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          bytes[i] = slice.charCodeAt(i);
        }
        byteArrays.push(new Uint8Array(bytes));
      }
      image.url = URL.createObjectURL(
        new Blob(byteArrays, { type: "image/jpeg" })
      );
    }
    // execute the callback to handle the uploaded image
    await onSave(image);

    resetForm();
    setSubmitting(false);
    popModal();
  }

  return (
    <Formik
      initialValues={InitialImageUploadValues}
      onSubmit={handleSubmit}
      validationSchema={ImageUploadSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => (
        <Form
          className="w-96 space-y-4"
          onSubmit={(e: any) => {
            e.preventDefault();
            formikProps.validateForm();
            formikProps.handleSubmit();
          }}
          noValidate
        >
          <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 sm:mb-5">
            <h1 className="font-semibold text-lg text-gray-900">
              Upload Image
            </h1>
          </div>

          <div className="space-y-3 rounded-md border-2 border-dashed border-gray-300 bg-gray-100 p-3">
            {allowHttpsUpload && (
              <>
                <Field
                  type="input"
                  name={IMAGE_URL}
                  placeholder="https://"
                  title="URL"
                  component={FormikAdminInput}
                />

                <div>
                  <div className="flex items-center justify-center">
                    <div className="w-full border-t border-gray-400"></div>
                    <div className="px-3 text-gray-400 dark:text-gray-600">
                      OR
                    </div>
                    <div className="w-full border-t border-gray-400"></div>
                  </div>
                </div>
              </>
            )}

            <ImageUploader formikFieldName={BASE64}></ImageUploader>
          </div>

          {allowAdvancedOptions && (
            <div className="rounded-md border border-gray-300 bg-gray-100 py-2 px-4">
              <button
                type="button"
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
                className="w-full space-x-4 rounded-md text-left text-sm text-gray-600"
              >
                <ChevronUp
                  className={`icon-sm text-gray-800 transition ${
                    showAdvancedOptions && "rotate-180"
                  }`}
                />
                <span>Advanced options</span>
              </button>
              {showAdvancedOptions && (
                <div className="mt-2 space-y-2 border-t border-gray-300 pt-2">
                  <Field
                    type="input"
                    name={ALT_TEXT}
                    placeholder=""
                    title="Alternative Description"
                    component={FormikAdminInput}
                  />
                  <div className="flex gap-4">
                    <Field
                      type="number"
                      name={WIDTH}
                      placeholder=""
                      title="Width"
                      component={FormikAdminInput}
                    />
                    <Field
                      type="number"
                      name={HEIGHT}
                      placeholder=""
                      title="Height"
                      component={FormikAdminInput}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="flex w-full items-center justify-between">
            <button
              type="button"
              className="rounded bg-gray-500 py-2 px-4 font-bold text-white hover:bg-gray-600"
              onClick={() => {
                formikProps.resetForm();
                popModal();
              }}
            >
              Cancel
            </button>
            <button
              // using type="submit" causes the form to trigger the newsletter blast
              type="button"
              onClick={() => formikProps.submitForm()}
              className="rounded bg-teal-500 py-2 px-4 font-bold text-white hover:bg-teal-600"
            >
              {formikProps.isSubmitting ? <Spinner /> : <span>Save</span>}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ImageUploadForm;
