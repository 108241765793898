import { useAppDispatch, useAppSelector } from "../../hooks";
import { addToast } from "./reducer";
import { Toast } from "./types";

export function useToasts(): Toast[] {
  const toasts = useAppSelector(({ toasts: { toasts } }) => toasts);
  return toasts;
}

export const useShowToast = () => {
  const dispatch = useAppDispatch();
  const currentId = useAppSelector(({ toasts: { currentId } }) => currentId);
  const id = currentId + 1;
  return {
    showToast: ({ code, title, body }: any) => {
      dispatch(addToast({ id, code, title, body }));
    },
    showSuccessToast: (title: string, body: string) => {
      dispatch(addToast({ id, code: 0, title, body }));
    },
    showErrorToast: (title: string, body: string) => {
      dispatch(addToast({ id, code: -1, title, body }));
    },
  };
};
