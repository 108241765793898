import { ImageAdd } from "@styled-icons/fluentui-system-filled";
import { useField } from "formik";
import { ChangeEvent, DragEvent, useEffect, useState } from "react";

interface Props {
  label?: string;
  formikFieldName: string;
}

/**
 * Image upload input component for Formik forms.
 * The formikFieldName prop is the name of the field in the formik form.
 * It's value will be the base64 encoded image data, excluding the prefix.
 * @returns
 */
const ImageUploader = ({ label, formikFieldName: name }: Props) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const hasError = meta.touched && !!meta.error;

  const [imgSrc, setImgSrc] = useState<string | null>(field.value ?? null);

  useEffect(() => {
    if (field.value.startsWith("http")) {
      setImgSrc(field.value);
    }
  }, [field.value]);

  async function handleDrop(e: DragEvent<HTMLElement>) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileProvided(file);
  }

  async function handleFileChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    handleFileProvided(file);
  }

  async function handleFileProvided(file: File) {
    if (!file) {
      return;
    }

    if (file.type.match(/^(image\/jpeg|image\/jpg|image\/png)$/i) === null) {
      alert("Please provide a JPEG, JPG, or PNG file.");
      return;
    }

    const fr = new FileReader();

    fr.addEventListener(
      "loadend",
      () => {
        if (fr.result) {
          const fullBase64 = fr.result.toString();
          const base64Data = fullBase64.split(",")[1]; // extract the raw base64 data
          setImgSrc(fullBase64); // use full base64 (with prefix) for display
          setValue(base64Data); // only store raw base64 data in formik value
        }
      },
      false
    );

    fr.readAsDataURL(file);
  }

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm text-zinc-600 dark:text-zinc-300"
      >
        {label ?? "Image File"}
      </label>

      <label
        className={`mt-2 flex w-full cursor-pointer flex-col items-center rounded-md border border-gray-400 bg-white p-5 text-center hover:bg-gray-100`}
        onDrop={async (e) => await handleDrop(e)}
        onDragOver={(e) => e.preventDefault()}
      >
        {imgSrc ? (
          <img className="h-52 w-auto" src={imgSrc} alt={"Upload"} />
        ) : (
          <>
            <ImageAdd className="m-4 max-h-[3rem] min-h-[3rem] text-gray-400 hover:cursor-pointer" />

            <h2 className="font-medium mt-1 tracking-wide text-gray-900">
              Add Photo
            </h2>

            <p className="mt-2 text-xs tracking-wide text-gray-500">
              or drag and drop
            </p>
          </>
        )}

        {hasError && (
          <div className="ml-1 mt-1 text-sm text-red-700">{meta.error}</div>
        )}

        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          maxLength={2048}
          onChange={async (e) => await handleFileChange(e)}
          className="hidden"
        />
      </label>
    </div>
  );
};

export default ImageUploader;
