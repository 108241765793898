import { UseQueryHookResult } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { ReactNode, useEffect, useState } from "react";
import { animated, useSpring, useTransition } from "react-spring";
import Loader from "../Loader";

interface IAdminSectionProps {
  children: ReactNode;
  title: string | ReactNode;
  query?: UseQueryHookResult<any>;
  loading: boolean;
  loadingMessage?: string;
  order: number;
  base64File?: string;
  fileName?: string;
}

const DashboardSection = ({
  children,
  title,
  query,
  loading,
  loadingMessage,
  order,
  base64File,
  fileName,
}: IAdminSectionProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(loading);

  useEffect(() => {
    if (loading === false) {
      // set a timeout
      const timeout = setTimeout(() => {
        setIsLoading(loading);
      }, 350);
      return;
    }

    setIsLoading(loading);
  }, [loading]);

  const spring = useSpring({
    delay: 100 + order * 200,
    config: {
      tension: 120,
      friction: 14,
    },
    from: {
      transform: "translateX(10%)",
      opacity: 0,
    },
    to: {
      transform: "translateX(0)",
      opacity: 1,
    },
  });

  const childTransition = useTransition(!loading, {
    config: {
      mass: 1,
      tension: 120,
      friction: 14,
      duration: 500,
    },
    from: {
      opacity: 0,
      // maxHeight: "0px",
    },
    enter: {
      opacity: 1,
      // maxHeight: "1000px",
    },
    leave: {
      opacity: 0.3,
      // maxHeight: "0px",
    },
  });

  return (
    <div
      style={{ zIndex: 25 - order }}
      className="relative mb-4 w-full last:mb-96 md:px-4"
    >
      <animated.div
        style={spring}
        className="flex-col justify-center space-y-4 p-4"
      >
        {isLoading ? (
          <div className="flex flex-col items-center justify-center p-8">
            <Loader />
            <h1 className="text-lg">{loadingMessage}</h1>
          </div>
        ) : (
          childTransition(
            (style, show) =>
              show && (
                <animated.div style={style} className="">
                  {children}
                </animated.div>
              )
          )
        )}
      </animated.div>
    </div>
  );
};

export default DashboardSection;
