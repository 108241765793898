import { Dollar, ExternalLink } from "@styled-icons/fa-solid";
import { BoxMultiple, Money, Tag } from "@styled-icons/fluentui-system-filled";
import { StyledIcon } from "@styled-icons/styled-icon";
import { Card, Flex, Title } from "@tremor/react";
import { ReactNode, useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import CrudTable, { RowData } from "../../../components/CrudTable";
import { DropdownItem } from "../../../components/Dropdown";
import UpsertOfferingForm from "../../../components/Forms/UpsertOfferingForm";
import { useDeleteOfferingMutation } from "../../../redux/api/offerings/api";
import { usePaginatedOfferings } from "../../../redux/api/offerings/hooks";
import { resetOfferings } from "../../../redux/api/offerings/reducer";
import {
  AllOfferingTypes,
  Offering,
  OfferingOption,
  OfferingType,
} from "../../../redux/api/offerings/types";
import { useAppDispatch } from "../../../redux/hooks";
import { ModalType } from "../../../redux/state/modals/types";
import { useShowToast } from "../../../redux/state/toasts/hooks";

const SearchOfferings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { showToast } = useShowToast();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [triggerDeleteOffering] = useDeleteOfferingMutation();

  const categoryFilter = searchParams.get("category");

  useEffect(() => {
    // reset search when category changes
    setSearchQuery("");
  }, [searchParams.get("category")]);

  const { allOfferings, isLoading, loadMore, totalOfferings } =
    usePaginatedOfferings({
      limit: 25,
      active: false,
      orderBy: "name-asc",
      ...(categoryFilter && { category: categoryFilter as OfferingType }),
      ...(searchQuery && { search: searchQuery }),
    });

  async function handleDeleteOffering(id: string | null) {
    console.log(`Deleting offering with id ${id}`);
    try {
      if (id) {
        const res = await triggerDeleteOffering({ id }).unwrap();
        dispatch(resetOfferings());
        console.log(`Successfully deleted offering with id ${id}`, res);
      }
    } catch (e) {
      console.log(`Failed to delete offering with id ${id}`, e);
    }
  }

  const crudTableRows = allOfferings.map((offering) => {
    const searchIndices = [
      offering.name,
      ...offering.options.flatMap((option: OfferingOption) => [option.name]),
    ];

    return OfferingCrudTableRow(offering, [], searchIndices);
  });

  return (
    <Card>
      <Flex justifyContent="between" className="mb-6">
        <Title>Search Offerings</Title>
      </Flex>

      <CrudTable
        navigation={false}
        setSearchQuery={setSearchQuery}
        rows={crudTableRows}
        modalType={ModalType.OFFERING_CRUD}
        crudFormFactory={(id: string | null) => (
          <UpsertOfferingForm offeringId={id} />
        )}
        loading={isLoading}
        deleteAction={handleDeleteOffering}
        imageMode={"cover"}
        filterConfig={{
          category: {
            values: AllOfferingTypes,
            label: "Category",
            type: "text",
          },
          // status: {
          //   values: ["active", "inactive"],
          //   label: "Status",
          //   type: "text",
          // },
        }}
      />
      {/* Footer Pagination Control */}
      {!isLoading && (
        <div className="my-0.5">
          <div
            className={`border-b-zinc-20 relative flex h-fit w-full flex-col items-center justify-center gap-4 rounded-bl-md rounded-br-md p-2 text-xs font-bold text-zinc-500 sm:flex-row`}
          >
            <div className="flex flex-col items-center gap-2">
              <p>
                Showing {allOfferings.length} of {totalOfferings} items
              </p>
              {allOfferings.length < totalOfferings && (
                <button
                  className="rounded-lg border px-4 py-2 transition-colors hover:bg-teal-500 hover:text-white"
                  onClick={() => {
                    loadMore();
                  }}
                >
                  <span>Load More</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

const StatusTag = ({
  numInactive,
  totalOptions,
}: {
  numInactive: number;
  totalOptions: number;
}) => (
  <div
    className={`h-fit w-fit rounded-md shadow-sm ${
      numInactive === 0
        ? "bg-green-500"
        : numInactive === totalOptions
        ? "bg-red-500"
        : "bg-amber-500"
    } px-2 py-0.5 text-xs uppercase tracking-wide text-white`}
  >
    <p>
      {numInactive === 0 ? (
        <span>active</span>
      ) : numInactive === totalOptions ? (
        <span>inactive</span>
      ) : (
        <span>
          {numInactive} of {totalOptions} inactive
        </span>
      )}
    </p>
  </div>
);

const DataField = ({
  icon: Icon,
  label,
  children,
}: {
  icon: StyledIcon;
  label: string;
  children: ReactNode;
}) => (
  <div className="text-base">
    <h6 className="flex items-center space-x-1 text-sm font-semibold text-stone-500">
      <Icon className="inline-block h-4 w-4 text-stone-400" />
      <span>{label}</span>
    </h6>
    {children}
  </div>
);

const OfferingCrudTableRow = (
  offering: Offering,
  actions: DropdownItem[],
  searchIndices: string[]
): RowData => {
  const prices = (offering?.options ?? [])
    .map((option) => option.price)
    .sort((a, b) => a - b) ?? [0];

  const minPrice = prices[0];
  const maxPrice = prices[prices.length - 1];

  const isSoldOut = offering.options?.every(
    (option) => option.availableInventory === 0
  );

  const firstActiveOption = offering.options?.find((o) => o.active);
  const numInactive = offering.options?.reduce(
    (acc, option) => acc + (option.active ? 0 : 1),
    0
  );

  const data = (
    <div key={offering.id} className="grid h-full grid-cols-12">
      <div className="col-span-12 flex h-full flex-col items-start justify-between space-y-4 whitespace-nowrap p-4 md:col-span-6">
        <div className=" max-w-full space-y-2">
          {/* Name */}
          <h1 className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold text-stone-700">
            {offering.name}
          </h1>
          <div className="flex flex-wrap items-center gap-2">
            {StatusTag({ numInactive, totalOptions: offering.options.length })}
            {isSoldOut && (
              <div className="h-fit w-fit rounded-md border bg-stone-50 px-1 py-0.5 text-xs uppercase tracking-wide text-red-600 shadow-sm">
                <p>Sold Out</p>
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full flex-wrap gap-2">
          {/* {transitDistance !== TransitDistance.None && (
            <div className="flex max-w-fit flex-1 flex-col items-center justify-center text-base">
              <TransitDistanceIcon distance={transitDistance} />
            </div>
          )} */}
          {firstActiveOption && (
            <p className="flex-0 group flex h-fit w-fit items-center gap-2 text-lg hover:text-sky-700">
              <NavLink
                to={`/shop/${offering.type}/${offering.id}/${firstActiveOption.id}`}
                className="rounded-md bg-sky-700 px-2 py-1 text-sm text-white transition-colors group-hover:bg-sky-600"
              >
                Shop Page
              </NavLink>
              <ExternalLink className="h-3 w-3" />
            </p>
          )}
        </div>
      </div>

      <div className="col-span-12 flex h-full flex-col p-4 md:col-span-6">
        <div className="flex h-fit flex-wrap gap-4 sm:gap-8">
          <DataField icon={Tag} label="Category">
            <p className="font-semibold capitalize">
              {offering.type.replaceAll("-", " ")}
            </p>
          </DataField>
          {!!minPrice && !!maxPrice && (
            <DataField icon={Money} label="Price">
              <p className="space-x-1 font-semibold capitalize">
                <span>
                  {minPrice.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </span>
                {minPrice < maxPrice && (
                  <>
                    <span>-</span>
                    <span>
                      {maxPrice.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </span>
                  </>
                )}
              </p>
            </DataField>
          )}
          <DataField icon={BoxMultiple} label="Stock">
            <p className="flex items-center space-x-2 font-semibold capitalize">
              {offering.options.reduce(
                (acc, option) => acc + option.availableInventory,
                0
              ) < 5 ? (
                <span className="inline-block h-2 w-2 rounded-full bg-red-600"></span>
              ) : offering.options.reduce(
                  (acc, option) => acc + option.availableInventory,
                  0
                ) >= 5 &&
                offering.options.reduce(
                  (acc, option) => acc + option.availableInventory,
                  0
                ) <= 10 ? (
                <span className="inline-block h-2 w-2 rounded-full bg-yellow-500"></span>
              ) : (
                <span className="inline-block h-2 w-2 rounded-full bg-green-600"></span>
              )}
              <span>
                {offering.options.reduce(
                  (acc, option) => acc + option.availableInventory,
                  0
                )}
              </span>
            </p>
          </DataField>
          {offering.taxBehavior && (
            <DataField icon={Dollar} label="Tax">
              <p className="font-semibold capitalize">{offering.taxBehavior}</p>
            </DataField>
          )}
        </div>
      </div>
    </div>
  );

  return {
    id: offering.id,
    displayName: offering.name,
    imageUrl: offering.options[0]?.image,
    altImageUrl: offering.options[0]?.image,
    data: data,
    actions: actions,
    searchIndices: searchIndices,
  };
};

export default SearchOfferings;
