import {
  Action,
  Middleware,
  MiddlewareAPI,
  isFulfilled,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import { addToast } from "../state/toasts/reducer";

/**
 * Log a warning and show a toast
 */
export const handleApiResponseValue: Middleware =
  (api: MiddlewareAPI) => (next) => (action: Action) => {
    const { type } = action;

    // Ignore actions that are not the result of an API call or mutation
    if (!type.endsWith("/fulfilled") && !type.endsWith("/rejected")) {
      return next(action);
    }

    let payload;
    let isError = false;

    if (isRejectedWithValue(action)) {
      isError = true;
      payload = (action.payload as any).data?.data;
    } else if (isFulfilled(action)) {
      payload = (action.payload as any)?.data;
    }

    // Check if the payload contains a data object
    if (!payload) {
      console.warn(
        `The action ${type} was ${
          isError ? "rejected" : "fulfilled"
        } with a value but the payload does not contain a valid data object.`
      );
      return next(action);
    }

    // Only show a toast if the response has a code, message, and clientMessage
    if (payload.code && payload.message && payload.clientMessage) {
      api.dispatch(
        addToast({
          id: api.getState().toasts.currentId + 1,
          code: payload.code,
          title: payload.message,
          body: payload.clientMessage,
        })
      );
    }

    return next(action);
  };
