import { ONETIME, SUBSCRIPTION, SUBSCRIPTION_PRICE_ID } from "./fieldNames";

export interface PurchaseOptionValues {
  [ONETIME]?: boolean;
  [SUBSCRIPTION]?: boolean;
  [SUBSCRIPTION_PRICE_ID]?: string | undefined;
}

export function initialPurchaseOptionValues(
  subscribable: boolean
): PurchaseOptionValues {
  return {
    ...(subscribable && {
      [ONETIME]: true,
      [SUBSCRIPTION]: false,
      [SUBSCRIPTION_PRICE_ID]: undefined,
    }),
  };
}
