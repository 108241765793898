import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";
import reportsApi from "../reports/api";
import { Order } from "./types";

const ordersApi = createApi({
  reducerPath: "ordersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/orders`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    /**
     * Search orders
     */
    searchOrders: builder.query({
      query: (searchParams) => {
        const params = Object.fromEntries(
          Object.entries(searchParams).filter(
            ([_, value]) => value !== null && value !== undefined
          )
        );
        return {
          url: `/search`,
          params: params,
          method: "GET",
        };
      },
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as Order[],
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ orderId }) => ({
                type: "Order" as const,
                id: orderId,
              })),
              "Order",
            ]
          : ["Order"],
    }),

    /**
     * Update an order
     */
    updateOrder: builder.mutation({
      query: ({ orderId, ...patch }) => ({
        url: `/${orderId}`,
        body: patch,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Order", id: arg.orderId },
      ],
      transformResponse: (response, meta, arg) => (response as any).data.data,
      onQueryStarted: async (
        { orderId, ...patch },
        { dispatch, queryFulfilled }
      ) => {
        if (patch.fulfilled) {
          // Invalidate the FulfillmentReports query so that it will be refetched
          dispatch(reportsApi.util.invalidateTags(["FulfillmentReports"]));
          // Remove the order from the unfulfilled orders list when it is fulfilled
          const patchResult = dispatch(
            ordersApi.util.updateQueryData(
              "searchOrders",
              {
                fulfilled: false,
              },
              (draft) => {
                return draft.filter((order) => order.orderId !== orderId);
              }
            )
          );
          // Undo the optimistic update if the request fails
          queryFulfilled.catch(patchResult.undo);
        }
      },
    }),
  }),
});

export const { useSearchOrdersQuery, useUpdateOrderMutation } = ordersApi;

export default ordersApi;
