import { createContext, FC, ReactNode, useContext } from "react";
import { EditorType } from "../redux/state/lexical/types";

/**
 * The purpose of this context is to wrap the LexicalEditor component
 * and its children with the type of editor that is being used, so that the
 * html/images of the editor can be captured and saved to the redux store.
 */

type ContextState = {
  editorType: EditorType;
};

const LexicalEditorContext = createContext<ContextState | undefined>(undefined);

interface Props {
  children: ReactNode;
  type: EditorType;
}

const LexicalEditorProvider: FC<Props> = ({ children, type }: Props) => {
  const state: ContextState = {
    editorType: type,
  };

  return (
    <LexicalEditorContext.Provider value={state}>
      {children}
    </LexicalEditorContext.Provider>
  );
};

function useLexicalEditor() {
  const context = useContext(LexicalEditorContext);

  if (!context) {
    throw new Error(
      "useLexicalEditorId must be used within a LexicalEditorIdProvider"
    );
  }

  return context;
}

export { LexicalEditorProvider, useLexicalEditor };
