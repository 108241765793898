import * as Sentry from "@sentry/react";
import { logEvent } from "firebase/analytics";
import { ReactNode, useEffect } from "react";
import { analytics } from "../../config/firebase";
import Page500 from "../ErrorPages/500";

interface IFallbackProps {
  error: Error;
  eventId: string | null;
}

const Fallback = ({ error, eventId }: IFallbackProps) => {
  const errorDetails = error.stack || error.message;

  useEffect(() => {
    logEvent(analytics, "exception", {
      description: `Message: ${error.message}\nStack:${error.stack}`,
    });
  }, []);

  return <Page500 />;
};

async function updateServiceWorker(): Promise<ServiceWorkerRegistration> {
  const ready = await navigator.serviceWorker.ready;
  // the return type of update is incorrectly typed as Promise<void>. See
  // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration/update
  return ready.update() as unknown as Promise<ServiceWorkerRegistration>;
}

const updateServiceWorkerInBackground = async () => {
  try {
    const registration = await updateServiceWorker();

    // We want to refresh only if we detect a new service worker is waiting to be activated.
    // See details about it: https://web.dev/service-worker-lifecycle/
    if (registration?.waiting) {
      await registration.unregister();

      // Makes Workbox call skipWaiting().
      // For more info on skipWaiting see: https://web.dev/service-worker-lifecycle/#skip-the-waiting-phase
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  } catch (error) {
    console.error("Failed to update service worker", error);
  }
};

interface IErrorBoundaryProps {
  children: ReactNode;
}

const ErrorBoundary = ({ children }: IErrorBoundaryProps) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, eventId }) => (
        <Fallback error={error} eventId={eventId} />
      )}
      beforeCapture={(scope) => {
        scope.setLevel("fatal");
      }}
      onError={updateServiceWorkerInBackground}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
