import { Save } from "@styled-icons/fluentui-system-regular";
import { Field, Form, Formik, FormikHelpers } from "formik";
import _ from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux";
import {
  useCreateMakerMutation,
  useUpdateMakerMutation,
} from "../../../redux/api/makers/api";
import { makeSelectMakerById } from "../../../redux/api/makers/hooks";
import { useAppDispatch } from "../../../redux/hooks";
import { makeSelectEditor } from "../../../redux/state/lexical/hooks";
import { resetEditor } from "../../../redux/state/lexical/reducer";
import { EditorType } from "../../../redux/state/lexical/types";
import { usePopModal } from "../../../redux/state/modals/hooks";
import { ModalType } from "../../../redux/state/modals/types";
import { FormikAdminInput } from "../../Formik";
import ImageUploader from "../../Formik/ImageUploadInput";
import LexicalEditor from "../../LexicalEditor";
import Spinner from "../../Spinner";
import {
  MAKER_DESCRIPTION,
  MAKER_FEATURE_IMAGE,
  MAKER_LOGO,
  MAKER_NAME,
  MAKER_WEBSITE,
} from "./fieldNames";
import { MakerValues, initMakerValues } from "./initialValues";
import { makerValidationSchema } from "./validationSchema";

interface Props {
  readonly makerId: string | null;
}

const UpsertMakerForm = ({ makerId }: Props) => {
  const dispatch = useAppDispatch();

  const selectEditor = makeSelectEditor();
  const editor = useSelector((state: AppState) =>
    selectEditor(state, EditorType.MakerCrud)
  );

  const selectMakerById = useMemo(makeSelectMakerById, [makerId]);
  const maker = useSelector((state: AppState) =>
    selectMakerById(state, makerId)
  );

  const [triggerCreateMaker] = useCreateMakerMutation();
  const [triggerUpdateMaker] = useUpdateMakerMutation();

  const popModal = usePopModal(ModalType.MAKER_CRUD);

  /**
   * Handle form submission
   * @param values
   * @param formikHelpers
   */
  async function handleSubmit(
    values: MakerValues,
    formikHelpers: FormikHelpers<MakerValues>
  ) {
    if (maker) {
      await handleUpdate(maker.id, values, formikHelpers);
    } else {
      await handleCreate(values, formikHelpers);
    }
  }

  async function handleUpdate(
    id: string,
    values: MakerValues,
    formikHelpers: FormikHelpers<MakerValues>
  ): Promise<void> {
    const initVals: MakerValues = initMakerValues(maker);
    const patchBody = _.omitBy(values, (v, k) => v === (initVals as any)[k]);

    // if the patchBody contains a Logo or Feature Image, ensure they are base64 otherwise remove them
    if (patchBody.base64Logo && patchBody.base64Logo.startsWith("http")) {
      delete patchBody.base64Logo;
    }

    if (
      patchBody.base64FeatureImage &&
      patchBody.base64FeatureImage.startsWith("http")
    ) {
      delete patchBody.base64FeatureImage;
    }

    const res = await triggerUpdateMaker({
      id: id,
      body: patchBody,
    }).unwrap();
    if (res.logoUrl) {
      console.log(res.logoUrl);
      // perform a manual update of the maker in the store to bust the image
    }
    formikHelpers.setSubmitting(false);
    popModal();
  }

  /**
   * Create a new offering
   * @param values
   * @param formikHelpers
   */
  async function handleCreate(
    values: MakerValues,
    formikHelpers: FormikHelpers<MakerValues>
  ) {
    try {
      await triggerCreateMaker(values);
      formikHelpers.resetForm();
      dispatch(resetEditor(editor.id));
      formikHelpers.setSubmitting(false);
      popModal();
    } catch (e) {
      console.error(`error creating maker: ${e}`);
    }
  }

  return (
    <div>
      <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 dark:border-gray-600 sm:mb-5">
        <h1 className="text-lg font-semibold text-gray-900 dark:text-white">
          {true ? <span>Create Maker</span> : <span>Update Maker</span>}
        </h1>
      </div>

      <Formik
        initialValues={initMakerValues(maker)}
        onSubmit={handleSubmit}
        validationSchema={makerValidationSchema}
      >
        {(props) => {
          return (
            <Form
              className="space-y-4"
              onSubmit={(e) => {
                e.preventDefault();
                props.validateForm();
                props.handleSubmit();
              }}
              noValidate
            >
              <div className="flex w-full gap-2">
                <Field
                  type="text"
                  name={MAKER_NAME}
                  title="Name"
                  placeholder="Maker name"
                  component={FormikAdminInput}
                />
                <Field
                  type="text"
                  name={MAKER_WEBSITE}
                  title="Website URL"
                  placeholder="https://www.example.com"
                  component={FormikAdminInput}
                />
              </div>

              <LexicalEditor
                label="Description"
                type={EditorType.MakerCrud}
                fieldName={MAKER_DESCRIPTION}
                toolbarOptions={{
                  allowImages: false,
                }}
              />

              <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2">
                <ImageUploader label={"Logo"} formikFieldName={MAKER_LOGO} />
                <ImageUploader
                  label={"Feature Image"}
                  formikFieldName={MAKER_FEATURE_IMAGE}
                />
              </div>

              <div className="flex space-x-2">
                {props.dirty && (
                  <button
                    type="submit"
                    className="flex items-center gap-2 rounded-md bg-teal-600 p-2 text-white"
                  >
                    {props.isSubmitting ? (
                      <>
                        <Spinner />
                        <span>Saving...</span>
                      </>
                    ) : (
                      <>
                        <Save className="h-5" />
                        <span>{maker ? "Update" : "Save"} Maker</span>
                      </>
                    )}
                  </button>
                )}
                <button
                  type="button"
                  onClick={() => {
                    props.resetForm();
                    dispatch(resetEditor(editor.id));
                    popModal();
                  }}
                  className="rounded-md border bg-white p-2"
                >
                  Discard
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default UpsertMakerForm;
