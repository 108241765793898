import { ChangeEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DashboardSection from "../../components/DashboardSection";
import Loader from "../../components/Loader";
import { ValidEmail } from "../../constants/regex";
import {
  usePatchUserRolesMutation,
  useRetrieveAllRolesQuery,
  useRetrieveUserRolesQuery,
} from "../../redux/api/roles/api";

interface Props {}

const ManageRoles = ({}: Props) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [_searchInput, _setSearchInput] = useState<string>();

  const {
    data: allUserRoles,
    error,
    isLoading,
    isFetching,
  } = useRetrieveAllRolesQuery(null);

  const {
    data: userRoles,
    error: userRolesError,
    isLoading: loadingUserRoles,
    isFetching: fetchingUserRoles,
  } = useRetrieveUserRolesQuery(
    {
      email: searchParams.get("email"),
    },
    { skip: !searchParams.has("email") }
  );

  const [triggerPatchUserRoles] = usePatchUserRolesMutation();

  const _isLoading =
    isLoading || isFetching || loadingUserRoles || fetchingUserRoles;

  async function handleSearchUser() {
    if (!_searchInput || !ValidEmail.test(_searchInput)) {
      return;
    }

    setSearchParams({ email: _searchInput });
  }

  async function handleUpdateUserRole(
    e: ChangeEvent<HTMLInputElement>,
    roleId: string
  ) {
    const patchRoles = {} as { [role: string]: boolean };
    patchRoles[roleId] = e.currentTarget.checked;

    await triggerPatchUserRoles({
      email: searchParams.get("email"),
      roles: patchRoles,
    });
  }

  function handleSearchInputChange(e: React.FormEvent<HTMLInputElement>) {
    _setSearchInput(e.currentTarget.value);
  }

  return (
    <DashboardSection title={"Manage User Roles"} loading={false} order={1}>
      <div className="flex w-full">
        <input
          className="search-input mr-2"
          type={"text"}
          placeholder={"Search a user by email"}
          defaultValue={searchParams.get("email") ?? undefined}
          onKeyDown={(e) => e.key === "Enter" && handleSearchUser()}
          onChange={handleSearchInputChange}
        />

        <button className="btn" onClick={handleSearchUser}>
          Search
        </button>
      </div>

      {_isLoading && <Loader />}

      {!_isLoading &&
        allUserRoles &&
        !error &&
        !userRolesError &&
        searchParams.get("email") && (
          <div className="pl-2 pt-2">
            <h1 className="my-2 text-lg">User Roles:</h1>
            {allUserRoles &&
              allUserRoles.map(({ id, name }) => {
                const userHasRole = (userRoles ?? {})[id] === true;

                return (
                  <div className="flex p-1" key={id}>
                    <input
                      className="mx-2 w-4 border-red-300 bg-red-100 text-red-500 focus:ring-red-200"
                      type="checkbox"
                      id={id}
                      value={id}
                      name={id}
                      defaultChecked={userHasRole}
                      onChange={async (e) => await handleUpdateUserRole(e, id)}
                    />

                    <label htmlFor={id} className="text-lg">
                      {name}
                    </label>
                  </div>
                );
              })}
          </div>
        )}
    </DashboardSection>
  );
};

export default ManageRoles;
