import {
  ArrowUturnLeftIcon,
  CheckIcon,
  CurrencyDollarIcon,
  EyeIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Drag } from "@styled-icons/fluentui-system-filled";
import {
  Button,
  Card,
  Col,
  Flex,
  Grid,
  Icon,
  List,
  ListItem,
  Subtitle,
  Switch,
  Title,
} from "@tremor/react";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  ResponderProvided,
} from "react-beautiful-dnd";
import { RingLoader } from "react-spinners";
import Stripe from "stripe";
import * as Yup from "yup";
import {
  NumberInput,
  TextInput,
  Textarea,
} from "../../../components/Formik/Tremor";
import {
  useCreatePickupLocationMutation,
  useDeletePickupLocationMutation,
  useRetrievePickupLocationsQuery,
  useUpdatePickupLocationMutation,
} from "../../../redux/api/offerings/api";
import { PickupLocation } from "../../../redux/api/offerings/types";
import {
  useCreateShippingRateMutation,
  useGetShippingRatesQuery,
  useUpdateShippingRateMutation,
} from "../../../redux/api/stripe/api";

// Formik PICKUP LOCATION Config
const PICKUP_LOCATION_ACTIVE = "active";
const PICKUP_LOCATION_NAME = "name";
const PICKUP_LOCATION_DESCRIPTION = "description";
const PICKUP_LOCATION_PRIORITY = "priority";
interface PickupLocationValues {
  [PICKUP_LOCATION_ACTIVE]?: boolean;
  [PICKUP_LOCATION_NAME]?: string;
  [PICKUP_LOCATION_DESCRIPTION]?: string;
  [PICKUP_LOCATION_PRIORITY]?: number;
}
const PickupLocationSchema = Yup.object().shape({
  // [PICKUP_LOCATION_ACTIVE]: Yup.boolean().required(),
  [PICKUP_LOCATION_PRIORITY]: Yup.number()
    .positive("Priority must be positive")
    .required("Priority is required"),
  [PICKUP_LOCATION_NAME]: Yup.string()
    .min(3, "Name is too short")
    .max(35, "Name is too long")
    .required("Name is required"),
  [PICKUP_LOCATION_DESCRIPTION]: Yup.string()
    .min(3, "Description is too short")
    .max(75, "Description is too long")
    .required("Description is required"),
});
// End Formik Config

// Formik SHIPPING RATES Config
const SHIPPING_RATE_ACTIVE = "active";
const SHIPPING_RATE_HIDDEN = "hidden";
const SHIPPING_RATE_CURRENCY = "currency";
const SHIPPING_RATE_DISPLAY_NAME = "displayName";
const SHIPPING_RATE_DESCRIPTION = "description";
const SHIPPING_RATE_PRICE = "price";
const SHIPPING_RATE_PRIORITY = "priority";
interface ShippingRateValues {
  [SHIPPING_RATE_ACTIVE]?: boolean;
  [SHIPPING_RATE_HIDDEN]?: number;
  [SHIPPING_RATE_CURRENCY]: string;
  [SHIPPING_RATE_DISPLAY_NAME]: string;
  [SHIPPING_RATE_DESCRIPTION]: string;
  [SHIPPING_RATE_PRICE]: number;
  [SHIPPING_RATE_PRIORITY]: number;
}
const ShippingRateSchema = Yup.object().shape({
  [SHIPPING_RATE_DISPLAY_NAME]: Yup.string()
    .min(3, "Name is too short")
    .max(35, "Name is too long")
    .required("Name is required"),
  [SHIPPING_RATE_CURRENCY]: Yup.string().oneOf(["usd"]).required(),
  [SHIPPING_RATE_DESCRIPTION]: Yup.string()
    .min(3, "Description is too short")
    .max(75, "Description is too long")
    .required("Description is required"),
  [SHIPPING_RATE_PRICE]: Yup.number()
    .min(0, "Price must be 0 or positive")
    .required("Price is required"),
  [SHIPPING_RATE_PRIORITY]: Yup.number()
    .positive("Priority must be positive")
    .required("Priority is required"),
});
// End Formik Config

const ShippingRates = () => {
  const [selectedEditRates, setSelectedEditRates] = useState<
    Map<string, boolean>
  >(new Map());
  const [deletedRates, setDeletedRates] = useState<Map<string, boolean>>(
    new Map()
  );

  const [createPickupLocation] = useCreatePickupLocationMutation();
  const [deletePickupLocation] = useDeletePickupLocationMutation();
  const [updatePickupLocation] = useUpdatePickupLocationMutation();

  const [createShippingRate] = useCreateShippingRateMutation();
  const [updateShippingRates] = useUpdateShippingRateMutation();

  const {
    data: pickupLocationsData,
    isLoading: loadingPickupLocations,
    isFetching: fetchingPickupLocations,
  } = useRetrievePickupLocationsQuery({});
  const {
    data: shippingRatesData,
    isLoading: loadingShippingRates,
    isFetching: fetchingShippingRates,
  } = useGetShippingRatesQuery({});

  const [pickupLocations, setPickupLocations] = useState<PickupLocation[]>(
    pickupLocationsData ?? []
  );
  const [shippingRates, setShippingRates] = useState<Stripe.ShippingRate[]>(
    shippingRatesData ?? []
  );

  useEffect(() => {
    if (pickupLocationsData) {
      console.log(pickupLocationsData);
      setPickupLocations(pickupLocationsData);
    }
  }, [pickupLocationsData]);

  useEffect(() => {
    if (shippingRatesData) {
      setShippingRates(shippingRatesData);
    }
  }, [shippingRatesData]);

  if (!shippingRatesData) {
    return (
      <div className="flex flex-col items-center justify-center space-y-4 py-12">
        <RingLoader />
        <Title>Loading Delivery Options</Title>
      </div>
    );
  }

  // START PICKUP LOCATIONS

  const initPickupLocationValues = (
    location?: PickupLocation
  ): PickupLocationValues => {
    return {
      [PICKUP_LOCATION_ACTIVE]: location === undefined ? true : location.active,
      [PICKUP_LOCATION_NAME]: location?.name ?? "",
      [PICKUP_LOCATION_DESCRIPTION]: location?.description ?? "",
      [PICKUP_LOCATION_PRIORITY]:
        location?.priority ?? pickupLocations.length + 1,
    };
  };

  async function handleCreatePickupLocation(
    values: PickupLocationValues,
    helpers: FormikHelpers<PickupLocationValues>
  ) {
    // log formik errors
    await createPickupLocation(values).unwrap();
    helpers.resetForm({ values: initPickupLocationValues() });
    helpers.setSubmitting(false);
  }

  async function handleUpdatePickupLocation(
    locationId: string | undefined,
    values: PickupLocationValues,
    helpers: FormikHelpers<PickupLocationValues>
  ) {
    if (!locationId) return;
    const updatedLocation = await updatePickupLocation({
      id: locationId,
      ...values,
    }).unwrap();
    helpers.resetForm({ values: initPickupLocationValues(updatedLocation) });
    helpers.setSubmitting(false);
  }

  const reorderPickupLocations = (
    list: PickupLocation[],
    startIndex: number,
    endIndex: number
  ) => {
    // reorder the pickup locations
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEndPickupLocations = (
    result: DropResult,
    provided: ResponderProvided
  ) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Reordering the items
    if (pickupLocationsData) {
      const newLocations = reorderPickupLocations(
        pickupLocationsData,
        result.source.index,
        result.destination.index
      );

      setPickupLocations(newLocations);

      // Update the priority of each pickup location
      newLocations.forEach(async (location, index) => {
        await updatePickupLocation({
          id: location.id,
          [PICKUP_LOCATION_PRIORITY]: index + 1,
        });
      });
    }
  };

  // END PICKUP LOCATIONS

  // START SHIPPING RATES

  const initShippingRateValues = (
    rate?: Stripe.ShippingRate
  ): ShippingRateValues => {
    return {
      ...(rate && { [SHIPPING_RATE_ACTIVE]: rate.active }),
      [SHIPPING_RATE_CURRENCY]: rate?.fixed_amount?.currency ?? "usd",
      [SHIPPING_RATE_DISPLAY_NAME]: rate?.display_name ?? "",
      [SHIPPING_RATE_DESCRIPTION]: rate?.metadata?.description ?? "",
      [SHIPPING_RATE_PRICE]: rate?.fixed_amount?.amount
        ? rate?.fixed_amount.amount / 100
        : 0,
      [SHIPPING_RATE_PRIORITY]: rate?.metadata?.priority
        ? Number.parseInt(rate.metadata.priority)
        : shippingRatesData.length + 1,
    };
  };

  async function handleCreateShippingRate(
    values: ShippingRateValues,
    helpers: FormikHelpers<ShippingRateValues>
  ) {
    await createShippingRate({
      ...values,
      // convert the price to cents for Stripe
      [SHIPPING_RATE_PRICE]: values[SHIPPING_RATE_PRICE] * 100,
    }).unwrap();
    helpers.resetForm({ values: initShippingRateValues() });
    helpers.setSubmitting(false);
  }

  async function handleUpdateShippingRate(
    rateId: string,
    values: ShippingRateValues,
    helpers: FormikHelpers<ShippingRateValues>
  ) {
    const updatedRate = await updateShippingRates({
      rateId,
      body: {
        [SHIPPING_RATE_ACTIVE]: values[SHIPPING_RATE_ACTIVE],
        [SHIPPING_RATE_HIDDEN]: values[SHIPPING_RATE_HIDDEN],
        [SHIPPING_RATE_DESCRIPTION]: values[SHIPPING_RATE_DESCRIPTION],
        [SHIPPING_RATE_PRIORITY]: values[SHIPPING_RATE_PRIORITY],
      },
    }).unwrap();
    helpers.resetForm({ values: initShippingRateValues(updatedRate) });
    helpers.setSubmitting(false);
    setSelectedEditRates(selectedEditRates.set(rateId, false));
  }

  const reorderShippingRates = (
    list: Stripe.ShippingRate[],
    startIndex: number,
    endIndex: number
  ) => {
    // reorder the shipping rates
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function handleDragEnd(result: DropResult, provided: ResponderProvided) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // Reordering the items
    if (shippingRatesData) {
      const newRates = reorderShippingRates(
        shippingRatesData,
        result.source.index,
        result.destination.index
      );

      setShippingRates(newRates);

      // Update the priority of each shipping rate
      newRates.forEach(async (rate, index) => {
        await updateShippingRates({
          rateId: rate.id,
          body: {
            [SHIPPING_RATE_PRIORITY]: index + 1,
          },
        });
      });
    }
  }

  const pickupLocationLineHeight = 60 * pickupLocations.length;
  const shippingRateLineHeight = 120 * shippingRates.length;

  return (
    <Grid numItems={1} numItemsSm={3} className="gap-6">
      <Col numColSpan={1} numColSpanSm={3}>
        <Card className="flex flex-col space-y-4">
          <div className="flex flex-col items-start justify-center space-y-2">
            <Title>Pickup Locations</Title>

            <Subtitle>
              Pickup locations appear at checkout in the order they are listed
              here. Toggle the location on or off by clicking the switch.
            </Subtitle>
          </div>

          <Formik
            initialValues={initPickupLocationValues()}
            onSubmit={handleCreatePickupLocation}
            validationSchema={PickupLocationSchema}
          >
            {(props) => (
              <Form
                className="flex grow space-x-2"
                onSubmit={(e: any) => {
                  e.preventDefault();
                  props.validateForm();
                  props.handleSubmit();
                }}
                noValidate
              >
                <Grid numItems={1} numItemsSm={4} className="w-full gap-2">
                  <Col numColSpan={1} numColSpanSm={2}>
                    <Field
                      name={PICKUP_LOCATION_NAME}
                      placeholder="Display Name"
                      component={TextInput}
                    />
                  </Col>
                  <Col numColSpan={1} numColSpanSm={2}>
                    <Field
                      name={PICKUP_LOCATION_DESCRIPTION}
                      placeholder="Description"
                      component={TextInput}
                    />
                  </Col>
                  <Col numColSpan={1} className="w-full">
                    <Button
                      type="submit"
                      disabled={props.isSubmitting}
                      loading={props.isSubmitting}
                      className="w-full"
                    >
                      Create
                    </Button>
                  </Col>
                </Grid>
              </Form>
            )}
          </Formik>

          <DragDropContext onDragEnd={handleDragEndPickupLocations}>
            <Droppable droppableId="pickupLocations">
              {(provided) => (
                <List
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="flex w-full flex-col"
                  style={{ minHeight: pickupLocationLineHeight }}
                >
                  {pickupLocations.map((location, index) => {
                    console.log(location);
                    return (
                      <Draggable
                        key={location.id}
                        draggableId={location.id}
                        index={index}
                      >
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            className="!left-auto max-h-[60px] min-h-[60px] bg-white"
                            {...provided.draggableProps}
                          >
                            <Icon icon={Drag} {...provided.dragHandleProps} />
                            <Formik
                              initialValues={initPickupLocationValues(location)}
                              onSubmit={(values, helpers) =>
                                handleUpdatePickupLocation(
                                  location.id,
                                  values,
                                  helpers
                                )
                              }
                              validationSchema={PickupLocationSchema}
                            >
                              {(props) => (
                                <Form
                                  className="flex w-full items-center gap-4"
                                  onSubmit={(e: any) => {
                                    e.preventDefault();
                                    props.validateForm();
                                    props.handleSubmit();
                                  }}
                                  noValidate
                                >
                                  <div className="flex grow items-center justify-start gap-2">
                                    <div className="flex items-center justify-between px-1">
                                      <Switch
                                        checked={location.active ?? false}
                                        onChange={async (value: boolean) => {
                                          const updatedLocation =
                                            await updatePickupLocation({
                                              id: location.id,
                                              [PICKUP_LOCATION_ACTIVE]: value,
                                            }).unwrap();
                                          props.resetForm({
                                            values:
                                              initPickupLocationValues(
                                                updatedLocation
                                              ),
                                          });
                                        }}
                                      ></Switch>
                                    </div>
                                    <div className="flex grow flex-col">
                                      <Grid className="grow gap-1">
                                        <div className="flex w-full flex-col">
                                          {/* Existing Pickup Location Name */}
                                          <p className="truncate text-base font-bold">
                                            {location.name}
                                          </p>
                                          {/* Existing Pickup Location Description */}
                                          <p className="truncate text-sm font-bold">
                                            {location.description}
                                          </p>
                                        </div>
                                      </Grid>
                                    </div>
                                  </div>

                                  {/* Delete Icon */}
                                  <Button
                                    type="button"
                                    variant="light"
                                    size="xs"
                                    icon={TrashIcon}
                                    color="red"
                                    disabled={props.isSubmitting}
                                    loading={props.isSubmitting}
                                    onClick={async () => {
                                      await deletePickupLocation({
                                        id: location.id,
                                      }).unwrap();
                                    }}
                                  ></Button>
                                </Form>
                              )}
                            </Formik>
                          </ListItem>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Card>
      </Col>
      <Col numColSpan={1} numColSpanSm={3} numColSpanMd={1}>
        <Grid className="gap-6">
          <Card className="flex flex-col space-y-4">
            <Flex justifyContent="between" className="space-x-2">
              <Title>New Shipping Rate</Title>
            </Flex>
            <Formik
              initialValues={initShippingRateValues()}
              onSubmit={handleCreateShippingRate}
              validationSchema={ShippingRateSchema}
            >
              {(props) => (
                <Form
                  className="flex flex-col space-y-2"
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    props.validateForm();
                    props.handleSubmit();
                  }}
                  noValidate
                >
                  <div className="flex w-full flex-col">
                    <Field
                      type="input"
                      name={SHIPPING_RATE_DISPLAY_NAME}
                      placeholder="Display Name"
                      component={TextInput}
                    />
                  </div>
                  <div className="flex w-full flex-col">
                    <Field
                      type="number"
                      name={SHIPPING_RATE_PRICE}
                      placeholder="Currency"
                      icon={CurrencyDollarIcon}
                      component={NumberInput}
                    />
                  </div>
                  <div className="flex w-full flex-col">
                    <Field
                      type="input"
                      name={SHIPPING_RATE_DESCRIPTION}
                      placeholder="Description"
                      component={Textarea}
                    />
                  </div>
                  <Button
                    type="submit"
                    disabled={props.isSubmitting}
                    loading={props.isSubmitting}
                  >
                    Create Shipping Rate
                  </Button>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Col>
      <Col numColSpan={1} numColSpanSm={3} numColSpanMd={2}>
        <Card className="space-y-6">
          <div className="space-y-2">
            <Title>Shipping Rates</Title>
            <Subtitle>
              Shipping rates appear at checkout in the order they are listed
              here. Reposition the rates by dragging and dropping them. Toggle
              the rate on or off by clicking the switch.
            </Subtitle>
          </div>

          <div className="flex w-full flex-col items-start justify-start space-y-1">
            <div className="flex w-full items-center justify-between pb-2">
              <div className="flex w-full items-center">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="shippingRates">
                    {(provided) => (
                      <List
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ minHeight: shippingRateLineHeight }}
                      >
                        {shippingRates.map((rate, index) => {
                          /**
                           * Toggles the selected state of the shipping rate
                           */
                          function toggleIsSelected() {
                            setSelectedEditRates(
                              new Map(
                                selectedEditRates.set(
                                  rate.id,
                                  !selectedEditRates.get(rate.id)
                                )
                              )
                            );
                          }
                          const isSelected = selectedEditRates.get(rate.id);
                          function toggleIsDeleted() {
                            setDeletedRates(
                              new Map(
                                deletedRates.set(
                                  rate.id,
                                  !deletedRates.get(rate.id)
                                )
                              )
                            );
                          }
                          const isDeleted = deletedRates.get(rate.id);
                          return (
                            <Draggable
                              key={rate.id.toString()}
                              draggableId={rate.id.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <ListItem
                                    className="!left-auto !top-auto max-h-[120px] min-h-[120px] bg-white"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <Formik
                                      initialValues={initShippingRateValues(
                                        rate
                                      )}
                                      onSubmit={(values, helpers) =>
                                        handleUpdateShippingRate(
                                          rate.id,
                                          values,
                                          helpers
                                        )
                                      }
                                      validationSchema={ShippingRateSchema}
                                    >
                                      {(props) => (
                                        <Form
                                          className="flex w-full items-center gap-4"
                                          onSubmit={(e: any) => {
                                            e.preventDefault();
                                            props.validateForm();
                                            props.handleSubmit();
                                          }}
                                          noValidate
                                        >
                                          <div className="flex grow items-center justify-start gap-2">
                                            <Icon
                                              icon={Drag}
                                              {...provided.dragHandleProps}
                                            />
                                            <div className="flex items-center justify-between px-1">
                                              <Switch
                                                checked={
                                                  props.values[
                                                    SHIPPING_RATE_ACTIVE
                                                  ]
                                                }
                                                onChange={async (
                                                  value: boolean
                                                ) => {
                                                  const updatedRate =
                                                    await updateShippingRates({
                                                      rateId: rate.id,
                                                      body: {
                                                        [SHIPPING_RATE_ACTIVE]:
                                                          value,
                                                      },
                                                    }).unwrap();
                                                  props.resetForm({
                                                    values:
                                                      initShippingRateValues(
                                                        updatedRate
                                                      ),
                                                  });
                                                }}
                                              ></Switch>
                                            </div>
                                            <div className="flex grow flex-col ">
                                              {selectedEditRates.get(
                                                rate.id
                                              ) ? (
                                                <div className="flex w-full items-center justify-start">
                                                  <Field
                                                    type="input"
                                                    name={
                                                      SHIPPING_RATE_DESCRIPTION
                                                    }
                                                    component={Textarea}
                                                    className="flex max-h-[110px] max-w-full flex-col items-start justify-start space-y-1"
                                                  />
                                                </div>
                                              ) : (
                                                <Grid className="grow gap-1">
                                                  <div className="flex w-full flex-col">
                                                    <p className="truncate text-base font-bold">
                                                      {rate.display_name}
                                                    </p>
                                                  </div>
                                                  <div className="flex w-full flex-col">
                                                    <p className="truncate text-sm font-bold">
                                                      {
                                                        rate.metadata
                                                          .description
                                                      }
                                                    </p>
                                                  </div>
                                                  <div className="flex w-full flex-col">
                                                    <p className="text-sm font-bold">
                                                      {rate.fixed_amount?.amount
                                                        ? `$${(
                                                            rate.fixed_amount
                                                              .amount / 100
                                                          ).toFixed(2)}`
                                                        : "FREE"}
                                                    </p>
                                                  </div>
                                                </Grid>
                                              )}
                                            </div>
                                          </div>
                                          {/* Buttons for Save, Discard, Edit, View, and Delete */}
                                          <div className="flex items-center space-x-3">
                                            {/* Save Button: Visible only if the form is dirty and not deleted */}
                                            {props.dirty && !isDeleted && (
                                              <Button
                                                type="submit"
                                                variant="light"
                                                icon={CheckIcon}
                                                disabled={props.isSubmitting}
                                                loading={props.isSubmitting}
                                              ></Button>
                                            )}
                                            {/* Discard/Edit/View Button */}
                                            {!isDeleted && (
                                              <Button
                                                type="button"
                                                variant="light"
                                                icon={
                                                  props.dirty
                                                    ? ArrowUturnLeftIcon
                                                    : isSelected
                                                    ? EyeIcon
                                                    : PencilIcon
                                                }
                                                color={
                                                  props.dirty
                                                    ? "gray"
                                                    : isSelected
                                                    ? "teal"
                                                    : "teal"
                                                }
                                                disabled={props.isSubmitting}
                                                loading={
                                                  props.isSubmitting &&
                                                  !isSelected
                                                }
                                                onClick={() => {
                                                  if (props.dirty) {
                                                    props.resetForm({
                                                      values:
                                                        initShippingRateValues(
                                                          rate
                                                        ),
                                                    });
                                                  }
                                                  toggleIsSelected();
                                                }}
                                              ></Button>
                                            )}
                                            {/* Delete Button */}
                                            <Button
                                              type="button"
                                              variant="light"
                                              size="xs"
                                              icon={TrashIcon}
                                              color="red"
                                              disabled={props.isSubmitting}
                                              loading={isDeleted}
                                              onClick={async () => {
                                                toggleIsDeleted();
                                                props.setFieldValue(
                                                  SHIPPING_RATE_HIDDEN,
                                                  1
                                                );
                                                props.setFieldValue(
                                                  SHIPPING_RATE_ACTIVE,
                                                  false
                                                );
                                                await props.submitForm();
                                              }}
                                            ></Button>
                                          </div>
                                        </Form>
                                      )}
                                    </Formik>
                                  </ListItem>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                      </List>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Grid>
  );
};

export default ShippingRates;
