import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Stripe } from "stripe";
import { apiBaseUrl, prepareDefaultHeaders } from "..";

const stripeApi = createApi({
  reducerPath: "stripeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/stripe`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ["Customer", "BillingSession", "Subscription", "ShippingRates"],
  endpoints: (builder) => ({
    retrieveCustomer: builder.query({
      query: () => ({
        url: `/customer`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.customer as Stripe.Customer,
      providesTags: ["Customer"],
    }),

    updateCustomer: builder.mutation({
      query: (body) => ({
        url: `/customer`,
        body: body,
        method: "PATCH",
      }),
      transformResponse: (response, meta, arg) => response as any,
      invalidatesTags: ["Customer"],
    }),

    fetchBillingSession: builder.query({
      query: () => ({
        url: `/customer/billing-session`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.session as Stripe.BillingPortal.Session,
      providesTags: ["BillingSession"],
    }),

    createSubscription: builder.mutation({
      query: (body) => ({
        url: `/subscriptions`,
        body: body,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) => {
        return (response as any).data.data as Stripe.Subscription;
      },
    }),

    fetchActiveSubscriptions: builder.query({
      query: () => ({
        url: `/subscriptions`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.subscriptions as Stripe.Subscription[],
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "Subscription" as const,
                id: id,
              })),
              "Subscription",
            ]
          : ["Subscription"],
    }),

    deleteSubscription: builder.mutation({
      query: ({ subscriptionId }) => ({
        url: `/subscriptions/${subscriptionId}`,
        method: "DELETE",
      }),
      transformResponse: (response, meta, arg) => response as any,
      invalidatesTags: (result, error, arg) => [
        { type: "Subscription", id: arg.subscriptionId },
      ],
    }),

    createShippingRate: builder.mutation({
      query: (body) => ({
        url: `/shipping-rates`,
        body: body,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.rate as Stripe.ShippingRate,
      invalidatesTags: ["ShippingRates"],
    }),

    getShippingRates: builder.query({
      query: ({ active }: { active?: boolean }) => {
        // Construct the base URL
        let url = `/shipping-rates`;

        // Append the `active` parameter to the URL if it is provided
        if (active !== undefined) {
          url += `?active=${active}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["ShippingRates"],
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.rates as Stripe.ShippingRate[],
    }),

    updateShippingRate: builder.mutation({
      query: ({ rateId, body }) => ({
        url: `/shipping-rates/${rateId}`,
        body: body,
        method: "PATCH",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.rate as Stripe.ShippingRate,
      invalidatesTags: ["ShippingRates"],
    }),

    createCheckoutSession: builder.mutation({
      query: (body) => ({
        url: `/checkout-sessions`,
        body: body,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.session as Stripe.Checkout.Session,
    }),
  }),
});

export const {
  useRetrieveCustomerQuery,
  useUpdateCustomerMutation,
  useFetchBillingSessionQuery,
  useCreateSubscriptionMutation,
  useFetchActiveSubscriptionsQuery,
  useDeleteSubscriptionMutation,
  useGetShippingRatesQuery,
  useCreateShippingRateMutation,
  useUpdateShippingRateMutation,
  useCreateCheckoutSessionMutation,
} = stripeApi;

export default stripeApi;
