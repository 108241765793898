import { CalendarMonth } from "@styled-icons/fluentui-system-regular";
import Stripe from "stripe";
import Table, { RowData } from "../../../../../components/Table";
import { GetSubscriptionDescription } from "../../../../../helpers/SubscriptionHelpers";
import { SubscriptionInterval } from "../../../../../redux/api/offerings/types";
import SubscriptionActions from "./actions";

interface Props {
  subscriptions: Stripe.Subscription[];
}

const SubscriptionsTable = ({ subscriptions }: Props) => {
  // Hooks
  const optionsLookup: { [id: string]: any } = {};

  const orderTableHeaders = ["Customer", "Product", "Details", "Actions"];

  const rowData: RowData[] =
    subscriptions?.map((subscription: Stripe.Subscription, index) => {
      console.log(subscription);
      const customer = subscription.customer as Stripe.Customer;

      const interval = subscription.items.data[0].plan
        .interval as SubscriptionInterval;

      const intervalCount = subscription.items.data[0].plan.interval_count;

      const plan = (subscription as any).plan as Stripe.Plan;

      const active = subscription.status === "active";

      const subscriptionProduct = optionsLookup[plan.product as string];

      // extract subscription dates into a separate component
      const currPeriodStart = subscription.current_period_start
        ? new Date(subscription.current_period_start * 1000).toLocaleDateString(
            "en-US",
            {
              year: "2-digit",
              month: "numeric",
              day: "numeric",
            }
          )
        : "";
      const currPeriodEnd = subscription.current_period_end
        ? new Date(subscription.current_period_end * 1000).toLocaleDateString(
            "en-US",
            {
              year: "2-digit",
              month: "numeric",
              day: "numeric",
            }
          )
        : "";

      const canceledAt = subscription.canceled_at
        ? new Date(subscription.canceled_at * 1000).toLocaleDateString("en-US")
        : "";

      const amount = subscription.items.data[0].price.unit_amount;

      let statusColor = active ? "bg-green-600" : "bg-red-600";
      if (subscription.pause_collection) {
        statusColor = "bg-yellow-500";
      }

      return {
        id: `${subscription.id}`,
        colValues: [
          <div key={`${subscription.id}-${index}-name`}>
            <div className="flex flex-col">
              <span className="capitalize">{customer.name}</span>
              <span className="text-xs lowercase text-gray-500">
                {customer.email}
              </span>
            </div>
          </div>,
          <div
            key={`${subscription.id}-${index}-type`}
            className="flex items-center justify-center gap-3"
          >
            {subscriptionProduct && (
              <div className="flex flex-col">
                <span className="">{subscriptionProduct.fullName}</span>
                <span className="text-xs text-gray-500">
                  {subscriptionProduct.name}
                </span>
              </div>
            )}
          </div>,
          <div
            key={`${subscription.id}-${index}-type`}
            className="flex items-center justify-center gap-3"
          >
            <span
              className={`${statusColor} inline-block h-2 w-2 rounded-full shadow-md`}
            ></span>
            <p
              key={`${subscription.id}-${index}-interval`}
              className="flex flex-col"
            >
              <span className="capitalize">
                ${amount && (amount / 100).toFixed(2)} /{" "}
                {GetSubscriptionDescription(interval, intervalCount)}
              </span>
              <span className="flex items-center space-x-1 text-xs text-gray-500">
                <CalendarMonth className="inline-block h-4 w-4" />
                <span>
                  {currPeriodStart} - {currPeriodEnd}
                </span>
              </span>
              {canceledAt && (
                <span className="text-xs text-red-500">
                  Canceled on {canceledAt}
                </span>
              )}
            </p>
          </div>,
          <div className="relative h-full w-full">
            <SubscriptionActions subscription={subscription} />
          </div>,
        ],
      };
    }) ?? [];

  return <Table headers={orderTableHeaders} rowData={rowData} />;
};

export default SubscriptionsTable;
