import { Mail, PersonCircle } from "@styled-icons/fluentui-system-regular";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { animated, useTrail, useTransition } from "react-spring";
import { auth } from "../../config/firebase";
import {
  AllSocialAccounts,
  SocialAccountInfo,
} from "../../constants/SocialMedia";
import {
  useExtendedNavIsOpen,
  useToggleExtendedNav,
} from "../../redux/state/app/hooks";
import { useToggleLoginModal } from "../../redux/state/modals/hooks";

interface Props {}

interface MenuItem {
  name: string;
  to: string;
}

const menuItems: MenuItem[] = [
  {
    name: "Shop",
    to: "/shop",
  },
  {
    name: "Offerings",
    to: "/offerings",
  },
  {
    name: "About",
    to: "/story",
  },
  {
    name: "Prayer",
    to: "/prayer",
  },
  {
    name: "Makers",
    to: "/makers",
  },
  {
    name: "Newsletter",
    to: "/newsletter/archive",
  },
  {
    name: "FAQ",
    to: "/faq",
  },
];

const NavMenu = ({}: Props) => {
  const isOpen = useExtendedNavIsOpen();
  const openLoginModal = useToggleLoginModal();
  const toggleExtendedNav = useToggleExtendedNav();

  const transition = useTransition(isOpen, {
    from: {
      display: "none",
    },
    enter: {
      display: "flex",
    },
    leave: {
      display: "none",
    },
  });

  const menuItemAnimation = {
    config: {
      tension: 120,
      friction: 14,
    },
    from: {
      opacity: 0,
      transform: "translateY(50px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0px)",
    },
  };

  const [menuItemSprings, api] = useTrail(
    menuItems.length,
    {
      ...menuItemAnimation,
    },
    []
  );

  useEffect(() => {
    api.start({
      delay: 0,
      ...menuItemAnimation,
    });
  }, [isOpen]);

  return transition(
    (props, isOpen) =>
      isOpen && (
        <animated.div
          style={props}
          className="hide-scroll fixed bottom-0 left-0 right-0 top-14 z-40 flex flex-col items-center justify-center overflow-y-scroll bg-stone-800 pt-8 transition-all"
        >
          <div className="flex w-full flex-1 items-center ">
            <div className="grid w-full grid-cols-12">
              <ul className="col-span-10 col-start-2 flex w-full flex-col space-y-4 font-serif text-4xl text-stone-200 sm:text-5xl">
                {menuItems.map(({ to, name }, index) => (
                  <animated.li
                    key={index}
                    style={menuItemSprings[index]}
                    className="fancy-underline w-fit cursor-pointer after:h-1 after:bg-stone-400"
                  >
                    <NavLink to={to} onClick={toggleExtendedNav}>
                      {name}
                    </NavLink>
                  </animated.li>
                ))}
              </ul>
            </div>
          </div>

          <div className="flex w-full items-center pb-20 pt-12">
            <div className="grid w-full grid-cols-12 ">
              <ul className="col-span-5 col-start-2 space-y-1 text-sm uppercase tracking-wider text-stone-300 [direction:ltr]">
                {AllSocialAccounts.map((account) => {
                  const { name, url, icon } = SocialAccountInfo[account];

                  return (
                    <li key={name}>
                      <a
                        href={url}
                        rel="noreferrer"
                        target="_blank"
                        className="flex w-fit items-center justify-center space-x-1 transition-colors duration-300 ease-in-out hover:text-stone-50"
                      >
                        {icon(4)}
                        <span>{name}</span>
                      </a>
                    </li>
                  );
                })}

                <li>
                  <NavLink
                    to="/contact"
                    className="flex w-fit items-center justify-center space-x-1 hover:text-stone-50"
                    onClick={toggleExtendedNav}
                  >
                    <Mail className="aspect-square h-4" />
                    <span>contact us</span>
                  </NavLink>
                </li>
              </ul>

              <ul className="col-span-5 col-start-7 space-y-1 text-sm uppercase tracking-wider text-stone-300 [direction:rtl]">
                <li className="flex w-fit items-center justify-center space-x-1 transition-colors duration-300 ease-in-out hover:text-stone-50">
                  {auth.currentUser?.isAnonymous ? (
                    <span onClick={openLoginModal}>login</span>
                  ) : (
                    <NavLink
                      to="/account"
                      className="flex w-fit items-center justify-center"
                      onClick={toggleExtendedNav}
                    >
                      <span>my account</span>
                      <PersonCircle className="mr-2 aspect-square h-4" />
                    </NavLink>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </animated.div>
      )
  );
};

export default NavMenu;
