import { Card, Title } from "@tremor/react";
import DashboardSection from "../../components/DashboardSection";
import Table, { RowData } from "../../components/Table";
import { useRetrieveSubscribersQuery } from "../../redux/api/newsletter/api";

import TriggerNewsletterForm from "../../components/Forms/TriggerNewsletterForm";

const Newsletter = (props: any) => {
  const retrieveSubscribersQuery = useRetrieveSubscribersQuery({});

  const {
    data: subscribers,
    isLoading: loadingSubscribers,
    isFetching: fetchingSubscribers,
  } = retrieveSubscribersQuery;

  const tableHeaders = ["Email", "Date Subscribed"];

  const tableRowData: RowData[] =
    subscribers?.map((val) => ({
      id: val.email,
      colValues: [
        <span key={val.email}>{val.email}</span>,
        <span key={`${val.email}-date`}>
          {new Date(val.dateSubscribed).toLocaleDateString()}
        </span>,
      ],
    })) ?? [];

  return (
    <DashboardSection title={""} loading={false} order={0}>
      <div className="space-y-4">
        <Card className="space-y-4">
          <Title>Subscribers</Title>
          <Table headers={tableHeaders} rowData={tableRowData} />
        </Card>

        <Card className="space-y-4">
          <Title>Trigger Newsletter</Title>
          <TriggerNewsletterForm />
        </Card>
      </div>
    </DashboardSection>
  );
};
export default Newsletter;
