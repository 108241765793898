import {
  addDoc,
  collection,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import Stripe from "stripe";
import { FulfillmentOption } from "../components/Forms/CheckoutForm";
import { auth, db } from "../config/firebase";
import { PickupLocation } from "../redux/api/offerings/types";
import { useCreateCheckoutSessionMutation } from "../redux/api/stripe/api";

const successUrl = () => {
  const user = auth.currentUser;

  if (user?.isAnonymous) {
    return `${window.location.origin}`;
  } else {
    return `${window.location.origin}/account/orders`;
  }
};

export function useCheckout() {
  const [triggerCreateCheckoutSession] = useCreateCheckoutSessionMutation();

  return async function checkout(
    uid: string,
    lineItems: Stripe.Checkout.SessionCreateParams.LineItem[],
    cancelUrl: string,
    fulfillmentOption: FulfillmentOption | null = null,
    shippingRate: Stripe.ShippingRate | null = null,
    pickupLocation: PickupLocation | null = null
  ) {
    const customerRef = doc(db, "customers", uid);

    const customerSnap = await getDoc(customerRef);
    const customer = customerSnap.data();

    const checkoutParams: Stripe.Checkout.SessionCreateParams = {
      ...(customer?.stripeId && {
        customer: customer?.stripeId,
        customer_update: {
          shipping: "auto",
          address: "auto",
        },
      }),
      mode: "payment",
      line_items: lineItems,
      success_url: successUrl(),
      cancel_url: cancelUrl,
      phone_number_collection: {
        enabled: true,
      },
      automatic_tax: {
        enabled: true,
      },
      allow_promotion_codes: true,
      ...(fulfillmentOption === "delivery" &&
        shippingRate !== null && {
          shipping_address_collection: {
            allowed_countries: ["US"],
          },
          shipping_options: [{ shipping_rate: shippingRate.id }],
        }),
      metadata: {
        fulfillmentOption: fulfillmentOption || "none", // Always include fulfillment option
        ...(fulfillmentOption === "delivery" &&
          shippingRate !== null && {
            shippingType: shippingRate.display_name,
          }),
        ...(fulfillmentOption === "pickup" &&
          pickupLocation && {
            pickupLocation: pickupLocation.name,
          }),
      },
    };

    try {
      const res = await triggerCreateCheckoutSession(checkoutParams).unwrap();

      const url = res?.url;
      if (url) {
        window.location.assign(url);
      } else {
        throw new Error("No url returned from checkout session");
      }
    } catch (error: any) {
      alert(`An error occured: ${error.message}`);
    }
  };
}

export function useExpressCheckout() {
  /**
   * Handles express checkout and subscription checkout
   * @param mode "payment" or "subscription"
   * @param uid the user id
   * @param priceId
   * @param cancelUrl
   */
  return async function expressCheckout(
    uid: string,
    priceId: string,
    cancelUrl: string
  ): Promise<void> {
    const customerRef = doc(db, "customers", uid);

    const customerCheckoutSessionsRef = collection(
      customerRef,
      "checkout_sessions"
    );

    const docRef = await addDoc(customerCheckoutSessionsRef, {
      mode: "payment",
      price: priceId,
      success_url: successUrl(),
      cancel_url: cancelUrl,
      phone_number_collection: {
        enabled: true,
      },
      automatic_tax: {
        enabled: true,
      },
    });

    // Wait for the CheckoutSession to get attached by the extension
    onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data() as any;
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  };
}
