import { ArrowBack, ArrowForward } from "@styled-icons/ionicons-solid";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TableRow from "./TableRow";

export type RowData = {
  id: string;
  colValues: JSX.Element[];
  children?: JSX.Element;
};

interface Props {
  headers: string[];
  rowData: RowData[];
}

const Table = ({ headers, rowData }: Props) => {
  const [_openRow, _setOpenRow] = useState<number | null>(null);

  const [_currPage, _setCurrPage] = useState<number>(1);
  const [_itemsPerPage, _setItemsPerPage] = useState<number>(10);

  const tableRef = useRef<HTMLDivElement>(null);

  const numRows = rowData.length;
  const maxPages = Math.ceil(numRows / _itemsPerPage);
  const indexStart = (_currPage - 1) * _itemsPerPage;
  const indexEnd = Math.min(numRows, _currPage * _itemsPerPage);

  console.log(indexStart, numRows, _currPage * _itemsPerPage);

  const [searchParams, setSearchParams] = useSearchParams();

  const tableParam = searchParams.get("table");

  // check if the table is open by getting the url's table param
  // if it's not open, set the table param to the first row's id
  // if it is open, set the table param to null
  useEffect(() => {
    if (tableParam) {
      const tableParam = Number(searchParams.get("table"));
      if (tableParam > 0 && tableParam <= rowData.length) {
        _setOpenRow(tableParam);
      }
    }
  }, [tableParam]);

  function handleChangePage(page: number) {
    if (page < 1 || page > maxPages) {
      return;
    }
    _setCurrPage(page);
  }

  return (
    <div className="w-full rounded-md " ref={tableRef}>
      {/* Table Header */}

      <div
        className={`flex w-full rounded-tl-md rounded-tr-md border-[1px] bg-zinc-100 p-2 px-4 text-xs font-bold text-zinc-600 dark:border-zinc-900 dark:bg-zinc-700 dark:text-zinc-300`}
      >
        {headers.map((header) => (
          <div className="flex-1 items-center break-all" key={header}>
            {header}
          </div>
        ))}
      </div>

      {/* Table Rows */}

      {rowData.slice(indexStart, indexEnd).map((data, i) => (
        <TableRow
          key={data.id}
          id={data.id}
          data={data}
          index={i}
          openRow={_openRow}
          setOpenRow={_setOpenRow}
          tableRef={tableRef}
        />
      ))}

      {/* Table Footer */}

      <div
        className={`flex h-14 w-full items-center justify-end rounded-bl-md rounded-br-md border-[1px] border-t-0 border-b-zinc-200 bg-white p-2 text-xs font-bold text-zinc-500 dark:border-zinc-900 dark:bg-zinc-700 dark:text-zinc-300`}
      >
        <div className="mx-2 break-all">
          <label htmlFor="items-per-page">Items per page:</label>

          <select
            className="select ml-1 mr-2 bg-transparent"
            name="items-per-page"
            id="items-per-page"
            onChange={(e) => {
              _setItemsPerPage(Number(e.currentTarget.value));
              _setCurrPage(1);
            }}
            defaultValue={10}
          >
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
          </select>
        </div>

        <span className="mx-4">
          {indexStart + 1} &#8211; {indexEnd} of {numRows}
        </span>

        <ArrowBack
          className="mx-4 min-w-[1rem] max-w-[1rem] hover:cursor-pointer"
          onClick={() => handleChangePage(_currPage - 1)}
        />

        <ArrowForward
          className="mx-4 min-w-[1rem] max-w-[1rem] hover:cursor-pointer"
          onClick={() => handleChangePage(_currPage + 1)}
        />
      </div>
    </div>
  );
};

export default Table;
