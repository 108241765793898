import { Info } from "@styled-icons/fluentui-system-filled";
import { Trash } from "@styled-icons/ionicons-solid";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Stripe from "stripe";
import * as Yup from "yup";
import { auth } from "../../config/firebase";
import { useCheckout } from "../../hooks/useCheckout";
import {
  useDeleteCartOptionMutation,
  useFetchCartQuery,
  useUpdateCartMutation,
} from "../../redux/api/cart/api";
import { useRetrievePickupLocationsQuery } from "../../redux/api/offerings/api";
import { PickupLocation } from "../../redux/api/offerings/types";
import { useGetShippingRatesQuery } from "../../redux/api/stripe/api";
import { useToggleCartModal } from "../../redux/state/modals/hooks";
import Spinner from "../Spinner";

// #region Formik
export type FulfillmentOption = "pickup" | "delivery";

export const FULFILLMENT_OPTION = "fulfillmentOption";
export const SHIPPING_RATE = "shippingRate";
export const PICKUP_LOCATION = "pickupLocation";

export interface ICheckoutValues {
  [FULFILLMENT_OPTION]: FulfillmentOption | null;
  [SHIPPING_RATE]: Stripe.ShippingRate | null;
  [PICKUP_LOCATION]: PickupLocation | null;
}

export const initialValues: ICheckoutValues = {
  [FULFILLMENT_OPTION]: null,
  [SHIPPING_RATE]: null,
  [PICKUP_LOCATION]: null,
};

export const CheckoutSchema = Yup.object().shape({});

// #endregion Formik

/**
 * Checkout Form Component
 * @param props
 * @returns
 */
const CheckoutForm = ({}) => {
  const toggleCartModal = useToggleCartModal();
  const checkout = useCheckout();

  const { data: pickupLocations } = useRetrievePickupLocationsQuery({
    active: true,
  });
  const { data: shippingRates } = useGetShippingRatesQuery({ active: true });

  const [triggerUpdateCart] = useUpdateCartMutation();
  const [triggerDeleteCartOption] = useDeleteCartOptionMutation();
  const { data: cart, isLoading: loadingCart } = useFetchCartQuery(
    auth.currentUser?.uid,
    {
      skip: !auth.currentUser?.uid,
    }
  );

  const cartTotal = cart?.totalPrice ?? 0;
  const isEligibleForDelivery = cartTotal >= 30;

  const offerings = cart?.offerings ?? {};
  const numOptions = Object.values(offerings).reduce(
    (acc, offering) => acc + Object.keys(offering.options).length,
    0
  );

  const [isCheckingOut, setIsCheckingOut] = useState<boolean>(false);

  async function handleUpdateOption(
    offeringId: string,
    priceId: string,
    quantity: number
  ) {
    await triggerUpdateCart({
      offeringId,
      priceId,
      quantity,
    });
  }

  async function handleRemoveOption(offeringId: string, optionId: string) {
    await triggerDeleteCartOption({ offeringId, optionId });
  }

  async function handleSubmitCheckoutForm(
    values: ICheckoutValues,
    actions: FormikHelpers<ICheckoutValues>
  ) {
    setIsCheckingOut(true);

    const options = Object.values(offerings).flatMap((offering) =>
      Object.values(offering.options)
    );

    const optionLineItems = options.map((o) => ({
      price: o.priceId,
      quantity: o.quantity,
    }));

    if (auth.currentUser?.uid) {
      await checkout(
        auth.currentUser.uid,
        optionLineItems,
        window.location.origin,
        values[FULFILLMENT_OPTION],
        values[SHIPPING_RATE],
        values[PICKUP_LOCATION]
      );
    }

    setIsCheckingOut(false);
    toggleCartModal();
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitCheckoutForm}
      validationSchema={CheckoutSchema}
    >
      {({
        values,
        setFieldValue,
        validateForm,
        handleSubmit,
        errors,
        touched,
      }) => (
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            validateForm();
            handleSubmit();
          }}
          noValidate
        >
          <div className="max-w-sm p-4" aria-modal="true" role="dialog">
            <h1 className="flex w-full justify-center font-serif text-2xl">
              My Cart
            </h1>

            {numOptions === 0 && (
              <p className="my-12 w-full text-center text-lg">
                Your cart is currently empty. Visit our{" "}
                <NavLink
                  to="/shop"
                  className="inline-flex font-bold"
                  onClick={toggleCartModal}
                >
                  farm store page
                </NavLink>{" "}
                to browse our currently available offerings.
              </p>
            )}

            <div className="mt-6 space-y-4">
              {!loadingCart && numOptions > 0 && (
                <div>
                  <ul className="space-y-4">
                    {Object.keys(offerings).flatMap((offeringId) => {
                      const offering = offerings[offeringId];
                      return Object.keys(offerings[offeringId].options).map(
                        (optionId) => {
                          const option =
                            offerings[offeringId].options[optionId];
                          return (
                            <li
                              key={optionId}
                              className="flex items-center justify-between gap-10 space-x-8"
                            >
                              <NavLink
                                to={option.shopUrl}
                                className="group flex cursor-pointer items-center"
                                onClick={toggleCartModal}
                              >
                                <img
                                  src={`${option.imageUrl}`}
                                  alt=""
                                  className="aspect-square h-16 w-16 rounded object-cover"
                                />
                                <div className="ml-4">
                                  <h3 className="group-fancy-underline relative text-sm text-zinc-900 after:h-[1px] after:bg-stone-600">
                                    {offering.name}
                                  </h3>
                                  <dl className="mt-0.5 space-y-px text-xs text-zinc-600">
                                    {option.name}
                                  </dl>
                                  <dl className="mt-0.5 space-y-px text-xs text-zinc-600">
                                    ${option.unitPrice.toFixed(2)}
                                  </dl>
                                </div>
                              </NavLink>

                              {/*  */}

                              <div className="flex items-center gap-2">
                                <select
                                  value={option.quantity}
                                  className="w-14 cursor-pointer rounded border border-zinc-200 bg-white py-0.5 pl-1 pr-0 focus:border-zinc-300 focus:outline-none focus:ring-0"
                                  onChange={(e) => {
                                    // calculate the difference between the current quantity and the new quantity
                                    const difference =
                                      Number(e.currentTarget.value) -
                                      option.quantity;
                                    handleUpdateOption(
                                      offeringId,
                                      option.priceId,
                                      difference
                                    );
                                  }}
                                >
                                  {new Array(option.availableQuantity)
                                    .fill(0)
                                    .map((_, i) => (
                                      <option key={i}>{i + 1}</option>
                                    ))}
                                </select>

                                <button
                                  type="button"
                                  className="text-zinc-600 transition hover:text-red-600"
                                  onClick={async () =>
                                    await handleRemoveOption(
                                      offeringId,
                                      optionId
                                    )
                                  }
                                >
                                  <span className="sr-only">Remove item</span>
                                  <Trash className="h-5 w-5" />
                                </button>
                              </div>
                            </li>
                          );
                        }
                      );
                    })}
                  </ul>
                  <div className="mb-1 mt-6">
                    <h2 className="mb-2 mt-3 flex items-center justify-between text-left text-xs text-zinc-400">
                      {!isEligibleForDelivery ? (
                        <span>
                          Delivery options are available for orders of $30 or
                          more.
                        </span>
                      ) : (
                        <span>How would you like to receive your order?</span>
                      )}
                      <NavLink
                        to="/faq#purchase-options"
                        onClick={toggleCartModal}
                      >
                        <Info className="h-4 w-4 text-slate-600" />
                      </NavLink>
                    </h2>
                    {/* Shipping Rate Selection */}
                    <ul className="flex flex-col gap-2" role="group">
                      {/* Pickup Location Options */}
                      {pickupLocations &&
                        pickupLocations.map((location: PickupLocation) => (
                          <li key={location.id} className="flex">
                            <Field
                              type="checkbox"
                              id={`${PICKUP_LOCATION}-${location.id}`}
                              name={PICKUP_LOCATION}
                              // value={location.id}
                              onChange={(e: any) => {
                                if (e.currentTarget.checked) {
                                  setFieldValue(FULFILLMENT_OPTION, "pickup");
                                  setFieldValue(SHIPPING_RATE, null);
                                  setFieldValue(PICKUP_LOCATION, location);
                                } else {
                                  setFieldValue(FULFILLMENT_OPTION, null);
                                  setFieldValue(SHIPPING_RATE, null);
                                  setFieldValue(PICKUP_LOCATION, null);
                                }
                              }}
                              checked={
                                values[PICKUP_LOCATION]?.id === location.id
                              }
                              className="peer hidden [&:checked_+_label]:ring-2"
                            />
                            <label
                              htmlFor={`${PICKUP_LOCATION}-${location.id}`}
                              className="peer-checked:ring-slate-60/40 flex w-full cursor-pointer flex-col gap-2 rounded border border-zinc-400 bg-zinc-50 px-5 py-2 align-middle text-zinc-600 hover:bg-white hover:text-zinc-700 peer-checked:border-slate-600 peer-checked:bg-white peer-checked:text-slate-600 peer-checked:ring-1"
                            >
                              <p className="flex w-full items-center justify-between">
                                <span className="text-sm font-semibold capitalize">
                                  {location.name}
                                </span>
                                <span className="text-sm leading-none text-zinc-500">
                                  FREE
                                </span>
                              </p>
                              <p className="text-xs leading-4 text-zinc-400">
                                {location.description}
                              </p>
                            </label>
                          </li>
                        ))}

                      {/* Delivery Options */}

                      {isEligibleForDelivery &&
                        shippingRates?.map((rate) => {
                          const rateAmount = rate.fixed_amount?.amount ?? 0;
                          const displayAmount =
                            rateAmount === 0
                              ? "FREE"
                              : `$${(rateAmount / 100).toFixed(2)}`;
                          return (
                            <li key={rate.id} className="flex">
                              <Field
                                type="checkbox"
                                id={`${SHIPPING_RATE}-${rate.id}`}
                                name={SHIPPING_RATE}
                                onChange={(e: any) => {
                                  if (e.currentTarget.checked) {
                                    setFieldValue(
                                      FULFILLMENT_OPTION,
                                      "delivery"
                                    );
                                    setFieldValue(SHIPPING_RATE, rate);
                                    setFieldValue(PICKUP_LOCATION, null);
                                  } else {
                                    setFieldValue(FULFILLMENT_OPTION, null);
                                    setFieldValue(SHIPPING_RATE, null);
                                    setFieldValue(PICKUP_LOCATION, null);
                                  }
                                }}
                                checked={values[SHIPPING_RATE]?.id === rate.id}
                                className="peer hidden [&:checked_+_label]:ring-2"
                              />
                              <label
                                htmlFor={`${SHIPPING_RATE}-${rate.id}`}
                                className="peer-checked:ring-slate-60/40 flex w-full cursor-pointer flex-col gap-2 rounded border border-zinc-400 bg-zinc-50 px-5 py-2 align-middle text-zinc-600 hover:bg-white hover:text-zinc-700 peer-checked:border-slate-600 peer-checked:bg-white peer-checked:text-slate-600 peer-checked:ring-1"
                              >
                                <p className="flex w-full items-center justify-between">
                                  <span className="text-sm font-semibold capitalize">
                                    {rate.display_name}
                                  </span>
                                  <span className="text-sm leading-none text-zinc-500">
                                    {displayAmount}
                                  </span>
                                </p>
                                {rate.metadata.description && (
                                  <p className="text-xs leading-4 text-zinc-400">
                                    {rate.metadata.description}
                                  </p>
                                )}
                              </label>
                            </li>
                          );
                        })}
                    </ul>

                    {/* Fulfillment Error Messages */}

                    {errors[FULFILLMENT_OPTION] &&
                      touched[FULFILLMENT_OPTION] && (
                        <div className="mt-2 text-xs text-red-700">
                          <ErrorMessage name={FULFILLMENT_OPTION} />
                        </div>
                      )}
                  </div>
                </div>
              )}

              {/* Checkout Total Preview */}

              {numOptions > 0 && (
                <div className="space-y-4 text-center">
                  <div className="flex justify-end border-t border-zinc-300 pt-4">
                    <div className="w-full">
                      <dl className="text-sm text-zinc-700">
                        {values[SHIPPING_RATE] && (
                          <div className="flex justify-between !text-base">
                            <dt className="font-bold">Shipping</dt>
                            <dd>
                              $
                              {(
                                (shippingRates?.find(
                                  (sr) => sr.id === values[SHIPPING_RATE]?.id
                                )?.fixed_amount?.amount ?? 0) / 100
                              ).toFixed(2)}
                            </dd>
                          </div>
                        )}
                        <div className="flex justify-between !text-base">
                          <dt className="font-bold">Total</dt>
                          <dd>
                            $
                            {(!!values[SHIPPING_RATE]?.id
                              ? cartTotal +
                                (shippingRates?.find(
                                  (sr) => sr.id === values[SHIPPING_RATE]?.id
                                )?.fixed_amount?.amount ?? 0) /
                                  100
                              : cartTotal
                            ).toFixed(2)}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded bg-slate-800 px-5 py-3 text-sm text-zinc-50 transition hover:bg-slate-700"
                  >
                    {isCheckingOut ? (
                      <div className="flex items-center">
                        <span className="mr-2">Processing</span> <Spinner />
                      </div>
                    ) : (
                      "Checkout"
                    )}
                  </button>

                  <NavLink
                    to="/shop"
                    onClick={toggleCartModal}
                    className="inline-block text-sm text-zinc-500 underline underline-offset-4 transition hover:text-zinc-600"
                  >
                    Continue shopping
                  </NavLink>
                </div>
              )}
            </div>
          </div>

          <CartTotalWatcher cartTotal={cartTotal} />
        </Form>
      )}
    </Formik>
  );
};

const CartTotalWatcher = ({ cartTotal }: { cartTotal: number }) => {
  const formikContext = useFormikContext<ICheckoutValues>();

  useEffect(() => {
    if (cartTotal < 30) {
      formikContext.setFieldValue(FULFILLMENT_OPTION, null);
      formikContext.setFieldValue(SHIPPING_RATE, null);
      formikContext.setFieldValue(PICKUP_LOCATION, null);
    }
  }, [cartTotal]);

  return null; // This component does not render anything
};

export default CheckoutForm;
