import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import {
  Badge,
  Button,
  Card,
  Col,
  Grid,
  List,
  ListItem,
  Metric,
  Subtitle,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Title,
} from "@tremor/react";
import { RingLoader } from "react-spinners";
import { handleDownload } from ".";
import { DateStamp } from "../../../helpers/DateHelpers";
import { formatPhoneNumber } from "../../../helpers/StringHelpers";
import { useGetFulfillmentReportsQuery } from "../../../redux/api/reports/api";

const FulfillmentReports = () => {
  const {
    data: fulfillmentReportData,
    isLoading,
    isFetching,
  } = useGetFulfillmentReportsQuery({});

  const pickingReport = fulfillmentReportData?.pickingReport.report;
  const packingReport = fulfillmentReportData?.packingReport.report;

  const quantityData = pickingReport?.items.map((item) => ({
    fullName: item.fullName,
    quantity: item.quantity,
  }));

  const { totalValue, totalQuantity } = pickingReport?.items.reduce(
    (acc, item) => {
      acc.totalValue += item.totalPrice;
      acc.totalQuantity += item.quantity;
      return acc;
    },
    { totalValue: 0, totalQuantity: 0 }
  ) ?? { totalValue: 0, totalQuantity: 0 };

  if (isLoading || isFetching) {
    return (
      <div className="flex flex-col items-center justify-center space-y-4 py-12">
        <RingLoader />
        <Title>Loading Fulfillment Reports</Title>
      </div>
    );
  }

  return (
    <Grid numItemsLg={1} className="w-full gap-8">
      <Grid numItems={3} className="gap-4">
        <Card>
          <Title>Customers</Title>
          <Metric>{packingReport?.orders?.length ?? 0}</Metric>
        </Card>
        <Card>
          <Title>Total Order Value</Title>
          <Metric>${totalValue.toFixed(2)}</Metric>
        </Card>
        <Card>
          <Title>Total Items</Title>
          <Metric>{totalQuantity}</Metric>
        </Card>
      </Grid>

      <TabGroup className="space-y-4">
        <TabList>
          <Tab>Picking Report</Tab>
          <Tab>Packing Report</Tab>
        </TabList>

        {/* Picking Report */}
        <TabPanels>
          <TabPanel className="space-y-4">
            <div className="flex w-full items-start justify-between p-6">
              <Title>Open Order Picking Report</Title>
              <Button
                size="sm"
                variant="primary"
                icon={ArrowDownTrayIcon}
                iconPosition="right"
                className="w-fit tracking-wide"
                disabled={!fulfillmentReportData?.pickingReport?.base64}
                onClick={() => {
                  if (fulfillmentReportData?.pickingReport?.base64)
                    handleDownload(
                      `picking-report.${DateStamp()}.xlsx`,
                      fulfillmentReportData.pickingReport.base64
                    );
                }}
              >
                Picking Report
              </Button>
            </div>
            {quantityData && (
              <Card>
                <List>
                  {quantityData.map((item) => (
                    <ListItem key={item.fullName}>
                      {item.fullName}
                      <Badge size="sm">{item.quantity}</Badge>
                    </ListItem>
                  ))}
                </List>
              </Card>
            )}
          </TabPanel>

          {/* Packing Report */}
          <TabPanel className="space-y-4">
            <div className="flex w-full items-start justify-between p-6">
              <Title>Open Order Packing Report</Title>
              <Button
                size="sm"
                variant="primary"
                icon={ArrowDownTrayIcon}
                iconPosition="right"
                className="w-fit tracking-wide"
                disabled={!fulfillmentReportData?.packingReport?.base64}
                onClick={() => {
                  if (fulfillmentReportData?.packingReport?.base64)
                    handleDownload(
                      `packing-report.${DateStamp()}.xlsx`,
                      fulfillmentReportData.packingReport.base64
                    );
                }}
              >
                Packing Report
              </Button>
            </div>
            {packingReport && (
              <Grid numItemsMd={2} numItemsSm={1} className="gap-8">
                {packingReport.orders.map((order, index) => {
                  const totalOrderQuantity = order.items.reduce(
                    (acc, item) => acc + item.quantity,
                    0
                  );
                  return (
                    <Card className="space-y-2">
                      <div className="space-y-2">
                        <div className="space-y-2">
                          <div className="flex w-full items-center justify-between pr-2">
                            <Title className="text-lg font-bold">
                              {order.customer.name}
                            </Title>
                            <Text>
                              {totalOrderQuantity} item
                              {totalOrderQuantity > 1 && "s"}
                            </Text>
                          </div>

                          {/* Customer Details */}

                          <Grid
                            numItemsSm={6}
                            numItems={1}
                            className="gap-1 pl-1"
                          >
                            {order.customer.email && (
                              <>
                                <Col numColSpanSm={2}>
                                  <Subtitle className="text-sm">Email</Subtitle>
                                </Col>
                                <Col numColSpanSm={4}>
                                  <Text className="text-sm">
                                    {order.customer.email}
                                  </Text>
                                </Col>
                              </>
                            )}
                            {order.customer.phone && (
                              <>
                                <Col numColSpanSm={2}>
                                  <Subtitle className="text-sm">Phone</Subtitle>
                                </Col>
                                <Col numColSpanSm={4}>
                                  <Text className="text-sm">
                                    {formatPhoneNumber(order.customer.phone)}
                                  </Text>
                                </Col>
                              </>
                            )}
                            {order.address && (
                              <>
                                <Col numColSpanSm={2}>
                                  <Subtitle className="text-sm">
                                    Address
                                  </Subtitle>
                                </Col>
                                <Col numColSpanSm={4}>
                                  <Text className="flex flex-col text-sm">
                                    <span>{order.address?.line1}</span>
                                    <span>{order.address?.line2}</span>
                                    <span>
                                      {order.address?.city},{" "}
                                      {order.address?.state}{" "}
                                      {order.address?.postal_code}
                                    </span>
                                  </Text>
                                </Col>
                              </>
                            )}
                          </Grid>

                          {/* Fulfillment Details */}
                          {order.fulfillmentOption && (
                            <Grid
                              numItemsSm={6}
                              numItems={1}
                              className="gap-1 pl-1"
                            >
                              {order.shippingType && (
                                <>
                                  <Col numColSpanSm={2}>
                                    <Subtitle className="text-sm">
                                      Shipping Type
                                    </Subtitle>
                                  </Col>
                                  <Col numColSpanSm={4}>
                                    <Text className="text-sm capitalize">
                                      {order.shippingType}
                                    </Text>
                                  </Col>
                                </>
                              )}
                              {order.pickupLocation && (
                                <>
                                  <Col numColSpanSm={2}>
                                    <Subtitle className="text-sm">
                                      Pickup Location
                                    </Subtitle>
                                  </Col>
                                  <Col numColSpanSm={4}>
                                    <Text className="text-sm">
                                      {order.pickupLocation}
                                    </Text>
                                  </Col>
                                </>
                              )}
                              {order.shippingAddress && (
                                <>
                                  <Col numColSpanSm={2}>
                                    <Subtitle className="text-sm">
                                      Delivery Address
                                    </Subtitle>
                                  </Col>
                                  <Col numColSpanSm={4}>
                                    <Text className="flex flex-col text-sm">
                                      <span>
                                        {order.shippingAddress?.line1}
                                      </span>
                                      <span>
                                        {order.shippingAddress?.line2}
                                      </span>
                                      <span>
                                        {order.shippingAddress?.city},{" "}
                                        {order.shippingAddress?.state}{" "}
                                        {order.shippingAddress?.postal_code}
                                      </span>
                                    </Text>
                                  </Col>
                                </>
                              )}
                            </Grid>
                          )}
                        </div>
                      </div>

                      <List className="px-2">
                        {order.items.map((item, itemIndex) => (
                          <ListItem
                            key={itemIndex}
                            className="flex justify-between"
                          >
                            <div className="flex max-w-[300px]">
                              <Text className="whitespace-normal break-words">
                                {item.fullName}
                              </Text>
                            </div>
                            <Badge size="sm">{item.quantity}</Badge>
                          </ListItem>
                        ))}
                      </List>
                    </Card>
                  );
                })}
              </Grid>
            )}
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </Grid>
  );
};

export default FulfillmentReports;
