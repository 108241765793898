import { Field, Form, Formik, FormikHelpers } from "formik";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { FormikAdminInput } from "../../Formik";
import { LOGIN_EMAIL, LOGIN_PASSWORD } from "./fieldNames";
import initialValues, { LoginFormValues } from "./initialValues";
import validationSchema from "./validationSchema";

const LoginForm = () => {
  const { handleEmailSignIn, handleResetPassword } = useAuthentication();

  function handleLogin(
    values: LoginFormValues,
    helpers: FormikHelpers<LoginFormValues>
  ) {
    handleEmailSignIn(values.email, values.password);
    helpers.setSubmitting(false);
    helpers.resetForm();
  }

  const handleForgotPassword = async (
    email: string,
    setFieldError: (field: string, message: string) => void,
    setFieldTouched: (
      field: string,
      isTouched: boolean,
      shouldValidate?: boolean
    ) => void
  ) => {
    setFieldTouched(LOGIN_EMAIL, true, false);

    if (!email) {
      setFieldError(LOGIN_EMAIL, "Email is required to reset password.");
      return;
    }

    try {
      await validationSchema.fields[LOGIN_EMAIL].validate(email);
      await handleResetPassword(email);
    } catch (error) {}
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleLogin}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        setFieldError,
        validateForm,
        setFieldTouched,
        values,
      }) => (
        <Form
          className="w-full space-y-4"
          onSubmit={(e: any) => {
            e.preventDefault();
            validateForm();
            handleSubmit();
          }}
          noValidate
        >
          <Field
            type="email"
            name={LOGIN_EMAIL}
            title="Email"
            component={FormikAdminInput}
          />

          <div className="flex flex-col">
            <Field
              type="password"
              name={LOGIN_PASSWORD}
              title="Password"
              component={FormikAdminInput}
            />

            <button
              type="button"
              className="fancy-underline relative w-fit pl-1 pt-1 text-sm text-teal-500 after:h-[1px] after:bg-teal-500 hover:text-teal-600 hover:after:bg-teal-600"
              onClick={async () =>
                await handleForgotPassword(
                  values.email,
                  setFieldError,
                  setFieldTouched
                )
              }
            >
              Reset Password
            </button>
          </div>

          <button
            type="submit"
            className="font-medium inline-block w-full rounded bg-stone-700 px-12 py-3 text-sm text-white shadow-sm transition-all hover:bg-stone-800 hover:shadow-lg"
          >
            Log In
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
