import { OrderItem } from "../../redux/api/orders/types";

interface Props {
  items: OrderItem[];
}

const LineItems = ({ items }: Props) => {
  return (
    <div className="flex w-full flex-1 flex-col self-start">
      <div className="flex bg-zinc-200 p-1 text-xs">
        <span className="w-12 text-center">Qty</span>
        <span className="flex-1">Offering</span>
        <span className="w-12 text-center">Total</span>
      </div>

      <div className="border-2 border-solid border-zinc-200">
        {items.map((item, i) => {
          return (
            <div
              className="flex p-1 text-sm odd:bg-white even:bg-zinc-50"
              key={`offering-${i}`}
            >
              <span className="w-12 text-center">{item.quantity}</span>
              <span className="flex-1">{item.description}</span>
              <span className="w-12 text-center">
                ${item.totalAmount.toFixed(2)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LineItems;
