import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Modal, ModalsState, ModalType } from "./types";

export const initialState: ModalsState = {
  openModals: Object.values(ModalType).reduce(
    (acc, type) => ({ ...acc, [type]: [] }),
    {} as Record<ModalType, Modal[]>
  ),
  modalOrder: [],
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    pushModal(state, action: PayloadAction<Modal>) {
      const modal = action.payload;
      const type = modal.type;
      if (!state.openModals[type]) {
        state.openModals[type] = [modal];
      } else {
        state.openModals[type].push(modal);
      }
      state.modalOrder.push(modal.type); // add this line to track the order
    },
    popModal(state, action: PayloadAction<ModalType | undefined>) {
      if (action.payload) {
        // pop a specific modal if a type is provided
        const type = action.payload;
        if (state.openModals[type].length > 0) {
          state.openModals[type].pop();
        }
      } else if (state.modalOrder.length > 0) {
        // otherwise pop the last modal in the order
        const type = state.modalOrder.pop();
        if (type && state.openModals[type]) {
          state.openModals[type].pop();
        }
      }
    },
  },
});

export const { pushModal, popModal } = modalsSlice.actions;

export default modalsSlice.reducer;
