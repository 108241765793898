import { ArrowLeft, ArrowRight } from "@styled-icons/fluentui-system-regular";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Logo } from "../components/Logo";
import { useRetrieveMakersQuery } from "../redux/api/makers/api";
import { Maker } from "../redux/api/makers/types";

const MakersPage = () => {
  const { data, isLoading, isFetching, error } = useRetrieveMakersQuery({}, {});

  // check the url params to see if we are looking at a specific maker
  // if so, display the maker page
  // otherwise, display the list of makers
  const { makerSlug } = useParams<{ makerSlug: string }>();

  const [maker, setMaker] = useState<Maker | undefined | null>(null);

  useEffect(() => {
    if (makerSlug) {
      const maker = data?.find((maker) => maker.slug === makerSlug);
      setMaker(maker);
      // scroll to top
      window.scrollTo(0, 0);
    } else {
      setMaker(null);
    }
  }, [makerSlug]);

  if (maker) {
    return <MakerPage maker={maker} />;
  }

  return (
    <div className="mb-20 grid grid-cols-12">
      <div className="col-auto col-start-3 col-end-11 my-20 flex flex-col items-center space-y-4 md:col-start-4 md:col-end-10 md:my-36">
        <div className="mb-12 flex flex-col items-center space-y-4">
          <div className="w-48">
            <Logo style={"color"} />
          </div>
          <p className="font-blackstone text-6xl">
            <span>Collective</span>
          </p>
        </div>
        <h1 className="font-serif text-3xl leading-loose tracking-wide md:text-4xl">
          #MaranathaFarmCollective
        </h1>
        <article className="flex flex-col gap-2 space-y-2 font-serif text-lg leading-loose tracking-wide">
          <p>
            One of our guiding principles is to shine a light on how far our
            food travels to our tables. We use icons on each product page so
            that you know how far the product has traveled, and that you are
            truly eating locally. A big part of this initiative is highlighting
            the abundance of our local food shed. We make every effort to use
            organic/regenerative/sustainably grown local ingredients in our
            products, and the same goes for the farms and people we work with.
          </p>
          <p>
            We are humbled and grateful to partner with local folks in food and
            the arts to promote farms in our region, as well as New Jersey
            artisans that share their talents to create unique specialty
            products. They collaborate with us in ways that display their many
            gifts, and the best of our farm. These are people who share our
            passion for regenerative farming practices and sustainability. When
            you support us altogether, you are supporting the good work
            happening to restore our land, organizations who value their
            employees, respect the animals in their care, and walk the good
            walk. From all of us at the Maranatha Farm Collective, we are deeply
            grateful for your support. Thank you!
          </p>
        </article>
      </div>

      {/* Display All of the Makers in a Dynamic Grid */}
      <div className="col-span-10 col-start-2 grid grid-cols-1 gap-6 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {data?.map((maker) => MakerElement({ maker }))}
      </div>
    </div>
  );
};

interface MakerElementProps {
  maker: Maker;
}

const MakerElement = ({ maker }: MakerElementProps) => {
  return (
    <NavLink
      to={`/makers/${maker.slug}`}
      className="group h-full w-full space-y-4"
    >
      <div className="w-full flex-1 overflow-hidden">
        <img
          className="aspect-[2/3] h-full w-full transform object-cover transition-transform duration-300 group-hover:scale-105"
          src={maker.featureImageUrl}
          alt={maker.name}
        />
      </div>

      <h1 className="font-serif text-lg font-bold tracking-wider md:text-xl">
        {maker.name}
      </h1>
      {/* <p dangerouslySetInnerHTML={{ __html: maker.description }}></p> */}
    </NavLink>
  );
};

interface MakerPageProps {
  maker: Maker;
}

const MakerPage = ({ maker }: MakerPageProps) => {
  return (
    <div className=" mb-24 grid grid-cols-12">
      <div className="relative col-start-3 col-end-11 my-8 flex flex-col items-center space-y-8 text-center">
        <NavLink
          to={"/makers"}
          className="group invisible absolute top-0 left-0 flex cursor-pointer items-center sm:visible"
        >
          <ArrowLeft className=" m-4 h-8 w-8 text-gray-500 transition-transform duration-200 hover:text-gray-900 group-hover:-translate-x-2 group-hover:scale-105" />
          <span className="font-serif text-sm uppercase tracking-wider transition-all duration-200 group-hover:font-bold">
            all makers
          </span>
        </NavLink>

        <div className="max-w-xs">
          <img
            src={maker.logoUrl}
            alt={maker.name}
            className="object-cover mix-blend-multiply"
          />
        </div>

        <div className="max-w-lg">
          <section
            className="max-w-lg space-y-4 text-lg leading-loose tracking-wide"
            dangerouslySetInnerHTML={{ __html: maker.description }}
          ></section>
        </div>

        {maker.websiteUrl && (
          <a
            href={maker.websiteUrl}
            target="__blank"
            className="fancy-underline relative flex w-fit items-center text-sm font-bold uppercase tracking-widest after:h-[2px] after:bg-stone-600"
          >
            <span>{maker.websiteUrl}</span>
            <ArrowRight className="ml-2 h-4 w-4" />
          </a>
        )}
      </div>
    </div>
  );
};

export default MakersPage;
