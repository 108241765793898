/**
 * Returns the current date in the format YYYY.MM.DD
 */
export const DateStamp = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Adding 1 since month index starts from 0
  const day = String(currentDate.getDate()).padStart(2, "0");
  return `${year}.${month}.${day}`;
};

export const DateStampFromRange = (from: Date, to: Date) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);
  const fromYear = fromDate.getFullYear();
  const fromMonth = String(fromDate.getMonth() + 1).padStart(2, "0"); // Adding 1 since month index starts from 0
  const fromDay = String(fromDate.getDate()).padStart(2, "0");
  const toYear = toDate.getFullYear();
  const toMonth = String(toDate.getMonth() + 1).padStart(2, "0"); // Adding 1 since month index starts from 0
  const toDay = String(toDate.getDate()).padStart(2, "0");
  return `${fromYear}.${fromMonth}.${fromDay}-${toYear}.${toMonth}.${toDay}`;
};
