import { getAuth, updateProfile, User } from "firebase/auth";
import { Field, Form, Formik, FormikHelpers } from "formik";
import DashboardSection from "../../components/DashboardSection";
import { FormikAdminInput } from "../../components/Formik";

const ManageAccount = ({}) => {
  const auth = getAuth();

  async function handleAccountUpdate(
    values: any,
    { setSubmitting, resetForm }: FormikHelpers<any>
  ) {
    const user = auth.currentUser as User;

    await updateProfile(user, { displayName: values.name });
  }

  return (
    <DashboardSection title="Manage Account" loading={false} order={1}>
      <Formik
        initialValues={{ name: auth.currentUser?.displayName }}
        onSubmit={handleAccountUpdate}
      >
        {({ values }) => (
          <Form>
            <div className="mb-4 flex items-end md:w-1/2">
              <div className="flex-1">
                <Field
                  type="text"
                  name="name"
                  title="User Name"
                  component={FormikAdminInput}
                />
              </div>
              {values.name !== auth.currentUser?.displayName && values.name && (
                <button className="btn ml-1 h-[50px]" type="submit">
                  Update
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </DashboardSection>
  );
};

export default ManageAccount;
