import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";
import {
  Offering,
  OfferingType,
  PaginatedOfferingsPayload,
  PickupLocation,
} from "./types";

const offeringsApi = createApi({
  reducerPath: "offeringsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/offerings`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: [
    "Categories",
    "Offerings",
    "HerbOfTheWeek",
    "PaginatedOfferings",
    "PickupLocations",
  ],
  endpoints: (builder) => ({
    /**
     * RETRIEVAL ENDPOINTS
     */
    activeOfferingCategories: builder.query({
      query: ({ active }) => {
        const params = new URLSearchParams();
        if (active !== null && active !== undefined) {
          params.append("active", active.toString());
        }
        const queryString = params.toString();
        const url = `/categories${queryString ? `?${queryString}` : ""}`;
        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response, meta, arg) => {
        return (response as any).data.data as OfferingType[];
      },
      providesTags: ["Categories"],
    }),

    retrieveOffering: builder.query({
      query: ({ id, active }: { id: string; active?: boolean | null }) => {
        const params = new URLSearchParams();
        if (active !== null && active !== undefined) {
          params.append("active", active.toString());
        }
        const queryString = params.toString();
        const url = `/${id}${queryString ? `?${queryString}` : ""}`;
        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as Offering,
      providesTags: (result, error, { id }) => [{ type: "Offerings", id: id }],
    }),

    retrieveOfferings: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => (response as any).data.data,
      providesTags: ["Offerings"],
    }),

    paginatedOfferings: builder.query({
      query: (p: PaginatedOfferingsPayload) => {
        const params = new URLSearchParams();
        if (p.startAfter !== null && p.startAfter !== undefined) {
          params.append("startAfter", p.startAfter);
        }
        if (p.orderBy !== null && p.orderBy !== undefined) {
          params.append("orderBy", p.orderBy);
        }
        if (p.limit !== null && p.limit !== undefined) {
          params.append("limit", p.limit.toString());
        }
        if (p.search !== null && p.search !== undefined) {
          params.append("q", p.search);
        }
        if (p.category !== null && p.category !== undefined) {
          params.append("category", p.category);
        }
        if (p.active !== null && p.active !== undefined) {
          params.append("active", p.active.toString());
        }
        if (p.transitDistances !== null && p.transitDistances !== undefined) {
          params.append(
            "transitDistance",
            p.transitDistances.map((x) => x.toString()).join(",")
          );
        }
        const queryString = params.toString();
        const url = `/paginated${queryString ? `?${queryString}` : ""}`;
        return {
          url: url,
          method: "GET",
        };
      },
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as {
          offerings: Offering[];
          lastStartAfter: string | null;
          nextStartAfter: string | null;
          total: number;
        },
      providesTags: (result, error, { startAfter, limit, category }) => [
        {
          type: "PaginatedOfferings",
          id: `startAfter=${startAfter}&limit=${limit}&category=${category}`,
        },
        { type: "PaginatedOfferings", id: "LIST" },
      ],
    }),

    getHerbOfTheWeek: builder.query({
      query: () => ({
        url: "/herb-of-the-week",
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => (response as any).data.data,
      providesTags: ["HerbOfTheWeek"],
    }),

    createOffering: builder.mutation({
      query: (body) => ({
        url: "/",
        method: "POST",
        body,
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as Offering,
      invalidatesTags: () => [{ type: "Offerings", id: "LIST" }],
    }),

    updateOffering: builder.mutation({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: "PATCH",
        body,
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as Offering,
      invalidatesTags: (result, error, { id }) => [
        { type: "Offerings", id },
        { type: "PaginatedOfferings", id: "LIST" },
      ],
    }),

    deleteOffering: builder.mutation({
      query: ({ id }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Offerings", id },
        { type: "PaginatedOfferings", id: "LIST" },
      ],
    }),

    updateHerbOfTheWeek: builder.mutation({
      query: (updateData) => ({
        url: "/herb-of-the-week",
        method: "PATCH",
        body: updateData,
      }),
      transformResponse: (response, meta, arg) => (response as any).data.data,
      onQueryStarted: (response, { dispatch, queryFulfilled }) => {
        // Update the herb of the week when the request is sent
        const patchResult = dispatch(
          offeringsApi.util.updateQueryData(
            "getHerbOfTheWeek",
            null,
            (draft: any) => {
              Object.assign(draft, response);
            }
          )
        );
        // Undo the optimistic update if the request fails
        queryFulfilled.catch(patchResult.undo);
      },
    }),

    createPickupLocation: builder.mutation({
      query: (body: Omit<PickupLocation, "id">) => ({
        url: "/pickup-locations",
        method: "POST",
        body,
      }),
      invalidatesTags: ["PickupLocations"],
    }),

    updatePickupLocation: builder.mutation({
      query: ({ id, ...body }: PickupLocation) => ({
        url: `/pickup-locations/${id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["PickupLocations"],
    }),

    retrievePickupLocations: builder.query({
      query: ({ active }: { active?: boolean }) => {
        // Construct the base URL
        let url = `/pickup-locations`;

        // Append the `active` parameter to the URL if it is provided
        if (active !== undefined) {
          url += `?active=${active}`;
        }

        return {
          url,
          method: "GET",
        };
      },
      // cache by active status
      providesTags: ["PickupLocations"],
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.locations as PickupLocation[],
    }),

    deletePickupLocation: builder.mutation({
      query: ({ id }) => ({
        url: `/pickup-locations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PickupLocations"],
    }),
  }),
});

export const {
  useActiveOfferingCategoriesQuery,
  usePaginatedOfferingsQuery,
  useRetrieveOfferingQuery,
  useRetrieveOfferingsQuery,
  useUpdateOfferingMutation,
  useGetHerbOfTheWeekQuery,
  useUpdateHerbOfTheWeekMutation,
  useCreateOfferingMutation,
  useDeleteOfferingMutation,
  useCreatePickupLocationMutation,
  useUpdatePickupLocationMutation,
  useRetrievePickupLocationsQuery,
  useDeletePickupLocationMutation,
  usePrefetch,
} = offeringsApi;

export default offeringsApi;
