import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth } from "firebase/auth";
import { initializeFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(
    process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string
  ),
  isTokenAutoRefreshEnabled: true,
});

const auth = getAuth(app);

const functions = getFunctions(app);

const db = initializeFirestore(app, {
  experimentalAutoDetectLongPolling: true,
});

const storage = getStorage(app);

const performance = getPerformance(app);

// connect to local firebase functions emulator for development testing
// connectFunctionsEmulator(functions, "localhost", 5001);

export { app, analytics, appCheck, auth, functions, db, storage, performance };
