import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { $createHeadingNode, $createQuoteNode } from "@lexical/rich-text";
import { $wrapNodes } from "@lexical/selection";
import {
  ChatSquareQuote,
  ListOl,
  ListUl,
  TextParagraph,
  TypeH1,
  TypeH2,
  TypeH3,
} from "@styled-icons/bootstrap";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
} from "lexical";
import { ReactElement, RefObject, useEffect, useRef } from "react";

export const supportedBlockTypes = new Set([
  "paragraph",
  "quote",
  "h1",
  "h2",
  "h3",
  "ul",
  "ol",
]);

export const blockTypeToBlockName: { [key: string]: ReactElement } = {
  paragraph: (
    <>
      <TextParagraph className="h-5 w-5" />
      <span>Normal</span>
    </>
  ),
  h1: (
    <>
      <TypeH1 className="h-5 w-5" />
      <span>Large Header</span>
    </>
  ),
  h2: (
    <>
      <TypeH2 className="h-5 w-5" />
      <span>Medium Header</span>
    </>
  ),
  h3: (
    <>
      <TypeH3 className="h-5 w-5" />
      <span>Small Header</span>
    </>
  ),
  ul: (
    <>
      <ListUl className="h-5 w-5" />
      <span>Bullet List</span>
    </>
  ),
  ol: (
    <>
      <ListOl className="h-5 w-5" />
      <span>Numbered List</span>
    </>
  ),
  quote: (
    <>
      <ChatSquareQuote className="h-5 w-5" />
      <span>Quote</span>
    </>
  ),
};

interface ToolbarDropdownProps {
  editor: any;
  blockType: string;
  toolbarRef: RefObject<HTMLDivElement>;
  setShowBlockOptionsDropDown: (show: boolean) => void;
}

const BlockStylesDropdown = ({
  editor,
  blockType,
  toolbarRef,
  setShowBlockOptionsDropDown,
}: ToolbarDropdownProps) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      dropDown.style.top = `${top + scrollTop + 40}px`;
      dropDown.style.left = `${left + scrollLeft - 10}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: any) => {
        const target = event.target;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatMediumHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h2"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== "h3") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h3"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatFunctions = {
    paragraph: formatParagraph,
    h1: formatLargeHeading,
    h2: formatMediumHeading,
    h3: formatSmallHeading,
    ul: formatBulletList,
    ol: formatNumberedList,
    quote: formatQuote,
  } as { [key: string]: () => void };

  return (
    <div
      className="absolute z-50 rounded-lg border border-gray-100 bg-white shadow-md"
      ref={dropDownRef}
    >
      {Object.entries(blockTypeToBlockName).map(([blockTypeKey, blockName]) => (
        <button
          key={blockTypeKey}
          className={`m-1 flex w-40 items-center gap-4 rounded-lg bg-white p-2 text-sm tracking-wide text-black transition-colors ${
            blockType === blockTypeKey ? "bg-sky-100" : "hover:bg-sky-50"
          }`}
          onClick={formatFunctions[blockTypeKey]}
        >
          {blockName}
        </button>
      ))}
    </div>
  );
};

export default BlockStylesDropdown;
