import { Image } from "@styled-icons/fluentui-system-filled";
import { useMemo } from "react";

const H1Skeleton = () => {
  const getRandomWidth = () => {
    const widths = ["w-1/2", "w-1/3", "w-2/3", "w-3/5", "w-4/5"];
    return widths[Math.floor(Math.random() * widths.length)];
  };

  const getRandomSegments = () => {
    return Math.floor(Math.random() * 3) + 1; // returns a number between 1 and 3
  };

  // Use memoization to cache the random widths and number of segments
  const [randomWidths, segments] = useMemo(() => {
    return [
      Array.from({ length: getRandomSegments() }).map(() => getRandomWidth()),
      getRandomSegments(),
    ];
  }, []);

  return (
    <div role="status" className={`flex w-full animate-pulse`}>
      {Array.from({ length: segments }).map((_, index) => (
        <div
          key={index}
          className={`h-4 ${randomWidths[index]} mr-1 rounded-full bg-gray-300 last:mr-0 dark:bg-gray-700`}
        ></div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const ParagraphSkeleton = ({ size = "md" }) => {
  const sizes: { [size: string]: string[] } = {
    sm: ["w-1/4", "w-1/3", "w-1/2", "w-2/3", "w-3/4"],
    md: ["w-1/3", "w-1/2", "w-2/3", "w-3/4", "w-4/5"],
    lg: ["w-1/2", "w-2/3", "w-3/4", "w-4/5", "w-full"],
    xl: ["w-2/3", "w-3/4", "w-4/5", "w-full"],
  };

  const getRandomWidth = () => {
    const widths = sizes[size];
    return widths[Math.floor(Math.random() * widths.length)];
  };

  const getRandomSegments = () => {
    return Math.floor(Math.random() * 5) + 1; // returns a number between 1 and 5
  };

  const layout = useMemo(() => {
    return Array.from({ length: 6 }).map(() =>
      Array.from({ length: getRandomSegments() }).map(() => getRandomWidth())
    );
  }, [size]);

  return (
    <div role="status" className="max-w-lg animate-pulse space-y-2.5">
      {layout.map((row, rowIndex) => (
        <div key={rowIndex} className="flex w-full items-center space-x-2">
          {row.map((width, segmentIndex) => (
            <div
              key={segmentIndex}
              className={`h-2.5 rounded-full ${
                segmentIndex % 2 === 0
                  ? "bg-gray-200 dark:bg-gray-700"
                  : "bg-gray-300 dark:bg-gray-600"
              } ${width}`}
            ></div>
          ))}
        </div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const ControlPanelSkeleton = ({ size = "md" }) => {
  const rowCounts: { [size: string]: [number, number] } = {
    sm: [1, 2], // 1 to 2 rows for small
    md: [2, 3], // 2 to 3 rows for medium
    lg: [3, 5], // 3 to 5 rows for large
    xl: [4, 6], // 4 to 6 rows for extra large
  };

  const elementsPerRow: { [size: string]: [number, number] } = {
    sm: [1, 2], // 1 to 2 buttons for small
    md: [2, 4], // 2 to 4 buttons for medium
    lg: [3, 5], // 3 to 5 buttons for large
    xl: [4, 6], // 4 to 6 buttons for extra large
  };

  const buttonWidths: { [size: string]: string } = {
    sm: "w-20",
    md: "w-24",
    lg: "w-28",
    xl: "w-32",
  };

  const buttonHeights: { [size: string]: string } = {
    sm: "h-6",
    md: "h-8",
    lg: "h-10",
    xl: "h-12",
  };

  const [minRows, maxRows] = rowCounts[size];
  const [minElements, maxElements] = elementsPerRow[size];

  const buttonWidth = buttonWidths[size];
  const buttonHeight = buttonHeights[size];

  const numberOfRows = useMemo(() => {
    return Math.floor(Math.random() * (maxRows - minRows + 1)) + minRows;
  }, [minRows, maxRows]);

  const rows = useMemo(() => {
    return Array.from({ length: numberOfRows }).map(() => {
      const numberOfElements =
        Math.floor(Math.random() * (maxElements - minElements + 1)) +
        minElements;
      return Array.from({ length: numberOfElements }).map(() => buttonWidth);
    });
  }, [numberOfRows, buttonWidth, minElements, maxElements]);

  return (
    <div role="status" className="animate-pulse space-y-2">
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="flex space-x-2">
          {row.map((width, buttonIndex) => (
            <button
              key={buttonIndex}
              className={`rounded ${buttonHeight} ${width} bg-gray-300 dark:bg-gray-600`}
              disabled
            ></button>
          ))}
        </div>
      ))}
      <span className="sr-only">Loading...</span>
    </div>
  );
};

const ImageSkeleton = () => {
  return (
    <div
      role="status"
      className="h-full min-w-full animate-pulse space-y-8 md:flex md:items-center md:space-y-0 md:space-x-8"
    >
      <div className="flex h-full min-h-[250px] min-w-full items-center justify-center rounded bg-gray-300 py-32 dark:bg-gray-700 sm:w-96 sm:py-8 md:py-4">
        <Image className="w-8 text-gray-400" />
      </div>
    </div>
  );
};

export { ControlPanelSkeleton, H1Skeleton, ImageSkeleton, ParagraphSkeleton };
