import * as Yup from "yup";
import { ValidEmail } from "../../../constants/regex";
import {
  REGISTER_CONFIRM_PASSWORD,
  REGISTER_EMAIL,
  REGISTER_FIRST_NAME,
  REGISTER_LAST_NAME,
  REGISTER_NEWSLETTER_SIGNUP,
  REGISTER_PASSWORD,
} from "./fieldNames";

const validationSchema = Yup.object().shape({
  [REGISTER_FIRST_NAME]: Yup.string()
    .min(2, "Please provide your first name")
    .max(50, "First name is too long")
    .required("Please provide your first name"),
  [REGISTER_LAST_NAME]: Yup.string()
    .min(2, "Please provide your last name")
    .max(50, "Last name is too long")
    .required("Please provide your last name"),
  [REGISTER_EMAIL]: Yup.string()
    .trim()
    .matches(ValidEmail, {
      message: "Invalid email address",
      excludeEmptyString: true,
    })
    .required("Please provide an email"),
  [REGISTER_PASSWORD]: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(50, "Password is too long")
    .required("Please provide a password"),
  [REGISTER_CONFIRM_PASSWORD]: Yup.string()
    .oneOf([Yup.ref(REGISTER_PASSWORD), null], "Passwords do not match")
    .required("Please confirm your password"),
  [REGISTER_NEWSLETTER_SIGNUP]: Yup.boolean(),
});

export default validationSchema;
