import { Facebook, Instagram, Linkedin } from "@styled-icons/boxicons-logos";

export enum SocialAccount {
  Instagram,
  Facebook,
  LinkedIn,
}

export const AllSocialAccounts = [
  SocialAccount.Instagram,
  SocialAccount.Facebook,
  SocialAccount.LinkedIn,
];

export const SocialAccountInfo: {
  [key in SocialAccount]: {
    name: string;
    url: string;
    icon: (height: number) => React.ReactNode;
  };
} = {
  [SocialAccount.Instagram]: {
    name: "Instagram",
    url: "https://www.instagram.com/maranathafarmnj/",
    icon: (height) => <Instagram className={`aspect-square h-${height}`} />,
  },
  [SocialAccount.Facebook]: {
    name: "Facebook",
    url: "https://www.facebook.com/profile.php?id=100090116379423",
    icon: (height) => <Facebook className={`aspect-square h-${height}`} />,
  },
  [SocialAccount.LinkedIn]: {
    name: "LinkedIn",
    url: "https://www.linkedin.com/company/maranathafarm/",
    icon: (height) => <Linkedin className={`aspect-square h-${height}`} />,
  },
};
