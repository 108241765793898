import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from "./types";

export const initialState: ApplicationState = {
  extendedNavIsOpen: false,
  loginModalMessage: null,
  dark: false,
};

const appSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setExtendedNavIsOpen(state, action: PayloadAction<boolean>) {
      state.extendedNavIsOpen = action.payload;
    },
    setLoginModalMessage(state, action: PayloadAction<string | null>) {
      state.loginModalMessage = action.payload;
    },
    setDarkMode(state, action: PayloadAction<boolean>) {
      state.dark = action.payload;
    },
  },
});

export const { setExtendedNavIsOpen, setLoginModalMessage, setDarkMode } =
  appSlice.actions;

export default appSlice.reducer;
