import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setExtendedNavIsOpen } from "./reducer";

export function useExtendedNavIsOpen(): boolean {
  const extendedNavIsOpen = useAppSelector(
    ({ app: { extendedNavIsOpen } }) => extendedNavIsOpen
  );

  return extendedNavIsOpen;
}

export function useToggleExtendedNav(): () => void {
  const isOpen = useExtendedNavIsOpen();
  const dispatch = useAppDispatch();
  return useCallback(
    () => dispatch(setExtendedNavIsOpen(!isOpen)),
    [dispatch, isOpen]
  );
}

export function useDarkMode(): boolean {
  const dark = useAppSelector(({ app: { dark } }) => dark);

  return dark;
}
