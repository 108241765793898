import { Button, Divider } from "@tremor/react";
import ModalContainer from ".";
import { useUpdateOrderMutation } from "../../redux/api/orders/api";
import { Order } from "../../redux/api/orders/types";
import {
  useOpenModal,
  useOpenModalCount,
  usePopModal,
} from "../../redux/state/modals/hooks";
import { ModalType } from "../../redux/state/modals/types";
import OrderDetails from "../OrderDetails";

const OrderDetailsModal = ({ order }: { order: Order }) => {
  const popModal = usePopModal();

  const [triggerUpdateOrder, { isLoading }] = useUpdateOrderMutation();

  const openModalCount = useOpenModalCount();

  const newsletterModal = useOpenModal(ModalType.ORDER_DETAILS);

  return (
    newsletterModal && (
      <ModalContainer
        dismissible={true}
        scrollable={true}
        hasMask={true}
        index={openModalCount + 1}
        type={ModalType.ORDER_DETAILS}
      >
        <h1 className="text-center text-2xl font-bold">
          Order Details ({order.orderId})
        </h1>

        <OrderDetails order={order} />

        <Divider />

        <div className="flex justify-between gap-2">
          <Button variant="secondary" color="red" onClick={() => popModal()}>
            Close
          </Button>

          {!order.fulfilled && (
            <Button
              color="emerald"
              variant="primary"
              loading={isLoading}
              onClick={async () => {
                await triggerUpdateOrder({
                  orderId: order.orderId,
                  fulfilled: true,
                });
                popModal();
              }}
            >
              Mark Fulfilled
            </Button>
          )}
        </div>
      </ModalContainer>
    )
  );
};

export default OrderDetailsModal;
