import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@tremor/react";
import saveAs from "file-saver";
import { useEffect, useState } from "react";
import DashboardSection from "../../../components/DashboardSection";
import { UserRole } from "../../../constants/firestore";
import { useAuthentication } from "../../../hooks/useAuthentication";
import FulfillmentReports from "./FulfillmentReports";
import { RevenueReports } from "./RevenueReports";

export const handleDownload = (fileName: string, base64: string) => {
  const byteCharacters = atob(base64 || "");
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/octet-stream" });
  saveAs(blob, fileName);
};

interface ReportTab {
  title: string;
  component: React.FC;
  hash: string;
  claims?: UserRole[];
}

const Reports = () => {
  const { currentUserHasValidClaims } = useAuthentication();
  const [tabIndex, setTabIndex] = useState(0);

  const tabs: ReportTab[] = [
    {
      title: "Fulfillment",
      component: FulfillmentReports,
      hash: "#fulfillment",
    },
    {
      title: "Revenue",
      component: RevenueReports,
      hash: "#revenue",
      claims: [UserRole.Admin],
    },
  ].filter((tab) => !tab.claims || currentUserHasValidClaims(tab.claims));

  useEffect(() => {
    const hash = window.location.hash;
    const foundIndex = tabs.findIndex((tab) => tab.hash === hash);
    setTabIndex(foundIndex >= 0 ? foundIndex : 0);
  }, [tabs]);

  return (
    <DashboardSection title={""} order={0} loading={false}>
      <TabGroup
        index={tabIndex}
        onIndexChange={(index) => {
          const newHash = tabs[index]?.hash || "#";
          window.history.replaceState(null, "", newHash);
          setTabIndex(index);
        }}
      >
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.title}>{tab.title}</Tab>
          ))}
        </TabList>

        <TabPanels className="pt-4">
          {tabs.map((tab) => (
            <TabPanel key={tab.title}>
              {tab.component ? <tab.component /> : null}
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </DashboardSection>
  );
};

export default Reports;
