import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../..";
import { EditorState, EditorType } from "./types";

/**
 * @returns a selector that provides the editor with the given id
 */
export const makeSelectEditor = () =>
  createSelector(
    [
      (state: AppState) => state.lexical.editors,
      (state: AppState, type: EditorType) => type,
    ],
    (editors: { [type in EditorType]: EditorState }, type: EditorType) => {
      return editors[type];
    }
  );
