import { Search } from "@styled-icons/fluentui-system-regular";
import { TextInput } from "@tremor/react";
import { Form, FormikProps } from "formik";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { SEARCH_INPUT } from "./fieldNames";
import { SearchOrdersFormValues } from "./initialValues";

interface Props {
  props: FormikProps<SearchOrdersFormValues>;
}

const SearchForm = ({ props }: Props) => {
  const [searchParams] = useSearchParams();

  const emailSearchParam = searchParams.get("email");

  useEffect(() => {
    if (emailSearchParam) {
      props.setFieldValue(SEARCH_INPUT, emailSearchParam);
    }
  }, [emailSearchParam]);

  return (
    <Form
      className="flex grow flex-wrap gap-2"
      onSubmit={(e: any) => {
        e.preventDefault();
        props.validateForm();
        props.handleSubmit();
      }}
      noValidate
    >
      <div className="flex flex-grow flex-col">
        <TextInput
          icon={Search}
          type="text"
          placeholder="someone@example.com"
          value={props.values[SEARCH_INPUT]}
          onChange={(e: any) => {
            props.setFieldValue(SEARCH_INPUT, e.target.value);
          }}
          onKeyDown={(e: any) => e.key === "Enter" && props.submitForm()}
        />
      </div>
    </Form>
  );
};

export default SearchForm;
