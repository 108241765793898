import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";

const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/content`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  endpoints: (builder) => ({
    uploadImage: builder.mutation({
      query: (body) => ({
        url: `/images`,
        body: body,
        method: "POST",
      }),
      // API returns the image url
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.url as string,
    }),
  }),
});

export const { useUploadImageMutation } = contentApi;

export default contentApi;
