import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";
import { getUrlSlug } from "../../../helpers/StringHelpers";
import { Maker } from "./types";

const makersApi = createApi({
  reducerPath: "makersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/makers`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ["Makers"],
  endpoints: (builder) => ({
    createMaker: builder.mutation({
      query: (body) => ({
        url: `/`,
        body: body,
        method: "POST",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as Maker,
      invalidatesTags: [{ type: "Makers", id: "LIST" }],
    }),

    retrieveMakers: builder.query({
      query: (searchParams) => ({
        url: `/`,
        params: Object.fromEntries(
          Object.entries(searchParams).filter(
            ([_, value]) => value !== null && value !== undefined
          )
        ),
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        const maker = (response as any).data.data as Maker[];
        // set the slug for each maker
        maker.forEach((maker) => {
          maker.slug = getUrlSlug(maker.name);
          maker.logoUrl += `?${new Date().getTime()}`;
          maker.featureImageUrl += `?${new Date().getTime()}`;
        });
        return maker;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Makers", id } as const)),
              { type: "Makers", id: "LIST" },
            ]
          : [{ type: "Makers", id: "LIST" }],
    }),

    updateMaker: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/${id}`,
        body: patch,
        method: "PATCH",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as Maker,
      invalidatesTags: (result, error, arg) => [{ type: "Makers", id: arg.id }],
    }),

    deleteMaker: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Makers", id: arg }],
    }),
  }),
});

export const {
  useCreateMakerMutation,
  useRetrieveMakersQuery,
  useUpdateMakerMutation,
  useDeleteMakerMutation,
} = makersApi;

export default makersApi;
