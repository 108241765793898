import {
  REGISTER_CONFIRM_PASSWORD,
  REGISTER_EMAIL,
  REGISTER_FIRST_NAME,
  REGISTER_LAST_NAME,
  REGISTER_NEWSLETTER_SIGNUP,
  REGISTER_PASSWORD,
} from "./fieldNames";

export interface RegisterFormValues {
  [REGISTER_FIRST_NAME]: string;
  [REGISTER_LAST_NAME]: string;
  [REGISTER_EMAIL]: string;
  [REGISTER_PASSWORD]: string;
  [REGISTER_CONFIRM_PASSWORD]: string;
  [REGISTER_NEWSLETTER_SIGNUP]: boolean;
}

const initialValues: RegisterFormValues = {
  [REGISTER_FIRST_NAME]: "",
  [REGISTER_LAST_NAME]: "",
  [REGISTER_EMAIL]: "",
  [REGISTER_PASSWORD]: "",
  [REGISTER_CONFIRM_PASSWORD]: "",
  [REGISTER_NEWSLETTER_SIGNUP]: false,
};

export default initialValues;
