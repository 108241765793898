import { getToken } from "firebase/app-check";
import { appCheck, auth } from "../../config/firebase";

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL as string;

export const prepareDefaultHeaders = async (
  headers: Headers,
  { getState, endpoint }: { getState: () => unknown; endpoint: string }
): Promise<Headers> => {
  headers.set("Content-Type", "application/json");

  const appCheckTokenResponse = await getToken(appCheck);
  headers.set("X-Firebase-AppCheck", appCheckTokenResponse.token);

  const idToken = await auth.currentUser?.getIdToken();
  if (idToken) {
    headers.set(`Authorization`, `Bearer ${idToken}`);
  }

  return headers;
};
