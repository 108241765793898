import {
  BuildingShop,
  ChevronRight,
  ErrorCircle,
  LeafThree,
} from "@styled-icons/fluentui-system-filled";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { animated, useTransition } from "react-spring";
import PurchaseOptionsForm from "../components/Forms/PurchaseOptionsForm";
import {
  ControlPanelSkeleton,
  H1Skeleton,
  ImageSkeleton,
  ParagraphSkeleton,
} from "../components/Skeletons";
import TransitDistanceIcon from "../components/TransitDistanceIcon";
import { getReadableList, toFriendlyCase } from "../helpers/StringHelpers";
import { useAnalytics } from "../hooks/useAnalytics";
import useTruncatedText from "../hooks/useTruncatedText";
import {
  useGetHerbOfTheWeekQuery,
  usePaginatedOfferingsQuery,
  useRetrieveOfferingQuery,
} from "../redux/api/offerings/api";
import { Offering, TransitDistance } from "../redux/api/offerings/types";
import { ShopOfferingCard } from "./Shop";

const ProductDetailsPage = () => {
  const navigate = useNavigate();

  const { logViewItem } = useAnalytics();

  const {
    data: hotw,
    isLoading: loadingHotw,
    isFetching: fetchingHotw,
  } = useGetHerbOfTheWeekQuery(null);

  // retrieve the url params
  const { type, offeringId, optionId } = useParams();

  if (!offeringId || !optionId) {
    navigate("/shop");
  }

  console.log(`Offering ID: ${offeringId}`);

  const relatedOfferingsRef = useRef<HTMLDivElement>(null);

  const {
    data: offering,
    isLoading: isLoadingOffering,
    isFetching: isFetchingOffering,
  } = useRetrieveOfferingQuery({
    id: offeringId ?? "",
  });

  const loadingOffering = isLoadingOffering || isFetchingOffering;

  console.log(`Offering data: ${JSON.stringify(offering, null, 2)}`);

  const option = offering?.options.find((o) => o.id === optionId) ?? null;

  const availableInventory = option?.availableInventory ?? 0;
  const reservedInventory = option?.reservedInventory ?? 0;
  const realInventory = availableInventory - reservedInventory;
  console.log(`Available inventory: ${availableInventory}`);
  console.log(`Real inventory: ${realInventory}`);
  console.log(`Reserved inventory: ${reservedInventory}`);
  const isSoldOut = realInventory <= 0;
  console.log(`Is sold out: ${isSoldOut}`);

  const {
    data: relatedOfferingsData,
    isLoading: loadingRelatedOfferings,
    isFetching: fetchingRelatedOfferings,
  } = usePaginatedOfferingsQuery({
    category: offering?.type,
    active: true,
    limit: 25,
  });

  const relatedOfferings: Offering[] =
    relatedOfferingsData?.offerings.filter((o) => o.id !== offering?.id) ?? [];

  const maxDescriptionLength = 250;
  const {
    truncatedText: descriptionText,
    isTruncated,
    toggleTruncated,
  } = useTruncatedText(option?.description ?? null, maxDescriptionLength);

  /**
   * Add a wheel listener to the related offerings container
   */
  useEffect(() => {
    const node = relatedOfferingsRef.current;
    if (node) {
      node.addEventListener("wheel", (evt) => {
        // only scroll left if the node is overflowing
        if (node.offsetWidth < node.scrollWidth) {
          evt.preventDefault();
          node!.scrollLeft += evt.deltaY;
        }
      });
    }
  }, [relatedOfferingsRef?.current]);

  /**
   * Handle related offering click side effects
   * @param offering
   */
  function handleRelatedOfferingClick(offering: Offering) {
    logViewItem(offering);
    navigate(`/shop/${offering.type}/${offering.id}/${offering.options[0].id}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="mb-36">
      <Helmet>
        <title>Shop | Maranatha Farm</title>
        <link rel="canonical" href={`http://www.maranatha.farm`} />
      </Helmet>

      <section className="overflow-hidden text-stone-700">
        <div className="mx-auto w-full space-y-4 p-5 lg:w-4/5 lg:max-w-7xl lg:px-5 lg:py-24">
          <div className="flex w-full flex-wrap md:flex-row-reverse md:flex-nowrap">
            <div className="w-full md:ml-6 md:w-1/2">
              {loadingOffering ? (
                <ImageSkeleton />
              ) : (
                <img
                  className="min-w-full border border-stone-200 object-cover object-center"
                  src={`${option?.image}`}
                  alt="offering"
                />
              )}
            </div>
            <div className="mt-6 w-full space-y-6 md:mt-0 md:w-1/2">
              <div className="flex flex-col space-y-3 bg-stone-100 p-8">
                {/* Breadcrumbs */}
                <section className="title-font text-sm uppercase tracking-wide text-stone-600">
                  <NavLink to={`/shop`}>
                    <span>shop</span>
                  </NavLink>
                  <span>&nbsp;&#47;&nbsp;</span>
                  {type && (
                    <NavLink to={`/shop/${type}`} className="font-bold">
                      <span>{toFriendlyCase(type).toUpperCase()}</span>
                    </NavLink>
                  )}
                </section>
                {loadingOffering ? (
                  <H1Skeleton />
                ) : (
                  <h1 className="text-3xl font-bold tracking-wide text-stone-900">
                    {offering?.name}
                  </h1>
                )}
                {offering?.options?.length && offering?.options.length > 1 && (
                  <h2 className="italic">
                    Available in{" "}
                    {getReadableList(offering.options.map((o: any) => o.name))}
                  </h2>
                )}
                {loadingOffering ? (
                  <ParagraphSkeleton />
                ) : (
                  <>
                    <div className="space-y-2">
                      <span className="text-2xl text-stone-700">
                        {option?.prettyPrice}
                      </span>
                    </div>
                    {isSoldOut ? (
                      <div className="flex w-fit items-center space-x-1 rounded-full border-2 border-red-600 bg-stone-50 py-0.5 pl-1 pr-2">
                        <ErrorCircle className="icon text-red-600" />
                        <span className="text-sm text-red-600">Sold Out</span>
                      </div>
                    ) : (
                      <div className="text-sm text-stone-500">
                        <span>Available Quantity: {realInventory}</span>
                      </div>
                    )}
                    <p className="whitespace-pre-wrap leading-relaxed tracking-wide text-stone-800">
                      {descriptionText}
                    </p>
                    {descriptionText &&
                      descriptionText.length > maxDescriptionLength && (
                        <button
                          onClick={toggleTruncated}
                          className="self-end text-sm font-bold tracking-wide text-gray-500 underline underline-offset-4 transition-colors hover:text-gray-900"
                        >
                          {isTruncated ? "Read more" : "Read less"}
                        </button>
                      )}
                  </>
                )}
                {/* Dropdowns */}
                {!loadingOffering && (
                  <ul className="space-y-1 border-t border-stone-200">
                    {/* Transit Distance */}

                    {offering &&
                      offering.transitDistance &&
                      offering.transitDistance !== TransitDistance.None && (
                        <ProductDescriptionDropdown
                          title={
                            <div className="flex items-center space-x-4 font-bold text-stone-700">
                              <span className="tracking-wide">
                                Travel Distance
                              </span>
                              <span className="text-stone-400">
                                <TransitDistanceIcon
                                  distance={offering.transitDistance}
                                />
                              </span>
                            </div>
                          }
                        >
                          <div className="flex flex-col">
                            {offering.transitDescription && (
                              <span className="tracking-wide">
                                {offering.transitDescription}
                              </span>
                            )}
                            <NavLink
                              to="/faq#transit-distance-icons"
                              className="mt-4 w-fit border-b border-slate-700 text-slate-700"
                            >
                              Learn more
                            </NavLink>
                          </div>
                        </ProductDescriptionDropdown>
                      )}

                    {/* Herb of the Week */}

                    {hotw && option?.hasHerbOfTheWeek && (
                      <ProductDescriptionDropdown
                        title={
                          <div className="flex items-center space-x-4 font-bold text-stone-700">
                            <span className="tracking-wide">
                              Herb of the Week
                            </span>
                            <span className="">
                              <LeafThree className="icon text-emerald-900" />
                            </span>
                          </div>
                        }
                      >
                        <p className="flex flex-col tracking-wide">
                          This week's herb is {`${hotw.herb}`}
                        </p>
                      </ProductDescriptionDropdown>
                    )}

                    {/* Meet the maker */}

                    {offering?.makerId && offering?.maker?.name && (
                      <ProductDescriptionDropdown
                        title={
                          <div className="flex items-center space-x-4 font-bold text-stone-700">
                            <span className="tracking-wide">
                              Meet the Maker
                            </span>
                            <span className="">
                              <BuildingShop className="icon text-stone-800" />
                            </span>
                          </div>
                        }
                      >
                        <div className="flex flex-col">
                          <p
                            className="flex flex-col tracking-wide"
                            dangerouslySetInnerHTML={{
                              __html: offering?.maker?.description ?? "",
                            }}
                          />
                        </div>
                      </ProductDescriptionDropdown>
                    )}
                  </ul>
                )}
              </div>

              {/* Purchase Options */}

              {loadingOffering ? (
                <ControlPanelSkeleton size="sm" />
              ) : (
                <>
                  {offering && option && (
                    <PurchaseOptionsForm
                      offering={offering}
                      option={option}
                      isOutOfStock={isSoldOut}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Related Offerings Section */}

      {relatedOfferings.length > 0 && (
        <section
          className={`container mx-auto flex px-5 py-24 md:w-4/5 ${
            relatedOfferings.length === 0 && "hidden"
          }`}
        >
          <div className="w-full">
            <h1 className="mb-8 text-2xl">You Might Also Like</h1>

            <div
              className="hide-scroll flex max-h-min w-full space-x-8 overflow-x-auto border-b border-stone-400 pb-6"
              ref={relatedOfferingsRef}
            >
              {relatedOfferings.map((offering, i) => (
                <animated.div
                  key={offering.id}
                  className="h-auto min-w-[300px] max-w-[300px]"
                >
                  <ShopOfferingCard
                    offering={offering}
                    clickAction={handleRelatedOfferingClick}
                    i={i}
                  />
                </animated.div>
              ))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

interface ProductDescriptionDropdownProps {
  title: JSX.Element;
  children?: JSX.Element;
}

const ProductDescriptionDropdown = ({
  title,
  children,
}: ProductDescriptionDropdownProps) => {
  const [_isOpen, _setIsOpen] = useState<boolean>(false);

  const transition = useTransition(_isOpen, {
    from: {
      maxHeight: "0px",
      marginTop: "0px",
      opacity: 0,
    },
    enter: {
      maxHeight: "1000px",
      marginTop: "12px",
      opacity: 1,
    },
    leave: {
      maxHeight: "0px",
      marginTop: "0px",
      opacity: 0,
    },
  });

  return (
    <li className="w-full list-none border-b border-stone-200 py-4">
      <button
        className="flex w-full items-center justify-between"
        onClick={() => _setIsOpen(!_isOpen)}
      >
        {title}{" "}
        <ChevronRight
          className={`icon-sm ${_isOpen && "rotate-90"} transition-transform`}
        />
      </button>

      {transition(
        (props, show) =>
          show && (
            <animated.div style={props} className="">
              {children}
            </animated.div>
          )
      )}
    </li>
  );
};

export default ProductDetailsPage;
