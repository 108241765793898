import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";

const newsletterApi = createApi({
  reducerPath: "newsletterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/newsletter`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ["Subscribers"],
  endpoints: (builder) => ({
    retrieveSubscribers: builder.query({
      query: () => ({
        url: `/subscribers`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.subscribers as [
          { email: string; dateSubscribed: number }
        ],
      providesTags: ["Subscribers"],
    }),

    addSubscriber: builder.mutation({
      query: ({ email }) => ({
        url: `/subscribers`,
        body: { email: email },
        method: "POST",
      }),

      transformResponse: (response, meta, arg) => (response as any).data.data,
    }),

    deleteSubscriber: builder.mutation({
      query: ({ email }) => ({
        url: `/subscribers`,
        body: { email: email },
        method: "DELETE",
      }),

      transformResponse: (response, meta, arg) => response as any,
    }),

    triggerNewsletter: builder.mutation({
      query: (body) => ({
        url: `/`,
        body: body,
        method: "POST",
      }),

      transformResponse: (response, meta, arg) => response as any,
    }),
  }),
});

export const {
  useRetrieveSubscribersQuery,
  useAddSubscriberMutation,
  useDeleteSubscriberMutation,
  useTriggerNewsletterMutation,
} = newsletterApi;

export default newsletterApi;
