import ModalContainer from ".";
import { useOpenModalCount } from "../../redux/state/modals/hooks";
import { ModalType } from "../../redux/state/modals/types";
import CheckoutForm from "../Forms/CheckoutForm";

const Cart = () => {
  const openModalCount = useOpenModalCount(ModalType.CART);

  return (
    <ModalContainer
      type={ModalType.CART}
      hasMask={true}
      dismissible={true}
      scrollable={true}
      index={openModalCount + 1}
    >
      <CheckoutForm />
    </ModalContainer>
  );
};

export default Cart;
