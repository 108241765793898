import { Checkmark, ErrorCircle } from "@styled-icons/fluentui-system-filled";
import { ErrorMessage, FieldInputProps, FormikState, getIn } from "formik";
import { ReactNode } from "react";

const InputElementStyle = ({ hasError }: { hasError: boolean }) =>
  [
    `mt-2 block w-full rounded-md border border-gray-400 bg-white px-5 py-3 text-gray-700 focus:border-teal-600 focus:outline-none focus:ring focus:ring-teal-600/40 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 appearance-none`,
    hasError && `!border-red-700 !ring-red-400 focus:border-red-700`,
  ].join(" ");

export interface IFormElementProps {
  field: FieldInputProps<any>;
  form: FormikState<any>;
  title: string | JSX.Element;
  label?: boolean;
  theme?: "light" | "dark";
  children?: JSX.Element;
  button?: ReactNode;
}

// Form Checkbox

export const FormikCheckbox = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <input {...field} {...props} id={field.name} className="peer hidden" />

        <label
          htmlFor={field.name}
          className="flex h-5 w-5 cursor-pointer items-center justify-center bg-gray-200 text-gray-200 peer-checked:bg-slate-800 peer-checked:text-white"
        >
          <Checkmark className="icon-sm" />
        </label>

        <label
          htmlFor={field.name}
          className="ml-4 flex w-fit cursor-pointer items-center"
        >
          <span className="text-gray-800">{title}</span>
        </label>
      </div>

      <span className="ml-9 text-sm text-red-700">
        <ErrorMessage name={field.name} />
      </span>
    </div>
  );
};

// Form Input for Admin interfaces

export const FormikToggle = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const hasError = touch && error;

  return (
    <label
      htmlFor={field.name}
      className="relative h-8 min-h-[2rem] w-14 min-w-[3.5rem] cursor-pointer"
    >
      <input
        {...field}
        {...props}
        id={field.name}
        type="checkbox"
        className="peer sr-only [&:checked_+_span_svg[data-checked-icon]]:block [&:checked_+_span_svg[data-unchecked-icon]]:hidden"
      />

      <span className="absolute inset-0 z-10 m-1 inline-flex h-6 w-6 items-center justify-center rounded-full bg-white text-gray-400 transition peer-checked:translate-x-6">
        <svg
          data-unchecked-icon
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>

        <svg
          data-checked-icon
          xmlns="http://www.w3.org/2000/svg"
          className="hidden h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>

      <span className="absolute inset-0 rounded-full bg-red-500 shadow-inner transition peer-checked:bg-green-500"></span>
    </label>
  );
};

export const FormikAdminInput = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const hasError = touch && error;

  return (
    <div className="flex-1">
      <label
        htmlFor={field.name}
        className="mb-2 block text-sm text-gray-600 dark:text-gray-200"
      >
        {title}
      </label>
      <div className="relative">
        <input
          {...field}
          {...props}
          id={field.name}
          className={InputElementStyle({ hasError })}
          onWheel={(e: any) => {
            // Prevent the input value change
            e.target.blur();
            // Prevent the page/container scrolling
            e.stopPropagation();
          }}
        />
        {hasError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <ErrorCircle className="icon h-4 w-4 text-red-700" />
          </div>
        )}
      </div>

      <div className="ml-1 mt-1 text-sm text-red-700">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};

// Form Input for User interfaces

export const FormikInput = ({
  field,
  form,
  title,
  label = true,
  theme,
  ...props
}: IFormElementProps) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const hasError = touch && error;

  return (
    <div>
      {label && (
        <label
          htmlFor={field.name}
          className="mb-4 block text-xs tracking-wider text-gray-200"
        >
          {title}
        </label>
      )}

      <div className="flex">
        <div className="relative flex h-12 flex-1">
          <input
            {...field}
            {...props}
            id={field.name}
            className={`flex-1 appearance-none rounded-none border-2 ${
              theme === "light"
                ? "border-stone-200 text-stone-200 placeholder-stone-200"
                : "border-gray-800 text-gray-800 placeholder-gray-800"
            } bg-transparent px-5 py-3 text-xs uppercase tracking-wider outline-none ${
              hasError && "border-red-600"
            }`}
          />

          {hasError && (
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
              <ErrorCircle className="icon h-4 w-4 text-red-700" />
            </div>
          )}
        </div>

        {props.button}
      </div>

      <div className="ml-1 mt-1 text-sm text-red-700">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};

// Form Select Option

export const FormikSelect = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const hasError = touch && error;

  return (
    <div className="flex-1">
      <label
        htmlFor={field.name}
        className="mb-2 block text-sm text-gray-600 dark:text-gray-200"
      >
        {title}
      </label>
      <div className="relative">
        <select
          {...field}
          {...props}
          id={field.name}
          className={InputElementStyle({ hasError })}
        >
          {props.children}
        </select>
        {hasError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <ErrorCircle className="icon h-4 w-4 text-red-700" />
          </div>
        )}
      </div>
      <div className="ml-1 mt-1 text-sm text-red-700">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};

export const AltFormikSelect = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  return (
    <div className="flex-1">
      <select
        {...field}
        {...props}
        id={field.name}
        className={`cursor-pointer bg-transparent focus:outline-none`}
      >
        {props.children}
      </select>
    </div>
  );
};

// Form Text Area

export const FormikTextArea = ({
  field,
  form,
  title,
  ...props
}: IFormElementProps) => {
  const error = getIn(form.errors, field.name);
  const touch = getIn(form.touched, field.name);
  const hasError = touch && error;

  return (
    <div className="flex-1">
      <label className="mb-2 block text-sm text-gray-600 dark:text-gray-200">
        {title}
      </label>
      <div className="relative">
        <textarea
          {...field}
          {...props}
          className={`${InputElementStyle({ hasError })} whitespace-pre-line`}
        />
        {hasError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            <ErrorCircle className="icon h-4 w-4 text-red-700" />
          </div>
        )}
      </div>
      <div className="ml-1 mt-1 text-sm text-red-700">
        <ErrorMessage name={field.name} />
      </div>
    </div>
  );
};

export interface IFormikInput {
  field: FieldInputProps<any>;
  form: FormikState<any>;
  props: any;
}

type ButtonElementProps = IFormikInput & {};

export const FormButton = ({ field, form, ...props }: ButtonElementProps) => {};
