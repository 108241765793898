import {
  CONTACT_INQUIRY_COMMENTS,
  CONTACT_INQUIRY_EMAIL,
  CONTACT_INQUIRY_FIRST_NAME,
  CONTACT_INQUIRY_LAST_NAME,
  CONTACT_INQUIRY_PHONE,
} from "./fieldNames";

export interface IContactUsValues {
  [CONTACT_INQUIRY_FIRST_NAME]: string;
  [CONTACT_INQUIRY_LAST_NAME]: string;
  [CONTACT_INQUIRY_EMAIL]: string;
  [CONTACT_INQUIRY_PHONE]: string;
  [CONTACT_INQUIRY_COMMENTS]: string;
}

export const initialValues: IContactUsValues = {
  [CONTACT_INQUIRY_FIRST_NAME]: "",
  [CONTACT_INQUIRY_LAST_NAME]: "",
  [CONTACT_INQUIRY_EMAIL]: "",
  [CONTACT_INQUIRY_PHONE]: "",
  [CONTACT_INQUIRY_COMMENTS]: "",
};
