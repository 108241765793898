import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";
import { UserRole } from "./types";

const rolesApi = createApi({
  reducerPath: "rolesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/roles`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ["User", "Roles"],
  endpoints: (builder) => ({
    retrieveAllRoles: builder.query({
      query: () => ({
        url: `/`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as UserRole[],
      providesTags: ["Roles"],
    }),

    retrieveUserRoles: builder.query({
      query: ({ email }) => ({
        url: `/${email}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data.roles as
          | {
              [key: string]: any;
            }
          | undefined,
      providesTags: (result, error, arg) => [{ type: "User", id: arg.email }],
    }),

    patchUserRoles: builder.mutation({
      query: ({ email, ...body }) => ({
        url: `/${email}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "User", id: arg.email },
      ],
    }),
  }),
});

export const {
  useRetrieveAllRolesQuery,
  useRetrieveUserRolesQuery,
  usePatchUserRolesMutation,
} = rolesApi;

export default rolesApi;
