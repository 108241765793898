import * as Yup from "yup";
import { HEIGHT, IMAGE_URL, WIDTH } from "./fieldNames";

export const ImageUploadSchema = Yup.object().shape({
  [IMAGE_URL]: Yup.string().trim().url("Please enter a valid URL"),
  [WIDTH]: Yup.number()
    .integer()
    .min(50)
    .max(550)
    .required("Width is required"),
  [HEIGHT]: Yup.number().integer().required("Height is required"),
});
