import {
  ALT_TEXT,
  BASE64,
  CUSTOM_STYLES,
  HEIGHT,
  IMAGE_URL,
  WIDTH,
} from "./fieldNames";

export interface ImageUploadValues {
  [IMAGE_URL]: string;
  [BASE64]: string;
  [ALT_TEXT]: string;
  [CUSTOM_STYLES]: string;
  [WIDTH]: number;
  [HEIGHT]: number;
}

export const InitialImageUploadValues: ImageUploadValues = {
  [IMAGE_URL]: "",
  [BASE64]: "",
  [ALT_TEXT]: "",
  [CUSTOM_STYLES]: "",
  [WIDTH]: 550,
  [HEIGHT]: 0,
};
