import * as Yup from "yup";
import { ValidEmail } from "../../../constants/regex";
import { LOGIN_EMAIL, LOGIN_PASSWORD } from "./fieldNames";

const validationSchema = Yup.object().shape({
  [LOGIN_EMAIL]: Yup.string()
    .matches(ValidEmail, {
      message: "Invalid email address",
      excludeEmptyString: true,
    })
    .required("Please provide your email address"),
  [LOGIN_PASSWORD]: Yup.string().required("Please provide your password"),
});

export default validationSchema;
