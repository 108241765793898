import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";

const contactApi = createApi({
  reducerPath: "contactApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/contact`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  endpoints: (builder) => ({
    createInquiry: builder.mutation({
      query: (body) => ({
        url: `/inquiries`,
        body: body,
        method: "POST",
      }),

      transformResponse: (response, meta, arg) => (response as any).data.data,
    }),
  }),
});

export const { useCreateInquiryMutation } = contactApi;

export default contactApi;
