import "../assets/styles/animate.css";

const useAnimation = () => {
  /**
   * Assigns an observer to an array of div refs
   * @param arrRef
   * @returns
   */
  function ApplySlideIn(
    arrRef: React.MutableRefObject<HTMLDivElement[]>,
    reverse: boolean = false,
    cascading: boolean = true
  ) {
    if (!arrRef?.current) return;

    arrRef.current = arrRef.current.slice(0, arrRef.current?.length);

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });

    arrRef.current.forEach((el) => observer.observe(el));

    arrRef.current.forEach((el, i) => {
      el.classList.add(reverse ? "hidden-reverse" : "hidden_");
      el.style.transitionDelay = cascading ? `${i * 100}ms` : "10ms";
    });
  }

  return { ApplySlideIn };
};

export default useAnimation;
