import { ChevronDown } from "@styled-icons/fluentui-system-regular";
import { Cart } from "@styled-icons/ionicons-solid";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { auth } from "../../config/firebase";
import { usePageEffects } from "../../hooks/usePageEffects";
import {
  useExtendedNavIsOpen,
  useToggleExtendedNav,
} from "../../redux/state/app/hooks";
import {
  useToggleCartModal,
  useToggleLoginModal,
} from "../../redux/state/modals/hooks";
import { AltLogo } from "../Logo";

interface LinkGroup {
  path: string;
  subOptions?: { name: string; path: string }[];
}

const links: { [link: string]: LinkGroup } = {
  shop: { path: "/shop" },
  offerings: { path: "/offerings" },
  about: {
    path: "/story",
    subOptions: [
      { name: "our story", path: "/story" },
      { name: "our prayer", path: "/prayer" },
      { name: "makers", path: "/makers" },
      { name: "newsletter", path: "/newsletter/archive" },
    ],
  },
  faq: { path: "/faq" },
};

const initializeSelectedSubOptions = (links: { [link: string]: LinkGroup }) => {
  const initialOptions: { [key: string]: { name: string; path: string } } = {};
  for (const key in links) {
    if (((links[key].subOptions && links[key].subOptions?.length) || 0) > 0) {
      initialOptions[key] = links[key].subOptions![0];
    } else {
      initialOptions[key] = { name: key, path: links[key].path };
    }
  }
  return initialOptions;
};

const NavBar = (props: any) => {
  const toggleCartModal = useToggleCartModal();
  const toggleLoginModal = useToggleLoginModal();

  const extendedNavIsOpen = useExtendedNavIsOpen();
  const toggleExtendedNav = useToggleExtendedNav();

  const { isScrolled } = usePageEffects();
  const location = useLocation();

  const [selectedSubOptions, setSelectedSubOptions] = useState(
    initializeSelectedSubOptions(links)
  );

  const handleNavigationClick = () => {
    toggleExtendedNav();
  };

  const handleSubOptionClick = (
    parentKey: string,
    subOption: { name: string; path: string }
  ) => {
    setSelectedSubOptions((prev) => ({ ...prev, [parentKey]: subOption }));
  };

  const filteredSubOptions = (
    subOptions: { name: string; path: string }[],
    parentKey: string
  ) => {
    return subOptions.filter(
      (subOption) => subOption.name !== selectedSubOptions[parentKey].name
    );
  };

  const isLinkActive = (path: string, subOptions?: { path: string }[]) => {
    if (location.pathname === path) return true;
    if (subOptions) {
      return subOptions.some(
        (subOption) => location.pathname === subOption.path
      );
    }
    return false;
  };

  return (
    <>
      <header
        className={`fixed left-0 right-0 z-50 grid text-xs uppercase shadow-sm transition-all duration-300 ease-in-out ${
          isScrolled ? "h-14 max-h-14" : "h-24 max-h-24"
        } ${
          extendedNavIsOpen ? "bg-stone-800" : "bg-stone-300"
        } grid-cols-4 md:grid-cols-12`}
      >
        {/* Open/Close Navigation Menu Button */}
        <div className="col-span-1 flex items-center justify-center md:hidden">
          <button
            className="cursor-pointer transition-all ease-in-out"
            onClick={handleNavigationClick}
          >
            <div
              className={`h-[2px] w-[24px] transform transition-all delay-100 duration-500 ease-in-out ${
                extendedNavIsOpen
                  ? "translate-y-[2px] -rotate-45 bg-stone-300"
                  : "mb-[5px] rotate-180 bg-stone-800"
              }`}
            ></div>
            <div
              className={`h-[2px] w-[24px] transform transition-all delay-200 duration-500 ease-in-out ${
                extendedNavIsOpen
                  ? "-rotate-90 scale-0 bg-stone-300"
                  : "mb-[5px] rotate-180 bg-stone-800"
              }`}
            ></div>
            <div
              className={`h-[2px] w-[24px] transform transition-all delay-200 duration-500 ease-in-out ${
                extendedNavIsOpen
                  ? "-translate-y-[2px] rotate-45 bg-stone-300"
                  : "mb-[0px] rotate-180 bg-stone-800"
              }`}
            ></div>
          </button>
        </div>

        {/* Navigation Links */}
        <ul className="col-span-5 hidden items-center space-x-4 pl-12 tracking-widest md:flex">
          {Object.keys(links).map((key, index) => (
            <li key={index} className="group relative">
              <NavLink
                to={selectedSubOptions[key].path}
                className={`fancy-underline relative flex items-center after:h-0.5 after:bg-stone-600 ${
                  isLinkActive(
                    selectedSubOptions[key].path,
                    links[key].subOptions
                  ) && "after:!scale-100"
                }`}
              >
                {selectedSubOptions[key].name}
                {links[key].subOptions && (
                  <ChevronDown className={`ml-1 w-3 text-black`} />
                )}
              </NavLink>
              {links[key].subOptions && (
                <ul className="absolute left-0 mt-0 hidden min-w-full bg-transparent group-hover:block group-focus:block">
                  <div className="mt-2 rounded-sm bg-stone-200 py-1 shadow-lg">
                    {filteredSubOptions(links[key].subOptions!, key).map(
                      (subOption, subIndex) => (
                        <li key={subIndex} className="px-4 py-2">
                          <NavLink
                            to={subOption.path}
                            className="fancy-underline relative block max-w-fit text-stone-700 after:h-0.5 after:bg-stone-600"
                            onClick={() => handleSubOptionClick(key, subOption)}
                          >
                            {subOption.name}
                          </NavLink>
                        </li>
                      )
                    )}
                  </div>
                </ul>
              )}
            </li>
          ))}
        </ul>

        {/* Maranatha Farm Logo SVG */}
        <div className="col-span-2 flex items-center justify-center md:col-span-2">
          <NavLink className="w-48 min-w-[192px]" to="/">
            <AltLogo style={extendedNavIsOpen ? "light" : "color"} />
          </NavLink>
        </div>

        {/* Login/Account & Cart Navigation Links */}
        <ul className="col-span-5 hidden items-center justify-end tracking-widest md:flex">
          <li className="flex h-full max-w-fit flex-1 items-center px-10">
            {auth.currentUser?.isAnonymous ? (
              <span
                className="fancy-underline relative cursor-pointer after:h-0.5 after:bg-stone-600"
                onClick={toggleLoginModal}
              >
                login
              </span>
            ) : (
              <NavLink
                to="/account"
                className={({ isActive }) =>
                  `fancy-underline relative after:h-0.5 after:bg-stone-600 ${
                    isActive && "after:!scale-100"
                  }`
                }
              >
                account
              </NavLink>
            )}
          </li>
          <li
            onClick={toggleCartModal}
            className="flex h-full max-w-fit flex-1 cursor-pointer items-center bg-stone-400 px-10 transition-colors duration-300 ease-in-out hover:bg-stone-500 hover:text-stone-200"
          >
            <span>cart</span>
          </li>
        </ul>

        {/* Cart Icon */}
        <div className="col-span-1 flex items-center justify-center md:hidden">
          <Cart
            className={[
              `icon-lg cursor-pointer transition-all duration-300 ease-in-out`,
              extendedNavIsOpen ? "text-stone-300" : "text-stone-800",
            ].join(" ")}
            onClick={toggleCartModal}
          />
        </div>
      </header>
    </>
  );
};

export default NavBar;
