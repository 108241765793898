import { ChevronDown, FilterAlt } from "@styled-icons/boxicons-solid";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toFriendlyCase } from "../../helpers/StringHelpers";

export type FilterConfig = {
  [key: string]: {
    type: "text" | "number" | "date";
    label: string;
    values: string[];
  };
};

interface Props {
  filterConfig: FilterConfig;
}

const CrudTableFilter = ({ filterConfig }: Props) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      {/* Filter Dropdown */}
      <button
        className="flex h-[36px] items-center justify-center space-x-1 rounded-md border-2 border-gray-600 bg-none px-2 py-1 font-bold text-gray-600 hover:bg-gray-600 hover:text-stone-50"
        onClick={() => {
          setShowFilterMenu(!showFilterMenu);
        }}
      >
        <FilterAlt className="w-4" />
        <span>Filter</span>
        <ChevronDown className="w-4" />
      </button>
      {/* Filter Dropdown Menu */}
      {showFilterMenu && (
        <div
          className="absolute right-0 top-1/2 z-[99] min-w-max translate-y-6 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg"
          role="menu"
        >
          <div
            className="flex items-center justify-between px-2 py-1"
            role="none"
          >
            <h1 className="w-full text-center text-base font-bold text-gray-600">
              Options
            </h1>
          </div>
          {Object.entries(filterConfig || {}).map(([k, v]) => {
            return (
              <div key={k} className="flex flex-col gap-1 p-2">
                <span className="text-sm font-bold text-gray-600">
                  {v.label}
                </span>
                <ul className="grid grid-cols-2 gap-2" role="group">
                  {v.values.map((val) => (
                    <li key={val} className="flex">
                      <input
                        type="radio"
                        id={`${k}-${val}`}
                        name={"filter-" + k}
                        value={val}
                        className="peer hidden"
                        checked={val === searchParams.get(k)}
                        onChange={(e) => {
                          setSearchParams((prevSearchParams) => {
                            return { ...prevSearchParams, [k]: val };
                          });
                        }}
                      />
                      <label
                        htmlFor={`${k}-${val}`}
                        className="flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border bg-transparent py-2 px-2 text-black transition-all hover:border-teal-700 hover:bg-teal-600 hover:text-white peer-checked:border-teal-500 peer-checked:bg-teal-600 peer-checked:text-white peer-checked:ring-1 peer-checked:ring-teal-500/40"
                      >
                        <span className="text-sm font-semibold capitalize">
                          {toFriendlyCase(val)}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
          {/* Reset Button */}
          <div className="grid grid-cols-2 gap-2 p-2">
            <button
              className="flex items-center justify-center rounded-md border border-teal-600 bg-teal-600 bg-none px-2 py-1 font-bold text-stone-50 transition-colors hover:bg-teal-500"
              onClick={() => {
                setSearchParams({});
              }}
            >
              Reset
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CrudTableFilter;
