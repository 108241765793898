import * as Yup from "yup";
import {
  MAKER_DESCRIPTION,
  MAKER_FEATURE_IMAGE,
  MAKER_LOGO,
  MAKER_NAME,
  MAKER_WEBSITE,
} from "./fieldNames";

export const makerValidationSchema = () => {
  return Yup.object().shape({
    // [MAKER_ID]: Yup.string(),
    [MAKER_NAME]: Yup.string()
      .required("name is required")
      .max(50, "name must be less than 50 characters")
      .trim(),
    [MAKER_WEBSITE]: Yup.string().url("website must be a valid url").trim(),
    [MAKER_DESCRIPTION]: Yup.string()
      .min(5, "description must be greater than 5 characters")
      .max(2500, "description must be less than 2500 characters")
      .not(
        [
          '<p class="relative m-0 mb-2 leading-8 text-gray-800 text-base"><br></p>',
        ],
        "description is required"
      )
      .required("description is required"),
    [MAKER_LOGO]: Yup.string().required("image is required"),
    [MAKER_FEATURE_IMAGE]: Yup.string().required("image is required"),
  });
};
