import { Button, Card, Title } from "@tremor/react";
import DashboardSection from "../../components/DashboardSection";
import { auth } from "../../config/firebase";
import { useFetchBillingSessionQuery } from "../../redux/api/stripe/api";

interface Props {}

const ManageBilling = ({}: Props) => {
  const { data: billingSession, isLoading } = useFetchBillingSessionQuery(
    auth.currentUser?.uid,
    {
      skip: !auth.currentUser?.uid,
    }
  );

  return (
    <DashboardSection
      title="Billing & Subscriptions"
      loading={isLoading}
      order={1}
    >
      <Card className="space-y-6">
        <Title>Manage Billing & Subscriptions</Title>

        {billingSession && (
          <Button
            loading={isLoading}
            variant="primary"
            onClick={() => {
              window.open(billingSession.url, "_blank");
            }}
          >
            View Billing Portal
          </Button>
        )}
      </Card>
    </DashboardSection>
  );
};

export default ManageBilling;
