import { Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { auth } from "../../../config/firebase";
import { GetSubscriptionDescription } from "../../../helpers/SubscriptionHelpers";
import { useAnalytics } from "../../../hooks/useAnalytics";
import { useExpressCheckout } from "../../../hooks/useCheckout";
import {
  useFetchCartQuery,
  useUpdateCartMutation,
} from "../../../redux/api/cart/api";
import { useAppDispatch } from "../../../redux/hooks";

import { Offering, OfferingOption } from "../../../redux/api/offerings/types";
import { setLoginModalMessage } from "../../../redux/state/app/reducer";
import {
  useOpenModal,
  useToggleCartModal,
  useToggleLoginModal,
} from "../../../redux/state/modals/hooks";
import { ModalType } from "../../../redux/state/modals/types";
import { AltFormikSelect, FormikCheckbox } from "../../Formik";
import Spinner from "../../Spinner";
import { ONETIME, SUBSCRIPTION, SUBSCRIPTION_PRICE_ID } from "./fieldNames";
import {
  PurchaseOptionValues,
  initialPurchaseOptionValues,
} from "./initialValues";
import PurchaseOptionsValidationSchema from "./validationSchema";

interface Props {
  offering: Offering;
  option: OfferingOption;
  isOutOfStock: boolean;
}

const PurchaseOptionsForm = ({ offering, option, isOutOfStock }: Props) => {
  const user = auth.currentUser;

  const navigate = useNavigate();
  const { logAddToCart } = useAnalytics();
  const dispatch = useAppDispatch();
  const expressCheckout = useExpressCheckout();
  const toggleLoginModal = useToggleLoginModal();
  const cartModal = useOpenModal(ModalType.CART);
  const toggleCartModal = useToggleCartModal();

  const [triggerUpdateCartOptions] = useUpdateCartMutation();
  const { data: cart } = useFetchCartQuery(user?.uid, {
    skip: !user?.uid,
  });

  const cartOptions = cart?.offerings ?? {};
  const cartOptionQuantity =
    cartOptions[option.id]?.options[option.id].quantity ?? 0;

  const subscriptionsRenewingThisWeek =
    option.subscription?.renewingThisWeek ?? 0;
  const availableQuantity =
    option.availableInventory - subscriptionsRenewingThisWeek;

  const [_expressCheckout, _setExpressCheckout] = useState<boolean>(false);
  const [_subscribe, _setSubscribe] = useState<boolean>(false);

  async function handleExpressCheckout(values: PurchaseOptionValues) {
    if (auth.currentUser?.uid) {
      _setExpressCheckout(true);

      await expressCheckout(
        auth.currentUser.uid,
        option.priceId,
        `${window.location.origin}/shop/${offering.type}/${offering.id}/${option.id}`
      );
    }
  }

  async function handleSubscribe(values: PurchaseOptionValues) {
    if (auth.currentUser?.uid && values.subscriptionPriceId) {
      navigate(
        `/subscribe?offering=${offering.id}&option=${option.id}&price=${values.subscriptionPriceId}`
      );
    }
  }

  async function handleAddToCart(
    values: PurchaseOptionValues,
    helpers: FormikHelpers<any>
  ) {
    logAddToCart(offering.type, offering.options[0]);

    await triggerUpdateCartOptions({
      offeringId: offering.id,
      priceId: option.priceId,
      quantity: 1,
    });

    if (!cartModal) {
      toggleCartModal();
    }
  }

  return (
    <Formik
      key={option.id}
      initialValues={initialPurchaseOptionValues(
        option.subscription?.enabled ?? false
      )}
      onSubmit={handleAddToCart}
      validationSchema={PurchaseOptionsValidationSchema}
    >
      {(formikProps) => (
        <Form
          className="mt-4 flex flex-col"
          onSubmit={(e: any) => {
            e.preventDefault();
            formikProps.validateForm();
            formikProps.handleSubmit();
          }}
          noValidate
        >
          <div className="mb-4 flex flex-wrap gap-3">
            {offering.options.map((o) => (
              <NavLink
                key={o.id}
                to={`/shop/${offering.type}/${offering.id}/${o.id}`}
                className={({ isActive }) =>
                  `flex w-fit items-center border-2 px-6 py-2 font-bold capitalize transition-colors focus:outline-none ${
                    isActive
                      ? "border-gray-800 bg-gray-100 text-gray-800"
                      : "border-gray-400 text-gray-400 hover:border-gray-800 hover:bg-gray-100 hover:text-gray-800"
                  }`
                }
              >
                {o.name}
              </NavLink>
            ))}
          </div>

          {!isOutOfStock &&
            !!option.subscription?.enabled &&
            option.subscription?.options &&
            option.subscription.options.length > 0 && (
              <div className={`mb-4 space-y-3 ${!!!offering && "hidden"}`}>
                <Field
                  type="checkbox"
                  name={ONETIME}
                  title={
                    <span>
                      One time purchase&ensp;<b>{option.prettyPrice}</b>
                    </span>
                  }
                  component={FormikCheckbox}
                  onClick={() => {
                    formikProps.setValues({
                      [SUBSCRIPTION]: false,
                    });
                  }}
                />

                <div className="flex">
                  <Field
                    type="checkbox"
                    name={SUBSCRIPTION}
                    title={
                      <span>
                        Subscription&ensp;<b>{option.prettyPrice}</b> /{" "}
                      </span>
                    }
                    component={FormikCheckbox}
                    onClick={() => {
                      formikProps.setValues({
                        [ONETIME]: false,
                        [SUBSCRIPTION_PRICE_ID]:
                          option.subscription?.options[0].priceId,
                      });
                    }}
                  />

                  <Field
                    name={SUBSCRIPTION_PRICE_ID}
                    component={AltFormikSelect}
                  >
                    {option.subscription.options.map((o) => (
                      <option key={o.priceId} value={o.priceId}>
                        {GetSubscriptionDescription(
                          o.interval,
                          o.intervalCount
                        )}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
            )}

          {!isOutOfStock && (
            <div>
              {!formikProps.values.subscription && (
                <div className="flex space-x-4">
                  <button
                    type="submit"
                    className="flex items-center border border-gray-800 bg-gray-800 px-6 py-2 tracking-wider text-white hover:bg-gray-900 focus:outline-none"
                    disabled={cartOptionQuantity === availableQuantity}
                  >
                    Add To Cart
                  </button>
                </div>
              )}

              {formikProps.values.subscription && (
                <div>
                  <button
                    type="button"
                    className="flex items-center border border-gray-800 bg-gray-800 px-6 py-2 tracking-wider text-white hover:bg-gray-900 focus:outline-none"
                    onClick={async () => {
                      // users must be logged in to subscribe
                      if (user?.isAnonymous) {
                        dispatch(setLoginModalMessage("Sign In to Subscribe"));
                        toggleLoginModal();
                      } else {
                        await handleSubscribe(formikProps.values);
                      }
                    }}
                  >
                    {_subscribe ? (
                      <div className="flex w-fit space-x-2">
                        <span>Processing...</span> <Spinner />
                      </div>
                    ) : (
                      <span>Subscribe</span>
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default PurchaseOptionsForm;
