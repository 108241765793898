import {
  LeafOne,
  LeafThree,
  LeafTwo,
} from "@styled-icons/fluentui-system-filled";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import HomeImage from "../assets/images/hd/HomeImage.jpeg";
import SlickGallery from "../components/SlickGallery";
import useAnimation from "../hooks/useAnimation";
import { useToggleNewsletterSignupModal } from "../redux/state/modals/hooks";

interface LandingItemContentInterface {
  title: string;
  body: string;
}

const LeafIcons = [
  <LeafOne className="h-12 text-slate-600" />,
  <LeafTwo className="h-12 text-slate-700" />,
  <LeafThree className="h-12 text-slate-800" />,
];

const LandingItemContent: LandingItemContentInterface[] = [
  {
    title: "our history",
    body: "Maranatha Farm was founded in 2020 by third-generation entrepreneur Michele Logan. Michele is an innovator in the credit card industry, and decided to take a similar approach to land stewardship when she and her family moved to the Somerset Hills of New Jersey in 2015.",
  },
  {
    title: "about us",
    body: "Maranatha (mar-uh-<strong>nath</strong>-uh) means <i>Come, O Lord</i>. It is commonly known as the first Christian prayer. We honor God in all things.",
  },
  {
    title: "our vision",
    body: "Combining land conservation, land stewardship, permaculture, regenerative agroforestry, and water management, our forest farm has set an aim to rebalance the local ecosystem while honoring the interdependence of the earth, animals and people.",
  },
];

const Home = (props: any) => {
  const landingItemRefs = useRef<HTMLDivElement[]>([]);
  const [_animated, _setAnimated] = useState(false);
  const { ApplySlideIn } = useAnimation();

  const toggleNewsletterSignupModal = useToggleNewsletterSignupModal();

  useEffect(() => {
    ApplySlideIn(landingItemRefs);

    _setAnimated(true);

    const newsletterSignup = localStorage.getItem(
      "show-newsletter-signup-modal"
    );

    if (newsletterSignup === null) {
      setTimeout(() => {
        console.log("show newsletter signup modal");
        toggleNewsletterSignupModal();
        localStorage.setItem("show-newsletter-signup-modal", "false");
      }, 1000);
    }
  }, []);

  return (
    <div className="flex h-auto w-full flex-col items-center justify-center">
      <div
        className="h-screen w-screen bg-cover bg-[40%_50%] md:bg-[50%_15%]"
        style={{ backgroundImage: `url(${HomeImage})` }}
      >
        <div className="flex h-full w-full items-center justify-center bg-black/30">
          <section className="flex flex-col items-center space-y-8">
            <h2 className="font-blackstone text-4xl font-bold text-zinc-200">
              welcome to
            </h2>
            <div>
              <span className="text-4xl font-bold uppercase tracking-wider text-zinc-50 ">
                Maranatha
              </span>
              <div className="flex min-w-full items-center justify-between">
                <span className="w-full border-b-2 border-zinc-400"></span>
                <span className="mx-2 text-xl uppercase tracking-widest text-zinc-50">
                  Farm
                </span>
                <span className="w-full border-b-2 border-zinc-400"></span>
              </div>
            </div>
            <NavLink
              to="/contact"
              className="cursor-pointer rounded border-2 border-zinc-400 bg-transparent px-6 py-4 text-xl font-bold uppercase text-white transition-colors hover:bg-zinc-50/50 hover:text-slate-700"
            >
              contact us
            </NavLink>
          </section>
        </div>
      </div>
      <div className="z-10 flex h-auto w-full flex-col justify-center bg-zinc-50">
        <section className="m-8 flex flex-col items-center justify-center md:m-12 md:flex-row md:items-start">
          {LandingItemContent.map((content: LandingItemContentInterface, i) => (
            <div
              className="m-4 flex h-full max-w-[400px] flex-1 flex-col items-center rounded-sm border-2 border-zinc-400 bg-white p-8 text-center shadow"
              key={`landing-${i}`}
              ref={(el: HTMLDivElement) => {
                landingItemRefs.current[i] = el;
              }}
            >
              {LeafIcons[i]}
              <h1 className="mt-1 font-blackstone text-4xl font-bold tracking-wider text-zinc-600">
                {content.title}
              </h1>
              <br />
              <span
                className="border-t-2 border-solid border-zinc-400 pt-8 text-xl font-light"
                dangerouslySetInnerHTML={{ __html: content.body }}
              />
            </div>
          ))}
        </section>
        <SlickGallery />
      </div>
    </div>
  );
};

export default Home;
