import { Maker } from "../makers/types";

/**
 * Note: Do not include slashes in the OfferingType enum values.
 * They are used to create URLs and will break if they contain slashes.
 */
export enum OfferingType {
  Eggs = "eggs",
  MeatAndFish = "meat-&-fish",
  BakedGoods = "baked-goods",
  FruitsAndVegetables = "fruits-&-vegetables",
  Mushrooms = "mushrooms",
  HerbsAndFlowers = "herbs-&-flowers",
  PlantsAndTrees = "plants-&-trees",
  HerbalTeasAndSpices = "herbal-teas-&-spices",
  JamsAndPreserves = "jams-&-preserves",
  Provisions = "provisions",
  SpecialtyGoods = "specialty-goods",
  FarmEssentials = "farm-essentials",
  Other = "Other",
}

export const AllOfferingTypes = [
  OfferingType.Eggs,
  OfferingType.MeatAndFish,
  OfferingType.BakedGoods,
  OfferingType.FruitsAndVegetables,
  OfferingType.Mushrooms,
  OfferingType.HerbsAndFlowers,
  OfferingType.PlantsAndTrees,
  OfferingType.HerbalTeasAndSpices,
  OfferingType.JamsAndPreserves,
  OfferingType.Provisions,
  OfferingType.SpecialtyGoods,
  OfferingType.FarmEssentials,
  OfferingType.Other,
];

export enum TransitDistance {
  None = "none",
  Farm = "farm",
  Local = "local",
  Regional = "regional",
  National = "national",
  Far = "far",
}

export const TransitDistanceLabels: {
  [key in TransitDistance]: string;
} = {
  [TransitDistance.None]: "Disabled",
  [TransitDistance.Farm]: "Farm-to-Table",
  [TransitDistance.Local]: "0-50 miles",
  [TransitDistance.Regional]: "51-150 miles",
  [TransitDistance.National]: "151-500 miles",
  [TransitDistance.Far]: "> 500 miles",
};

export const AllTransitDistances = [
  TransitDistance.None,
  TransitDistance.Farm,
  TransitDistance.Local,
  TransitDistance.Regional,
  TransitDistance.National,
  TransitDistance.Far,
];

export enum SubscriptionInterval {
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

export enum TaxBehavior {
  Unspecified = "unspecified",
  Exclusive = "exclusive",
  // Inclusive = "inclusive",
}

export type Offering = {
  id: string;
  type: OfferingType;
  name: string;
  fromPrice: number;
  slug: string;
  options: OfferingOption[];
  transitDistance?: TransitDistance;
  transitDescription?: string;
  taxBehavior?: TaxBehavior;
  makerId?: string;
  maker?: Maker;
  active: boolean;
  prettyPrice: string;
  createdOn: number;
  updatedOn: number;
};

export type OfferingOption = {
  id: string;
  name: string;
  fullName: string;
  price: number;
  prettyPrice: string;
  priceId: string;
  amount: number; // max purchase amount
  availableInventory: number;
  reservedInventory: number;
  description: string;
  image: string;
  shopUrl: string;
  subscription?: Subscription;
  activeSubscriptions?: number;
  active: boolean;
  hasHerbOfTheWeek: boolean;
  createdOn: number;
  updatedOn: number;
};

export type Subscription = {
  enabled: boolean;
  totalSubscribers?: number;
  renewingThisWeek?: number;
  options: SubscriptionOption[];
};

export type SubscriptionOption = {
  interval: SubscriptionInterval;
  intervalCount: number;
  priceId: string;
  amount: number;
  subscribers?: number;
  createdOn: number;
  updatedOn: number;
};

export type OfferingsLookup = {
  [offeringId: string]: Offering;
};

export type OptionsLookup = {
  [optionId: string]: OfferingOption;
};

export interface PaginatedOfferingsPayload {
  active?: boolean | null;
  category?: string | null;
  orderBy?: "name-asc" | "name-desc" | "age-desc" | null;
  startAfter?: string | null;
  limit?: number | null;
  transitDistances?: TransitDistance[] | null;
  search?: string | null;
}

/**
 * All values must be optional since this is used to PATCH
 */
export interface PickupLocation {
  id: string;
  name?: string;
  description?: string;
  active?: boolean;
  priority?: number;
}
