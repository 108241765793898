import { FilterLeft } from "@styled-icons/bootstrap";
import { Search } from "@styled-icons/fluentui-system-regular";
import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { animated, useTrail } from "react-spring";
import Loader from "../components/Loader";
import { Fern1 } from "../constants/images";
import { toFriendlyCase } from "../helpers/StringHelpers";
import { useAnalytics } from "../hooks/useAnalytics";
import {
  useActiveOfferingCategoriesQuery,
  useGetHerbOfTheWeekQuery,
} from "../redux/api/offerings/api";
import { usePaginatedOfferings } from "../redux/api/offerings/hooks";
import {
  Offering,
  OfferingOption,
  OfferingType,
} from "../redux/api/offerings/types";

function ScrollToCategoryNav() {
  const shopCategoryNav = document.getElementById("shop-category-nav");
  if (shopCategoryNav) {
    const rect = shopCategoryNav.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const targetY = rect.top + scrollTop;
    const targetX = rect.left + window.pageXOffset;
    window.scroll({
      top: targetY - 100,
      left: targetX,
      behavior: "smooth",
    });
  }
}

interface SortOption {
  title: string;
  option: "name-asc" | "name-desc" | "age-desc";
}

const sortOptions: SortOption[] = [
  {
    title: "Name A \u2013 Z",
    option: "name-asc",
  },
  {
    title: "Name Z \u2013 A",
    option: "name-desc",
  },
  {
    title: "Newest",
    option: "age-desc",
  },
];

export const ShopPage = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);

  const focusSearchInput = () => {
    searchInputRef.current && searchInputRef.current.focus();
  };

  const { logViewItem } = useAnalytics();

  const navigate = useNavigate();

  const { type } = useParams();

  const selectedType = type ?? "new";

  const [orderBy, setOrderBy] = useState<SortOption | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  const { allOfferings, isLoading, loadMore, totalOfferings } =
    usePaginatedOfferings({
      active: true,
      category: ["all", "new"].includes(selectedType)
        ? null
        : (selectedType as OfferingType),
      orderBy:
        selectedType === "new"
          ? "age-desc"
          : selectedType === "all"
          ? "name-asc"
          : orderBy?.option,
      search,
    });

  const { data: hotw } = useGetHerbOfTheWeekQuery(null);
  const { data: categories } = useActiveOfferingCategoriesQuery({
    active: true,
  });

  const offeringTypes = categories?.map((t) => t.toLowerCase()) ?? [];

  if (
    !["all", "new"].includes(selectedType) &&
    !Object.values(OfferingType)
      .map((t) => t.toLowerCase())
      .includes(selectedType)
  ) {
    // navigate back to shop page if the type is not valid
    navigate("/shop");
  }

  function handleOfferingClick(offering: Offering) {
    logViewItem(offering);
    navigate(`/shop/${offering.type}/${offering.id}/${offering.options[0].id}`);
    window.scrollTo(0, 0);
  }

  const handleClickOrderByOptions = (option: SortOption | null) => {
    setOrderBy(option);
  };

  useEffect(() => {
    setOrderBy(null);
    setSearch(null);
    ScrollToCategoryNav();
  }, [selectedType]);

  const offeringAnimation = {
    config: {
      tension: 200,
      friction: 14,
      clamp: true,
    },
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  };

  const [offeringSprings, api] = useTrail(
    allOfferings.length,
    {
      delay: 2,
      ...offeringAnimation,
    },
    []
  );

  return (
    <div className="flex flex-col items-center">
      <section className="flex max-w-7xl flex-col space-y-6 p-8 pb-12 first:mt-0">
        {/* Nav Bar / Search + Categpries */}
        <div className="flex flex-col items-start space-y-6 border border-stone-500 px-3 pb-6 pt-2">
          {/* Search Bar */}
          <Formik
            initialValues={{ search: "" }}
            onSubmit={(values, formikHelpers) => {
              if (values.search !== "" && values.search.length > 2) {
                setSearch(values.search);
              }
              formikHelpers.setSubmitting(false);
              formikHelpers.resetForm();
            }}
          >
            {(formikProps) => (
              <Form className="flex min-w-full flex-1 items-center justify-center">
                <div className="group relative flex w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-4">
                    <OptionDropdown
                      title={
                        <p className="font-medium flex-start flex items-center text-sm">
                          <FilterLeft className="mr-2 inline-block h-6 w-6 text-stone-500 hover:text-stone-600" />
                        </p>
                      }
                      currentOption={orderBy}
                      options={sortOptions}
                      handleClickOption={handleClickOrderByOptions}
                    />
                  </div>
                  <Field
                    type="text"
                    name="search"
                    id="search"
                    className="block h-16 flex-1 border-b border-gray-500 bg-transparent py-0.5 pl-14 pr-10 text-center font-blackstone text-xl leading-relaxed tracking-widest placeholder-gray-500 hover:border-b-stone-500 focus:border-b-stone-500 focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-2xl
                      "
                    placeholder="Search all offerings..."
                  />
                  <button
                    type="button"
                    onClick={focusSearchInput}
                    className="absolute inset-y-0 right-0 flex items-center pr-4 text-stone-400 group-hover:text-stone-500 group-focus:text-stone-500"
                  >
                    <label htmlFor="search" className="cursor-pointer">
                      <Search className="h-5 w-5" />
                    </label>
                  </button>
                </div>
              </Form>
            )}
          </Formik>

          {/* Categories */}

          <div
            id="shop-category-nav"
            className="flex w-full flex-wrap gap-4 px-4"
          >
            {["New Items", "All Offerings", ...offeringTypes].map((type) => {
              let to;
              switch (type) {
                case "New Items":
                  to = "/shop/new";
                  break;
                case "All Offerings":
                  to = "/shop/all";
                  break;
                default:
                  to = `/shop/${type.toLowerCase()}`;
                  break;
              }

              return (
                <NavLink
                  end
                  to={to}
                  className="font-medium group relative inline-block font-serif tracking-wide"
                  key={type}
                >
                  {({ isActive }) => (
                    <>
                      {!isActive && (
                        <span className="absolute inset-0 translate-x-0.5 translate-y-0.5 bg-stone-700 transition-transform group-hover:translate-x-0 group-hover:translate-y-0"></span>
                      )}

                      <span
                        className={`relative block border border-current px-4 py-2 ${
                          isActive
                            ? "bg-stone-700 text-stone-50"
                            : "bg-white text-stone-900 hover:bg-stone-100 hover:text-stone-950"
                        }`}
                      >
                        {toFriendlyCase(type)}
                      </span>
                    </>
                  )}
                </NavLink>
              );
            })}
          </div>
        </div>

        {/* CTA */}
        <div className="flex flex-col md:flex-row md:gap-6">
          {/* CTA Image */}
          <div
            className="relative flex w-full overflow-hidden bg-cover bg-center shadow-sm sm:max-h-[350px] sm:min-h-[350px] md:max-h-full md:w-[350px]"
            style={{ backgroundImage: `url(${Fern1})` }}
          >
            <div className="flex-1 bg-stone-900/30"></div>
            <h1 className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center font-blackstone text-6xl font-bold lowercase tracking-wide text-stone-100">
              Shop {selectedType.replaceAll("-", " ")}
            </h1>
          </div>

          {/* CTA Description */}
          <div className="w-full flex-1 border border-stone-500 bg-stone-100 p-8 shadow-sm">
            <article className="space-y-3 text-lg">
              <h2 className="font-serif text-2xl italic tracking-wide text-zinc-700">
                As we continue to grow, we invite you to join us on our journey.
              </h2>

              <p className="font-light">
                During the growing season we offer pastured chicken and duck
                eggs on a weekly basis, and other farm products including
                specialty produce, baked goods, and hardwood grown mushrooms
                when available.
              </p>

              <p className="font-light">
                Please note that our deadline for weekly orders is 5:00 PM on
                Sunday for pickup between 2:30 PM and 5:30 PM on Wednesdays at
                our <i>75 Willow Avenue, Peapack Farm Store</i>. If Wednesdays
                at that time are inconvenient, we will do our best to
                accommodate your schedule. We are also open by appointment.
                Please email michele@maranatha.farm to schedule.
              </p>

              {hotw && hotw.isEnabled && (
                <p className="font-light">
                  We include a beautiful herb bunch that changes weekly when you
                  buy one dozen or more of our eggs. This week's selection is{" "}
                  {hotw.herb}. Please see below for more information.
                </p>
              )}
            </article>
          </div>
        </div>

        {/* Offerings */}

        {!isLoading && allOfferings.length === 0 && (
          <div className="flex flex-col items-center justify-center p-8">
            <h1 className="text-lg">No offerings found.</h1>
          </div>
        )}

        {isLoading && allOfferings.length === 0 ? (
          <div className="flex flex-col items-center justify-center p-8">
            <Loader />
            <h1 className="text-lg">Loading offerings...</h1>
          </div>
        ) : (
          <div className="space-y-8">
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {allOfferings &&
                allOfferings.length > 0 &&
                allOfferings
                  .filter((o) => !!o && !!o.options && o.options.length > 0)
                  .map((offering: Offering, i: number) => {
                    const imageOverride = offering.options.find(
                      (o: OfferingOption) => o.name === "1 Dozen"
                    )?.image;
                    return (
                      <animated.div
                        style={offeringSprings[i]}
                        key={offering.id}
                      >
                        <ShopOfferingCard
                          offering={offering}
                          clickAction={handleOfferingClick}
                          i={i}
                          imageOverride={imageOverride}
                        />
                      </animated.div>
                    );
                  })}
            </div>

            {/* Footer Pagination Control */}
            <div className="my-8">
              <div
                className={`border-b-zinc-20 relative flex h-fit w-full flex-col items-center justify-center gap-4 rounded-bl-md rounded-br-md p-2 text-xs font-bold text-zinc-500 sm:flex-row`}
              >
                <div className="flex flex-col items-center gap-2">
                  <p>
                    Showing {allOfferings.length} of {totalOfferings} items
                  </p>
                  {allOfferings.length < totalOfferings && (
                    <button
                      className="rounded-lg border px-4 py-2 transition-colors hover:bg-teal-500 hover:text-white"
                      onClick={loadMore}
                    >
                      <span>Load More</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

interface ShopOfferingCardProps {
  offering: Offering;
  clickAction: (offering: Offering) => any;
  i: number;
  imageOverride?: string;
}

export const ShopOfferingCard = ({
  offering,
  clickAction,
  i,
  imageOverride,
}: ShopOfferingCardProps) => {
  return (
    <div key={offering.id}>
      <div onClick={() => clickAction(offering)} className="group block">
        <div className="relative">
          <img
            src={imageOverride ?? offering.options[0].image}
            alt={offering.name}
            className={`aspect-square w-full cursor-pointer rounded object-cover`}
          />
        </div>
        <div className="mt-3">
          <h3 className="group-fancy-underline after-text-st relative w-fit cursor-pointer text-gray-900 after:h-0.5 after:bg-stone-600">
            {offering.name}
          </h3>
          <p className="mt-1 text-sm text-gray-700">
            From ${offering.fromPrice.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};

export const OptionDropdown = ({
  title,
  currentOption,
  options,
  handleClickOption,
}: {
  title: React.ReactNode;
  currentOption: SortOption | null;
  options: SortOption[];
  handleClickOption: (option: SortOption | null) => any;
}) => {
  var details = document.querySelector("details");
  document.addEventListener("click", function (e) {
    if (details && !details.contains(e.target as Node)) {
      details.removeAttribute("open");
    }
  });

  return (
    <div className="relative">
      <details className="group [&_summary::-webkit-details-marker]:hidden">
        <summary className="flex cursor-pointer items-center gap-2 text-gray-900 transition">
          <span className="font-medium text-sm">{title}</span>
        </summary>
        <div className="z-50 group-open:absolute group-open:start-0 group-open:top-auto group-open:mt-2">
          <div className="w-48 rounded border border-gray-200 bg-white">
            <ul className="flex flex-col gap-2 p-4">
              {options &&
                options.length &&
                options.map((o) => {
                  const k = `Sort${o.option}-${o.title}`;
                  return (
                    <li key={k} className="flex-1">
                      <input
                        type="radio"
                        name="Sort Options"
                        id={k}
                        checked={o.option === currentOption?.option}
                        disabled={o.option === currentOption?.option}
                        className="peer hidden [&:checked_+_label_#icon]:flex"
                        onClick={(e) => {
                          if (e.currentTarget.checked) {
                            handleClickOption(o);
                          } else {
                            handleClickOption(null);
                            // set it to unchecked
                            e.currentTarget.checked = false;
                          }
                        }}
                      />
                      <label
                        htmlFor={k}
                        className="font-medium block w-full cursor-pointer border border-current px-6 py-3 text-center text-sm text-stone-700 transition-colors duration-300 hover:bg-teal-500 peer-checked:bg-teal-700 peer-checked:text-white"
                      >
                        {o.title}
                      </label>
                    </li>
                  );
                })}
              {/* Reset Options */}
              <div className="flex justify-center py-0.5">
                <button
                  className="text-xs text-gray-500 underline hover:text-gray-700"
                  onClick={() => {
                    if (currentOption) {
                      handleClickOption(null);
                    }
                  }}
                >
                  Reset
                </button>
              </div>
            </ul>
          </div>
        </div>
      </details>
    </div>
  );
};

export default ShopPage;
