import { Mail } from "@styled-icons/fluentui-system-regular";
import { Link, NavLink } from "react-router-dom";
import RFFLogo from "../../assets/RFF-Logo.png";
import {
  AllSocialAccounts,
  SocialAccountInfo,
} from "../../constants/SocialMedia";
import NewsletterForm from "../Forms/NewsletterSignupForm";
import { LogoTransparent } from "../Logo";

const Footer = () => {
  return (
    <footer
      aria-label="Site Footer"
      className="relative bottom-0 w-full bg-gray-800 px-12 pb-8 pt-24"
    >
      <div className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-12 gap-y-14">
          {/* Logo Section */}
          <div className="col-span-12 flex h-full flex-col justify-between gap-8 sm:col-span-3">
            <div className="w-24">
              <NavLink to="/">
                <LogoTransparent style={"light"} />
              </NavLink>
            </div>

            <div className="mt-auto w-24">
              <img
                src={RFFLogo}
                alt="River Friendly Farm Certified"
                className="w-full"
              />
            </div>
          </div>

          <div className="col-span-12 grid w-full grid-flow-row auto-rows-auto grid-cols-1 gap-y-14 pb-20 [direction:ltr] sm:col-span-9 lg:grid-cols-2 lg:[direction:rtl]">
            <NewsletterForm theme={"light"} />

            <div className="grid grid-cols-7 gap-y-10 [direction:ltr] lg:col-span-1">
              {/* Follow Us Section */}
              <div className="col-span-7 space-y-6 sm:col-span-2">
                <p className="text-xs font-bold tracking-widest text-stone-300">
                  FOLLOW US
                </p>
                <nav aria-label="Footer Helpful Nav">
                  <ul className="space-y-2 font-serif text-sm tracking-wide">
                    {AllSocialAccounts.map((account) => {
                      const { name, url, icon } = SocialAccountInfo[account];

                      return (
                        <li
                          className="fancy-underline relative flex w-fit text-stone-100 after:h-[1px] after:bg-stone-100"
                          key={name}
                        >
                          <a
                            href={url}
                            rel="noreferrer"
                            target="_blank"
                            className="flex items-center justify-center space-x-2"
                          >
                            {icon(4)}
                            <span>{name}</span>
                          </a>
                        </li>
                      );
                    })}

                    <li className="fancy-underline relative flex w-fit text-stone-100 after:h-[1px] after:bg-stone-100">
                      <Link
                        to="/contact"
                        className="flex items-center justify-center space-x-2"
                      >
                        <Mail className="aspect-square h-4" />
                        <span>Contact Us</span>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              {/* Our Farm Section */}
              <div className="col-span-7 space-y-6 sm:col-span-2">
                <p className="text-xs font-bold tracking-widest text-stone-300">
                  OUR FARM
                </p>
                <nav aria-label="Footer About Nav">
                  <ul className="space-y-2 font-serif text-sm tracking-wide">
                    <li className="fancy-underline relative flex w-fit text-stone-100 after:h-[1px] after:bg-stone-100">
                      <Link
                        to="/shop"
                        className="flex items-center justify-center space-x-2"
                      >
                        Shop
                      </Link>
                    </li>
                    <li className="fancy-underline relative flex w-fit text-stone-100 after:h-[1px] after:bg-stone-100">
                      <Link
                        to="/offerings"
                        className="flex items-center justify-center space-x-2"
                      >
                        Offerings
                      </Link>
                    </li>
                    <li className="fancy-underline relative flex w-fit text-stone-100 after:h-[1px] after:bg-stone-100">
                      <Link
                        to="/story"
                        className="flex items-center justify-center space-x-2"
                      >
                        About
                      </Link>
                    </li>
                    <li className="fancy-underline relative flex w-fit text-stone-100 after:h-[1px] after:bg-stone-100">
                      <Link
                        to="/prayer"
                        className="flex items-center justify-center space-x-2"
                      >
                        Prayer
                      </Link>
                    </li>
                    <li className="fancy-underline relative flex w-fit text-stone-100 after:h-[1px] after:bg-stone-100">
                      <Link
                        to="/faq"
                        className="flex items-center justify-center space-x-2"
                      >
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 border-t border-slate-50 pt-3">
          <div className="items-center justify-between text-center sm:flex">
            <p className="space-x-1 text-xs text-zinc-300">
              <Link
                className="inline-block transition hover:text-zinc-200"
                to="/terms-and-conditions"
              >
                TERMS & CONDITIONS
              </Link>

              <span>&middot;</span>

              <Link
                to="/privacy-policy"
                className="inline-block transition hover:text-zinc-200"
              >
                PRIVACY POLICY
              </Link>
            </p>

            <p className="mt-4 font-serif text-xs text-zinc-200 sm:order-first sm:mt-0">
              &copy;&ensp;Maranatha Farm {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
