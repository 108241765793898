import { createAvatar } from "@dicebear/core";
import * as initials from "@dicebear/initials";
import {
  AuthProvider,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  IdTokenResult,
  linkWithCredential,
  linkWithPopup,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  updateProfile,
  User,
  UserCredential,
} from "firebase/auth";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { storage } from "../config/firebase";
import { useUpdateCustomerMutation } from "../redux/api/stripe/api";
import { usePopModal } from "../redux/state/modals/hooks";
import { useShowToast } from "../redux/state/toasts/hooks";

interface UserState {
  claims: any | null;
  photoURL: string | null;
  displayName: string | null;
  currentUser: User | null;
}

export const useAuthentication = () => {
  const navigate = useNavigate();
  const { showToast, showSuccessToast } = useShowToast();
  const auth = getAuth();
  const popModal = usePopModal();

  const [userState, setUserState] = useState<UserState>({
    claims: null,
    photoURL: null,
    displayName: null,
    currentUser: auth.currentUser,
  });

  const [triggerUpdateCustomer] = useUpdateCustomerMutation();

  const updateUserInfo = useCallback(async () => {
    if (!auth.currentUser) return;

    const userIdToken = await auth.currentUser.getIdTokenResult();
    const claims = userIdToken?.claims ?? null;
    const photoURL =
      auth.currentUser.photoURL ??
      auth.currentUser.providerData[0]?.photoURL ??
      null;
    const displayName =
      auth.currentUser.displayName ??
      auth.currentUser.providerData[0]?.displayName ??
      null;

    setUserState({
      claims,
      photoURL,
      displayName,
      currentUser: auth.currentUser,
    });
  }, [auth.currentUser]);

  useEffect(() => {
    updateUserInfo();
  }, [updateUserInfo]);

  const handleAnonymousSignIn = useCallback(async (): Promise<User | null> => {
    try {
      const userCredential = await signInAnonymously(auth);
      return userCredential.user;
    } catch (error) {
      return null;
    }
  }, [auth]);

  const handleEmailSignIn = useCallback(
    (email: string, password: string) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          navigate("/account");
          popModal();
        })
        .catch(handleLoginError);
    },
    [auth, navigate, popModal]
  );

  const handleEmailRegister = useCallback(
    async (name: string, email: string, password: string) => {
      let userCredential: UserCredential | null = null;

      if (auth.currentUser && auth.currentUser.isAnonymous) {
        userCredential = await linkAnonymousAccountWithEmail(
          auth.currentUser,
          email,
          password
        );
      } else {
        try {
          userCredential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
        } catch (error: any) {
          handleLoginError(error);
        }
      }

      if (userCredential) {
        const { user } = userCredential;

        if (!user.photoURL) {
          const avatar = createAvatar(initials, {
            seed: name,
            fontFamily: ["Brush Script MT"],
            backgroundType: ["solid"],
          });
          const avatarArrayBuffer = await avatar.jpeg().toArrayBuffer();
          const path = `/users/profiles/${user.uid}.jpeg`;
          const storageRef = ref(storage, path);
          const uploadAvatarResult = await uploadBytes(
            storageRef,
            avatarArrayBuffer
          );
          const avatarURL = await getDownloadURL(uploadAvatarResult.ref);
          await updateProfile(user, { photoURL: avatarURL });
        }

        if (!user.displayName) {
          await updateProfile(user, { displayName: name });
        }

        await triggerUpdateCustomer({ email, name });

        navigate("/");
        popModal();
      }
    },
    [auth, navigate, popModal, triggerUpdateCustomer]
  );

  const handleGoogleSignIn = useCallback(async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    if (auth.currentUser && auth.currentUser.isAnonymous) {
      await linkAnonymousAccountWithPopup(auth.currentUser, googleAuthProvider);
    }
  }, [auth]);

  const handleFacebookSignIn = useCallback(async () => {
    const facebookAuthProvider = new FacebookAuthProvider();
    if (auth.currentUser && auth.currentUser.isAnonymous) {
      await linkAnonymousAccountWithPopup(
        auth.currentUser,
        facebookAuthProvider
      );
    }
  }, [auth]);

  const linkAnonymousAccountWithEmail = useCallback(
    async (
      currentUser: User,
      email: string,
      password: string
    ): Promise<UserCredential | null> => {
      try {
        const emailAuthCredential = EmailAuthProvider.credential(
          email,
          password
        );
        const userCredential = await linkWithCredential(
          currentUser,
          emailAuthCredential
        );
        return userCredential;
      } catch (error: any) {
        handleLoginError(error);
        if (error.code === "auth/email-already-in-use" && error.credential) {
          return await signInWithCredential(auth, error.credential);
        }
      }
      return null;
    },
    [auth]
  );

  const linkAnonymousAccountWithPopup = useCallback(
    async (currentUser: User, provider: AuthProvider) => {
      let userCredential: UserCredential | null = null;
      try {
        userCredential = await linkWithPopup(currentUser, provider);
      } catch (error: any) {
        if (error.code === "auth/credential-already-in-use") {
          let existingOAuthCredential;
          switch (provider.providerId) {
            case "google.com":
              existingOAuthCredential =
                GoogleAuthProvider.credentialFromError(error);
              break;
            case "facebook.com":
              existingOAuthCredential =
                FacebookAuthProvider.credentialFromError(error);
              break;
            default:
              break;
          }
          if (existingOAuthCredential) {
            userCredential = await signInWithCredential(
              auth,
              existingOAuthCredential
            );
          }
        }
      } finally {
        popModal();
        if (userCredential) {
          await auth.currentUser?.reload();
          await triggerUpdateCustomer({
            email: userCredential.user.email,
            name: userCredential.user.displayName,
          });
        }
      }
    },
    [auth, popModal, triggerUpdateCustomer]
  );

  const handleResetPassword = useCallback(
    async (email: string) => {
      if (!email) return;
      try {
        await sendPasswordResetEmail(auth, email);
        showSuccessToast(
          "Password reset email sent",
          "Please check your email for further instructions."
        );
      } catch (error) {
        handleLoginError(error);
      }
    },
    [auth, showSuccessToast]
  );

  const handleLoginError = useCallback(
    (error: any) => {
      const message =
        FirebaseErrorMap[error.code] ??
        "There was an error authenticating your account, please try again.";
      showToast({ code: -1, title: "Authentication Error", body: message });
    },
    [showToast]
  );

  const currentUserHasValidClaims = useCallback(
    async (validClaims: string[]) => {
      const userIdToken: IdTokenResult | null =
        (await auth.currentUser?.getIdTokenResult()) ?? null;
      const userClaims = userIdToken?.claims ?? null;
      if (!userClaims) return false;
      return validClaims.some((validClaim) =>
        userClaims.hasOwnProperty(validClaim)
      );
    },
    [auth]
  );

  return {
    ...userState,
    handleAnonymousSignIn,
    handleEmailSignIn,
    handleEmailRegister,
    handleGoogleSignIn,
    handleFacebookSignIn,
    currentUserHasValidClaims,
    handleResetPassword,
  };
};

const FirebaseErrorMap: { [code: string]: string } = {
  "auth/invalid-email": "The email address provided is invalid.",
  "auth/email-already-in-use":
    "The email address is already in use by another account.",
  "auth/wrong-password": "The provided password is invalid.",
  "auth/weak-password": "The password must be 6 characters long or more.",
  "auth/user-not-found":
    "An account could not be found with the provided credentials.",
  "auth/too-many-requests": "Too many requests. Try again later.",
};
