import { Tab, TabGroup, TabList } from "@tremor/react";
import { ElementType, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { UserRole } from "../../constants/firestore";
import { useAuthentication } from "../../hooks/useAuthentication";

type DashboardTitle = string;

export type DashboardElement = {
  to?: string;
  title: DashboardTitle;
  icon: ElementType<any>;
  notificationAmount?: number;
  action?: () => any;
  requiredRoles?: UserRole[];
  childGroup?: DashboardElement[];
};

const Dashboard = ({ groups }: { groups: DashboardElement[] }) => {
  const { claims } = useAuthentication();

  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    setSelectedIndex(
      groups.findIndex((group) => group.to === window.location.pathname)
    );
  }, [window.location.pathname]);

  function handleIndexChange(index: number) {
    const navTo = groups[index].to;
    const action = groups[index].action;

    if (navTo && !action) {
      navigate(navTo);
    }

    if (!action) {
      setSelectedIndex(index);
    }
  }

  return (
    <div className="mx-auto flex min-h-screen w-full flex-col items-center justify-start">
      {/* Dashboard Menu */}

      <TabGroup
        index={selectedIndex}
        onIndexChange={handleIndexChange}
        className="w-fit"
      >
        <TabList variant="solid">
          {groups
            .filter((group) => {
              if (group.requiredRoles) {
                return group.requiredRoles.some((role) => claims?.[role]);
              } else {
                return true;
              }
            })
            .map((group: DashboardElement, index: number) => {
              return (
                <Tab
                  key={index}
                  className="capitalize"
                  icon={group.icon}
                  onClick={() => {
                    if (group.action) {
                      group.action();
                    }
                  }}
                >
                  {group.title}
                </Tab>
              );
            })}
        </TabList>
      </TabGroup>

      {/* Dashboard Section Outlet */}
      <div className="w-full max-w-5xl">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
