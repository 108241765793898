import * as Yup from "yup";
import {
  AllOfferingTypes,
  OfferingType,
  TaxBehavior,
  TransitDistance,
} from "../../../redux/api/offerings/types";
import {
  OFFERING_MAKER_ID,
  OFFERING_NAME,
  OFFERING_OPTIONS,
  OFFERING_TAX_BEHAVIOR,
  OFFERING_TRANSIT_DESCRIPTION,
  OFFERING_TRANSIT_DISTANCE,
  OFFERING_TYPE,
  OPTION_ACTIVE,
  OPTION_AMOUNT,
  OPTION_AVAILABLE_INVENTORY,
  OPTION_DESCRIPTION,
  OPTION_HAS_HERB_OF_THE_WEEK,
  OPTION_HAS_SUBSCRIPTION,
  OPTION_ID,
  OPTION_IMAGE,
  OPTION_NAME,
  OPTION_PRICE,
} from "./fieldNames";

export const optionValidationSchema = () => {
  return Yup.object().shape({
    [OPTION_ID]: Yup.string(),
    [OPTION_NAME]: Yup.string()
      .trim()
      .required("name is required")
      .max(50, "name must be less than 50 characters"),
    [OPTION_AMOUNT]: Yup.number()
      .required("amount is required")
      .positive("amount must be positive")
      .integer("amount must be an integer"),
    [OPTION_AVAILABLE_INVENTORY]: Yup.number()
      .required("inventory is required")
      .min(0, "inventory cannot be negative")
      .integer("inventory must be an integer"),
    [OPTION_PRICE]: Yup.number()
      .required("price is required")
      .positive("price must be positive"),
    [OPTION_DESCRIPTION]: Yup.string()
      .min(5, "description must be greater than 5 characters")
      .max(3000, "description must be less than 3000 characters")
      .required("description is required"),
    [OPTION_IMAGE]: Yup.string().required("image is required"),
    [OPTION_HAS_HERB_OF_THE_WEEK]: Yup.boolean().required(
      "herb of the week is required"
    ),
    [OPTION_ACTIVE]: Yup.boolean().required("active status is required"),
    [OPTION_HAS_SUBSCRIPTION]: Yup.boolean().required(),
  });
};

export const OfferingValidationSchema = Yup.object().shape({
  [OFFERING_TYPE]: Yup.mixed<OfferingType>()
    .oneOf(AllOfferingTypes)
    .required("offering type is required"),
  [OFFERING_NAME]: Yup.string().required("offering name is required"),
  [OFFERING_TRANSIT_DISTANCE]: Yup.mixed<TransitDistance>()
    .oneOf(Object.values(TransitDistance))
    .required("offering transit distance is required"),
  [OFFERING_TRANSIT_DESCRIPTION]: Yup.string()
    .max(1000, "transit description must be less than 1000 characters")
    .notRequired(),
  [OFFERING_OPTIONS]: Yup.array()
    .of(optionValidationSchema())
    .required()
    .min(1, "an offering must have at least 1 option")
    .max(12, "an offering may only contain up to 12 options"),
  [OFFERING_TAX_BEHAVIOR]: Yup.mixed<TaxBehavior>().required(
    "tax behavior is required"
  ),
  [OFFERING_MAKER_ID]: Yup.string().optional(),
});
