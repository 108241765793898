import { useEffect, useState } from "react";

const useTruncatedText = (text: string | null, maxLength: number) => {
  const [truncatedText, setTruncatedText] = useState<string | null>(null);
  const [isTruncated, setIsTruncated] = useState(true);

  useEffect(() => {
    if (isTruncated && text && text.length > maxLength) {
      setTruncatedText(text.substring(0, maxLength) + "...");
    } else if (text) {
      setTruncatedText(text);
    } else {
      setTruncatedText(null);
    }
  }, [text, maxLength, isTruncated]);
  if (!text) return { truncatedText, isTruncated, toggleTruncated: () => {} };

  const toggleTruncated = () => {
    setIsTruncated(!isTruncated);
  };

  return { truncatedText, isTruncated, toggleTruncated };
};

export default useTruncatedText;
