import {
  ArrowDownTrayIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import {
  BarChart,
  BarList,
  Bold,
  Button,
  Card,
  CategoryBar,
  DateRangePicker,
  DateRangePickerItem,
  DateRangePickerValue,
  List,
  ListItem,
  Subtitle,
  Tab,
  TabGroup,
  TabList,
  Title,
} from "@tremor/react";
import { useState } from "react";

import { RingLoader } from "react-spinners";
import { handleDownload } from ".";
import { DateStampFromRange } from "../../../helpers/DateHelpers";
import { useGetRevenueReportsQuery } from "../../../redux/api/reports/api";
import {
  MonthNames,
  MonthNamesType,
  MonthlyProductData,
} from "../../../redux/api/reports/types";

interface DateRange {
  key: string;
  label: string;
  from: Date;
  to: Date;
}

const valueFormatter = (number: number) =>
  `$${Intl.NumberFormat("us").format(number).toString()}`;

export function RevenueReports() {
  const today: Date = new Date();
  today.setHours(0, 0, 0, 0);

  const createCleanDate = (
    year: number | null = null,
    month: number = 0,
    day: number | null = null
  ): Date => {
    const date = new Date();
    if (year !== null) date.setFullYear(year);
    if (day !== null) date.setDate(day);
    date.setMonth(month);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getStartDate = (days: number): Date => {
    const date = new Date(today);
    date.setDate(date.getDate() - days);
    return date;
  };

  const ranges: DateRange[] = [
    {
      key: "ytd",
      label: "Year to Date",
      from: createCleanDate(today.getFullYear(), 0, 1),
      to: today,
    },
    {
      key: "6months",
      label: "Last 6 Months",
      from: getStartDate(180),
      to: today,
    },
    {
      key: "12months",
      label: "Last 12 Months",
      from: getStartDate(365),
      to: today,
    },
    {
      key: "2023",
      label: "Year End 2023",
      from: createCleanDate(2023, 0, 1),
      to: createCleanDate(2023, 11, 31),
    },
  ];

  const dateRangeOptions: DateRange[] = ranges.map(
    ({ key, label, from, to }): DateRange => ({
      key,
      from,
      to,
      label,
    })
  );

  const [dateRange, setDateRange] = useState<DateRangePickerValue>({
    from: getStartDate(365), // default to "Last 365 Days"
    to: new Date(),
  });

  const { data, isLoading, isFetching, error } = useGetRevenueReportsQuery({
    from: dateRange.from?.getTime().toString(),
    to: dateRange.to?.getTime().toString(),
  });

  const yearlyRevenueReport = data?.yearlyRevenueReport.report;
  const yearlyRevenueReportDownload = data?.yearlyRevenueReport.base64;

  const productRevenueReport = data?.productRevenueReport.report;
  const productRevenueReportDownload = data?.productRevenueReport.base64;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const barListData = Object.entries(
    productRevenueReport?.yearlyProductData ?? {}
  )
    .map(([k, v]) => ({
      name: k,
      value: selectedIndex === 0 ? v.totalAmount : v.quantity,
    }))
    .sort((a, b) => b.value - a.value);

  const grossGrossTotalValue = yearlyRevenueReport?.monthlyTotals
    ? Object.values(yearlyRevenueReport?.monthlyTotals).reduce(
        (acc, val) => acc + val,
        0
      )
    : 0;

  const totalItemsSold = yearlyRevenueReport?.monthlyProductData
    ? Object.values(yearlyRevenueReport?.monthlyProductData).reduce(
        (acc, val) => {
          return (
            acc + Object.values(val).reduce((acc, val) => acc + val.quantity, 0)
          );
        },
        0
      )
    : 0;

  const monthYearToDate = (monthYear: string): Date => {
    const [month, year] = monthYear.split(" ");
    const monthIndex = MonthNames.indexOf(month as MonthNamesType);
    return new Date(parseInt(year, 10), monthIndex);
  };

  let chartData: {
    date: string;
    sales: number;
    products: { name: string; sales: number; quantity: number }[];
  }[] = [];

  if (yearlyRevenueReport?.monthlyProductData) {
    const sortedMonthlyProductData: MonthlyProductData = Object.entries(
      yearlyRevenueReport.monthlyProductData
    )
      .sort(
        ([a], [b]) =>
          monthYearToDate(a).getTime() - monthYearToDate(b).getTime()
      )
      .reduce((acc, [key, value]) => {
        acc[key as MonthNamesType] = value;
        return acc;
      }, {} as MonthlyProductData);

    chartData = Object.entries(sortedMonthlyProductData).map(
      ([month, data]) => {
        const totalSales = Object.values(data).reduce(
          (acc, val) => acc + val.totalAmount,
          0
        );

        return {
          date: month,
          sales: totalSales,
          products: Object.entries(data)
            .map(([product, productData]) => {
              return {
                name: product,
                sales: productData.totalAmount,
                quantity: productData.quantity,
              };
            })
            .sort((a, b) => b.sales - a.sales),
        };
      }
    );
  }

  const DetailsTooltip = ({
    payload,
    active,
    label,
  }: {
    payload?: any;
    active?: any;
    label?: any;
  }) => {
    if (!active || !payload) return null;

    const categoryPayload = payload[0];
    if (!categoryPayload) return null;

    const selectedItem = chartData.find((item) => item.date === label);
    if (!selectedItem) return null;

    return (
      <div className="w-72 rounded-lg border border-gray-200 bg-white text-sm drop-shadow-md sm:-translate-y-10">
        <div className="flex items-start rounded-t-lg border-b border-gray-200 bg-gray-50 p-4">
          <CategoryBar
            values={selectedItem.products.map(
              (productData) => productData.sales
            )}
            showLabels={false}
            colors={[
              "indigo",
              "violet",
              "fuchsia",
              "teal",
              "cyan",
              "sky",
              "amber",
              "orange",
              "red",
              "pink",
              "rose",
              "stone",
              "slate",
              "emerald",
              "green",
            ]}
            className="w-full"
          />
        </div>

        <div className="p-4 ">
          <List>
            {selectedItem.products.map((productData) => (
              <ListItem key={productData.name} className="space-x-2">
                <div className="flex items-center space-x-2 truncate">
                  <span className="truncate">{productData.name}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <span>{valueFormatter(productData.sales)}</span>
                  <span>({productData.quantity})</span>
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    );
  };

  const SelectedDate = () =>
    isFetching ? (
      <span>Loading...</span>
    ) : (
      <>
        <span>
          {dateRange.from?.toLocaleDateString()} -{" "}
          {dateRange.to?.toLocaleDateString()}
        </span>
      </>
    );

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center space-y-4 py-12">
        <RingLoader />
        <Title>Loading Revenue Reports</Title>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex w-full items-start justify-end">
        <div className="z-[999] flex flex-col">
          <DateRangePicker
            maxDate={today}
            value={dateRange}
            onValueChange={setDateRange}
            enableYearNavigation={true}
          >
            {dateRangeOptions.map((option) => (
              <DateRangePickerItem
                key={option.key}
                value={option.key}
                from={option.from}
                to={option.to}
              >
                {option.label}
              </DateRangePickerItem>
            ))}
          </DateRangePicker>
          <Subtitle className="pl-2 pt-1 text-sm text-gray-500">
            Select a date range to generate a report
          </Subtitle>
        </div>
      </div>

      <Card className="z-[100]">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col space-y-1">
            <Bold>Monthly Revenue</Bold>
            <Subtitle className="text-sm text-gray-500">
              <SelectedDate />
            </Subtitle>
          </div>
          {yearlyRevenueReportDownload && dateRange?.from && dateRange?.to && (
            <Button
              size="sm"
              variant="primary"
              icon={ArrowDownTrayIcon}
              iconPosition="right"
              className="w-fit tracking-wide"
              onClick={() =>
                handleDownload(
                  `revenue-report.${DateStampFromRange(
                    dateRange.from ?? new Date(),
                    dateRange.to ?? new Date()
                  )}.xlsx`,
                  yearlyRevenueReportDownload
                )
              }
            >
              Revenue Report
            </Button>
          )}
        </div>
        <div className="mt-4 flex items-center justify-between">
          {/* Gross Sales */}
          <div className="flex items-center space-x-2">
            <span className="text-3xl font-semibold text-gray-700">
              {valueFormatter(grossGrossTotalValue)}
            </span>
            <span className="text-sm text-gray-500">Gross</span>
          </div>
          {/* Items Sold */}
          <div className="flex items-center space-x-2">
            <span className="text-3xl font-semibold text-gray-700">
              {totalItemsSold}
            </span>
            <span className="text-sm text-gray-500">Items Sold</span>
          </div>
        </div>
        <div className="my-8">
          <BarChart
            className="hidden h-72 sm:block"
            data={chartData}
            index="date"
            categories={["sales"]}
            colors={["teal-400"]}
            stack={true}
            valueFormatter={valueFormatter}
            yAxisWidth={70}
            showLegend={false}
            customTooltip={DetailsTooltip}
          />
          <BarChart
            className="h-80 sm:hidden"
            data={chartData}
            index="date"
            colors={["teal-400"]}
            categories={["sales"]}
            stack={true}
            valueFormatter={valueFormatter}
            showYAxis={false}
            showLegend={false}
            customTooltip={DetailsTooltip}
          />
        </div>
      </Card>

      <Card className=" space-y-6">
        <div className="flex w-full items-center justify-between self-start">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col space-y-1">
              <Bold>
                Gross Product {selectedIndex === 0 ? "Sales" : "Quantity"}
              </Bold>
              <Subtitle className="text-sm text-gray-500">
                <SelectedDate />
              </Subtitle>
            </div>

            {productRevenueReportDownload && (
              <Button
                size="sm"
                variant="primary"
                icon={ArrowDownTrayIcon}
                iconPosition="right"
                className="w-fit tracking-wide"
                onClick={() =>
                  handleDownload(
                    `product-revenue-report.${DateStampFromRange(
                      dateRange.from ?? new Date(),
                      dateRange.to ?? new Date()
                    )}.xlsx`,
                    productRevenueReportDownload
                  )
                }
              >
                Product Report
              </Button>
            )}
          </div>
        </div>

        <TabGroup
          index={selectedIndex}
          onIndexChange={setSelectedIndex}
          className="flex w-fit self-start"
        >
          <TabList variant="solid">
            <Tab icon={CurrencyDollarIcon}>Sales</Tab>
            <Tab icon={CalculatorIcon}>Quantity</Tab>
          </TabList>
        </TabGroup>

        <BarList
          data={barListData}
          valueFormatter={selectedIndex === 0 ? valueFormatter : undefined}
          className=""
        />
      </Card>
    </div>
  );
}
