import {
  DocumentBulletListMultiple,
  FoodGrains,
  News,
  People,
  PeopleTeam,
  Receipt,
} from "@styled-icons/fluentui-system-regular";
import { NavLink } from "react-router-dom";
import Dashboard, { DashboardElement } from "../../components/Dashboard";
import { AltLogo } from "../../components/Logo";
import { UserRole } from "../../constants/firestore";
import { useSearchOrdersQuery } from "../../redux/api/orders/api";

interface IAdminProps {}

const Admin = ({}: IAdminProps) => {
  const { data: orders } = useSearchOrdersQuery({
    fulfilled: false,
  });

  const AdminElements: DashboardElement[] = [
    {
      to: "/admin/orders",
      title: "orders",
      icon: Receipt,
      notificationAmount: orders?.length ?? 0,
      requiredRoles: [UserRole.Admin, UserRole.FulfillmentManager],
    },
    {
      to: "/admin/reports",
      title: "reports",
      icon: DocumentBulletListMultiple,
      requiredRoles: [UserRole.Admin, UserRole.FulfillmentManager],
    },
    {
      to: "/admin/offerings",
      title: "offerings",
      icon: FoodGrains,
      requiredRoles: [UserRole.Admin, UserRole.AdminReadonlyOfferings],
    },
    {
      to: "/admin/makers",
      title: "makers",
      icon: PeopleTeam,
      requiredRoles: [UserRole.Admin],
    },
    {
      to: "/admin/newsletter",
      title: "newsletter",
      icon: News,
      requiredRoles: [UserRole.Admin],
    },
    {
      to: "/admin/users",
      title: "Users",
      icon: People,
      requiredRoles: [UserRole.Admin],
    },
  ];

  return (
    <div className="flex flex-col items-center justify-start space-y-4 bg-gray-100 pt-8">
      <div className="flex h-20 w-full items-center justify-center">
        <div className="h-full w-56 cursor-pointer border-r border-gray-200 p-4">
          <NavLink to="/">
            <AltLogo style={"color"} />
          </NavLink>
        </div>
        <div className="flex h-full w-fit items-center justify-start p-4 text-2xl font-light text-gray-600">
          Admin
        </div>
      </div>

      <div className="w-full max-w-5xl">
        <Dashboard groups={AdminElements} />
      </div>
    </div>
  );
};

export default Admin;
