import { Field, Form, Formik, FormikHelpers } from "formik";
import { useCreateInquiryMutation } from "../../../redux/api/contact/api";
import { useShowToast } from "../../../redux/state/toasts/hooks";
import { FormikAdminInput, FormikTextArea } from "../../Formik";
import Spinner from "../../Spinner";
import {
  CONTACT_INQUIRY_COMMENTS,
  CONTACT_INQUIRY_EMAIL,
  CONTACT_INQUIRY_FIRST_NAME,
  CONTACT_INQUIRY_LAST_NAME,
  CONTACT_INQUIRY_PHONE,
} from "./fieldNames";
import { IContactUsValues, initialValues } from "./initialValues";
import { ContactUsSchema } from "./validationSchema";

/**
 * Formats a phone number to match (xxx) xxx-xxxx
 * @param val input phone value
 * @returns
 */
function formatPhoneNumber(val: string) {
  if (!val) return val;
  const num = val.replace(/[^\d]/g, "");
  if (num.length < 4) return num;
  if (num.length < 7) return `(${num.slice(0, 3)}) ${num.slice(3)}`;
  return `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6, 10)}`;
}

/**
 * Contact Us Form Component
 * @param props
 * @returns
 */
const ContactForm = ({}) => {
  const { showToast } = useShowToast();

  const [triggerCreateInquiry] = useCreateInquiryMutation();

  async function submitContactForm(
    values: IContactUsValues,
    formikHelpers: FormikHelpers<IContactUsValues>
  ) {
    await triggerCreateInquiry(values);

    formikHelpers.resetForm();
    formikHelpers.setSubmitting(false);

    showToast({
      code: 0,
      title: "Inquiry Sent",
      body: "Your inquiry was successfully sent!",
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitContactForm}
      validationSchema={ContactUsSchema}
    >
      {(formikProps) => (
        <Form
          onSubmit={(e: any) => {
            e.preventDefault();

            formikProps.validateForm();
            formikProps.handleSubmit();
          }}
          noValidate
        >
          <div className="mb-4 flex">
            <div className=" mx-1 flex-1">
              <Field
                type="text"
                name={CONTACT_INQUIRY_FIRST_NAME}
                title="First Name"
                component={FormikAdminInput}
              />
            </div>

            <div className=" mx-1 flex-1">
              <Field
                type="text"
                name={CONTACT_INQUIRY_LAST_NAME}
                title="Last Name"
                component={FormikAdminInput}
              />
            </div>
          </div>

          <div className=" mx-1 mb-4 flex-1">
            <Field
              type="email"
              name={CONTACT_INQUIRY_EMAIL}
              title="Email"
              component={FormikAdminInput}
            />
          </div>

          <div className=" mx-1 mb-4 flex-1">
            <Field
              type="text"
              name={CONTACT_INQUIRY_PHONE}
              title="Phone"
              onChange={(e: any) =>
                formikProps.setFieldValue(
                  "phone",
                  formatPhoneNumber(e.currentTarget.value)
                )
              }
              component={FormikAdminInput}
            />
          </div>

          <div className=" mx-1 mb-4 flex-1">
            <Field
              type="textarea"
              name={CONTACT_INQUIRY_COMMENTS}
              title="Message"
              rows={4}
              component={FormikTextArea}
            />
          </div>

          <button
            className="mt-6 flex w-full transform items-center justify-center rounded bg-teal-900 px-6 py-3 font-serif text-xl italic tracking-wider text-white transition-colors duration-300 hover:bg-teal-700 focus:outline-none focus:ring focus:ring-teal-600 focus:ring-opacity-50"
            type="submit"
          >
            {formikProps.isSubmitting ? <Spinner /> : <span>Submit</span>}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
