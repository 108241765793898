import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import cartApi from "./api/cart/api";
import contactApi from "./api/contact/api";
import contentApi from "./api/content/api";
import makersApi from "./api/makers/api";
import { handleApiResponseValue } from "./api/middleware";
import newsletterApi from "./api/newsletter/api";
import offeringsApi from "./api/offerings/api";
import offerings from "./api/offerings/reducer";
import ordersApi from "./api/orders/api";
import reportsApi from "./api/reports/api";
import rolesApi from "./api/roles/api";
import stripeApi from "./api/stripe/api";
import app from "./state/app/reducer";
import checkout from "./state/checkout/reducer";
import lexical from "./state/lexical/reducer";
import modals from "./state/modals/reducer";
import toasts from "./state/toasts/reducer";

const store = configureStore({
  reducer: {
    app,
    modals,
    checkout,
    offerings,
    toasts,
    lexical,
    [cartApi.reducerPath]: cartApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [newsletterApi.reducerPath]: newsletterApi.reducer,
    [offeringsApi.reducerPath]: offeringsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [stripeApi.reducerPath]: stripeApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [contentApi.reducerPath]: contentApi.reducer,
    [makersApi.reducerPath]: makersApi.reducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    cartApi.middleware,
    contactApi.middleware,
    newsletterApi.middleware,
    offeringsApi.middleware,
    ordersApi.middleware,
    rolesApi.middleware,
    stripeApi.middleware,
    reportsApi.middleware,
    contentApi.middleware,
    makersApi.middleware,
    handleApiResponseValue,
  ],
});

setupListeners(store.dispatch);

export default store;

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
