import * as Yup from "yup";
import { ValidEmail } from "../../../constants/regex";
import { NEWSLETTER_SIGNUP_EMAIL } from "./fieldNames";

export const NewsletterSignupSchema = Yup.object().shape({
  [NEWSLETTER_SIGNUP_EMAIL]: Yup.string()
    .trim()
    .matches(ValidEmail, {
      message: "Email is invalid",
      excludeEmptyString: true,
    })
    .required("Email is required"),
});
