// Offering Field Names
export const OFFERING_TYPE = "type";
export const OFFERING_NAME = "name";
export const OFFERING_TRANSIT_DISTANCE = "transitDistance";
export const OFFERING_TRANSIT_DESCRIPTION = "transitDescription";
export const OFFERING_OPTIONS = "options";
export const OFFERING_TAX_BEHAVIOR = "taxBehavior";
export const OFFERING_MAKER_ID = "makerId";

// Offering Option Field Names
export const OPTION_ID = "id";
export const OPTION_NAME = "name";
export const OPTION_AMOUNT = "amount";
export const OPTION_AVAILABLE_INVENTORY = "availableInventory";
export const OPTION_PRICE = "price";
export const OPTION_DESCRIPTION = "description";
export const OPTION_IMAGE = "image";
export const OPTION_ACTIVE = "active";
export const OPTION_HAS_HERB_OF_THE_WEEK = "hasHerbOfTheWeek";
export const OPTION_HAS_SUBSCRIPTION = "hasSubscription";
