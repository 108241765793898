import { useEffect } from "react";
import { AppState } from "../..";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { usePaginatedOfferingsQuery } from "./api";
import { pushOfferings, resetOfferings, setStartAfter } from "./reducer";
import { OfferingType, PaginatedOfferingsPayload } from "./types";

interface UsePaginatedNavigationProps {
  limit?: number | null;
  category?: OfferingType | null;
  active?: boolean | null;
  search?: string | null;
  orderBy?: "name-asc" | "name-desc" | "age-desc" | null;
}

export function usePaginatedOfferings({
  limit = 48,
  category = null,
  active = null,
  search = null,
  orderBy = null,
}: UsePaginatedNavigationProps) {
  const dispatch = useAppDispatch();

  const allOfferings = useAppSelector(
    (state: AppState) => state.offerings.offerings
  );
  const startAfter = useAppSelector(
    (state: AppState) => state.offerings.startAfter
  );

  // Effect to reset offerings and pagination when any parameter changes
  useEffect(() => {
    dispatch(resetOfferings());
  }, [limit, category, active, search, orderBy]);

  const payload: PaginatedOfferingsPayload = {
    limit,
    category,
    active,
    search,
    orderBy,
    ...(startAfter && { startAfter }),
  };

  const {
    data: response,
    isLoading,
    isFetching,
  } = usePaginatedOfferingsQuery(payload);

  useEffect(() => {
    // Append new offerings to existing offerings
    if (response?.offerings && response.offerings.length > 0) {
      dispatch(pushOfferings(response.offerings));
    }
  }, [response?.offerings]);

  // Load more offerings
  const loadMore = () => {
    if (response?.nextStartAfter) {
      dispatch(setStartAfter(response.nextStartAfter));
    }
  };

  return {
    allOfferings,
    isLoading: isLoading || isFetching,
    loadMore,
    totalOfferings: response?.total ? response.total : allOfferings.length,
  };
}
