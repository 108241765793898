import {
  Offering,
  TaxBehavior,
  TransitDistance,
} from "../../../redux/api/offerings/types";
import {
  OFFERING_MAKER_ID,
  OFFERING_NAME,
  OFFERING_OPTIONS,
  OFFERING_TAX_BEHAVIOR,
  OFFERING_TRANSIT_DESCRIPTION,
  OFFERING_TRANSIT_DISTANCE,
  OFFERING_TYPE,
  OPTION_ACTIVE,
  OPTION_AMOUNT,
  OPTION_AVAILABLE_INVENTORY,
  OPTION_DESCRIPTION,
  OPTION_HAS_HERB_OF_THE_WEEK,
  OPTION_HAS_SUBSCRIPTION,
  OPTION_ID,
  OPTION_IMAGE,
  OPTION_NAME,
  OPTION_PRICE,
} from "./fieldNames";

export interface OfferingValues {
  [OFFERING_TYPE]: string;
  [OFFERING_NAME]: string;
  [OFFERING_TRANSIT_DISTANCE]: TransitDistance;
  [OFFERING_TRANSIT_DESCRIPTION]: string;
  [OFFERING_OPTIONS]: OptionValues[];
  [OFFERING_TAX_BEHAVIOR]: TaxBehavior;
  [OFFERING_MAKER_ID]: string;
}

/**
 * Determines what values should populate the initial form state
 * @param offering an optional offering (for updating existing offerings)
 * @returns
 */
export const initialOfferingValues = (
  offering: Offering | null
): OfferingValues => {
  const values = {
    [OFFERING_TYPE]: offering?.type ?? "",
    [OFFERING_NAME]: offering?.name ?? "",
    [OFFERING_TRANSIT_DISTANCE]:
      offering?.transitDistance ?? TransitDistance.None,
    [OFFERING_TRANSIT_DESCRIPTION]: offering?.transitDescription ?? "",
    [OFFERING_TAX_BEHAVIOR]: offering?.taxBehavior ?? TaxBehavior.Unspecified,
    [OFFERING_MAKER_ID]: offering?.makerId ?? "",
    [OFFERING_OPTIONS]: offering?.options.map((option) => {
      return {
        [OPTION_ID]: option.id,
        [OPTION_NAME]: option.name,
        [OPTION_AMOUNT]: option.amount,
        [OPTION_AVAILABLE_INVENTORY]: option.availableInventory,
        [OPTION_PRICE]: option.price,
        [OPTION_DESCRIPTION]: option.description,
        [OPTION_IMAGE]: option.image,
        [OPTION_ACTIVE]: option.active,
        [OPTION_HAS_HERB_OF_THE_WEEK]: option.hasHerbOfTheWeek,
        [OPTION_HAS_SUBSCRIPTION]: option.subscription?.enabled ?? false,
      } as OptionValues;
    }) ?? [initialOptionValues],
  };

  return values;
};

export interface OptionValues {
  [OPTION_ID]: string | undefined;
  [OPTION_NAME]: string;
  [OPTION_AMOUNT]: number;
  [OPTION_AVAILABLE_INVENTORY]: number;
  [OPTION_PRICE]: number;
  [OPTION_DESCRIPTION]: string;
  [OPTION_IMAGE]: string;
  [OPTION_ACTIVE]: boolean;
  [OPTION_HAS_HERB_OF_THE_WEEK]: boolean;
  [OPTION_HAS_SUBSCRIPTION]: boolean;
}

export const initialOptionValues: OptionValues = {
  [OPTION_ID]: undefined,
  [OPTION_NAME]: "",
  [OPTION_AMOUNT]: 0,
  [OPTION_AVAILABLE_INVENTORY]: 0,
  [OPTION_PRICE]: 0,
  [OPTION_DESCRIPTION]: "",
  [OPTION_IMAGE]: "",
  [OPTION_ACTIVE]: true,
  [OPTION_HAS_HERB_OF_THE_WEEK]: false,
  [OPTION_HAS_SUBSCRIPTION]: false,
};
