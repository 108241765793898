import { Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useAddSubscriberMutation } from "../../../redux/api/newsletter/api";
import { usePopModal } from "../../../redux/state/modals/hooks";
import { FormikInput } from "../../Formik";
import Spinner from "../../Spinner";
import { NEWSLETTER_SIGNUP_EMAIL } from "./fieldNames";
import {
  InitialNewsletterSignupValues,
  NewsletterSignupValues,
} from "./initialValues";
import { NewsletterSignupSchema } from "./validationSchema";

interface Props {
  label?: boolean;
  theme?: "light" | "dark";
}

const NewsletterForm = ({ label, theme = "light" }: Props) => {
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [triggerAddSubscriber] = useAddSubscriberMutation();

  const popModal = usePopModal();

  async function submitNewsletterSubscribeForm(
    values: NewsletterSignupValues,
    formikHelpers: FormikHelpers<NewsletterSignupValues>
  ) {
    await triggerAddSubscriber({ email: values.email })
      .unwrap()
      .then((payload) => {
        setSubmitted(true);
      })
      .catch((error) => {
        console.log(error);
      });

    formikHelpers.resetForm();
    formikHelpers.setSubmitting(false);
    // automatically close the newsletter signup modal
    popModal();
  }

  return (
    <Formik
      initialValues={InitialNewsletterSignupValues}
      onSubmit={submitNewsletterSubscribeForm}
      validationSchema={NewsletterSignupSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formikProps) => (
        <Form
          className="flex-1 [direction:ltr] lg:col-span-1"
          onSubmit={(e: any) => {
            e.preventDefault();
            formikProps.validateForm();
            formikProps.handleSubmit();
          }}
          noValidate
        >
          <Field
            type="email"
            name={NEWSLETTER_SIGNUP_EMAIL}
            placeholder="EMAIL ADDRESS"
            title="STAY UPDATED"
            label={label}
            theme={theme}
            component={FormikInput}
            button={
              <button
                type="submit"
                className={`bg-${
                  theme === "light" ? "stone-200" : "gray-800"
                } py-3 px-5 text-xs font-bold tracking-wider text-${
                  theme === "light" ? "gray-800" : "stone-200"
                }`}
              >
                {formikProps.isSubmitting ? <Spinner /> : "SUBSCRIBE"}
              </button>
            }
          />
        </Form>
      )}
    </Formik>
  );
};

export default NewsletterForm;
