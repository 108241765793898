import {
  Timestamp,
  addDoc,
  collection,
  getFirestore,
} from "firebase/firestore";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { auth } from "../../../config/firebase";
import { DateStamp } from "../../../helpers/DateHelpers";
import { AppState } from "../../../redux";
import { useUploadImageMutation } from "../../../redux/api/content/api";
import { useTriggerNewsletterMutation } from "../../../redux/api/newsletter/api";
import { useAppDispatch } from "../../../redux/hooks";
import { makeSelectEditor } from "../../../redux/state/lexical/hooks";
import { resetEditor } from "../../../redux/state/lexical/reducer";
import { EditorType } from "../../../redux/state/lexical/types";
import { useOpenModal, usePopModal } from "../../../redux/state/modals/hooks";
import { pushModal } from "../../../redux/state/modals/reducer";
import { ModalType } from "../../../redux/state/modals/types";
import { FormikAdminInput } from "../../Formik";
import LexicalEditor from "../../LexicalEditor";
import Modal from "../../Modals";
import DangerModal from "../../Modals/DangerModal";
import Spinner from "../../Spinner";
import {
  IS_PREVIEW,
  NEWSLETTER_MESSAGE,
  NEWSLETTER_SUBJECT,
  PREVIEW_RECIPENTS,
} from "./fieldNames";
import { TriggerNewsletterValues, initialValues } from "./initialValues";
import NewsletterFormSchema from "./validationSchema";

const saveNewsletterToFirestore = async (subject: string, message: string) => {
  const db = getFirestore();
  try {
    await addDoc(collection(db, "newsletters"), {
      subject,
      message,
      date: Timestamp.fromDate(new Date()),
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

/**
 * Contact Us Form Component
 * @param props
 * @returns
 */
const TriggerNewsletterForm = () => {
  const previewNewsletterModal = useOpenModal(ModalType.PREVIEW_NEWSLETTER);

  let formikRef = useRef(null) as any;

  const popModal = usePopModal();

  const [triggerNewsletter] = useTriggerNewsletterMutation();
  const [uploadImage] = useUploadImageMutation();

  // get the newsletter editor from the redux state
  const dispatch = useAppDispatch();

  const selectEditor = makeSelectEditor();
  const editor = useSelector((state: AppState) =>
    selectEditor(state, EditorType.Newsletter)
  );

  async function handleSubmit(
    values: TriggerNewsletterValues,
    formikHelpers: FormikHelpers<TriggerNewsletterValues>
  ) {
    const path = values[IS_PREVIEW]
      ? `images/newsletter/preview`
      : `images/newsletter/uploads`;

    values[NEWSLETTER_MESSAGE] = await uploadNewsletterImages(
      path,
      editor.html,
      !values[IS_PREVIEW] // Don't revoke the blob urls when we're previewing
    );

    //update the newsletter_message raw html so that all styles are removed from span attributes
    const parser = new DOMParser();
    const doc = parser.parseFromString(values[NEWSLETTER_MESSAGE], "text/html");
    const spans = doc.querySelectorAll("span");
    spans.forEach((span) => {
      span.removeAttribute("style");
    });
    values[NEWSLETTER_MESSAGE] = doc.body.innerHTML;

    await triggerNewsletter(values);

    // Save the newsletter to Firestore if it's not a preview
    if (!values[IS_PREVIEW]) {
      await saveNewsletterToFirestore(
        values[NEWSLETTER_SUBJECT],
        values[NEWSLETTER_MESSAGE]
      );
    }

    if (values[IS_PREVIEW]) {
      // after the preview is sent, reset the isPreview state and close the modal
      await handleResetIsPreview(formikHelpers);
    } else {
      // after the newsletter is triggered, reset the form
      formikHelpers.resetForm();
      // reset the editor state in the redux store
      dispatch(resetEditor(editor.id));
    }

    popModal();
  }

  async function uploadNewsletterImages(
    path: string,
    html: string,
    revokeBlobUrls = true
  ): Promise<string> {
    let uploadedImages = 0;

    const uploadImagePromises = editor.images.map((image) => {
      return new Promise<void>(async (resolve, reject) => {
        if (image.url.startsWith("blob")) {
          const res = await fetch(image.url);
          const blob = await res.blob();

          const reader = new FileReader();
          reader.onloadend = async () => {
            try {
              const base64data = reader.result as string;
              const _path = await uploadImage({
                path: `${path}/${DateStamp()}/${Date.now()}/${++uploadedImages}.png`,
                base64: base64data.split(",")[1],
              }).unwrap();
              html = html.replace(image.url, _path);
              if (revokeBlobUrls) {
                URL.revokeObjectURL(image.url);
              }
              resolve();
            } catch (error) {
              reject(error);
            }
          };
          reader.readAsDataURL(blob);
        } else {
          resolve(); // if it's not a blob URL, we can resolve immediately
        }
      });
    });

    await Promise.all(uploadImagePromises);

    return html;
  }

  async function handleResetIsPreview(
    formikHelpers: FormikHelpers<TriggerNewsletterValues>
  ) {
    formikHelpers.setFieldValue(IS_PREVIEW, false);
    formikHelpers.setFieldValue(PREVIEW_RECIPENTS, "");
    await formikHelpers.validateForm();
  }

  useEffect(() => {
    if (previewNewsletterModal) {
      formikRef.current?.setFieldValue(
        PREVIEW_RECIPENTS,
        auth.currentUser?.email
      );
    }
  }, [previewNewsletterModal]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={NewsletterFormSchema}
      innerRef={formikRef}
    >
      {(formikProps) => (
        <Form
          className="space-y-4"
          onSubmit={(e: any) => {
            e.preventDefault();
            formikProps.validateForm();
            formikProps.handleSubmit();
          }}
          noValidate
        >
          <Field
            type="text"
            title="Subject"
            name={NEWSLETTER_SUBJECT}
            placeholder="Newsletter subject"
            component={FormikAdminInput}
          />

          <LexicalEditor
            label="Message"
            type={EditorType.Newsletter}
            fieldName={NEWSLETTER_MESSAGE}
          />

          <div className="flex w-full justify-between">
            <button
              type="button"
              className="rounded bg-slate-600 px-4 py-2 text-zinc-50"
              onClick={async () => {
                formikProps.setTouched({
                  [NEWSLETTER_SUBJECT]: true,
                  [NEWSLETTER_MESSAGE]: true,
                });
                const errors = await formikProps.validateForm();
                if (Object.keys(errors).length === 0) {
                  formikProps.setFieldValue(IS_PREVIEW, true);
                  dispatch(
                    pushModal({
                      id: editor.id,
                      type: ModalType.PREVIEW_NEWSLETTER,
                      dismissible: true,
                      scrollable: true,
                    })
                  );
                }
              }}
            >
              Send Preview
            </button>

            {previewNewsletterModal && (
              <Modal
                {...previewNewsletterModal}
                onClose={async () => {
                  await handleResetIsPreview(formikProps);
                }}
              >
                <form className="max-w-sm space-y-4">
                  <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 sm:mb-5 dark:border-gray-600">
                    <h1 className="text-lg font-semibold text-gray-900 dark:text-white">
                      Preview Newsletter
                    </h1>
                  </div>
                  <p className="p-1 text-sm text-gray-700">
                    To send a proof to multiple addresses, separate each address
                    with a comma.
                  </p>
                  <Field
                    type="input"
                    name={PREVIEW_RECIPENTS}
                    title="Emails"
                    component={FormikAdminInput}
                  />
                  <p className="rounded bg-amber-400/25 p-4 text-sm text-amber-700">
                    <strong>Warning:</strong> It is recommended only to send a
                    preview to your own email or someone who is expecting it.
                  </p>
                  <div className="flex justify-between">
                    <button
                      type="button"
                      className="rounded bg-gray-600 px-4 py-2 text-zinc-50"
                      onClick={async () => {
                        await handleResetIsPreview(formikProps);
                        popModal();
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="flex items-center justify-between space-x-2 rounded bg-teal-600 px-4 py-2 text-zinc-50"
                      disabled={formikProps.isSubmitting}
                      onClick={formikProps.submitForm}
                    >
                      {formikProps.values[IS_PREVIEW] &&
                      formikProps.isSubmitting ? (
                        <>
                          <Spinner />
                          <span>Sending...</span>
                        </>
                      ) : (
                        <span>Send</span>
                      )}
                    </button>
                  </div>
                </form>
              </Modal>
            )}

            <button
              type="button"
              className="flex items-center justify-between space-x-2 rounded bg-red-600 px-4 py-2 text-zinc-50"
              onClick={async () => {
                formikProps.setTouched({
                  [NEWSLETTER_SUBJECT]: true,
                  [NEWSLETTER_MESSAGE]: true,
                });
                const errors = await formikProps.validateForm();
                if (Object.keys(errors).length === 0) {
                  dispatch(
                    pushModal({
                      id: `${editor.id}-trigger-newsletter`,
                      type: ModalType.DANGER,
                    })
                  );
                }
              }}
            >
              {!formikProps.values[IS_PREVIEW] && formikProps.isSubmitting ? (
                <>
                  <Spinner />
                  <span>Sending...</span>
                </>
              ) : (
                <span>Trigger Newsletter</span>
              )}
            </button>

            <DangerModal
              id={`${editor.id}-trigger-newsletter`}
              title={"Trigger Newsletter Blast?"}
              message={
                "Are you sure you want to proceed with triggering a newsletter blast? This will send an email to every subscriber."
              }
              onConfirm={formikProps.handleSubmit}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TriggerNewsletterForm;
