import { OrderDateCreated } from "../../helpers/OrderHelpers";
import { formatPhoneNumber } from "../../helpers/StringHelpers";
import { Order } from "../../redux/api/orders/types";

interface IUserOrderProps {
  order: Order; // an order to override the response
}

const OrderDetails = ({ order }: IUserOrderProps) => {
  const _orderStatus = order.fulfilled ? "FULFILLED" : "UNFULFILLED";

  const { line1, line2, city, state, postalCode, country } =
    order.customer.address;

  const displayShippingDetails =
    order.fulfillmentOption === "delivery" &&
    order.shippingAddress &&
    order.shippingAmount != null;
  const displayPickupDetails =
    order.fulfillmentOption === "pickup" && order.pickupLocation;

  return (
    <div className="flex flex-col gap-4 p-4">
      <section
        className={`w-full rounded-md border-2 border-solid p-2 ${
          order.fulfilled
            ? "border-teal-100 bg-teal-50"
            : "border-red-100 bg-red-50"
        }`}
      >
        <b>Status</b>&nbsp;
        {_orderStatus}
      </section>

      <div className="grid grid-cols-12 gap-8">
        <div className="col-span-12 md:col-span-6">
          <h1 className="mb-1 w-full border-b border-gray-200 pb-0.5 text-xs uppercase text-gray-400">
            customer info
          </h1>
          <div className="grid max-w-xs grid-cols-6 gap-2 p-0.5">
            <span className="col-span-2 w-fit font-bold">Name:&nbsp;</span>
            <span className="col-span-4">{order.customer.name}</span>
            <span className="col-span-2 w-fit font-bold">Email:&nbsp;</span>
            <span className="col-span-4">{order.customer.email}</span>
            <span className="col-span-2 w-fit font-bold">Phone:&nbsp;</span>
            <span className="col-span-4">
              {formatPhoneNumber(order.customer.phone)}
            </span>
            <span className="col-span-2 w-fit font-bold">
              Billing Address:&nbsp;
            </span>
            <div className="col-span-4 flex flex-col">
              <span>{line1}</span>
              {line2 !== "N/A" && <span>{line2}</span>}
              <span className="space-x-1">
                {city && <span>{city}</span>}
                {state && <span>{state}</span>}
                {postalCode && <span>{postalCode}</span>}
              </span>
              {country && <span>{country}</span>}
            </div>

            <span className="col-span-2 w-fit font-bold">
              Purchase Date:&nbsp;
            </span>
            <span className="col-span-4">
              {OrderDateCreated(order, "long")}
            </span>
          </div>
        </div>

        <div className="col-span-12 space-y-2 md:col-span-6">
          <h1 className="mb-1 flex w-full items-center justify-between border-b border-gray-200 pb-0.5 text-xs uppercase text-gray-400">
            <span>order details</span>
          </h1>

          {/* Order Line Items Table */}
          <table className="w-full table-auto border-collapse">
            <thead className="bg-gray-100 text-left text-xs">
              <tr>
                <th className="p-1 text-center">Qty</th>
                <th className="p-1">Description</th>
                <th className="p-1">Unit Price</th>
                <th className="p-1">Subtotal</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {order.items.map((item, i) => (
                <tr className={"border-t odd:bg-gray-50 even:bg-white"}>
                  <td className="p-1 text-center">{item.quantity}</td>
                  <td className="p-1">{item.description}</td>
                  <td className="p-1 text-center">
                    ${item.unitAmount.toFixed(2)}
                  </td>
                  <td className="p-1 text-center">
                    ${item.totalAmount.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="bg-gray-100">
              <tr className="border-t-2 border-gray-200 text-sm">
                <td colSpan={3} className="p-1 text-right">
                  Order Total:
                </td>
                <td className="p-1 text-center font-bold">
                  ${order.total.toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>

          {/* Conditional Fulfillment Section */}
          {displayShippingDetails && (
            <>
              <h1 className="mb-1 flex w-full items-center justify-between border-b border-gray-200 pb-0.5 text-xs uppercase text-gray-400">
                <span>Shipping Details</span>
              </h1>
              <div className="grid max-w-xs grid-cols-6 gap-2 p-0.5">
                <span className="col-span-2 w-fit font-bold">
                  Method:&nbsp;
                </span>
                <span className="col-span-4">{order.shippingType}</span>

                {/* Shipping Amount */}
                <span className="col-span-2 w-fit font-bold">
                  Amount:&nbsp;
                </span>
                <span className="col-span-4">
                  ${order.shippingAmount?.toFixed(2)}
                </span>

                {/* Shipping Address */}
                <span className="col-span-2 w-fit font-bold">
                  Shipping Address:&nbsp;
                </span>
                <div className="col-span-4 flex flex-col">
                  <span>{order.shippingAddress?.line1}</span>
                  {order.shippingAddress?.line2 && (
                    <span>{order.shippingAddress.line2}</span>
                  )}
                  <span>{`${order.shippingAddress?.city}, ${order.shippingAddress?.state} ${order.shippingAddress?.postal_code}`}</span>
                  <span>{order.shippingAddress?.country}</span>
                </div>
              </div>
            </>
          )}

          {displayPickupDetails && (
            <>
              <h1 className="mb-1 flex w-full items-center justify-between border-b border-gray-200 pb-0.5 text-xs uppercase text-gray-400">
                <span>Pickup Details</span>
              </h1>
              {/* Pickup Location */}
              <div className="grid max-w-xs grid-cols-6 gap-2 p-0.5">
                <span className="col-span-2 w-fit font-bold">
                  Method:&nbsp;
                </span>
                <span className="col-span-4">{order.pickupLocation}</span>
              </div>
            </>
          )}

          {!displayShippingDetails && !displayPickupDetails && (
            <>
              <div className="mb-1 flex w-full items-center justify-between border-b border-gray-200 pb-0.5 text-xs uppercase text-gray-400">
                <span>Fulfillment Information</span>
              </div>
              {/* Unspecified Fulfillment Info */}
              <div className="grid max-w-xs grid-cols-6 gap-2 p-0.5">
                {/* Pickup Location */}
                <span className="col-span-6">N/A</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
