import {
  Timestamp,
  collection,
  getFirestore,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import Masonry from "react-masonry-css";
import { NavLink, useParams } from "react-router-dom";
import Spinner from "../components/Spinner";

interface NewsletterIssue {
  id: string;
  issueNumber: number;
  subject: string;
  message: string;
  date: Timestamp;
}

type NewsletterProps = {
  newsletter: NewsletterIssue;
};

const NewsletterPreview = ({ newsletter }: NewsletterProps) => {
  return (
    <li className="mb-6 list-none break-inside-avoid bg-stone-200 p-2 text-stone-900 transition duration-300 hover:bg-stone-300">
      <NavLink
        to={`/newsletter/archive/${newsletter.id}`}
        className="block p-6"
      >
        <div className="flex">
          <div>
            <p className="text-lg font-semibold">
              Maranatha Farm Newsletter #{newsletter.issueNumber}
            </p>
            <p className="text-stone-500">
              {newsletter.date?.toDate().toLocaleDateString()}
            </p>
          </div>
        </div>
        <p className="mt-6 text-xl">{newsletter.subject}</p>
      </NavLink>
    </li>
  );
};

const NewsletterDetails = ({ newsletter }: NewsletterProps) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-8 px-6 py-24 text-stone-900">
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-3xl font-bold">
          Maranatha Farm Newsletter #{newsletter.issueNumber}
        </h1>
        <p className="mt-4 text-xl">{newsletter.subject}</p>
        <p className="mt-4 text-stone-500">
          {newsletter.date?.toDate().toLocaleDateString()}
        </p>
      </div>
      <hr className="h-0.5 w-[200px] bg-stone-900" />
      <div
        className="ml-auto mr-auto mt-4 max-w-none py-8"
        dangerouslySetInnerHTML={{ __html: newsletter.message }}
      ></div>
    </div>
  );
};

const NewsletterArchivePage = () => {
  const { id } = useParams();
  const [selectedNewsletter, setSelectedNewsletter] =
    useState<NewsletterIssue | null>(null);

  const q = query(
    collection(getFirestore(), "newsletters"),
    orderBy("date", "desc")
  );

  const [value, loading, error] = useCollection(q, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  let length = value?.docs?.length ?? 0;

  const issues = value?.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id as string,
      issueNumber: length--,
      subject: data.subject as string,
      message: data.message as string,
      date: data.date as Timestamp,
    } as NewsletterIssue;
  });

  // Fetch specific newsletter if id is provided
  useEffect(() => {
    const fetchNewsletter = async () => {
      if (id) {
        const issue = issues?.find((doc) => doc.id === id) ?? null;
        setSelectedNewsletter(issue);
      } else {
        setSelectedNewsletter(null);
      }
    };

    fetchNewsletter();
  }, [id, issues]);

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <div className="min-h-screen bg-gray-100 sm:px-8 md:px-12">
      {loading && (
        <div className="flex min-h-screen items-center justify-center">
          <Spinner />
          <p className="ml-4 text-stone-900">Loading...</p>
        </div>
      )}
      {error && <p>Error: {error.message}</p>}
      {selectedNewsletter ? (
        <div className="mx-auto max-w-6xl p-4">
          <NewsletterDetails newsletter={selectedNewsletter} />
        </div>
      ) : (
        issues && (
          <div className="mx-auto max-w-6xl p-4">
            <h1 className="my-16 text-center font-blackstone text-6xl font-bold">
              Newsletter Archives
            </h1>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {issues.map((issue) => (
                <NewsletterPreview key={issue.id} newsletter={issue} />
              ))}
            </Masonry>
          </div>
        )
      )}
    </div>
  );
};

export default NewsletterArchivePage;
