import { NavLink } from "react-router-dom";
import Chickens1 from "../assets/images/hd/Chickens1.jpeg";
import { Bees1, Chicken6, Mushroom5 } from "../constants/images";

const Offerings = (props: any) => {
  return (
    <div>
      <div className="grid grid-cols-12 [direction:ltr] sm:[direction:rtl]">
        <img
          className="col-span-12 aspect-square h-full w-full object-cover object-top sm:col-span-6"
          src={Chickens1}
          alt="chickens"
        />

        <div className="col-span-12 flex flex-col items-center justify-center bg-gray-800 p-16 text-center [direction:ltr] sm:col-span-6 sm:aspect-square">
          <div className="flex max-w-xl flex-col items-center justify-center space-y-6 text-center">
            <h1 className="w-full font-blackstone text-6xl lowercase tracking-wider text-stone-300">
              Our Offerings
            </h1>
            <h2 className="font-serif text-2xl font-light text-stone-200">
              Maranatha Farm provides high-quality, pastured eggs and
              small-batch specialty goods and produce including baked goods with
              our own eggs, and hardwood grown mushrooms.
            </h2>
            <article className="space-y-4 text-lg font-light leading-relaxed tracking-wide text-stone-100">
              <p>
                We take pride in our commitment to raising our flock in an
                ethical and sustainable manner, providing them with ample access
                to fresh pasture, clean water, and a nutritious diet. As a
                result, our eggs are not only delicious but also packed with
                essential nutrients.
              </p>
              <p>
                We work hard to minimize our environmental impact and are
                committed to giving back to our community. We regularly donate
                surplus produce to{" "}
                <a
                  className="font-normal text-zinc-100 hover:border-b hover:border-slate-300 hover:text-slate-300"
                  href="https://www.elijahspromise.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Elijah's Promise Community Kitchen
                </a>{" "}
                to ensure that our food reaches those in need.
              </p>
            </article>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12">
        <img
          className="col-span-12 aspect-square h-full w-full object-cover object-top sm:col-span-6"
          src={Mushroom5}
          alt="mushroom"
        />

        <section className="col-span-12 flex h-fit flex-col items-center justify-center bg-stone-100 p-16 sm:col-span-6 sm:aspect-square">
          <div className="flex max-w-sm flex-col items-center justify-center space-y-6 text-center">
            <h1 className="w-full text-center font-blackstone text-6xl lowercase tracking-wider text-gray-800">
              meet the makers
            </h1>
            <h2 className="font-serif text-2xl font-light text-gray-700">
              Learn more about the local makers and artisans in our community
            </h2>
            <NavLink
              to="/makers"
              className="bg-stone-800 px-6 py-4 text-sm font-semibold uppercase tracking-wider text-white hover:bg-stone-700"
            >
              Meet The Makers
            </NavLink>
          </div>
        </section>
      </div>

      <div className="grid grid-cols-12 [direction:ltr] sm:[direction:rtl]">
        <img
          className="col-span-12 aspect-square h-full w-full object-cover object-top sm:col-span-6"
          src={Chicken6}
          alt="chicken"
        />

        <section className="col-span-12 flex flex-col items-center justify-center bg-gray-800 p-16 sm:col-span-6 sm:aspect-square">
          <div className="flex max-w-sm flex-col items-center justify-center space-y-6 text-center">
            <h1 className="w-full text-center font-blackstone text-6xl lowercase tracking-wider text-stone-300">
              Current Offerings
            </h1>
            <h2 className="font-serif text-2xl font-light text-stone-200">
              To browse all of our currently available offerings, be sure to
              check out our farm store
            </h2>
            <NavLink
              to="/shop"
              className="bg-stone-300 px-6 py-4 text-sm font-semibold uppercase tracking-wider hover:bg-stone-400"
            >
              farm store
            </NavLink>
          </div>
        </section>
      </div>

      <div className="grid grid-cols-12">
        <img
          className="col-span-12 aspect-square h-full w-full object-cover object-top sm:col-span-6"
          src={Bees1}
          alt="bees"
        />

        <section className="col-span-12 flex h-fit flex-col items-center justify-center bg-stone-200 p-16 sm:col-span-6 sm:aspect-square">
          <div className="flex max-w-sm flex-col items-center justify-center space-y-6 text-center">
            <h1 className="w-full text-center font-blackstone text-6xl lowercase tracking-wider text-gray-800">
              Future Offerings
            </h1>
            <h2 className="font-serif text-2xl font-light text-gray-700">
              Looking ahead, we are working on offering the following from our
              farm:
            </h2>
            <ul className="text-lg font-light">
              <li>
                Hardwood grown Oyster, Nameko, Lion's Mane &amp; Chestnut
                mushrooms
              </li>
              <li>Apples, Peaches, Pears</li>
              <li>Currants &amp; Elderberries</li>
              <li>Raspberries &amp; Blackberries</li>
              <li>Pasture-raised lamb</li>
              <li>Maple syrup</li>
              <li>Figs</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Offerings;
