import ModalContainer from ".";
import {
  useOpenModal,
  useOpenModalCount,
  usePopModal,
} from "../../redux/state/modals/hooks";
import { ModalType } from "../../redux/state/modals/types";
import NewsletterForm from "../Forms/NewsletterSignupForm";

const NewsletterSignupModal = (props: any) => {
  const { id, title, message, onClose, onConfirm } = props;

  const popModal = usePopModal();

  const openModalCount = useOpenModalCount();

  const newsletterModal = useOpenModal(ModalType.NEWSLETTER_SIGNUP);

  return (
    newsletterModal && (
      <ModalContainer
        dismissible={true}
        scrollable={true}
        index={openModalCount + 1}
        type={ModalType.NEWSLETTER_SIGNUP}
      >
        <div className="max-w-xl space-y-4 p-8 md:p-16">
          <h1 className="w-full text-center font-serif text-4xl">
            Are you on the list?
          </h1>
          <p className="w-full max-w-sm text-center font-serif tracking-wide">
            Be the first to hear about our latest offerings, seasonal recipes,
            and goings-on around the farm.
          </p>
          <NewsletterForm label={false} theme={"dark"} />
        </div>
      </ModalContainer>
    )
  );
};

export default NewsletterSignupModal;
