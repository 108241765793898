import {
  CheckmarkCircle,
  ErrorCircle,
} from "@styled-icons/fluentui-system-filled";
import { Close } from "@styled-icons/ionicons-solid";
import { useLayoutEffect } from "react";
import { animated, useSpring } from "react-spring";
import { useAppDispatch } from "../../redux/hooks";
import { useToasts } from "../../redux/state/toasts/hooks";

import { removeToast } from "../../redux/state/toasts/reducer";

const Toasts = () => {
  const dispatch = useAppDispatch();
  const toasts = useToasts();

  return (
    <ul className="fixed bottom-4 right-4 z-[999] flex h-auto w-96 max-w-fit flex-col items-center space-y-2">
      {toasts.map((toast) => {
        const { id } = toast;
        return (
          <Toast
            key={id}
            {...toast}
            onDismissClick={() => {
              dispatch(removeToast(id));
            }}
          />
        );
      })}
    </ul>
  );
};

export default Toasts;

interface ToastProps {
  id: number;
  code: number;
  title: string;
  body: string;
  onDismissClick: () => any;
}

const Toast = ({ id, code, title, body, onDismissClick }: ToastProps) => {
  const dispatch = useAppDispatch();

  const style = {
    from: {
      opacity: 0,
      transform: "translateX(100%)",
    },
    to: {
      opacity: 1,
      transform: "translateX(0%)",
    },
  };

  const [springProps, springApi] = useSpring(() => style);

  useLayoutEffect(() => {
    springApi.start(style);

    setTimeout(() => {
      springApi.start({
        from: { opacity: 1, transform: "translateX(0%)" },
        to: { opacity: 0, transform: "translateX(100%)" },
      });

      setTimeout(() => {
        dispatch(removeToast(id));
      }, 500);
    }, 10000);
  }, []);

  return (
    <animated.li
      role="alert"
      style={springProps}
      className={`w-full max-w-xs rounded border-l-4 bg-white p-2 shadow-md ${
        code >= 0 ? "border-teal-500" : "border-red-500"
      }`}
    >
      <button
        onClick={() => onDismissClick()}
        className="absolute top-2 right-2 cursor-pointer text-zinc-800"
      >
        <Close className="icon" />
      </button>

      <div className={`grid grid-cols-12`}>
        <div className="col-span-1 col-start-1 flex h-full items-center justify-center pr-2">
          {code >= 0 ? (
            <CheckmarkCircle className="icon text-teal-800" />
          ) : (
            <ErrorCircle className="icon text-red-800" />
          )}
        </div>

        <p className={`col-span-10 col-start-2 flex flex-col space-y-1`}>
          <strong
            className={`block text-sm ${
              code >= 0 ? "text-teal-800" : "text-red-800"
            }`}
          >
            {title}
          </strong>

          <p className={`text-sm text-gray-800`}>{body}</p>
        </p>
      </div>
    </animated.li>
  );
};
