export function toSingular(string: string) {
  return string.endsWith("s") ? string.substring(0, string.length - 1) : string;
}

/**
 * capitalizes a string and replaces dashes with whitespace
 * @param string
 * @returns
 */
export function toFriendlyCase(string: string) {
  return string
    .replace(/\b[a-z]/g, (char) => char.toUpperCase())
    .replace(/-/g, " ");
}

export function getRandomString(length: number): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let result = "";
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  for (let i = 0; i < length; i++) {
    result += characters.charAt(array[i] % charactersLength);
  }
  return result;
}

export function urlString(value: string): string {
  return value.toLowerCase().replace(" ", "-");
}

export function formatPhoneNumber(phoneNumber: string) {
  const formattedPhoneNumber = phoneNumber.replace(
    /^(\+\d{1})?(\d{3})(\d{3})(\d{4})$/,
    "$1 ($2) $3-$4"
  );
  return formattedPhoneNumber;
}

export function getReadableList(array: string[]) {
  if (array.length === 0) return "";
  else if (array.length === 1) return array[0];
  else if (array.length === 2) return array.join(" and ");
  else {
    let last = array.pop();
    return `${array.join(", ")}, and ${last}`;
  }
}

/**
 * Converts a string to a url-safe slug
 * @param string
 * @returns
 */
export function getUrlSlug(string: string) {
  return string.toLowerCase().replaceAll(" ", "-").replaceAll("'", "");
}
