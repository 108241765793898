import { Close } from "@styled-icons/ionicons-solid";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { animated, useTransition } from "react-spring";
import { usePopModal } from "../../redux/state/modals/hooks";
import { OpenModal } from "../../redux/state/modals/types";

const ModalContainer = ({
  index,
  type,
  hasMask = false,
  scrollable = false,
  dismissible = false,
  onClose,
  children,
}: Omit<OpenModal, "id"> & {
  index: number;
  onClose?: (...args: any[]) => any;
  children: React.ReactNode;
}) => {
  const popModal = usePopModal(type);
  const zIndex = index * 100;

  const handleClose = async () => {
    console.log(`Closing ${type}`);
    if (onClose) {
      await onClose();
    }
    popModal();
  };

  const transition = useTransition(true, {
    config: {
      tension: 120,
      friction: 17,
    },
    from: {
      display: "none",
      opacity: 0,
      transform: "translate(-50%, -50%) scale(0.35)",
    },
    enter: {
      display: "flex",
      opacity: 1,
      transform: "translate(-50%, -50%) scale(1)",
    },
  });

  useEffect(() => {
    // disable scrolling on the body when the mask is open
    document.body.style.overflow = !scrollable ? "hidden" : "unset";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [scrollable]);

  return ReactDOM.createPortal(
    transition(({ display, opacity, transform }, isOpen) => (
      <>
        {/* A mask to cover the background, when clicked will invoke the handleClose callback */}

        <animated.div
          className={`${
            hasMask ? "mask" : "fixed top-0 left-0 bottom-0 right-0"
          }`}
          style={{ display: display, opacity: opacity, zIndex: zIndex - 10 }}
          onClick={async () => {
            if (dismissible) {
              await handleClose();
            }
          }}
        ></animated.div>

        <animated.dialog
          className="hide-scroll fixed top-1/2 left-1/2 m-0 flex max-h-[95%] w-fit max-w-[90%] flex-col justify-start overflow-y-scroll rounded-md bg-zinc-50 p-4 shadow-xl"
          style={{ opacity: opacity, transform: transform, zIndex: zIndex }}
          onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
        >
          <Close
            size={30}
            onClick={handleClose}
            className="icon-lg absolute top-0 right-0 m-3 hover:cursor-pointer"
          />

          {children}
        </animated.dialog>
      </>
    )),
    document.getElementById(`${type}-root`) as HTMLElement
  );
};

export default ModalContainer;
