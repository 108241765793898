import { Card, Title } from "@tremor/react";
import { NavLink } from "react-router-dom";
import DashboardSection from "../../components/DashboardSection";
import LineItems from "../../components/LineItems";
import Table, { RowData } from "../../components/Table";
import { auth } from "../../config/firebase";
import { OrderDateCreated } from "../../helpers/OrderHelpers";
import { useSearchOrdersQuery } from "../../redux/api/orders/api";

interface Props {}

const MyOrders = ({}: Props) => {
  const {
    data: myOrders,
    isLoading: loadingMyOrders,
    isFetching: fetchingEmailOrders,
    error: emailOrdersError,
  } = useSearchOrdersQuery({
    uid: auth.currentUser?.uid,
  });

  const tableHeaders = ["Order #", "Type", "Date", "Total", "Status"];
  const tableRowData: RowData[] =
    myOrders?.map((order) => {
      const statusColor = order.fulfilled ? "text-teal-600" : "text-red-600";
      return {
        id: order.orderId,
        colValues: [
          <span className="font-bold" key={order.orderId}>
            {order.orderId}
          </span>,
          <span key={`${order.orderId}-type`} className="capitalize underline">
            {order.type}
          </span>,
          <span key={`${order.orderId}-date`}>
            {OrderDateCreated(order, "short")}
          </span>,
          <span key={`${order.orderId}-total`}>${order.total.toFixed(2)}</span>,
          <span
            className={`${statusColor} font-bold`}
            key={`${order.orderId}-status`}
          >
            {order.fulfilled ? "FULFILLED" : "UNFULFILLED"}
          </span>,
        ],
        children: (
          <div className="w-1/2 p-2">
            <LineItems items={order.items} />
          </div>
        ),
      };
    }) ?? [];

  return (
    <DashboardSection title={"My Orders"} loading={loadingMyOrders} order={1}>
      {myOrders?.length === 0 && (
        <div className="mx-auto my-10 text-center text-lg">
          It looks like you haven't placed any orders yet. Visit our&nbsp;
          <NavLink to="/shop" className="font-bold">
            farm store
          </NavLink>{" "}
          page to place an order!
        </div>
      )}

      {myOrders && myOrders?.length > 0 && (
        <Card className="space-y-4">
          <Title>Past Orders</Title>
          <Table headers={tableHeaders} rowData={tableRowData} />
        </Card>
      )}
    </DashboardSection>
  );
};

export default MyOrders;
