import NewsletterForm from "../components/Forms/NewsletterSignupForm";
import { Logo } from "../components/Logo";
import { Forest1 } from "../constants/images";

const NewsletterSignUpPage = () => {
  return (
    <div className="bg-zinc-50 dark:bg-gray-900 lg:h-[calc(100vh_-_96px)]">
      <div className="flex h-screen flex-col md:flex-row">
        <div
          className="h-1/2 w-full bg-cover bg-center md:h-full md:w-1/2"
          style={{ backgroundImage: `url(${Forest1})` }}
        ></div>

        <div className="mt-8 flex w-full flex-col items-center justify-start p-8 md:w-1/2">
          <div className="flex max-w-lg flex-col space-y-8">
            <div className="w-52 self-center">
              <Logo style={"color"} />
            </div>

            <h1 className="mt-6 font-blackstone text-4xl font-bold text-zinc-700 md:text-5xl">
              subscribe to our newsletter
            </h1>

            <p className="font-serif text-gray-700 dark:text-gray-300">
              Receive the latest information about new products and offering
              updates, goings on around the farm, and seasonal recipes.
            </p>

            <div>
              <NewsletterForm label={false} theme={"dark"} />

              <p className="mt-4 text-center text-sm text-gray-400">
                We respect your privacy
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSignUpPage;
