import DashboardSection from "../../../../components/DashboardSection";
import { Offering } from "../../../../redux/api/offerings/types";

interface Props {}

const ActiveSubscriptions = ({}: Props) => {
  // Hooks
  const allOfferings: Offering[] = [];

  return (
    <DashboardSection
      title={""}
      loading={false}
      loadingMessage={"Loading subscriptions..."}
      order={3}
    >
      <div>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {allOfferings
            // Filter offerings that have at least one option with an active subscription
            .filter((offering) =>
              offering.options.some(
                (option) =>
                  option.subscription?.enabled &&
                  option.subscription?.totalSubscribers
              )
            )
            .map((offering) => {
              const options = offering.options;

              const optionSubscriptions = options
                .filter(
                  (option) =>
                    option.subscription?.totalSubscribers ||
                    option.subscription?.renewingThisWeek
                )
                .map((option) => {
                  if (!option.subscription) return null;

                  return (
                    <div className="flex items-start gap-3">
                      <img
                        src={option.image}
                        className="h-12 w-12 rounded-full object-cover"
                        alt={offering.name}
                      />
                      <li
                        key={`${option.name}-stats`}
                        className="relative py-1"
                      >
                        <h2 className="mb-2 w-fit text-sm">
                          {option.name}, ${option.price.toFixed(2)}
                        </h2>
                        <ul className="ml-2 w-full space-y-1 text-sm">
                          <li className="space-x-1">
                            <span className="font-bold text-black">
                              {option.subscription.totalSubscribers ?? 0}
                            </span>
                            <span>Active</span>
                          </li>
                          <li className="space-x-1">
                            <span className="font-bold text-black">
                              {option.subscription.renewingThisWeek ?? 0}
                            </span>
                            <span>Renewing this week</span>
                          </li>
                        </ul>
                      </li>
                    </div>
                  );
                });

              return (
                <li
                  key={`${offering.name}-subscription-stats`}
                  className="overflow-hidden"
                >
                  <div className="space-y-4 p-4">
                    <h1 className="w-fit rounded-full bg-stone-200 px-4 py-1 font-bold leading-6 shadow-sm">
                      {offering.name}
                    </h1>
                    <ul className="mt-1 space-y-1">{optionSubscriptions}</ul>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </DashboardSection>
  );
};

export default ActiveSubscriptions;
