import { Field, Form, Formik, FormikHelpers } from "formik";
import { NavLink } from "react-router-dom";
import { useAuthentication } from "../../../hooks/useAuthentication";
import { useAddSubscriberMutation } from "../../../redux/api/newsletter/api";
import { FormikAdminInput } from "../../Formik";
import {
  REGISTER_CONFIRM_PASSWORD,
  REGISTER_EMAIL,
  REGISTER_FIRST_NAME,
  REGISTER_LAST_NAME,
  REGISTER_NEWSLETTER_SIGNUP,
  REGISTER_PASSWORD,
} from "./fieldNames";
import initialValues, { RegisterFormValues } from "./initialValues";
import validationSchema from "./validationSchema";

const RegisterForm = () => {
  const { handleEmailRegister } = useAuthentication();
  const [triggerAddSubscriber] = useAddSubscriberMutation();

  async function handleFormSubmit(
    values: RegisterFormValues,
    helpers: FormikHelpers<RegisterFormValues>
  ) {
    const name = `${values.firstName} ${values.lastName}`;
    await handleEmailRegister(name, values.email, values.password);
    if (values.newsletterSignup) {
      await triggerAddSubscriber({ email: values.email });
    }
    helpers.resetForm();
    helpers.setSubmitting(false);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form
          className="grid grid-cols-6 gap-2"
          onSubmit={(e: any) => {
            e.preventDefault();

            props.validateForm();
            props.handleSubmit();
          }}
          noValidate
        >
          <div className="col-span-6 sm:col-span-3">
            <Field
              type="text"
              name={REGISTER_FIRST_NAME}
              title="First Name"
              component={FormikAdminInput}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <Field
              type="text"
              name={REGISTER_LAST_NAME}
              title="Last Name"
              component={FormikAdminInput}
            />
          </div>

          <div className="col-span-6">
            <Field
              type="email"
              name={REGISTER_EMAIL}
              title="Email"
              component={FormikAdminInput}
            />
          </div>

          <div className="col-span-6">
            <Field
              type="password"
              name={REGISTER_PASSWORD}
              title="Password"
              component={FormikAdminInput}
            />
          </div>

          <div className="col-span-6">
            <Field
              type="password"
              name={REGISTER_CONFIRM_PASSWORD}
              title="Confirm Password"
              component={FormikAdminInput}
            />
          </div>

          <div className="col-span-6">
            <label htmlFor={REGISTER_NEWSLETTER_SIGNUP} className="flex gap-4">
              <Field
                type="checkbox"
                id={REGISTER_NEWSLETTER_SIGNUP}
                name={REGISTER_NEWSLETTER_SIGNUP}
                className="h-5 w-5 rounded-md border-stone-200 bg-white accent-slate-600 shadow-sm"
              />

              <span className="text-sm text-stone-700">
                I want to receive product updates and farm announcements.
              </span>
            </label>
          </div>

          <div className="col-span-6">
            <p className="text-sm">
              By creating an account, you agree to our&nbsp;
              <NavLink
                to="/terms-and-conditions"
                className="text-teal-500 hover:text-teal-600 hover:underline"
              >
                terms and conditions
              </NavLink>
              &nbsp;and&nbsp;
              <NavLink
                to="/privacy-policy"
                className="text-teal-500 hover:text-teal-600 hover:underline"
              >
                privacy policy
              </NavLink>
              .
            </p>
          </div>

          <div className="col-span-6 flex flex-col items-center justify-center">
            <button
              type="submit"
              className="font-medium inline-block w-full rounded bg-stone-700 px-12 py-3 text-sm text-white shadow-sm transition-all hover:bg-stone-800 hover:shadow-lg"
            >
              Register
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
