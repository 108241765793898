import { ExternalLink } from "@styled-icons/fa-solid";
import { Card, Title } from "@tremor/react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import CrudTable, { RowData } from "../../components/CrudTable";
import DashboardSection from "../../components/DashboardSection";
import { DropdownItem } from "../../components/Dropdown";
import UpsertMakerForm from "../../components/Forms/UpsertMakerForm";
import {
  useDeleteMakerMutation,
  useRetrieveMakersQuery,
} from "../../redux/api/makers/api";
import { Maker } from "../../redux/api/makers/types";
import { ModalType } from "../../redux/state/modals/types";

const Makers = () => {
  const { data, isLoading, isFetching, error } = useRetrieveMakersQuery({}, {});

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [rows, setRows] = useState<RowData[]>([]);

  const [triggerDeleteMaker] = useDeleteMakerMutation();

  const handleDeleteMaker = async (id: string | null) => {
    console.log(`Deleting maker with id ${id}`);
    if (!id) return;
    const res = await triggerDeleteMaker(id);
    if (res) {
      console.log(res);
    }
  };

  console.log(data);

  useEffect(() => {
    if (data) {
      const rowData = data?.map((maker: Maker) => {
        const makerSearchIndices = [maker.name];
        return MakerRowData(maker, [], makerSearchIndices);
      });
      setRows(rowData);
    }
  }, [data]);

  return (
    <DashboardSection title={""} loading={isLoading} order={0}>
      <div className="space-y-4">
        <Title>Makers</Title>
        <Card>
          <CrudTable
            loading={isLoading || isFetching}
            modalType={ModalType.MAKER_CRUD}
            rows={rows}
            crudFormFactory={(id: string | null) => (
              <UpsertMakerForm makerId={id} />
            )}
            deleteAction={handleDeleteMaker}
            setSearchQuery={setSearchQuery}
          ></CrudTable>
        </Card>
      </div>
    </DashboardSection>
  );
};

const MakerRowData = (
  maker: Maker,
  actions: DropdownItem[],
  searchIndices: string[]
): RowData => {
  console.log(maker);

  const data = (
    <div className="grid h-full grid-cols-12">
      <div className="col-span-12 flex h-full whitespace-nowrap p-4 md:col-span-6">
        <div className="flex h-full max-w-full flex-col items-start justify-between space-y-2">
          {/* Name */}
          <h1 className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-xl font-bold text-stone-700">
            {maker.name}
          </h1>

          <div className="flex flex-col gap-4">
            {maker.websiteUrl && (
              <p className="flex-0 group flex h-fit w-fit items-center gap-2 text-lg hover:text-teal-600">
                <a
                  href={maker.websiteUrl}
                  target="__blank"
                  className="rounded-md bg-teal-700 px-2 py-1 text-sm text-white transition-colors group-hover:bg-teal-600"
                >
                  Website
                </a>
                <ExternalLink className="h-3 w-3" />
              </p>
            )}
            <p className="flex-0 group flex h-fit w-fit items-center gap-2 text-lg hover:text-sky-700">
              <NavLink
                to={`/makers/${maker.slug}`}
                className="rounded-md bg-sky-700 px-2 py-1 text-sm text-white transition-colors group-hover:bg-sky-600"
              >
                Maker Page
              </NavLink>
              <ExternalLink className="h-3 w-3" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return {
    id: maker.id,
    displayName: maker.name,
    imageUrl: maker.logoUrl,
    data: data,
    actions: actions,
    searchIndices: searchIndices,
  };
};

export default Makers;
