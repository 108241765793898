import {
  IS_PREVIEW,
  NEWSLETTER_MESSAGE,
  NEWSLETTER_SUBJECT,
  PREVIEW_RECIPENTS,
} from "./fieldNames";

export interface TriggerNewsletterValues {
  [IS_PREVIEW]: boolean;
  [NEWSLETTER_SUBJECT]: string;
  [NEWSLETTER_MESSAGE]: string;
  [PREVIEW_RECIPENTS]: string;
}

export const initialValues: TriggerNewsletterValues = {
  [IS_PREVIEW]: false,
  [NEWSLETTER_SUBJECT]: "",
  [NEWSLETTER_MESSAGE]: "",
  [PREVIEW_RECIPENTS]: "",
};
