import { OfferingType } from "../offerings/types";

export const MonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
] as const;

export type MonthNamesType = (typeof MonthNames)[number];

export interface FulfillmentReports {
  packingReport: PackingReportResponse;
  pickingReport: PickingReport;
}

export interface PackingReport {
  orders: CustomerOrder[];
}

interface Address {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postal_code: string;
}

export interface CustomerOrder {
  customer: Customer;
  items: OrderItem[];
  total: number;
  address?: Address;
  fulfillmentOption?: string;
  shippingType?: string;
  shippingAddress?: Address;
  pickupLocation?: string;
}

export interface Customer {
  name: string;
  email: string;
  phone: string;
}

export interface OrderItem {
  offeringName: string;
  fullName: string;
  type: OfferingType;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

export interface PackingReportResponse {
  base64: string;
  report: {
    orders: CustomerOrder[];
  };
}

export interface PickingReport {
  base64: string;
  report: {
    items: OrderItem[];
  };
}

export interface RevenueReports {
  yearlyRevenueReport: YearlyRevenueReport;
  productRevenueReport: ProductRevenueReport;
}

export type MonthlyProductData = Record<
  MonthNamesType,
  {
    [product: string]: {
      totalAmount: number;
      quantity: number;
    };
  }
>;

export type MonthlyTotals = Record<MonthNamesType, number>;

export interface YearlyRevenueReport {
  base64: string;
  report: {
    monthlyProductData: MonthlyProductData;
    monthlyTotals: MonthlyTotals;
  };
}

export interface ProductRevenueReport {
  base64: string;
  report: {
    yearlyProductData: {
      [key: string]: {
        totalAmount: number;
        quantity: number;
      };
    };
    yearlyTotals: number;
  };
}
