import * as Yup from "yup";
import { PhoneNumber, ValidEmail } from "../../../constants/regex";
import {
  CONTACT_INQUIRY_COMMENTS,
  CONTACT_INQUIRY_EMAIL,
  CONTACT_INQUIRY_FIRST_NAME,
  CONTACT_INQUIRY_LAST_NAME,
  CONTACT_INQUIRY_PHONE,
} from "./fieldNames";

export const ContactUsSchema = Yup.object().shape({
  [CONTACT_INQUIRY_FIRST_NAME]: Yup.string()
    .min(2, "Please provide your first name")
    .max(50, "First name is too long")
    .required("Please provide your first name"),
  [CONTACT_INQUIRY_LAST_NAME]: Yup.string()
    .min(2, "Please provide your last name")
    .max(50, "Last name is too long")
    .required("Please provide your last name"),
  [CONTACT_INQUIRY_EMAIL]: Yup.string()
    .trim()
    .matches(ValidEmail, {
      message: "Invalid email address",
      excludeEmptyString: true,
    })
    .required("Please provide an email address"),
  [CONTACT_INQUIRY_PHONE]: Yup.string().matches(
    PhoneNumber,
    "Invalid phone number"
  ),
  [CONTACT_INQUIRY_COMMENTS]: Yup.string()
    .min(5, "Your message is too short")
    .max(1500, "Your message is too long")
    .required("What is your inquiry about?"),
});
