import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import TransitDistanceIcon from "../components/TransitDistanceIcon";

import { ChevronDown } from "@styled-icons/fluentui-system-regular";
import { animated, useTransition } from "react-spring";
import { AllSocialAccounts, SocialAccountInfo } from "../constants/SocialMedia";
import {
  AllTransitDistances,
  TransitDistance,
  TransitDistanceLabels,
} from "../redux/api/offerings/types";
import { useToggleNewsletterSignupModal } from "../redux/state/modals/hooks";

interface Props {}

const FaqPage = ({}: Props) => {
  const location = useLocation();

  const toggleNewsletterSignupModal = useToggleNewsletterSignupModal();

  const transitDistances = AllTransitDistances.filter(
    (d) => d !== TransitDistance.None
  );

  useEffect(() => {
    const hash = location.hash;

    if (hash && hash !== "#") {
      const id = decodeURIComponent(hash.substring(1));

      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]); // Re-run the effect when the location changes

  return (
    <div className="mb-52 bg-stone-50">
      <section className="container mx-auto mt-12 flex max-w-4xl flex-col items-center space-y-8 bg-gray-100 px-6 py-10 shadow-md">
        <div>
          <h1 className="my-4 font-serif text-3xl">
            Frequently Asked Questions
          </h1>
        </div>

        <div>
          <FaqSection
            title={
              <span>
                Can I come to your farm to buy things? Is Maranatha Farm open to
                the public?
              </span>
            }
          >
            <div className="space-y-5">
              <p className="leading-loose">
                At this time, we do not have public “open” hours on our farm.
                Maranatha Farm is currently closed to the public.
              </p>
              <p className="leading-loose">
                Items may be purchased via our{" "}
                <NavLink
                  to="/shop"
                  className="font-bold text-slate-600 hover:border-b hover:border-slate-600"
                >
                  farm store page
                </NavLink>
                , and picked up on Wednesdays from 2:30-5:30 P.M. in our farm
                store at 75 Willow Avenue in Peapack. We do have a limited
                number of items for sale in our physical store that can be
                purchased during normal store hours on Wednesdays. Store access
                is also available by appointment. Please contact{" "}
                <a
                  className="font-bold text-slate-600 hover:border-b hover:border-slate-600"
                  href="mailto:info@maranatha.farm"
                >
                  info@maranatha.farm
                </a>{" "}
                to schedule or ask a question. Thank you for your interest.
              </p>
            </div>
          </FaqSection>

          <FaqSection
            id="purchase-options"
            title={<span>How can I purchase your products?</span>}
          >
            <div className="space-y-5">
              <p className="leading-loose">
                Our primary method of selling our products is through our{" "}
                <NavLink
                  to="/shop"
                  className="font-bold text-slate-600 hover:border-b hover:border-slate-600"
                >
                  online farm store
                </NavLink>
                . We currently have two options for receiving your goods: Farm
                Store Pickup from early April until early December, and Delivery
                for a $10 additional charge. If you live within 15 miles of the
                farm and would like delivery, you will be asked to choose this
                option upon checkout. If you would prefer to pick up your order
                when our farm store is closed, please contact us directly and we
                are happy to accommodate your request.
              </p>
              <p className="leading-loose">
                We do not currently ship any items, but if you are interested in
                shipping please email us so that we can track demand for this
                service. More information about online ordering can be found on
                our Farm Store page. The farm store is updated weekly. Email
                subscribers are the first to know about product updates.
              </p>
            </div>
          </FaqSection>

          <FaqSection
            id="transit-distance-icons"
            title={
              <div className="h-fit items-center space-x-1 sm:flex">
                What do the following icons mean on your product pages?&emsp;
                {transitDistances.map((distance) => (
                  <span
                    className="m-0 inline-flex h-full w-min items-center"
                    key={distance}
                  >
                    <TransitDistanceIcon distance={distance} />
                  </span>
                ))}
              </div>
            }
          >
            <div className="space-y-5">
              <p className="leading-loose">
                In addition to creating awareness about how our food is grown,
                we would like to raise your awareness for how far your food has
                to travel to get to your plate.
              </p>
              <p className="leading-loose">
                Each icon serves as a visual representation of our commitment to
                sustainability and the partnerships we have established with
                organizations that share our passion for responsible and
                environmentally-friendly food production. We are very grateful
                for the collaborations we have fostered with organizations like
                ours who are passionate about sustainability.
              </p>
              <ul
                className="grid w-full grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-5"
                role="group"
              >
                {transitDistances.map((distance) => (
                  <li
                    key={distance}
                    className="inline-flex h-full w-full flex-col rounded-sm border border-slate-400 bg-white px-3 pt-3 text-slate-600 hover:bg-slate-100 hover:text-slate-700 peer-checked:border-slate-600 peer-checked:text-slate-600 peer-checked:ring peer-checked:ring-slate-600/40 dark:border-slate-400 dark:bg-slate-400 dark:text-slate-400 dark:hover:bg-slate-400 dark:hover:text-slate-400 dark:peer-checked:text-slate-500"
                  >
                    <div className="text-lg font-semibold capitalize">
                      {distance}
                    </div>
                    <div className="text-xs text-slate-500">
                      {TransitDistanceLabels[distance]}
                    </div>
                    <div className="my-1 flex h-full w-full items-center text-slate-600">
                      <TransitDistanceIcon distance={distance} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </FaqSection>

          <FaqSection title={<span>What are your growing practices?</span>}>
            <span className="leading-loose">
              Our farming style is regenerative, or beyond organic. In our
              Orchard, we have begun using Biodynamic and Holistic methods. We
              work with Mother Nature&rsquo;s tried and true systems to focus on
              biodiverse microclimates, thriving soils, and sustainably-grown
              and nutrient-dense crops. Absolutely no chemical fertilizers,
              pesticides, or herbicides are used - for our family&rsquo;s
              health, and for yours! We utilize sustainable practices like
              composting, hügelkultur, crop rotation, cover cropping, and
              tarping garden beds to let our soils rest. To encourage
              biodiversity and resist monoculture practices, we interplant many
              of our crops, and care for twelve beehives set up in the Apiary on
              our acreage.
            </span>
          </FaqSection>

          <FaqSection
            title={
              <span>What is the difference between chicken and duck eggs?</span>
            }
          >
            <ul className="list-inside list-disc space-y-2 leading-loose">
              <li>
                Duck eggs are larger in size than chicken eggs. Typically a
                standard duck egg is 1.5 times larger than a chicken egg. Our
                petite duck eggs are the same size as our standard chicken eggs,
                which is great when using them for baking.
              </li>
              <li>
                The whites in chicken eggs have a higher water content (more
                runny) than the whites in duck eggs.
              </li>
              <li>
                Duck eggs have larger yolks and a higher yolk-to-white-ratio
                than chicken eggs.
              </li>
              <li>
                Duck eggs taste richer, creamier, and more &ldquo;eggy&rdquo;
                than chicken eggs. In our home we like to use one duck egg with
                two chicken eggs to create a fluffy, slightly richer scrambled
                egg or frittata.
              </li>
              <li>Duck eggs are known for creating sky-high baked goods.</li>
            </ul>
          </FaqSection>

          <FaqSection
            title={<span>Why are there different sized eggs offered?</span>}
          >
            <div className="space-y-2 leading-loose">
              <p>
                <b>Chicken eggs:</b> When young chickens known as pullets begin
                laying, their eggs start out smaller. We call them practice eggs
                here around the farm, and over time the eggs get bigger. Since
                pullet eggs are medium sized eggs, we separate them and sell
                them at a lower price. Our standard chicken eggs are more in the
                large to extra large size range.
              </p>
              <p>
                <b>Duck eggs:</b> Since we raise a variety of Heritage breed
                ducks, some of our duck eggs are smaller than others. Generally,
                duck eggs are 1.5 times bigger than a chicken egg. Our Petite
                Duck eggs are very close in size to a chicken egg, so the Petite
                duck eggs can be substituted one for one with chicken eggs.
              </p>
            </div>
          </FaqSection>

          <FaqSection
            title={
              <span>Do I need to refrigerate the eggs from the farm?</span>
            }
          >
            <div className="space-y-2 leading-loose">
              <p>
                Eggs are laid with a nearly invisible natural coating on the
                shell that is applied as the last step in the laying process
                called the &ldquo;bloom&rdquo; or sometimes the
                &ldquo;cuticle&rdquo;.
              </p>
              <p>
                This coating is the first line of defense in keeping air and
                bacteria out of the egg. Since eggshells are porous, if you wash
                your eggs as soon as you collect them, you are removing that
                natural barrier.
              </p>
              <p>
                The bloom helps to keep bacteria from entering into the egg
                white through the shell. It also helps to keep the egg fresher
                longer by restricting the movement of air through the pores in
                the eggshell.
              </p>
              <p>
                Unwashed eggs can be left out on your kitchen counter at room
                temperature for several weeks, where they will still be
                perfectly edible, if not <i>quite</i> as fresh, as when they
                were laid. Many of our customers love to display their colorful
                eggs on the countertop. Pointy side down is best to preserve
                freshness so that the air sac stays at the top of the egg. It's
                also great to have room temperature eggs on stand-by for baking.
              </p>
              <p>
                In a perfect world, chickens&rsquo; eggs would be clean when you
                collect them from the nesting boxes. Certain times of the year
                especially, like Spring (aka mud season) and Fall, small specks
                of dirt, etc. appear on the egg so we wash the egg and
                refrigerate them. This is generally standard procedure for eggs
                purchased in the supermarket and why you will only find
                refrigerated eggs in stores.
              </p>
              <p>
                As a general rule, we do not wash our chicken or duck eggs
                unless there is a speck of dirt or more present, in which case
                we wash them. Ducks, in general, have dirtier eggs by their
                nature and sometimes even lay their eggs outside! Our black
                Cayuga duck Rose occasionally likes to lay her egg under a bush,
                or next to our pond, so it&rsquo;s a bit of an Easter egg hunt
                some days.
              </p>
              <p>
                All of this is to say that when we have washed any of the eggs
                in a carton, we will apply a label that says &ldquo;Washed,
                Please Refrigerate&rdquo;. Refrigerated eggs (washed or not)
                will last for 3-5 weeks in the refrigerator but won't taste as
                fresh as unwashed eggs of the same age. It&rsquo;s always a
                great idea to wash your eggs just before using if you have any
                concerns or are immuno-compromised.
              </p>
            </div>
          </FaqSection>

          <FaqSection
            title={<span>What can I expect if I buy your baked goods?</span>}
          >
            <div className="space-y-2 leading-loose">
              <p>
                At Maranatha Farm, we believe that healthy food should also be
                delicious and as local as possible. The way to achieve that with
                our baked goods is by using as many organic/regeneratively or
                sustainably grown ingredients from our local foodshed as we
                possibly can. In June of 2023 we received our Cottage Food
                license, which allows us to produce our baked goods from our
                Prep Kitchen right here on the farm.
              </p>
              <p>
                We use our own duck and chicken eggs, as well as local fruit and
                flours when available. We replace refined sugar with honey,
                maple syrup, or coconut sugar, based on which sweetener produces
                the best final result. In limited cases, we will use organic
                sugar. We use Organic Valley butter from their Northeast
                regional dairy farm, and healthy cooking oils like Avocado,
                Coconut and Safflower.
              </p>
              <p>
                In the case of our Gluten Free options, please note that we do
                NOT have a dedicated gluten free facility, and we process all of
                our baked goods in the same facility with products containing
                wheat.
              </p>
              <p>
                In collaboration with our professional baker Marissa Coda, we
                spent many months testing our recipes to bring you wholesome
                baked goods that you can feel good about sharing with your
                friends and family. If you have additional questions, please
                feel free to reach out to us on our{" "}
                <NavLink
                  to="/contact"
                  className="bold text-sky-400 underline underline-offset-4 transition-colors hover:text-sky-500"
                >
                  Contact Us
                </NavLink>{" "}
                page.
              </p>
            </div>
          </FaqSection>

          <FaqSection title={<span>How can I stay up to date?</span>}>
            <div className="flex flex-col space-y-8">
              <span className="leading-loose">
                We invite you to follow along on our social media channels! You
                can find us on{" "}
                <a
                  className="font-bold text-slate-600 hover:border-b hover:border-slate-600"
                  href="https://www.instagram.com/maranathafarmnj/"
                  target="__blank"
                >
                  Instagram
                </a>
                {", "}
                <a
                  className="font-bold text-slate-600 hover:border-b hover:border-slate-600"
                  href="https://www.facebook.com/profile.php?id=100090116379423"
                  target="__blank"
                >
                  Facebook
                </a>{" "}
                &{" "}
                <a
                  className="font-bold text-slate-600 hover:border-b hover:border-slate-600"
                  href="https://www.linkedin.com/company/maranathafarm/"
                  target="__blank"
                >
                  LinkedIn
                </a>
                . You can also sign up for our email newsletter. Email
                subscribers are among the first to know when we restock our{" "}
                <NavLink
                  to="/shop"
                  className="font-bold text-slate-600 hover:border-b hover:border-slate-600"
                >
                  online farm store
                </NavLink>{" "}
                and introduce new products.
              </span>

              <h2 className="w-full text-center font-blackstone text-xl font-bold uppercase">
                Follow us
              </h2>

              <ul className="flex items-center justify-center gap-4">
                {AllSocialAccounts.map((account) => {
                  const { name, url, icon } = SocialAccountInfo[account];
                  return (
                    <li key={name}>
                      <a
                        href={url}
                        target="__blank"
                        rel="noopener noreferrer"
                        aria-label={`Follow us on ${name}`}
                        className="flex items-center rounded-full bg-stone-300 p-2 text-gray-50 shadow-lg backdrop-blur transition-colors duration-300 ease-in-out hover:bg-white/50 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900"
                      >
                        {icon(8)}
                      </a>
                    </li>
                  );
                })}
              </ul>

              {/* <section className="flex w-full justify-center">
                <NavLink
                  to="/newsletter-signup"
                  className="w-fit bg-slate-800 p-6 text-white hover:bg-slate-700"
                >
                  Subscribe to our Newsletter
                </NavLink>
              </section> */}
            </div>
          </FaqSection>
        </div>

        <div className="flex flex-col items-center justify-center gap-4 md:flex-row">
          <div className="flex w-1/2 max-w-md flex-1 flex-col space-y-8 py-10 text-center font-serif text-base">
            <h2 className="font-blackstone text-3xl font-semibold sm:text-5xl">
              Stay connected with us!
            </h2>
            <p className="leading-8">
              Get exclusive updates, stories, and more. Our monthly newsletter
              is packed with heartwarming tales, farm updates, and special
              offers just for our subscribers.
            </p>
            <button
              className="ml-4 w-fit self-center rounded bg-sky-100 px-6 py-4 font-sans uppercase tracking-widest text-black shadow-lg transition-all duration-500 hover:translate-y-[2px] hover:bg-sky-500 hover:text-white hover:shadow-sm"
              onClick={() => toggleNewsletterSignupModal()}
            >
              Subscribe now
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

interface FaqSectionProps {
  id?: string;
  title: JSX.Element;
  children?: JSX.Element;
}

const FaqSection = ({ id, title, children }: FaqSectionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  const transition = useTransition(isOpen, {
    from: { opacity: 0, maxHeight: "0px" },
    enter: { opacity: 1, maxHeight: "9999px" },
    leave: { opacity: 0, maxHeight: "0px" },
  });

  const { hash } = useLocation();

  useEffect(() => {
    if (id && hash.toLowerCase() === `#${id}`.toLowerCase()) {
      setIsOpen(true);
    }
  }, [id, hash]);

  return (
    <div className="max-w-[800px] rounded-sm border-b-2 border-gray-200 bg-gray-100 md:min-w-[800px]">
      <div
        className="flex w-full cursor-pointer items-center justify-start gap-4 p-4 transition-colors hover:bg-gray-200"
        onClick={toggleOpen}
      >
        <ChevronDown
          className={`h-8 w-8 ${
            isOpen && "-rotate-180"
          } transition-all duration-200`}
        />
        <h1 className="text-left text-lg font-bold text-slate-700">{title}</h1>
      </div>

      {transition(
        (style, item) =>
          item && (
            <animated.div style={style} className="h-fit">
              <div className="px-4 py-4 pb-6 font-serif text-lg leading-loose tracking-wide text-slate-700">
                {children}
              </div>
            </animated.div>
          )
      )}
    </div>
  );
};

export default FaqPage;
