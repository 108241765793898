import { Card } from "@tremor/react";
import DashboardSection from "../../../../components/DashboardSection";
import { useFetchActiveSubscriptionsQuery } from "../../../../redux/api/stripe/api";
import SubscriptionsTable from "./Table";

interface Props {}

const CustomerSubscriptions = ({}: Props) => {
  // Redux APIs
  const activeSubscriptionsQuery = useFetchActiveSubscriptionsQuery(null);
  const {
    data: activeSubscriptions,
    isLoading: loadingActiveSubscriptions,
    isFetching: fetchingActiveSubscriptions,
  } = activeSubscriptionsQuery;

  return (
    <DashboardSection
      title={""}
      query={activeSubscriptionsQuery}
      loading={loadingActiveSubscriptions || fetchingActiveSubscriptions}
      loadingMessage={"Loading all customer subscriptions..."}
      order={3}
    >
      {(!activeSubscriptions || activeSubscriptions.length === 0) && (
        <div className="flex items-center justify-center py-20 text-lg">
          <span>No active subscriptions</span>
        </div>
      )}

      {activeSubscriptions && activeSubscriptions.length > 0 && (
        <Card>
          <SubscriptionsTable subscriptions={activeSubscriptions} />
        </Card>
      )}
    </DashboardSection>
  );
};

export default CustomerSubscriptions;
