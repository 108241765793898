import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ErrorCircle } from "@styled-icons/fluentui-system-filled";
import { ErrorMessage, useField } from "formik";
import { LexicalEditorProvider } from "../../context/LexicalEditorContext";
import { useAppDispatch } from "../../redux/hooks";
import { updateEditorHtml } from "../../redux/state/lexical/reducer";
import { EditorType } from "../../redux/state/lexical/types";
import { ImageNode } from "./nodes/ImageNode";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import HtmlPlugin from "./plugins/HtmlPlugin";
import ImagePlugin from "./plugins/ImagePlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndendLevelPlugin";
import ToolbarPlugin, { ToolbarOptions } from "./plugins/ToolbarPlugin";
import { DefaultTheme } from "./themes/Theme";

export function Placeholder() {
  return (
    <div className="pointer-events-none absolute left-[10px] top-[15px] inline-block select-none resize-none overflow-hidden overflow-ellipsis text-[15px] text-stone-600">
      Write your message here...
    </div>
  );
}

function onError(error: any) {
  console.error(error);
}

const initialConfig: InitialConfigType = {
  namespace: "TextEditor",
  theme: DefaultTheme,
  onError,
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
    ImageNode,
  ],
};

interface LexicalEditorProps {
  type: EditorType;
  fieldName: string; // the formik field name
  label: string;
  toolbarOptions?: ToolbarOptions;
}

function LexicalEditor({
  type,
  fieldName,
  label,
  toolbarOptions,
}: LexicalEditorProps) {
  const dispatch = useAppDispatch();
  const [field, meta, helpers] = useField(fieldName);
  const hasError = meta.touched && !!meta.error;
  // check if the field has an initial value and dispatch it to the store to hydrate the editor
  const initialFieldValue = field.value as string;
  dispatch(updateEditorHtml({ editor: type, html: initialFieldValue }));

  return (
    <LexicalEditorProvider type={type}>
      <LexicalComposer initialConfig={initialConfig}>
        <label
          htmlFor={fieldName}
          className="mb-2 mt-3 block text-sm text-gray-600 dark:text-gray-200"
        >
          {label}
        </label>
        <div
          className={`relative mx-auto !mt-2 rounded-lg border bg-stone-300 text-left leading-5 text-black ${
            hasError ? "!mb-0 border-red-600" : "!mb-5 border-stone-300"
          }`}
        >
          <ToolbarPlugin options={toolbarOptions} />
          <div className="relative rounded-br-lg rounded-bl-lg bg-white">
            <RichTextPlugin
              contentEditable={
                <ContentEditable className="relative min-h-[150px] py-[10px] px-[10px] caret-black focus:outline-none" />
              }
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ImagePlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <HtmlPlugin setValue={helpers.setValue} />
          </div>
          {hasError && (
            <div className="absolute inset-y-0 right-0 flex items-end pr-3 pb-3">
              <ErrorCircle className="icon h-4 w-4 text-red-700" />
            </div>
          )}
        </div>
        <div className="ml-1 !mt-1 text-sm text-red-700">
          <ErrorMessage name={fieldName} />
        </div>
      </LexicalComposer>
    </LexicalEditorProvider>
  );
}

export default LexicalEditor;
