import {
  CreditCardPerson,
  PersonLock,
  Receipt,
  SignOut,
} from "@styled-icons/fluentui-system-regular";
import { NavLink } from "react-router-dom";
import Dashboard, { DashboardElement } from "../../components/Dashboard";
import { AltLogo } from "../../components/Logo";
import { UserRole } from "../../constants/firestore";
import { useAuthentication } from "../../hooks/useAuthentication";
import { useFetchBillingSessionQuery } from "../../redux/api/stripe/api";

interface Props {}

const MyAccountPage = ({}: Props) => {
  const { currentUser } = useAuthentication();

  const uid = currentUser?.uid;

  const { data: billingSession, isLoading } = useFetchBillingSessionQuery(uid, {
    skip: !uid,
  });

  const MyAccountElements: DashboardElement[] = [
    {
      to: "/account/orders",
      title: "order history",
      icon: Receipt,
    },
    {
      to: "billing",
      title: "billing & subscriptions",
      icon: CreditCardPerson,
      action: () => {
        if (billingSession && !isLoading) {
          window.open(billingSession.url, "_blank");
        }
      },
    },
    {
      to: "/logout",
      title: "Logout",
      icon: SignOut,
    },
    {
      to: "/admin",
      title: "admin",
      icon: PersonLock,
      requiredRoles: [
        UserRole.Admin,
        UserRole.AdminReadonlyOfferings,
        UserRole.FulfillmentManager,
      ],
    },
  ];

  return (
    <div className="flex flex-col items-center justify-start space-y-4 bg-gray-100 pt-8">
      <div className="flex h-20 w-full items-center justify-center">
        <div className="h-full w-56 cursor-pointer border-r border-gray-200 p-4">
          <NavLink to="/">
            <AltLogo style={"color"} />
          </NavLink>
        </div>
        <div className="flex h-full w-fit items-center justify-start p-4 text-2xl font-light text-gray-600">
          Hello, {currentUser?.displayName?.split(" ")[0] ?? "there"}!
        </div>
      </div>

      <div className="w-full max-w-5xl">
        <Dashboard groups={MyAccountElements} />
      </div>
    </div>
  );
};

export default MyAccountPage;
