import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Offering } from "./types";

export interface OfferingsState {
  offerings: Offering[];
  startAfter: string | null;
}

export const initialState: OfferingsState = {
  offerings: [],
  startAfter: null,
};

const applicationSlice = createSlice({
  name: "offerings",
  initialState,
  reducers: {
    pushOfferings(state, action: PayloadAction<Offering[]>) {
      state.offerings.push(...action.payload);
    },
    unshiftOfferings(state, action: PayloadAction<Offering[]>) {
      state.offerings.unshift(...action.payload);
    },
    replaceOfferings(state, action: PayloadAction<Offering[]>) {
      action.payload.forEach((offering) => {
        const index = state.offerings.findIndex((o) => o.id === offering.id);
        if (index !== -1) {
          state.offerings[index] = offering;
        }
      });
    },
    deleteOffering(state, action: PayloadAction<string>) {
      const index = state.offerings.findIndex(
        (offering) => offering.id === action.payload
      );
      if (index !== -1) {
        state.offerings.splice(index, 1);
      }
    },
    setStartAfter(state, action: PayloadAction<string | null>) {
      state.startAfter = action.payload;
    },
    resetOfferings(state) {
      state.offerings = [];
      state.startAfter = null;
    },
  },
});

export const {
  pushOfferings,
  replaceOfferings,
  unshiftOfferings,
  setStartAfter,
  resetOfferings,
  deleteOffering,
} = applicationSlice.actions;

export default applicationSlice.reducer;
