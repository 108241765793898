import Chicken1 from "../assets/images/chicken-1.jpeg";
import Chicken2 from "../assets/images/chicken-2.jpeg";
import Chicken3 from "../assets/images/chicken-3.jpeg";
import Chicken4 from "../assets/images/chicken-4.jpeg";
import Chicken5 from "../assets/images/chicken-5.jpeg";
import Chicken6 from "../assets/images/chicken-6.jpeg";

import Berries1 from "../assets/images/berries-1.jpeg";
import Berries2 from "../assets/images/berries-2.jpeg";
import Berries3 from "../assets/images/berries-3.jpeg";

import Bees1 from "../assets/images/bees-1.jpeg";

import BlueBerries from "../assets/images/blue-berries.jpeg";
import Raspberries1 from "../assets/images/raspberries-1.jpeg";

import Apple1 from "../assets/images/apple-1.jpeg";
import Peach1 from "../assets/images/peach-1.jpeg";

import Peach2 from "../assets/images/peach-2.jpeg";

import Squash1 from "../assets/images/squash-1.jpeg";

import Strawberries1 from "../assets/images/strawberries-1.jpeg";
import Strawberries2 from "../assets/images/strawberries-2.jpeg";

import Butterfly1 from "../assets/images/butterfly-1.jpeg";

import Fern1 from "../assets/images/fern-1.jpeg";

import Pumpkins1 from "../assets/images/pumpkins-1.jpeg";

import Tomatoes1 from "../assets/images/tomatoes-1.jpeg";

import Duck1 from "../assets/images/duck-1.jpeg";
import Duck2 from "../assets/images/duck-2.jpeg";
import Duck3 from "../assets/images/duck-3.jpeg";
import Duck4 from "../assets/images/duck-4.jpeg";
import Duck5 from "../assets/images/duck-5.jpeg";
import Ducks1 from "../assets/images/ducks-1.jpeg";

import Lettuce1 from "../assets/images/lettuce-1.jpeg";

import Mushroom1 from "../assets/images/mushroom-1.jpeg";
import Mushroom2 from "../assets/images/mushroom-2.jpeg";
import Mushroom3 from "../assets/images/mushroom-3.jpeg";
import Mushroom4 from "../assets/images/mushroom-4.jpeg";
import Mushroom5 from "../assets/images/mushroom-5.jpeg";

import Forest1 from "../assets/images/forest-1.jpeg";

import Nature1 from "../assets/images/nature-1.jpeg";
import Nature2 from "../assets/images/nature-2.jpeg";
import Nature3 from "../assets/images/nature-3.jpeg";

import Eggs2 from "../assets/images/eggs-2.jpeg";
import Eggs3 from "../assets/images/eggs-3.jpeg";
import Eggs4 from "../assets/images/eggs-4.jpeg";
import Eggs5 from "../assets/images/eggs-5.jpeg";
import Eggs8 from "../assets/images/eggs-8.jpeg";
import Eggs9 from "../assets/images/eggs-9.jpeg";
import EggsInHand from "../assets/images/eggs-in-hand.jpeg";

import Shed1 from "../assets/images/shed-1.jpeg";
import Shed2 from "../assets/images/shed-2.jpeg";
import Shed3 from "../assets/images/shed-3.jpeg";
import Shed4 from "../assets/images/shed-4.jpeg";
import Shed5 from "../assets/images/shed-5.jpeg";

import StMichaelSnow from "../assets/images/michael-snow.jpeg";
import StMichael from "../assets/images/michael.jpeg";

import MotherMary from "../assets/images/mother-mary.jpeg";

import PrayerPageLanding from "../assets/images/prayer-page-landing.jpeg";

function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const SlideshowImages = [
  Shed5,
  Apple1,
  Duck1,
  Mushroom1,
  Chicken5,
  Fern1,
  Eggs4,
  Lettuce1,
  Ducks1,
  BlueBerries,
  Shed3,
  Peach1,
  Nature2,
  Mushroom2,
  Eggs2,
  Duck4,
  Pumpkins1,
  Chicken3,
  Mushroom4,
  Duck5,
  MotherMary,
  Chicken6,
  Duck2,
  Berries3,
  Chicken4,
  Shed1,
  Mushroom5,
  Duck3,
  Shed4,
  Nature3,
  Shed2,
  Nature1,
  Mushroom3,
  Eggs9,
];

export const AllImages = [
  Eggs2,
  Berries2,
  Chicken5,
  Shed3,
  Chicken6,
  Berries1,
  Nature2,
  Tomatoes1,
  Chicken2,
  Pumpkins1,
  Strawberries2,
  Peach1,
  Mushroom1,
  Shed4,
  Nature1,
  Mushroom2,
  Chicken3,
  Mushroom3,
  Eggs4,
  Nature3,
  Strawberries1,
  Eggs9,
  Lettuce1,
  Duck2,
  Eggs5,
  Apple1,
  Duck3,
  Shed5,
  Duck5,
  Eggs3,
  Duck4,
  Shed2,
  Eggs8,
  Chicken4,
  Duck1,
  Shed1,
  Peach2,
  Ducks1,
];

export const ShuffledImages = shuffle(AllImages);

export {
  Chicken1,
  Chicken2,
  Chicken3,
  Chicken4,
  Chicken5,
  Chicken6,
  Berries1,
  Berries2,
  Berries3,
  Bees1,
  BlueBerries,
  Raspberries1,
  Tomatoes1,
  Pumpkins1,
  Squash1,
  Strawberries1,
  Strawberries2,
  Peach1,
  Peach2,
  Apple1 as Peach3,
  Mushroom1,
  Mushroom2,
  Mushroom3,
  Lettuce1 as Mushroom4,
  Mushroom4 as Mushroom5,
  Mushroom5 as Mushroom6,
  Forest1,
  Nature1,
  Nature2,
  Nature3,
  Butterfly1,
  Ducks1,
  Duck1,
  Duck2,
  Duck3,
  Duck4,
  Duck5,
  Eggs2,
  Eggs3,
  Eggs4,
  Eggs5,
  Eggs8,
  Eggs9,
  EggsInHand,
  Shed1,
  Shed2,
  Shed3,
  Shed4,
  Shed5,
  StMichael,
  StMichaelSnow,
  MotherMary,
  PrayerPageLanding,
  Fern1,
};
