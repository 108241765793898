import { EditorThemeClasses } from "lexical";

export const DefaultTheme: EditorThemeClasses = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "placeholder-gray-400",
  paragraph:
    "relative m-0 mb-2 leading-8 text-gray-800 text-base whitespace-normal",
  quote: "border-l-4 border-gray-500 leading-8 italic my-4 pl-4 py-2",
  heading: {
    h1: "text-3xl text-black mb-3 leading-8 tracking-wider text-gray-800 font-bold",
    h2: "text-2xl text-gray-700 font-semibold mt-2 uppercase leading-8 text-gray-800",
    h3: "text-xl text-gray-600 font-semibold mt-2 leading-8 text-gray-800 italic",
  },
  list: {
    nested: {
      listitem: "list-none",
    },
    ol: "p-0 ml-4 list-decimal",
    ul: "p-0 ml-4 list-disc",
    listitem: "mb-4 mt-2 ml-12 mr-4 leading-8 text-gray-800",
  },
  image: "mx-auto my-4 p-2 border-2 border-gray-200",
  link: "text-blue-500 underline leading-8 hover:text-blue-600 text-base",
  text: {
    base: "whitespace-normal",
    bold: "font-bold block whitespace-normal",
    italic: "italic block",
    underline: "underline block",
    strikethrough: "line-through block",
    underlineStrikethrough: "underline line-through block",
    code: "bg-gray-200 px-1 rounded block",
  },
  code: "bg-gray-200 px-1 rounded",
  codeHighlight: {
    atrule: "text-green-400",
    attr: "text-blue-400",
    boolean: "text-purple-400",
    builtin: "text-blue-600",
    cdata: "text-gray-400",
    char: "text-yellow-500",
    class: "text-green-600",
    "class-name": "text-red-600",
    comment: "text-gray-400",
    constant: "text-purple-600",
    deleted: "text-red-400",
    doctype: "text-gray-400",
    entity: "text-purple-400",
    function: "text-blue-500",
    important: "font-bold",
    inserted: "text-green-400",
    keyword: "text-blue-500",
    namespace: "text-purple-400",
    number: "text-pink-400",
    operator: "text-blue-500",
    prolog: "text-gray-400",
    property: "text-blue-400",
    punctuation: "text-blue-500",
    regex: "text-pink-400",
    selector: "text-blue-600",
    string: "text-green-500",
    symbol: "text-purple-600",
    tag: "text-blue-500",
    url: "text-purple-400",
    variable: "text-red-500",
  },
};
