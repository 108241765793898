import { useEffect, useState } from "react";
import { useExtendedNavIsOpen } from "../redux/state/app/hooks";

export function usePageEffects() {
  const [isScrolled, setIsScrolled] = useState(false);

  const extendedNavIsOpen = useExtendedNavIsOpen();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(!extendedNavIsOpen);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [extendedNavIsOpen]);

  return { isScrolled };
}
