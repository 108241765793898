import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const firstPath = useRef(pathname.split("/")[1]);

  useEffect(() => {
    const currentFirstPath = pathname.split("/")[1];
    if (currentFirstPath !== firstPath.current) {
      firstPath.current = currentFirstPath;
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}
