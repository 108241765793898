import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { ArrowBack } from "@styled-icons/ionicons-solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import CheckoutForm from "../components/CheckoutForm";
import Loader from "../components/Loader";
import { AltLogo } from "../components/Logo";
import { useStripe as useStripeContext } from "../context/StripeContext";
import { GetSubscriptionDescription } from "../helpers/SubscriptionHelpers";
import { useRetrieveOfferingQuery } from "../redux/api/offerings/api";
import {
  OfferingOption,
  SubscriptionOption,
} from "../redux/api/offerings/types";

interface Props {
  mode: "payment" | "subscription";
}

const Checkout = ({ mode }: Props) => {
  const { stripe } = useStripeContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const offeringId = searchParams.get("offering");
  const optionId = searchParams.get("option");
  const priceId = searchParams.get("price");

  const {
    data: offering,
    isLoading: isLoadingOffering,
    isFetching: isFetchingOffering,
  } = useRetrieveOfferingQuery({
    id: offeringId ?? "",
  });

  const option = offering?.options.find((o) => o.id === optionId);

  if (!priceId || !optionId || !option) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <div className="flex w-fit flex-col">
          <Loader />
          <span className="text-lg">Loading...</span>
        </div>
      </div>
    );
  }

  let price = null;
  let subscriptionOption = null;
  if (mode === "payment") {
    price = option.priceId;
  } else if (mode === "subscription") {
    subscriptionOption = option.subscription?.options.find(
      (o) => o.priceId === priceId
    );
    price = subscriptionOption?.priceId;
  }

  if (
    (mode === "payment" && !price) ||
    (mode === "subscription" && !subscriptionOption)
  ) {
    navigate("/404");
  }

  const stripeElementsOptions: StripeElementsOptions = {
    mode: mode,
    amount: option.amount * 100,
    currency: "usd",
    loader: "always",
    capture_method: "automatic",
    paymentMethodCreation: "manual",
    appearance: {
      theme: "stripe",
      labels: "above",
    },
  };

  return (
    <div className="h-screen max-h-screen w-screen before:shadow-lg md:before:absolute md:before:right-0 md:before:h-full md:before:w-1/2 md:before:bg-white">
      <div className="relative my-16 flex max-h-screen w-full flex-col items-center md:absolute md:left-1/2 md:top-1/2 md:m-0 md:max-w-4xl md:-translate-x-1/2 md:-translate-y-1/2 md:flex-row md:justify-between">
        <div className="flex max-h-screen w-96 flex-col space-y-8">
          <button
            type="button"
            className="group relative min-h-[50px] w-52 cursor-pointer self-center md:self-start"
            onClick={() => {
              navigate(-1);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <div className="transition-all duration-100 group-hover:hidden group-hover:opacity-0">
              <AltLogo style={"color"} />
            </div>

            <div className="hidden opacity-0 transition-all duration-300 group-hover:flex group-hover:opacity-100">
              <span className="text-lg font-bold">Back</span>
            </div>

            <ArrowBack className="absolute -left-8 top-1/2 w-6 -translate-y-1/2 text-gray-400 transition-all group-hover:-translate-x-3 group-hover:text-gray-500" />
          </button>

          {mode === "payment" && price && (
            <PaymentDescription option={option} />
          )}

          {mode === "subscription" && subscriptionOption && (
            <div className="">
              <SubscriptionDescription
                option={option}
                subscriptionOption={subscriptionOption}
              />
            </div>
          )}
        </div>

        {/* Checkout Form */}

        <div className="hide-scroll w-96 py-16 md:p-0">
          <Elements stripe={stripe} options={stripeElementsOptions}>
            <CheckoutForm mode={mode} priceId={priceId} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

interface PaymentDescriptionProps {
  option: OfferingOption;
}

const PaymentDescription = ({ option }: PaymentDescriptionProps) => {
  const total = option.amount;

  return (
    <div className="">
      <div className="space-y-2">
        <h1 className="text-gray-500">Pay Maranatha Farm</h1>
        <p className="text-4xl text-gray-900">${total.toFixed(2)}</p>
      </div>

      <div className="">
        <div className="flow-root">
          <ul className="-my-4 divide-y divide-gray-100">
            <li className="flex items-center gap-4 py-4">
              <img
                src={option.image}
                alt=""
                className="aspect-square h-24 w-24 rounded object-cover"
              />
              <div className="flex w-full">
                <div className="flex flex-auto flex-col">
                  <h3 className="text-lg text-gray-900">{option.fullName}</h3>
                  <span className="inline">{option.name}</span>
                </div>
                <div className="mt-0.5 flex flex-auto justify-end space-y-px text-gray-600">
                  <span className="inline text-lg">{option.prettyPrice}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

interface SubscriptionDescriptionProps {
  option: OfferingOption;
  subscriptionOption: SubscriptionOption;
}

const SubscriptionDescription = ({
  option,
  subscriptionOption,
}: SubscriptionDescriptionProps) => {
  const intervalDescription = GetSubscriptionDescription(
    subscriptionOption.interval,
    subscriptionOption.intervalCount
  );

  return (
    <div className="">
      {/* Desktop Size */}
      <div className="hidden md:block">
        <div className="flex flex-col items-start gap-4">
          <div className="space-y-2">
            <h1 className="text-xl text-gray-500">
              Subscribe to {option.fullName}
            </h1>

            <p className="space-x-2">
              <span className="text-4xl text-gray-900">
                ${subscriptionOption.amount.toFixed(2)}
              </span>

              <span className="text-xl text-gray-500">
                {intervalDescription}
              </span>
            </p>
          </div>

          <img
            src={option.image}
            alt={option.fullName}
            className="h-full w-full rounded object-cover"
          />

          {/* <div className="">
            <p className="whitespace-pre-wrap text-sm text-gray-600">
              {option.description}
            </p>
          </div> */}
        </div>

        <p className="mt-4 text-sm text-gray-500">
          * Subscriptions are charged on Wednesday mornings. Cancel your
          subscription at any time by vistiting your account page.
        </p>
      </div>

      {/* Mobile Size */}
      <div className="flex w-full flex-col items-center gap-2 md:hidden">
        <img
          src={option.image}
          alt={option.fullName}
          className="w-24 rounded object-cover"
        />

        <p className="text-lg text-gray-500">{option.fullName}</p>

        <p className="space-x-2">
          <span className="text-3xl text-gray-900">
            ${subscriptionOption.amount.toFixed(2)}
          </span>

          <span className="text-xl text-gray-600">{intervalDescription}</span>
        </p>

        {/* <p className="max-w-xs whitespace-pre-wrap text-center text-sm tracking-wide text-gray-500">
          {option.description}
        </p> */}
      </div>
    </div>
  );
};

export default Checkout;
