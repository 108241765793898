import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CheckoutMode } from "./types";

export interface CheckoutState {
  mode?: "payment" | "subscription";
}

export const initialState: CheckoutState = {
  mode: undefined,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setCheckoutMode(state, action: PayloadAction<CheckoutMode>) {
      state.mode = action.payload;
    },
  },
});

export const { setCheckoutMode } = checkoutSlice.actions;

export default checkoutSlice.reducer;
