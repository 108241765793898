import { Warning } from "@styled-icons/fluentui-system-filled";
import { useState } from "react";
import ModalContainer from ".";
import { useOpenModal, usePopModal } from "../../redux/state/modals/hooks";
import {
  DangerModal as DangerModalProps,
  ModalType,
} from "../../redux/state/modals/types";
import Spinner from "../Spinner";

const DangerModal = (props: DangerModalProps) => {
  const { id, title, message, onClose, onConfirm } = props;

  const popModal = usePopModal();

  const [isConfirming, setIsConfirming] = useState(false);

  const openDangerModal = useOpenModal(ModalType.DANGER);

  async function handleConfirm() {
    if (onConfirm) {
      setIsConfirming(true);
      await onConfirm();
      setIsConfirming(false);
    }
    popModal();
  }

  return openDangerModal && openDangerModal.id === id ? (
    <ModalContainer
      index={openDangerModal.index}
      type={ModalType.DANGER}
      {...props}
      onClose={onClose}
    >
      <div className="max-w-sm space-y-6">
        <div className="flex items-center space-x-2">
          <Warning className="h-8 text-amber-600" />
          <h1 className="w-full pr-6 text-2xl font-bold">{title}</h1>
        </div>

        {message && <p className="text-gray-800">{message}</p>}

        <div className="flex space-x-4">
          <button
            type="button"
            className="rounded-md border border-gray-800 bg-white px-4 py-2 text-gray-800 hover:bg-gray-50"
            onClick={async () => {
              if (onClose) {
                await onClose();
              }
              popModal();
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className="flex space-x-2 rounded-md bg-red-600 px-4 py-2 text-white hover:bg-red-700"
            onClick={handleConfirm}
          >
            {isConfirming && (
              <>
                <Spinner />
              </>
            )}
            <span>Confirm</span>
          </button>
        </div>
      </div>
    </ModalContainer>
  ) : (
    <></>
  );
};

export default DangerModal;
