import { Cancel, Download, ExternalLink } from "@styled-icons/fa-solid";
import Stripe from "stripe";
import Dropdown, { DropdownItem } from "../../../../../components/Dropdown";
import DangerModal from "../../../../../components/Modals/DangerModal";
import { useDeleteSubscriptionMutation } from "../../../../../redux/api/stripe/api";
import { useAppDispatch } from "../../../../../redux/hooks";
import { pushModal } from "../../../../../redux/state/modals/reducer";
import { ModalType } from "../../../../../redux/state/modals/types";

interface Props {
  subscription: Stripe.Subscription;
}

const SubscriptionActions = ({ subscription }: Props) => {
  const dispatch = useAppDispatch();

  // Redux APIs
  const [triggerDeleteSubscription] = useDeleteSubscriptionMutation();

  const latestInvoice = subscription.latest_invoice as Stripe.Invoice;

  let cancelDate: Date | null = null;

  if (subscription.cancel_at) {
    cancelDate = new Date(subscription.cancel_at * 1000);
  }

  async function CancelSubscription() {
    console.log(
      "Deleting Subscription interval",
      subscription.items.data[0].plan.interval,
      subscription.items.data[0].plan.interval_count
    );
    await triggerDeleteSubscription({
      subscriptionId: subscription.id,
    });
  }

  const dropdownElements: DropdownItem[] = [
    {
      title: (
        <span className="flex w-full items-center justify-start gap-2">
          <ExternalLink className="w-3" />
          <span>View in Stripe</span>
        </span>
      ),
      action: () => {
        const url = `https://dashboard.stripe.com/subscriptions/${subscription.id}`;
        window.open(url, "_blank");
      },
    },
  ];

  if (latestInvoice) {
    dropdownElements.unshift({
      title: (
        <span className="flex w-full items-center justify-start gap-2">
          <Download className="w-3" />
          <span>Download Invoice</span>
        </span>
      ),
      action: () => {
        var a = document.createElement("a");
        a.href = latestInvoice.invoice_pdf as string;
        a.download = `${latestInvoice.number}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
    });
  }

  if (!cancelDate) {
    dropdownElements.unshift({
      title: (
        <span className="flex w-full items-center justify-start gap-2">
          <Cancel className="w-3" />
          <span>Cancel</span>
        </span>
      ),
      isDangerous: true,
      action: async () => {
        console.log(subscription.id);
        dispatch(
          pushModal({
            type: ModalType.DANGER,
            id: `${subscription.id}-delete`,
          })
        );
      },
    });
  }

  return (
    <div className="absolute top-1/2 z-auto h-full w-full -translate-x-1/2 -translate-y-1/2 transform">
      <Dropdown title="Actions" items={dropdownElements} />
      <DangerModal
        id={`${subscription.id}-delete`}
        title="Cancel Subscription?"
        message="This subscription will be permanently deleted. This action cannot be undone."
        onConfirm={CancelSubscription}
      />
    </div>
  );
};

export default SubscriptionActions;
