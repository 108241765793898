import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl, prepareDefaultHeaders } from "..";
import { Cart, UpdateCartRequest } from "./types";

const cartApi = createApi({
  reducerPath: "cartApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiBaseUrl}/cart`,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ["Cart"],
  endpoints: (builder) => ({
    fetchCart: builder.query({
      query: () => ({
        url: `/`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) =>
        (response as any).data.data as Cart,
      providesTags: ["Cart"],
    }),

    updateCart: builder.mutation({
      query: ({ ...body }: UpdateCartRequest) => ({
        url: `/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Cart"],
    }),

    deleteCartOption: builder.mutation({
      query: ({
        offeringId,
        optionId,
      }: {
        offeringId: string;
        optionId: string;
      }) => ({
        url: `/${offeringId}/${optionId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const {
  useFetchCartQuery,
  useUpdateCartMutation,
  useDeleteCartOptionMutation,
} = cartApi;

export default cartApi;
