import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { StripeProvider } from "./context/StripeContext";
import "./index.css";
import App from "./pages/App";
import store from "./redux";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <HelmetProvider>
    {/* <StrictMode> */}
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <StripeProvider>
          <App />
        </StripeProvider>
      </BrowserRouter>
    </Provider>
    {/* </StrictMode> */}
  </HelmetProvider>
);

if (process.env.REACT_APP_SERVICE_WORKER !== "false") {
  serviceWorkerRegistration.register();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
