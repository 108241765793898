import {
  Delete,
  Edit,
  Grid,
  TextBulletListLtr,
} from "@styled-icons/fluentui-system-filled";
import { Search } from "@styled-icons/fluentui-system-regular";
import { Field, Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { useOpenModal } from "../../redux/state/modals/hooks";
import { pushModal } from "../../redux/state/modals/reducer";
import { ModalType } from "../../redux/state/modals/types";
import Dropdown, { DropdownItem } from "../Dropdown";
import Loader from "../Loader";
import ModalContainer from "../Modals";
import DangerModal from "../Modals/DangerModal";
import CrudTableFilter, { FilterConfig } from "./filter";

export enum ViewType {
  List,
  Grid,
}

export type RowData = {
  id: string;
  displayName: string;
  imageUrl: string;
  altImageUrl?: string;
  data: JSX.Element;
  actions?: DropdownItem[];
  searchIndices?: string[]; // a list of strings that can be used to search for this row
};

interface CrudTableProps {
  rows: RowData[];
  modalType: ModalType;
  crudFormFactory: (id: string | null) => JSX.Element; // a function that will return a form for the given id
  deleteAction: (id: string) => void | Promise<void>;
  imageMode?: "cover" | "scale-down";
  filterConfig?: FilterConfig;
  navigation?: boolean;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}

const CrudTable = ({
  rows,
  modalType,
  crudFormFactory,
  deleteAction,
  imageMode = "scale-down",
  filterConfig,
  navigation = true,
  setSearchQuery,
  loading,
}: CrudTableProps) => {
  const [viewType, setViewType] = useState<ViewType>(ViewType.List);
  const [currPage, setCurrPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [selectedEntry, setSelectedEntry] = useState<string | null>(null);

  const dispatch = useAppDispatch();
  const modal = useOpenModal(modalType);

  const tableRef = useRef<HTMLDivElement>(null);

  const numRows = rows.length;
  const maxPages = Math.ceil(numRows / itemsPerPage);
  const sliceStart = (currPage - 1) * itemsPerPage;
  const sliceEnd = Math.min(numRows, currPage * itemsPerPage);

  function handleChangePage(page: number) {
    if (page < 1 || page > maxPages) {
      return;
    }
    setCurrPage(page);
  }

  const handleSubmit = (values: { search: string }) => {
    setSearchQuery(values.search);
  };

  let displayRows = rows.map((row, i) => (
    <CrudEntry
      id={row.id}
      key={row.id}
      displayName={row.displayName}
      imageUrl={row.imageUrl}
      altImageUrl={row.altImageUrl}
      data={row.data}
      actions={row.actions}
      viewType={viewType}
      deleteAction={deleteAction}
      setSelectedEntry={setSelectedEntry}
      modalType={modalType}
      imageMode={imageMode}
    />
  ));

  if (navigation) {
    displayRows = displayRows.slice(sliceStart, sliceEnd);
  }

  return (
    <div className="w-full rounded-md bg-white" ref={tableRef}>
      {/* Table Header */}

      <div className="flex w-full flex-col gap-2 p-0 sm:flex-row sm:items-center">
        <div className="flex flex-1">
          <Formik initialValues={{ search: "" }} onSubmit={handleSubmit}>
            {() => (
              <Form className="relative flex w-full items-center justify-start">
                <Field
                  type="text"
                  id="search"
                  name="search"
                  className="w-full rounded-md border border-gray-400 py-2 pl-8 pr-2 text-sm text-gray-900 placeholder-gray-500 focus:border-gray-500 focus:outline-none focus:ring-1 focus:ring-gray-500"
                  placeholder="Search"
                />
                <button
                  type="submit"
                  className="absolute inset-y-0 left-2 inline-flex items-center"
                >
                  <Search className="h-5 w-5" />
                </button>
              </Form>
            )}
          </Formik>
        </div>

        <div className="flex flex-1 items-center">
          <div className="relative flex flex-1 items-center gap-2 sm:justify-end">
            {/* Create Button */}
            <button
              className="flex h-[36px] items-center justify-center space-x-1 rounded-md bg-teal-700 px-4 font-bold text-white hover:bg-teal-600"
              onClick={() => {
                setSelectedEntry(null);
                dispatch(
                  pushModal({
                    id: "new-crud-entry",
                    type: modalType,
                    hasMask: true,
                    dismissible: true,
                  })
                );
              }}
            >
              <span>Create</span>
            </button>

            {filterConfig && <CrudTableFilter filterConfig={filterConfig} />}
          </div>

          {modal && (
            <ModalContainer {...modal}>
              {crudFormFactory(selectedEntry)}
            </ModalContainer>
          )}

          <div className="flex-0 ml-2 flex items-center gap-0.5">
            <div
              className={`flex cursor-pointer items-center rounded-md p-2 transition-colors hover:bg-gray-200 ${
                viewType === ViewType.List
                  ? "bg-gray-200 text-gray-700"
                  : "text-gray-400"
              }`}
              onClick={() => setViewType(ViewType.List)}
            >
              <TextBulletListLtr className="h-5 w-5" />
            </div>
            <div
              className={`flex cursor-pointer items-center rounded-md p-2 transition-colors hover:bg-gray-200 ${
                viewType === ViewType.Grid
                  ? "bg-gray-200 text-gray-700"
                  : "text-gray-400"
              }`}
              onClick={() => setViewType(ViewType.Grid)}
            >
              <Grid className="h-5 w-5" />
            </div>
          </div>
        </div>
      </div>

      {/* Table Rows */}

      {displayRows.length > 0 ? (
        <div
          className={`py-8 ${
            viewType === ViewType.List
              ? "flex flex-col gap-2"
              : "grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4"
          }`}
        >
          {displayRows}
        </div>
      ) : loading ? (
        <div className="my-16 flex w-full flex-col items-center justify-center">
          <Loader />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="my-16 flex w-full items-center justify-center">
          <p className="font-bold">No results found</p>
        </div>
      )}

      {selectedEntry && (
        <DangerModal
          id={`${selectedEntry}-delete`}
          title={`Confirm Delete`}
          message="Are you sure you want to delete this item? This action cannot be undone."
          onConfirm={async () => await deleteAction(selectedEntry)}
          onClose={() => setSelectedEntry(null)}
          hasMask={true}
        />
      )}

      {/* Table Footer */}

      {navigation && rows.length > 0 && (
        <div
          className={`border-b-zinc-20 relative flex h-fit w-full flex-col items-center justify-center gap-4 rounded-bl-md rounded-br-md p-2 text-xs font-bold text-zinc-500 sm:flex-row`}
        >
          <div className="flex flex-col items-center space-y-2">
            <span className="mx-4 text-xs text-gray-700">
              Showing <b className="text-gray-500">{sliceStart + 1}</b> to{" "}
              <b className="text-gray-500">{sliceEnd}</b> of{" "}
              <b className="text-gray-500">{numRows}</b> entries
            </span>

            <ul className=" flex w-fit cursor-pointer list-none divide-x divide-gray-700 rounded-lg border border-gray-700">
              <button
                className="rounded-tl-lg rounded-bl-lg py-2 px-4 transition-colors hover:bg-teal-500 hover:text-white"
                onClick={() => handleChangePage(currPage - 1)}
              >
                <a href="#">Prev</a>
              </button>
              <li
                className="rounded-tr-lg rounded-br-lg py-2 px-4 transition-colors hover:bg-teal-500 hover:text-white"
                onClick={() => handleChangePage(currPage + 1)}
              >
                <a href="#">Next</a>
              </li>
            </ul>
          </div>

          <div className="bottom-4 right-0 mx-2 break-all sm:absolute">
            <label htmlFor="items-per-page">Items per page:</label>

            <select
              className="select ml-1 mr-2 bg-transparent"
              name="items-per-page"
              id="items-per-page"
              onChange={(e) => {
                setItemsPerPage(Number(e.currentTarget.value));
                setCurrPage(1);
              }}
              defaultValue={10}
            >
              <option>10</option>
              <option>25</option>
              <option>50</option>
              <option>100</option>
              <option>500</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

interface CrudEntryProps {
  id: string; // a unique identifier for the row
  displayName: string;
  imageUrl: string;
  altImageUrl?: string;
  data: JSX.Element;
  actions?: DropdownItem[];
  viewType: ViewType;
  deleteAction?: (id: string) => void | Promise<void>;
  setSelectedEntry: React.Dispatch<React.SetStateAction<string | null>>;
  modalType: ModalType;
  imageMode?: "cover" | "scale-down";
}

/**
 * The individual crud entry to be displayed within the table
 * @param CrudEntryProps
 * @returns
 */
const CrudEntry = ({
  id,
  displayName,
  imageUrl,
  altImageUrl,
  data,
  actions,
  viewType,
  setSelectedEntry,
  modalType,
  imageMode = "scale-down",
}: CrudEntryProps) => {
  const dispatch = useAppDispatch();
  const dropdownItems: DropdownItem[] = [];

  if (actions) {
    dropdownItems.push(...actions);
  }

  dropdownItems.push(
    ...[
      {
        title: (
          <span className="flex w-full items-center justify-start space-x-4">
            <Edit className="w-4" />
            <span className="text-base font-semibold">Edit</span>
          </span>
        ),
        action: async () => {
          setSelectedEntry(id);
          dispatch(
            pushModal({
              id: id,
              type: modalType,
              hasMask: true,
              dismissible: true,
            })
          );
        },
      },
      {
        title: (
          <span className="flex w-full items-center justify-start space-x-4">
            <Delete className="w-4" />
            <span className="text-base font-semibold">Delete</span>
          </span>
        ),
        isDangerous: true,
        action: async () => {
          setSelectedEntry(id);
          dispatch(
            pushModal({
              type: ModalType.DANGER,
              id: `${id}-delete`,
            })
          );
        },
      },
    ]
  );

  const listImgStyle =
    imageMode === "cover" ? "object-cover" : "object-scale-down p-2";
  const gridImgStyle =
    imageMode === "cover" ? "bg-cover bg-center bg-no-repeat" : "bg-contain";

  const element =
    viewType === ViewType.List ? (
      <div
        key={id}
        className={`relative grid h-fit cursor-pointer grid-cols-12 rounded-md border bg-white shadow hover:bg-gray-50`}
      >
        {/* Row Image */}
        <div className="col-span-12 col-start-1 sm:col-span-3">
          <img
            src={imageUrl}
            alt={altImageUrl}
            className={`h-full w-full rounded-tl-md rounded-tr-md ${listImgStyle} mix-blend-multiply sm:aspect-square sm:rounded-bl-md sm:rounded-tr-none sm:rounded-br-none`}
          />
        </div>

        <div className="col-start-1 col-end-12 sm:col-start-4">{data}</div>

        <button
          className="absolute top-2 right-2"
          onClick={() => setSelectedEntry(id)}
        >
          <Dropdown title="Options" items={dropdownItems} />
        </button>
      </div>
    ) : (
      <div
        key={id}
        className="relative grid h-fit grid-cols-1 rounded-md border bg-white shadow-sm transition-colors duration-300 hover:bg-gray-50"
      >
        <div
          className={`aspect-square cursor-pointer rounded-md bg-cover bg-center bg-no-repeat transition-colors duration-300 ${gridImgStyle}`}
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <div className="group relative h-full w-full rounded-md bg-transparent transition-colors duration-300 hover:bg-gradient-to-b hover:from-transparent hover:to-black">
            <p className="absolute bottom-0 left-0 w-full overflow-hidden text-ellipsis pl-2 pb-1 text-lg font-bold leading-tight text-transparent transition-colors duration-500 group-hover:text-white">
              {displayName}
            </p>
          </div>
          <div className="absolute top-1 right-1 flex w-full justify-end">
            <Dropdown title="Options" items={dropdownItems} />
          </div>
        </div>
      </div>
    );

  return element;
};

export default CrudTable;
