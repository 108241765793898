import * as Yup from "yup";
import { ValidEmail } from "../../../constants/regex";
import { SEARCH_INPUT } from "./fieldNames";

const orderIdRegex = /^[0-9]*$/;

const SearchOrdersValidationSchema = Yup.object().shape({
  [SEARCH_INPUT]: Yup.string()
    .matches(
      new RegExp(`${ValidEmail.source}|${orderIdRegex.source}`),
      "Invalid search input"
    )
    .required(""),
});

export default SearchOrdersValidationSchema;
