import { Order } from "../redux/api/orders/types";

export const OrderDateCreated = (order: Order, format: string) => {
  const date = new Date(order.created._seconds * 1000);

  let dateString = null;

  switch (format) {
    default:
    case "short":
      dateString = date.toLocaleDateString("en-us", {
        year: "2-digit",
        day: "2-digit",
        month: "2-digit",
      });
      break;
    case "long":
      dateString = date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        hour: "numeric",
        year: "numeric",
        minute: "2-digit",
      });
      break;
  }

  return dateString;
};
