export interface ModalsState {
  openModals: Record<ModalType, Modal[]>;
  modalOrder: ModalType[];
}

export enum ModalType {
  LOGIN = "login-modal",
  CART = "cart-modal",
  DANGER = "danger-modal",
  OFFERING_CRUD = "offering-crud-modal",
  MAKER_CRUD = "maker-crud-modal",
  NEWSLETTER_SIGNUP = "newsletter-signup-modal",
  ORDER_DETAILS = "order-details-modal",
  IMAGE_UPLOAD = "image-upload-modal",
  PREVIEW_NEWSLETTER = "preview-newsletter-modal",
}

export interface Modal {
  type: ModalType;
  id: string;
  hasMask?: boolean;
  scrollable?: boolean;
  dismissible?: boolean;
}

export interface OpenModal extends Modal {
  index: number;
}

export interface ActionModal extends Omit<Modal, "type"> {
  onClose?: (...args: any[]) => any;
  onConfirm?: (...args: any[]) => any;
}

export interface DangerModal extends ActionModal {
  title: string;
  message: string;
}
