import ScaleLoader from "react-spinners/ScaleLoader";

interface LoaderProps {
  width?: number;
  height?: number;
  margin?: string;
}

const Loader = ({ width, height, margin }: LoaderProps) => {
  const style = {
    ...(width && { width: `${width}px` }),
    ...(height && { height: `${height}px` }),
    ...(margin && { margin: `${margin}` }),
  };

  return (
    <div className="m-auto flex flex-col items-center p-4" style={style}>
      <ScaleLoader className="text-slate-800"></ScaleLoader>
    </div>
  );
};

export default Loader;
